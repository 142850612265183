import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import "./DuonutNameModal.css";
import { toggleChangeDuonutNameModal, updateDuonutNameToReplace, updateDuonutDefaultName, updateDuonutNavigateUrl } from "../../../redux/slices/DuonutNameReducer";
import { Link, useNavigate } from "react-router-dom";

export default function DuonutNameModal() {
    const dispatch = useDispatch();
    const { active: changeDuonutNameModalActive, defaultdname, navigateurl } = useSelector((state) => state.DuonutName.changeDuonutNameModal);
    const navigate = useNavigate();
    const [duonutName, setduonutName] = useState("");
    // console.log("defaultdname", defaultdname, navigateurl)

    useEffect(() => {
        if (defaultdname) {
            setduonutName(defaultdname);
        } else {
            setduonutName("Untitled");
        }
        var copyText = document.getElementById("duonutname");
        if (copyText) {
            copyText.select();
        }
    }, [changeDuonutNameModalActive]);

    const handleClose = () => {
        dispatch(toggleChangeDuonutNameModal(false));
        dispatch(updateDuonutDefaultName(""));
        dispatch(updateDuonutNavigateUrl(""));
        dispatch(updateDuonutNameToReplace(""));
    };

    const handleCreate = () => {
        dispatch(toggleChangeDuonutNameModal(false));
        dispatch(updateDuonutNameToReplace(duonutName));

        setduonutName("");
        if (navigateurl) {
            navigate(navigateurl);
        } else {
            navigate("/design");
        }
        dispatch(updateDuonutDefaultName(""));
        dispatch(updateDuonutNavigateUrl(""));
    };

    return (
        <AnimatePresence>
            {changeDuonutNameModalActive && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
                    className="modal_container confirm_replace_modal "
                    onClick={handleClose}
                >
                    <motion.div
                        initial={{ scale: 0.7 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.7 }}
                        transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
                        className="modal_basic"
                        style={{ width: "340px", paddingTop: "1rem" }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div>
                            <div className="modal_header">Create New Form</div>
                            <div><b>Duonut Name</b></div>
                            <input type="text" id="duonutname"
                                style={{
                                    margin: "1rem auto", width: "100%", border: "1px solid black", fontSize: "1rem",
                                    borderRadius: "6px", height: "2rem", padding: "5px", background: "white"
                                }}
                                value={duonutName === null ? "" : duonutName}
                                onChange={(e) => (e.target.value.length > 50 ? null : setduonutName(e.target.value))} />

                            <br></br>
                            <br></br>
                            <div className="action-buttons">
                                <button className="btn btn-primary cancel-btn" onClick={handleClose}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary" onClick={() => handleCreate()}>
                                    Create
                                </button>
                            </div>
                        </div>
                        <div className="close-icon icon-button" onClick={handleClose}>
                            <img src="/assets/icons/close.svg" alt="" />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
