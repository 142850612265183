import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("token", token);
  return header;
};


export const aiDuonutSlide = createSlice({
  name: "aiDuonut",
  initialState: {
    response: [],
    data: []
  },
  reducers: {
    saveAiDuonutAns: (state, action) => {
      state.response = [action.payload];
    },
    saveAiDuonutData: (state, action) => {
      state.data = [action.payload];
    }
  }
});

export const getAiDuonutAsync = (paramsid) => async (dispatch) => {
  try {
    const display_id = paramsid.id;
    // console.log(display_id)
    // const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/aiviewer/` + display_id;
    const response = await axios.get(API_URL);
    // console.log(response.data);
    dispatch(saveAiDuonutData(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createAiDuonutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_ai_duonut`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editAiDuonutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_ai_duonut`;
    const response = await axios.patch(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};


export const deleteAiDuonutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_ai_duonut`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveAiAnsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/save_ai_answer_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    // dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAiAnsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/get_ai_answer_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveAiDuonutAns(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};


export const { saveAiDuonutAns, saveAiDuonutData } = aiDuonutSlide.actions;
export const showAiDuonutresponse = (state) => state.aiDuonut.response;
export const showAiDuonutData = (state) => state.aiDuonut.data;
export default aiDuonutSlide.reducer;
