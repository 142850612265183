import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./duonut.css"
import { useDispatch } from 'react-redux';
import ImageSlider from '../../Components/Duonut/imageSlider';
import useOutsideAlerter from "../../hooks/useOutsideAlerter"
import { getNumberSuffix } from '../../utils';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import VerticalBars from '../../assets/icons/myduonut/vertical-bars.svg'
import { selectUserActivity } from '../../redux/slices/userActivity';
import ShareModal from '../../Components/Modals/ModalBox/ShareModal';
import EmbedModal from '../../Components/Modals/ModalBox/EmbedModal';
// import QrCodeModal from '../../Components/Modals/ModalBox/QrCodeModal';
import NuggetsModal from '../../Components/Modals/ModalBox/NuggetsModal';
import { updateDeleteDuonutConfirmModal } from "../../redux/slices/formDetails";
import { frontendUrl } from "../../services/constants";

var shareData = {};

const Duonut = ({ name, display_id, total_views, setting, user_activity, likes, qrcode, duonut_category_id, created_at, id: duonutId, thumbnail }) => {
   const [showOptions, setShowOptions] = useState(false)
   const [popUpPositionX, setPopUpPositionX] = useState(null)
   const [popUpPositionY, setPopUpPositionY] = useState(null)

   const date = new Date(created_at)
   const { width, height } = useWindowDimensions()
   const duonutthumbnail_set = user_activity.length > 0 ? user_activity.map(item => item?.thumbnail) : [];
   const [currentIndex, setCurrentIndex] = useState(0)
   const [previousIndex, setPreviousIndex] = useState(0)
   const [isPlaying, setIsPlaying] = useState(false)
   const [showShareModal, setshowShareModal] = useState(false)
   const [showEmbedModal, setshowEmbedModal] = useState(false)
   const [showQrCode, setshowQrCode] = useState(false)

   const dispatch = useDispatch()
   const navigate = useNavigate()
   const location = useLocation()

   var frontend_url = "";
   var subDomain = localStorage.getItem("subDomain");
   if (subDomain) {
      frontend_url = `https://${subDomain}.duonut.com`
   } else {
      frontend_url = frontendUrl;
   }


   const popUpHandler = e => {
      setshowShareModal(false);
      setshowEmbedModal(false);
      setshowQrCode(false);
      setShowOptions(!showOptions)
      let pos = menuIconRef.current.getBoundingClientRect()
      let offsetLeft = menuIconRef.current.offsetLeft

      setPopUpPositionX(pos.left + 16)
      //if exceeds window width
      if (pos.left + 13 + 250 > width) {
         setPopUpPositionX(pos.left - 230)
      }
      setPopUpPositionY(menuIconRef.current.offsetTop - 10)


      e.stopPropagation();
   }

   // to close duonut options menu
   const optionsRef = useRef(null)
   const menuIconRef = useRef(null)
   const handleClose = () => setShowOptions(false)
   const handleShareModal = () => { setshowShareModal(false); setshowEmbedModal(false); setshowQrCode(false); }
   useOutsideAlerter(optionsRef, handleClose, handleShareModal);

   let timeInterval = useRef(null)

   //change thumbnails
   let intervalId;

   const changeThumbnails = (playing) => setIsPlaying(true)

   useEffect(() => {
      if (isPlaying === false) return
      intervalId = setInterval(() => {
         // console.log(currentIndex)
         if (currentIndex === duonutthumbnail_set.length - 1) {
            setCurrentIndex(0)
         }
         else {
            setCurrentIndex(prevIndex => prevIndex + 1)
         }
      }, 2000)
      return () => clearInterval(intervalId)
   }, [isPlaying, currentIndex]);

   const resetThumbnails = () => {
      clearInterval(intervalId)
      setIsPlaying(false)
      setCurrentIndex(0)
   }


   const handleView = (e) => {
      window.open(`/duonut/${display_id}`)
      e.stopPropagation();
   }

   const handleShare = (e) => {
      shareData = {
         display_id: display_id,
         duonut_name: name,
         password: "",
         message: ""
      }
      setshowShareModal(true);
      e.stopPropagation();
   }

   const handleEmbedCode = (e) => {
      shareData = {
         display_id: display_id,
         duonut_name: name,
         password: "",
         message: ""
      }
      setshowEmbedModal(true);
      e.stopPropagation();
   }

   const handleQrCode = (e) => {
      shareData = {
         display_id: display_id,
         duonut_name: name,
         password: "",
         message: "",
         qrcode: qrcode
      }
      setshowQrCode(true);
      e.stopPropagation();
   }

   useEffect(() => {
      if (duonutthumbnail_set.length === 1) return
      if (isPlaying === false) return clearInterval(timeInterval.current)

      if (currentIndex === 0) {
         setPreviousIndex(duonutthumbnail_set.length - 1)
      } else {
         setPreviousIndex(currentIndex - 1);
      }
      return () => clearInterval(timeInterval.current);
   }, [currentIndex]);


   return (
      <div className='duonut' style={{ cursor: "auto" }} onMouseEnter={() => changeThumbnails(true)} onMouseLeave={resetThumbnails} >
         {showShareModal && <ShareModal shareData={shareData} bgcolor={"#3184C9"} />}
         {showEmbedModal && <EmbedModal shareData={shareData} bgcolor={"#3184C9"} />}
         {/* {showQrCode && <QrCodeModal shareData={shareData} bgcolor={"#3184C9"} />} */}
         <div className="top">
            <div className="left">
               {/* <div className='duonutCategotyButton'><div style={{ width: "100%", textAlign: "center" }}> {duonut_category_id.name} </div></div> */}

            </div>
            <div className="right">
               <div style={{ display: 'flex', alignItems: 'center' }} onClick={e => popUpHandler(e)} ref={menuIconRef} >
                  {/* {isPlaying &&  */}
                  <img src={VerticalBars}
                     style={{ cursor: 'pointer', padding: '2px 6px', paddingLeft: '8px' }}
                  />
                  {/* } */}
               </div>
            </div>
         </div>
         <div className="img" onClick={handleView} style={{ cursor: "pointer", position: "relative" }}>
            {duonutthumbnail_set.length >= 1 && isPlaying ?
               <ImageSlider duonutthumbnail_set={duonutthumbnail_set}
                  currentIndex={currentIndex}
                  previousIndex={previousIndex}
                  isPlaying={isPlaying} />
               : setting?.coverPhoto ? <img src={setting?.coverPhoto} alt="" loading="lazy" />
                  : duonutthumbnail_set.length >= 1 ?
                     <ImageSlider duonutthumbnail_set={duonutthumbnail_set}
                        currentIndex={currentIndex}
                        previousIndex={previousIndex}
                        isPlaying={isPlaying} />
                     : <img src='/assets/thumbnail.jpg' alt="" loading="lazy" />
            }
         </div>
         <div className="bottom">
            <div className="left">
               <div className="activityName" style={{ textAlign: "left" }}>
                  {name}
               </div>
               <div style={{ display: "flex", fontSize: "0.8rem", padding: "5px 0px" }}>
                  <img style={{ width: "15px", marginRight: "8px" }} src={require('../../assets/Images/eye-black.svg').default} alt="" />
                  {total_views} Views
                  {/* <i className="fa fa-thumbs-o-up AD1trendingIcon" aria-hidden="true" style={{ paddingLeft: "1rem" }}></i>
                  {likes} Likes */}
               </div>
            </div>
         </div>
         {
            showOptions && <div ref={optionsRef}
               className="options"
               style={
                  // popUpPositionX < 1350 ?
                  { top: `${popUpPositionY}px`, left: `${popUpPositionX}px` }
                  //: { top: `${popUpPositionY + 260}px`, left: `${popUpPositionX - 280}px` }
               }>
               <div className="top">
                  <div className="popuptitle">
                     {name}
                  </div>
               </div>
               <div className="mainpopup">
                  <ul>
                     <li className='item' onClick={handleEmbedCode}>
                        <span>Get Embed Code</span>
                     </li>
                     <li className='item' onClick={handleView}>
                        <span>View as a user</span>
                     </li>
                     <li className='item' onClick={handleShare}>
                        <span>Share</span>
                     </li>
                  </ul>
               </div>
            </div>
         }
      </div>
   );
};

export default Duonut;
