import React, { useRef, useState, useEffect, useCallback } from 'react';
import './LeaderBoard.scss';
import { frontendUrl } from "../../../services/constants";
import { connect } from 'react-redux';
import Smiley from '../../../assets/Images/smiley-img.svg';
// import ProfilePic from '../../assets/Images/default-avatar.png';
import crownImg from '../../../assets/Images/crown-img.svg';
// import Footer1 from '../../viewerComponents/Footer1/Footer1.js';
// import ActivityRecommendation from '../../viewerComponents/ActivityRecommendation/ActivityRecommendation.js';

import { useNavigate } from 'react-router-dom';
import { showduonutData, showDuonutresponse } from '../../../redux/slices/DuonutReducer';
import { useSelector, useDispatch } from "react-redux";
// import Header from '../../viewerComponents/Header/Header';
import ReactCanvasConfetti from "react-canvas-confetti";
import leaderboardaudio from '../../../assets/audio/appluase.mp3';
// import FeedBackModel from '../../viewerComponents/ModalBox/FeedBackModel';
// import { useAudio } from '../../viewerComponents/BGAudio/useAudio';
import { openModal, showSignInForm } from "../../../redux/slices/formDetails";
// import { saveLikeAsync } from '../../redux/slices/LikeReducer';
import ConfettiExplosion from 'react-confetti-explosion';

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = (state) => {
    return {
    }
}


function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

const canvasStyles = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
};

function getAnimationSettings(originXA, originXB) {
    return {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: 150,
        origin: {
            x: randomInRange(originXA, originXB),
            y: Math.random() - 0.2
        }
    };
}

const LeaderBoard = (props) => {

    const nickname = props.nickname;
    const { setduonutPage } = props;
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    const duonutName = duonutDataMain[0].name;
    const displayId = duonutDataMain[0].display_id;
    const duonutId = duonutDataMain[0].id;
    const isAudio = duonutDataMain[0].setting.audio;
    // const show_recommendation = duonutDataMain[0].show_recommendation;
    const show_create_button = duonutDataMain[0].setting.show_create_button;
    const show_like_button = duonutDataMain[0].setting.show_like_button;
    const show_share_button = duonutDataMain[0].setting.show_share_button;
    const show_next_duonut_button = duonutDataMain[0].setting.show_next_duonut_button;
    const show_custom_button = duonutDataMain[0].setting.show_custom_button;
    const custom_button_message = duonutDataMain[0].setting.custom_button_message;
    const custom_button_url = duonutDataMain[0].setting.custom_button_url;
    const liked = duonutDataMain[0].setting.liked;
    const coupon_code = duonutDataMain[0].setting.coupon_code;
    const show_feedback = duonutDataMain[0].setting.show_feedback;

    const show_endpage = duonutDataMain[0].setting.show_endpage;
    const leaderboard_bgcolor = duonutDataMain[0].setting.leaderboard_bgcolor;
    const leaderboard_logo = duonutDataMain[0].setting.leaderboard_logo;
    const leaderboard_title = duonutDataMain[0].setting.leaderboard_title;
    const leaderboard_description = duonutDataMain[0].setting.leaderboard_description;
    const leaderboard_fontcolor = duonutDataMain[0].setting.leaderboard_fontcolor;
    const referral = duonutDataMain[0].setting.referral;
    const sub_domain = duonutDataMain[0].created_by.sub_domain;
    const show_confetti = duonutDataMain[0].setting.show_confetti;
    const play_audio = duonutDataMain[0].setting.play_audio;
    const redirect_url = duonutDataMain[0].setting.redirect_url;
    const slack_integration = duonutDataMain[0].slack_integration;
    const hubspot_integration = duonutDataMain[0].hubspot_integration;
    const gmail_integration = duonutDataMain[0].gmail_integration;
    const scheduled_workflow = duonutDataMain[0].scheduled_workflow;

    var frontend_url = "";
    if (sub_domain) {
        frontend_url = `https://${sub_domain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const leaderBoardApi = useSelector(showDuonutresponse);
    const leaderBoardApiData = leaderBoardApi[0];
    var top_three = [];
    var currentUserRank = leaderBoardApi[0].rank;
    // console.log(currentUserRank)
    for (let i = 0; i < 3; i++) {
        if (leaderBoardApi[0].top_scorer?.length > i)
            top_three.push(leaderBoardApi[0].top_scorer[i])
    }
    // console.log(top_three)
    // console.log(leaderBoardApiData)

    const dispatch = useDispatch();
    const handleClick = () => dispatch(openModal());
    const { isLoggedIn } = useSelector((state) => state.user);

    //////////////////////////////////////////
    const refAnimationInstance = useRef(null);
    const [intervalId, setIntervalId] = useState();

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const nextTickAnimation = useCallback(() => {
        if (refAnimationInstance.current) {
            refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
            refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
        }
    }, []);

    const startAnimation = useCallback(() => {
        if (!intervalId) {
            setIntervalId(setInterval(nextTickAnimation, 400));
        }
    }, [intervalId, nextTickAnimation]);

    const pauseAnimation = useCallback(() => {
        clearInterval(intervalId);
        setIntervalId(null);
    }, [intervalId]);

    const stopAnimation = useCallback(() => {
        clearInterval(intervalId);
        setIntervalId(null);
        refAnimationInstance.current && refAnimationInstance.current.reset();
    }, [intervalId]);

    useEffect(() => {
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalId]);

    ////////////////////////////////
    // const [playing, toggle] = useAudio();
    const [audioStatus, setAudioStatus] = useState(false);
    const [iscopyReferralCode, setiscopyReferralCode] = useState(false);
    const [referralLink, setreferralLink] = useState("");
    const [startAnim, setstartAnim] = useState(false);

    const source = {
        position: "absolute",
        right: "50%",
        left: "50%",
        top: "-150px",
        zIndex: "5000"
    };
    const bigExplodeProps = {
        force: 0.6,
        duration: 3000,
        particleCount: 200,
        floorHeight: 1600,
        floorWidth: 1600
    };


    useEffect(() => {
        setTimeout(() => {
            var appId = localStorage.getItem("appId");
            // console.log(appId)
            if (referral && isLoggedIn && appId) {
                setreferralLink(`${frontend_url}/duonut/${displayId}?refl=${appId}${duonutId}`);
            }
        }, 2000); //miliseconds
        // eslint-disable-next-line
    }, [isLoggedIn]);

    const showReferral = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
        }
    }

    const referralCodeCopybtn = () => {
        var copyText = referralLink
        // console.log(copyText)
        navigator.clipboard.writeText(copyText);
        setiscopyReferralCode(true);
    }

    const navigate = useNavigate();
    const NextQuiz = () => {
        // navigate('/duonut/' + next_package_id);
        window.location.reload();
    }
    const createDuonut = () => {
        window.open('/create');
        // window.location.reload();
    }
    const CustomBtn = () => {
        let url
        const regex = new RegExp("^[0-9]{10}$");
        const regex1 = new RegExp("^\\+91[0-9]{10}$");
        if (regex.test(custom_button_url) || regex1.test(custom_button_url)) {
            window.open("tel:" + custom_button_url);
        } else if (custom_button_url.toString().includes("http")) {
            url = custom_button_url;
            window.open(url);
        } else {
            url = 'https://' + custom_button_url;
            window.open(url);
        }

    }

    const NextBtn = () => {
        setTimeout(() => {
            setduonutPage(4);
        }, 1000); //miliseconds
    }
    const messagesEndRef = React.createRef()
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const ref = useRef(null);
    const ref1 = useRef(null);
    const audioRef = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [showFeedback, setShowFeedback] = useState(0);
    const [like, setlike] = useState(false);

    // const bgAudio = () => {
    //     setAudioStatus(true);
    // }

    // useEffect(() => {
    //     if (audioStatus) {
    //         toggle();
    //         setAudioStatus(false);
    //         // console.log("playing", audioStatus)
    //     }
    //     // eslint-disable-next-line
    // }, [audioStatus]);

    useEffect(() => {
        setlike(liked);
        // startAnimation();

        // scrollToBottom();

        if (show_confetti) {
            setstartAnim(true);
        }
        if (play_audio) {
            audioRef.current.play();
            audioRef.current.volume = 0.1;
        }
        setTimeout(() => {
            // pauseAnimation();
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            // stopAnimation();
            setShowFeedback(1);
            setstartAnim(false);
        }, 5000); //miliseconds
        // eslint-disable-next-line
    }, []);

    const likebtn = () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
        } else {
            var like_data = {
                display_id: displayId,
                likes: like ? 0 : 1
            }
            // dispatch(saveLikeAsync(like_data));
            setlike(!like);
        }

    }

    const onclickembedcode = () => {
        ref.current.click();
    }
    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const copycode = () => {
        var copyText = document.getElementById("iframearea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }

    var emotics = "";
    for (let i = 0; i < leaderBoardApiData.current_user?.total_question - leaderBoardApiData.current_user?.incorrect; i++) {
        emotics = emotics + "\u{1F7E2}";
    }
    for (let i = 0; i < leaderBoardApiData.current_user?.incorrect; i++) {
        emotics = emotics + "\u{1F534}";
    }
    // console.log(emotics);
    const shareLink = `View this  ${frontend_url}/duonut/${displayId} and share it among your network. Thank you so much.
        %0a${emotics} 
        %0a%23itsduonut`;

    const iframe = `<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; min-height: 80vh;" title="Duonut Iframe"></iframe>`;

    const rankBoard = () => {
        return (
            <div className='rank-board-wrapper' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }}>
                <img className='smiley-img' src={Smiley} alt="smiley-img" />
                {/* {show_share_button && <div className='action-icons'>
                    <FacebookIcon className='icon' style={{ backgroundColor: leaderboard_bgcolor, fill: leaderboard_fontcolor }} onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${frontend_url}/duonut/${displayId}`)} />
                    <LinkedInIcon className='icon' style={{ backgroundColor: leaderboard_bgcolor, fill: leaderboard_fontcolor }} onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${frontend_url}/duonut/${displayId}?page=ld`)} />
                    <WhatsAppIcon className='icon' style={{ backgroundColor: leaderboard_bgcolor, fill: leaderboard_fontcolor }} onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)} />
                    <TwitterIcon className='icon' style={{ backgroundColor: leaderboard_bgcolor, fill: leaderboard_fontcolor }} onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} />
                    <LinkIcon className='icon' style={{ backgroundColor: leaderboard_bgcolor, fill: leaderboard_fontcolor }} onClick={() => onclickembedcode()} />
                </div>} */}
                <div className='main-board'>
                    <h1 className='main-board-heading'>Good Job! Your Rank is #{currentUserRank}</h1>
                    <div className='your-score'>
                        <span>Your Score : {leaderBoardApiData.current_user?.score}</span>
                        {/* <span></span> */}
                    </div>
                    <div className='all-scores'>
                        <div className='score-item' style={{ border: `1px solid ${leaderboard_fontcolor}` }}>
                            <span>Question</span>
                            <span>{leaderBoardApiData.current_user?.total_question}</span>
                        </div>
                        <div className='score-item' style={{ border: `1px solid ${leaderboard_fontcolor}` }}>
                            <span>Correct</span>
                            <span>{leaderBoardApiData.current_user?.total_question - leaderBoardApiData.current_user?.incorrect}</span>
                        </div>
                        <div className='score-item' style={{ border: `1px solid ${leaderboard_fontcolor}` }}>
                            <span>Incorrect</span>
                            <span>{leaderBoardApiData.current_user?.incorrect}</span>
                        </div>
                    </div>
                </div>
                {/* <div className="LBarrow" onClick={scrollToBottom}>
                    <span></span>
                    <span></span>
                    <span></span>

                </div> */}
            </div>
        )

    }

    const renderLeaderBoard = () => {
        return (
            <div className='leader-board-wrapper' ref={messagesEndRef} style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor, border: `1px solid ${leaderboard_fontcolor}` }}>
                <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
                <audio
                    src={leaderboardaudio}
                    ref={audioRef}
                ></audio>
                {/* <img className='leaderBoardImg' src={require('../../../assets/Images/Leaderboard.svg').default} alt="nice" /> */}
                <h1 style={{ marginBottom: '2rem' }}>Leaderboard</h1>
                <div className='section-wrapper'>
                    <div className='leader-board-left'>
                        <div className={
                            currentUserRank === 2 ? "inner-div currentRank" :
                                "inner-div"}>
                            <p>2</p>
                            {/* <img className='profile-pic' src={ProfilePic} alt="profile-pic" /> */}
                            <img className='profile-pic' src={require('../../../assets/Images/profileicon.png')} alt="profile-pic" />
                            <span>{top_three.length > 1 ? top_three[1].nick_name : "Anonymous"}</span>
                            <p>{top_three.length > 1 ? top_three[1].score : "0"}</p>
                        </div>
                        <div className={
                            currentUserRank === 1 ? "inner-div currentRank" :
                                "inner-div"} style={{ position: "absolute", top: "-65%" }}>
                            <img className="crownImg" src={crownImg} alt="profile-pic" />
                            {/* <p>1</p> */}
                            <img className='profile-pic' src={require('../../../assets/Images/profileicon.png')} alt="profile-pic" />
                            <span>{top_three.length > 0 ? top_three[0].nick_name : "Anonymous"}</span>
                            <p>{top_three.length > 0 ? top_three[0].score : "0"}</p>
                        </div>
                        <div className={
                            currentUserRank === 3 ? "inner-div currentRank" :
                                "inner-div"}>
                            <p>3</p>
                            <img className='profile-pic' src={require('../../../assets/Images/profileicon.png')} alt="profile-pic" />
                            <span>{top_three.length > 2 ? top_three[2].nick_name : "Anonymous"}</span>
                            <p>{top_three.length > 2 ? top_three[2].score : "0"}</p>
                        </div>

                    </div>
                    <div className='leader-board-right' style={leaderBoardApiData.top_scorer?.length > 3 ? {} : { width: "0%" }}>
                        {leaderBoardApiData.top_scorer?.map((item, index) => {
                            if (index > 2) {
                                return (
                                    <div className={
                                        currentUserRank === (index + 1) ? "rank-item currentRank" :
                                            "rank-item"}
                                        style={{ border: `1px solid ${leaderboard_fontcolor}` }}
                                        key={index}>
                                        <div className='rank-left'>
                                            <span>{index + 1}</span>
                                            <img className='profile-pic' src={require('../../../assets/Images/profileicon.png')} alt="profile-pic" />
                                            <span>{item.nick_name}</span>
                                        </div>
                                        <span>{item.score}</span>
                                    </div>
                                )
                            }
                            else { return null }
                        })}
                        {currentUserRank > 10 &&
                            <div className='rank-item currentRank'>
                                <div className='rank-left'>
                                    <span>{currentUserRank}</span>
                                    <img className='profile-pic' src={require('../../../assets/Images/profilepic.svg').default} alt="profile-pic" />
                                    <span>{leaderBoardApiData.current_user.nick_name}</span>
                                </div>
                                <span>{leaderBoardApiData.current_user.score}</span>
                            </div>
                        }
                    </div>
                </div>

                {referral && !isLoggedIn && <button className='purple-button' onClick={() => showReferral()}>Get Referral Link</button>}
                {referral && isLoggedIn && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "2vh 2vh 2vh 3.5rem" }}>
                    <div className='SL1couponcode'>
                        <div>Referral Link - </div>
                        {referralLink}
                    </div>
                    <span style={{ marginLeft: "1rem", cursor: "pointer", fontSize: "0.8rem" }} onClick={() => referralCodeCopybtn()}>{iscopyReferralCode ? "Copied" : "Copy"}</span>
                </div>}

                {/* {show_like_button && <button className='purple-button' onClick={() => likebtn()}><i className="fa fa-thumbs-o-up" aria-hidden="true" style={{ fontSize: "2.4vh", paddingRight: "5px" }}></i>
                    {like && isLoggedIn ? "Liked" : "Like"} </button>} */}
                {show_create_button && <button className='purple-button' style={{ backgroundColor: leaderboard_fontcolor, color: leaderboard_bgcolor }} onClick={() => createDuonut()}> Create Duonut </button>}
                {show_next_duonut_button && <button className='purple-button' style={{ backgroundColor: leaderboard_fontcolor, color: leaderboard_bgcolor }} onClick={() => NextQuiz()}> Next Duonut </button>}
                {show_custom_button && <button className='purple-button' style={{ backgroundColor: leaderboard_fontcolor, color: leaderboard_bgcolor }} onClick={() => CustomBtn()}> {custom_button_message} </button>}
                {show_endpage && <button className='purple-button' style={{ backgroundColor: leaderboard_fontcolor, color: leaderboard_bgcolor }} onClick={() => NextBtn()}> Next </button>}

            </div>
        )
    }


    return (
        <div>
            {/* {show_home_footer_button && <Header />} */}
            <button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            </button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content LB1modalMain" style={{ backgroundColor: "#3184C9", color: "white" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='LB1shareTitle'> Share with your Friends</div>
                            <div className='LB1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div>
                            <textarea id='iframearea' rows="4" key={iframe}
                                style={{ width: "100%", padding: "5px", margin: "1vh auto", fontSize: "14px", height: "132px", display: "block" }}>
                                {iframe}
                            </textarea>
                            <button className='LB1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy"}</button>
                            <button className='LB1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            {leaderBoardApiData &&
                <div className='LB1join-us' style={{ backgroundColor: leaderboard_bgcolor, color: leaderboard_fontcolor }}>
                    {/* {showFeedback === 1 && show_feedback && <FeedBackModel nickname={nickname} displayId={displayId} />} */}
                    <div className='inner-wrap'>
                        {startAnim && <div style={source}>
                            <ConfettiExplosion {...bigExplodeProps} />
                        </div>}
                        {/* {isAudio && <div className='activity-icons'>
                            {playing ? <VolumeUpIcon className='icon' onClick={() => bgAudio()} />
                                : <VolumeMuteIcon className='icon' onClick={() => bgAudio()} />}
                        </div>} */}
                        {renderLeaderBoard()}
                        {rankBoard()}
                        {/* {show_recommendation && <ActivityRecommendation background={"#3184C9"} border={"#C729E7"} />} */}
                        {/* {show_home_footer_button && <Footer1 />} */}
                    </div>
                </div>
            }
        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoard);
