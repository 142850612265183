import React from "react";

export const Download = ({ fill }) => {
  return (
    <svg fill={fill ? fill : "#474747"} width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 13C1.41421 13 1.75 13.3358 1.75 13.75V15.75C1.75 15.8881 1.86193 16 2 16H14C14.1381 16 14.25 15.8881 14.25 15.75V13.75C14.25 13.3358 14.5858 13 15 13C15.4142 13 15.75 13.3358 15.75 13.75V15.75C15.75 16.7165 14.9665 17.5 14 17.5H2C1.0335 17.5 0.25 16.7165 0.25 15.75V13.75C0.25 13.3358 0.585786 13 1 13Z"
        fill="inherit"
      />
      <path
        d="M6.73795 0.5C6.22356 0.5 5.79447 0.893122 5.74955 1.40555C5.59591 3.15826 5.56881 4.91959 5.66838 6.67584C5.42139 6.68949 5.1745 6.70531 4.92773 6.7233L3.43822 6.83187C2.86392 6.87374 2.54288 7.5141 2.85293 7.99932C3.91497 9.66141 5.28343 11.1063 6.88538 12.2571L7.48212 12.6858C7.79162 12.9081 8.20849 12.9081 8.51799 12.6858L9.11473 12.2571C10.7167 11.1063 12.0851 9.66141 13.1472 7.99932C13.4572 7.5141 13.1362 6.87374 12.5619 6.83188L11.0724 6.7233C10.8256 6.70531 10.5787 6.68949 10.3317 6.67584C10.4313 4.91959 10.4042 3.15826 10.2506 1.40554C10.2056 0.89312 9.77655 0.5 9.26216 0.5H6.73795Z"
        fill="inherit"
      />
    </svg>
  );
};
