import React, { useRef, useEffect, useState } from 'react';
import { frontendUrl } from "../../../services/constants";
import './ShareModal.css';
import "../../../assets/bootstrap.min.css";
// import "../../../assets/bootstrap.bundle.min.js";
import { useNavigate } from 'react-router-dom';

const ShareModal = (props) => {
    // console.log(props);
    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }
    const duonutName = props.shareData.duonut_name;
    const displayId = props.shareData.display_id;
    const password = props.shareData.password;
    const message = props.shareData.message;
    const ref = useRef(null);
    const ref1 = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [iscopylink, setiscopylink] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 300000); //miliseconds
    }, []);


    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        ref1.current.click();
        window.open(`/duonut/${displayId}`);
        // window.location.reload();
    }

    const onclickembedcode = () => {
        setShow(!show);
    }

    const copylink = () => {
        var copyText = `${frontend_url}/duonut/${displayId}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopylink(true);
    }
    const copycode = () => {
        var copyText = document.getElementById("iframearea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }
    var shareLink;
    if (password) {
        shareLink = `I liked ${duonutName} duonut and I think you will like it too.
        %0aSee here - ${frontend_url}/duonut/${displayId}  
        %0aPassword - ${password}
        %0a\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538}\u{1F538} 
        %0a%23itsduonut`;
    } else {
        shareLink = `${frontend_url}/duonut/${displayId}`;
    }

    const iframe = `<iframe src="${frontend_url}/duonut/${displayId} " style="width: 100%; min-height: 80vh;" title="Duonut Iframe"></iframe>`;

    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#shareModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`shareModal${displayId}`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div>{duonutName}{message}</div>

                            <div className='SM1shareTitle'> Share your Content</div>
                            {/* <div className='PM1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <br></br>
                            <div className="">
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${frontend_url}/duonut/${displayId} `)}>
                                    <i className="fa fa-facebook" aria-hidden="false"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${frontend_url}/duonut/${displayId} ?page=ld`)}>
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                    <img style={{ marginTop: "6px" }} src="/assets/icons/twitter2.svg" />
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => onclickembedcode()}>
                                    <i className="fa fa-link" aria-hidden="true"></i>
                                </label>
                                <hr></hr>
                                <label onClick={() => window.open(`https://classroom.google.com/share?url=${frontend_url}/duonut/${displayId}`)}
                                    style={{ display: "block", cursor: "pointer", width: "3.5rem", height: "3.1rem", margin: "auto", marginTop: "5px", boxShadow: "0 2px 10px rgba(61,59,59,.2)" }}>
                                    <img src='/assets/classroom.png' />
                                </label>
                            </div>
                            <br></br>
                            {show && <><textarea id='iframearea' rows="4" key={iframe}
                                style={{ width: "100%", padding: "5px", margin: "1vh auto", fontSize: "14px", height: "132px", display: "block" }}>
                                {iframe}
                            </textarea>
                                <button className='SM1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy"}</button></>}
                            <button className='SM1Continuebtn' onClick={() => viewbtn()}>View</button>
                            <button className='SM1Continuebtn' onClick={() => copylink()}>{iscopylink ? "Copied" : "Copy Link"}</button>
                            <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div></>
    );

}

export default ShareModal;