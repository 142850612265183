import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showduonutData } from '../../redux/slices/DuonutReducer';

const Button = ({
  id,
  elementAttr,
  elementHoverAttr,
  conditions,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
  const { fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, margin, padding, borderRadius, url, url_data, imageWidth, imagePadding, flexDirection
  } = elementAttr;

  const duonutDataMain = useSelector(showduonutData);
  const duonutData = duonutDataMain[0];
  const { save_response, save_form_data } = useSelector(state => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const p_type = queryParams.get('type');

  // console.log(p_type)

  const [inputText, setInputText] = useState(text);
  const [isHovered, setIsHovered] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [formSuccess, setFormSuccess] = useState("");

  const color = isHovered ? elementHoverAttr?.color : elementAttr.color;
  const backgroundColor = isHovered ? elementHoverAttr?.backgroundColor : elementAttr.backgroundColor;
  const border = isHovered ? elementHoverAttr?.border : elementAttr.border;

  useEffect(() => {
    setInputText(text);
  }, [text]);

  const handleButtonTextChange = (e) => {
    // console.log(e.target.value);
    setInputText(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const validateForm = () => {
    let valid = true;
    let errors = "";

    console.log("vaidate", duonutData.user_activity, save_response);

    for (let index = 0; index < duonutData.user_activity[selectedSlide]?.elements?.length; index++) {
      let element = duonutData.user_activity[selectedSlide]?.elements[index];

      if (element?.elementType === "TextField" || element?.elementType === "TextArea" ||
        element?.elementType === "Email" || element?.elementType === "Password" ||
        element?.elementType === "Phone" || element?.elementType === "NumberField"
        || element?.elementType === "DateField" || element?.elementType === "FileUpload"
        || element?.elementType === "Dropdown" || element?.elementType === "DropdownMultiselect"
        || element?.elementType === "RadioGroup") {

        if (element?.elementAttributes?.required) {
          if (element?.elementType === "RadioGroup") {
            errors = `Please select 1 option.`
          } else {
            errors = `Please fill the ${element?.elementAttributes?.text} field`
          }
          valid = false;
          for (let i = 0; i < save_response.length; i++) {
            if ((save_response[i].step === selectedSlide + 1) && (save_response[i].id === element.id)) {
              errors = ""
              valid = true;
              break;
            }
          }
          if (!valid) break; // Exit the loop if validation fails
        }
      }

      if (element?.elementType === "Email") {
        for (let i = 0; i < save_response.length; i++) {
          if ((save_response[i].step === selectedSlide + 1) && (save_response[i].type === "Email")) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(save_response[i].answered_text)) {
              errors = 'Invalid email address';
              valid = false;
              break;
            }
          }
        }
        if (!valid) break; // Exit the loop if validation fails
      }

    }

    setFormErrors(errors);
    setTimeout(() => {
      setFormErrors("");
    }, 3000); //miliseconds
    return valid;
  };

  const handleButtonClick = () => {
    if (location.pathname.includes("/duonut/")) {
      console.log("click button", conditions?.action)

      if (duonutData.user_activity[selectedSlide]?.elements.some(element => element.elementType === "CheckboxGroup") ||
        duonutData.user_activity[selectedSlide]?.elements.some(element => element.elementType === "RadioGroup")) {

        var value = [];
        for (let i = 0; i < save_response.length; i++) {
          if ((save_response[i].step === selectedSlide + 1) &&
            (save_response[i].id === duonutData.user_activity[selectedSlide]?.elements.find(element => element.elementType === "CheckboxGroup")?.id)) {
            value = save_response[i].answered_text.split(';');
            break;
          }
          if ((save_response[i].step === selectedSlide + 1) &&
            (save_response[i].id === duonutData.user_activity[selectedSlide]?.elements.find(element => element.elementType === "RadioGroup")?.id)) {
            value = save_response[i].answered_text.split(';');
            break;
          }
        }
        console.log("inside button if", save_response, value)

        //check if other is selected field not empty
        if (value.length > 0) {
          for (let k = 0; k < value.length; k++) {
            console.log(value[k])
            if (value[k] === "other" || value[k] === "other - ") {
              setFormErrors("Please fill Other");
              setTimeout(() => {
                setFormErrors("");
              }, 3000); //miliseconds
              return null;
            }

          }
        }


        var nextPage;
        duonutData.user_activity[selectedSlide]?.condition?.map(node => {
          // console.log(node.condition.some(condition => condition === (value[0])))
          if ((value.length > 0 && (node.condition?.some(condition => condition === (value[0]))) || node.always)) {
            nextPage = node.target - 1;
          }
        })
        if (nextPage) {
          dispatch(updateGoToSlide(nextPage));
          return null;
        }
      }

      if (!validateForm()) return null;
      console.log("button inside p_type", p_type)

      if (p_type === "1") {
        var act_len = duonutData.user_activity.length;
        console.log(act_len);
        dispatch(updateGoToSlide(act_len));
        return null;
      }

      if (conditions?.action === "Go to next step") {
        dispatch(updateGoToSlide(selectedSlide + 1));
      } else if (conditions?.action === "Go to previous step") {
        dispatch(updateGoToSlide(selectedSlide - 1));
      } else if (conditions?.action === "Open url") {
        window.open(conditions.url);
        console.log(duonutData.user_activity.length, selectedSlide)
        if (duonutData.user_activity.length === selectedSlide + 1) {
          dispatch(updateGoToSlide(selectedSlide + 1));
        }
      } else if (conditions?.action === "Go to step") {
        dispatch(updateGoToSlide(conditions?.goToStep));
      } else if (conditions?.action === "Form submitted") {
        setFormSuccess("Form submitted Successfully.");
        setTimeout(() => {
          setFormSuccess("");
          window.location.reload();
        }, 2000); //miliseconds
      } else {
        dispatch(updateGoToSlide(selectedSlide + 1));
      }
    }
  }

  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  return (<>
    <button style={{
      display: "flex", justifyContent: textAlign, alignItems: 'center', flexDirection: flexDirection,
      backgroundColor, border: border ? border : "0", margin, borderRadius,
      cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
      width: "100%", height: "100%"
    }}
      type='submit'
      onClick={() => handleButtonClick()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>

      {(url || url_data) && <img src={(url_data !== undefined && Object.keys(url_data).length !== 0) ? url_data.uri : url} style={{
        minHeight: "0px",
        objectFit: "contain",
        maxWidth: "70%",
        maxHeight: "100%",
        margin: imagePadding,
        width: imageWidth,
      }} alt="" />}

      <EditText
        id={`button-input-${id}`}
        value={inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          color, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => { handleInputFocus(); setTimeout(() => document.querySelector(`#button-input-${id}`).select(), 100); }}
        onChange={(e) => handleButtonTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
    </button>
    {formErrors && <div style={{ color: 'red', paddingTop: "6px", position: "fixed" }}>{formErrors}</div>}
    {formSuccess && <div style={{ color: 'green', paddingTop: "6px", position: "fixed" }}>{formSuccess}</div>}
  </>
  );
}

export default Button;
