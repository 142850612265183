import React, { useState, useEffect, useRef } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus, setTextFormData } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, saveFormData } from '../../redux/slices/DuonutReducer';

const DateField = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, required,
    fieldPosition, borderBottom, gap, height,
  },
  conditions
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide, textFormData } = useSelector((state) => state.userActivity);
  const location = useLocation();
  const [dateField, setDateField] = useState('');
  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name, save_form_data } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const [inputText, setInputText] = useState(text);

  useEffect(() => {
    setInputText(text);

    var formData = {};
    formData[text] = id;
    // console.log(formData);
    dispatch(setTextFormData(formData));
  }, [text]);

  const handleDateFieldTextChange = (e) => {
    setInputText(e.target.value);
    // console.log(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const dateTimeout = useRef(null);

  const handleDateFieldInputChange = (e) => {
    if (location.pathname.includes("/duonut/")) {
      setDateField(e.target.value);

      clearTimeout(dateTimeout.current);
      dateTimeout.current = setTimeout(() => {

        var formData1 = {};
        formData1[text] = e.target.value;
        // console.log(formData);
        dispatch(saveFormData(formData1));

        var save_ans = {
          display_id: viewer_display_id,
          watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
          result: e.target.value ? 1 : 0,
          question: text,
          answered_text: e.target.value,
          // end: lastQue ? "1" : "0",
          nickname: viewer_nick_name,
          step: selectedSlide + 1,
          type: "DateField",
          // country: location[0] ? location[0].address?.country : "",
          // city: location[0] ? location[0].address?.state_district : "",
          // location: location[0] ? location[0].display_name : "",
          // url_params: props.details.queryParamsObject
        }
        // console.log(save_ans);
        const formData = new FormData();
        Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

        dispatch(saveQuizAnsAsync(formData));

        var save_response = {
          id: id,
          type: "DateField",
          step: selectedSlide + 1,
          question: text,
          answered_text: e.target.value,
        }
        dispatch(saveQuizResponse(save_response));

      }, 1000);

    }
  }

  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  return (
    <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: fieldPosition ? fieldPosition : "column", gap: gap ? gap : fieldPosition === 'row' ? '20px' : '0' }}>
      <EditText
        id={`date-input-${id}`}
        value={required && location.pathname.includes("/duonut/") ? inputText + " *" : inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          // display: "flex",
          // alignItems: "center",
          width: fieldPosition === 'row' ? "max-content" : "100%",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => {
          handleInputFocus();
          // setTimeout(() => document.querySelector(`#date-input-${id}`).select(), 100);
        }}
        onChange={(e) => handleDateFieldTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
      <input type='date' key={borderBottom} style={{
        width: "100%", minHeight: "40px", height: height,
        color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
        textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
        backgroundColor, border: borderBottom ? "0" : border, margin, borderRadius, borderBottom: border
      }}
        onChange={(e) => handleDateFieldInputChange(e)}
        readOnly={location.pathname.includes("/duonut/") ? false : true}
        required={required}
      />
    </div>
  );
}

export default DateField;
