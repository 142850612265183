import React, { useRef, useEffect, useState } from 'react';
import { frontendUrl } from "../../../services/constants";
import './EmbedModal.css';
import "../../../assets/bootstrap.min.css";
// import "../../../assets/bootstrap.bundle.min.js";
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const EmbedModal = (props) => {
    // console.log(props);
    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }
    const duonutName = props.shareData.duonut_name;
    const displayId = props.shareData.display_id;
    const password = props.shareData.password;
    const message = props.shareData.message;
    const ref = useRef(null);
    const ref1 = useRef(null);
    const { width } = useWindowDimensions();
    const [iscopy, setiscopy] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [embedType, setembedType] = useState('Standard');
    const [popupSize, setpopupSize] = useState('Large');
    const [iframeCode, setiframeCode] = useState(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 80%; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe>`);

    const handlePopupSizeChange = event => {
        setpopupSize(event.target.value);
        setiscopy(false);
        // console.log(event.target.value);
    };

    const selectEmbedType = (type) => {
        setembedType(type);
        setiscopy(false);
        // console.log(type);
    };


    useEffect(() => {
        if (embedType === "Standard") {
            setiframeCode(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 80%; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe>`);
        } else if (embedType === "Fullpage") {
            setiframeCode(`<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>`);
        } else if (embedType === "Mobile") {
            setiframeCode(`<div style="display: flex; justify-content: center;"><iframe src="${frontend_url}/duonut/${displayId}" style="width: 350px; min-height: 80vh; border: none;" title="Duonut Iframe"></iframe><div>`);
        } else if (embedType === "Popup" && popupSize === "Large") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
            \n<div style="width: 90%; height: 90%; position: relative;">
            \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
            \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
            \n</div>
            \n</div>`);
        } else if (embedType === "Popup" && popupSize === "Medium") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
            \n<div style="width: 70%; height: 70%; position: relative;">
            \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
            \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
            \n</div>
            \n</div>`);
        } else if (embedType === "Popup" && popupSize === "Small") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba(0,0,0,.75); display: flex; align-items: center; justify-content: center; z-index: 99;">
            \n<div style="width: 50%; height: 60%; position: relative;">
            \n<iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
            \n<button style="color: white; background: none; border: none; cursor: pointer; position: absolute; right: -5px; top: -35px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>
            \n</div>
            \n</div>`);
        } else if (embedType === "Popover") {
            setiframeCode(`<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
            <div style="position: fixed; right: 0px; bottom: 80px; min-width: 350px; height: 70vh; z-index: 99;">
            
            <div style="width: 100%; height: 100%; position: relative;">
            
            <iframe id="duonutPopUp" src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none; box-shadow: rgba(0,0,0,.08) 0 2px 4px, rgba(0,0,0,.06) 0 2px 12px;" title="Duonut Iframe"></iframe>
            
            <button style="width: 40px; height: 40px; background: white; border: none;  border-radius: 50%; cursor: pointer; position: absolute; right: 15px; bottom: -60px;" onclick="show()">
            <i class="fa fa-lightbulb-o" aria-hidden="true" style="font-size:30px"></i>
            </button>
            
            </div>            
            </div>
            <script>
              function show() {
                let divContainer = document.getElementById("duonutPopUp");
                if(divContainer.style.opacity==='0'){
                  divContainer.style.opacity='1';
                } else{
                  divContainer.style.opacity='0';
                }
              }
            </script>`);
        } else if (embedType === "Sidetab1") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; right: 0px; width: 350px; height: 70vh; z-index: 99;">
            <div style="width: 100%; height: 100%; position: relative;">            
            <iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
            <button style="color: black; background: none; border: none; cursor: pointer; position: absolute; right: 2px; top: -10px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>   
            </div>
            </div>`);
        } else if (embedType === "Sidetab2") {
            setiframeCode(`<div id="duonutPopUp" style="position: fixed; width: 350px; height: 70vh; z-index: 99;">
            <div style="width: 100%; height: 100%; position: relative;">            
            <iframe src="${frontend_url}/duonut/${displayId}" style="width: 100%; height: 100%; border: none;" title="Duonut Iframe"></iframe>
            <button style="color: black; background: none; border: none; cursor: pointer; position: absolute; right: 2px; top: -10px; font-size: 2rem;" onclick="document.getElementById('duonutPopUp').style.opacity='0'">x</button>   
            </div>
            </div>`);
        }
    }, [embedType, popupSize]);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 3000000); //miliseconds
    }, []);


    const continuebtn = () => {
        ref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        ref1.current.click();
        window.open('/duonut/' + displayId);
        // window.location.reload();
    }

    const onclickembedcode = () => {
        setShow(!show);
    }
    const copycode = () => {
        var copyText = document.getElementById("embediframearea");
        // console.log(copyText);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setiscopy(true);
    }


    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#embedModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`embedModal${displayId}`} tabIndex="-1" aria-labelledby="embedModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: width >= 768 ? "45vw" : "100%" }}>
                    <div className="modal-content EM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div>{duonutName}{message}</div>

                            <div className='SM1shareTitle'> Embed this code in your website</div>
                            <br></br>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className='EM1embedType' style={embedType === "Standard" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Standard")}>
                                    <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-standard" style={{ borderRadius: "4px" }}>
                                        <path d="M0.333496 4C0.333496 1.79086 2.12436 0 4.3335 0H36.3335C38.5426 0 40.3335 1.79086 40.3335 4V28C40.3335 30.2091 38.5426 32 36.3335 32H4.3335C2.12436 32 0.333496 30.2091 0.333496 28V4Z" fill="white"></path>
                                        <path d="M6.3335 11H34.3335V19C34.3335 20.1046 33.4381 21 32.3335 21H6.3335V11Z" fill="#3184C9"></path>
                                        <path d="M6.3335 6H26.3335C26.3335 7.10457 25.4381 8 24.3335 8H6.3335V6Z" fill="#737373"></path>
                                        <path d="M6.3335 24H18.3335C18.3335 25.1046 17.4381 26 16.3335 26H6.3335V24Z" fill="#737373"></path>
                                        <path d="M4.3335 1H36.3335V-1H4.3335V1ZM39.3335 4V28H41.3335V4H39.3335ZM36.3335 31H4.3335V33H36.3335V31ZM1.3335 28V4H-0.666504V28H1.3335ZM4.3335 31C2.67664 31 1.3335 29.6569 1.3335 28H-0.666504C-0.666504 30.7614 1.57207 33 4.3335 33V31ZM39.3335 28C39.3335 29.6569 37.9903 31 36.3335 31V33C39.0949 33 41.3335 30.7614 41.3335 28H39.3335ZM36.3335 1C37.9903 1 39.3335 2.34315 39.3335 4H41.3335C41.3335 1.23858 39.0949 -1 36.3335 -1V1ZM4.3335 -1C1.57207 -1 -0.666504 1.23858 -0.666504 4H1.3335C1.3335 2.34315 2.67664 1 4.3335 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Standard</span>
                                </div>
                                <div className='EM1embedType' style={embedType === "Fullpage" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Fullpage")}>
                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-fullpage" style={{ borderRadius: "4px" }}>
                                        <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                        <rect x="3" y="3" width="34" height="26" rx="2" fill="#3184C9"></rect>
                                        <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Full-Page</span>
                                </div>
                                <div className='EM1embedType' style={embedType === "Popover" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Popover")}>
                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px" }}>
                                        <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                        <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                        <circle cx="34" cy="26" r="2" fill="#3184C9"></circle>
                                        <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Popover</span>
                                </div>
                                <div className='EM1embedType' style={embedType === "Popup" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Popup")}>
                                    <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popup" style={{ borderRadius: "4px" }}>
                                        <path d="M0.666992 4C0.666992 1.79086 2.45785 0 4.66699 0H36.667C38.8761 0 40.667 1.79086 40.667 4V28C40.667 30.2091 38.8761 32 36.667 32H4.66699C2.45785 32 0.666992 30.2091 0.666992 28V4Z" fill="white"></path>
                                        <path d="M6.66699 6H34.667V24C34.667 25.1046 33.7716 26 32.667 26H6.66699V6Z" fill="#3184C9"></path>
                                        <path d="M4.66699 1H36.667V-1H4.66699V1ZM39.667 4V28H41.667V4H39.667ZM36.667 31H4.66699V33H36.667V31ZM1.66699 28V4H-0.333008V28H1.66699ZM4.66699 31C3.01014 31 1.66699 29.6569 1.66699 28H-0.333008C-0.333008 30.7614 1.90557 33 4.66699 33V31ZM39.667 28C39.667 29.6569 38.3238 31 36.667 31V33C39.4284 33 41.667 30.7614 41.667 28H39.667ZM36.667 1C38.3238 1 39.667 2.34315 39.667 4H41.667C41.667 1.23858 39.4284 -1 36.667 -1V1ZM4.66699 -1C1.90557 -1 -0.333008 1.23858 -0.333008 4H1.66699C1.66699 2.34315 3.01014 1 4.66699 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Popup</span>
                                </div>
                                <div className='EM1embedType' style={embedType === "Sidetab1" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Sidetab1")}>
                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px" }}>
                                        <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                        <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                        {/* <circle cx="34" cy="26" r="2" fill="#3184C9"></circle> */}
                                        <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Right Side Tab</span>
                                </div>
                                <div className='EM1embedType' style={embedType === "Sidetab2" ? { background: "rgb(227, 227, 227)" } : {}} onClick={() => selectEmbedType("Sidetab2")}>
                                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" data-qa="embed-type-popover" style={{ borderRadius: "4px", transform: "rotate(180deg)" }}>
                                        <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V28C40 30.2091 38.2091 32 36 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="white"></path>
                                        <path d="M26 7H36V23H28C26.8954 23 26 22.1046 26 21V7Z" fill="#3184C9"></path>
                                        {/* <circle cx="34" cy="26" r="2" fill="#3184C9"></circle> */}
                                        <path d="M4 1H36V-1H4V1ZM39 4V28H41V4H39ZM36 31H4V33H36V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM39 28C39 29.6569 37.6569 31 36 31V33C38.7614 33 41 30.7614 41 28H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#737373"></path>
                                    </svg>
                                    <span>Left Side Tab</span>
                                </div>
                            </div>
                            {embedType === "Popup" && <><br></br>
                                <div>Popup Size
                                    <select id="mySelect" style={{ padding: "5px", marginLeft: "1rem" }} value={popupSize}
                                        onChange={e => handlePopupSizeChange(e)}>
                                        <option selected value="Large">Large</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Small">Small</option>
                                    </select>
                                </div></>}
                            <br></br>
                            <textarea id='embediframearea' rows="4" key={iframeCode}
                                style={{ width: "100%", padding: "5px 0px", margin: "1vh auto", fontSize: "14px", height: "132px", display: "block", borderRadius: "10px" }}>
                                {iframeCode}
                            </textarea>
                            <button className='EM1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy"}</button>
                            <button className='EM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div></>
    );

}

export default EmbedModal;