import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};


export const reportApiSlide = createSlice({
  name: "reportApi",
  initialState: {
    response: []
  },
  reducers: {
    saveReportApi: (state, action) => {
      state.response = [action.payload];
    },
  }
});


export const editReportApiAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/report_api`;
    const response = await axios.patch(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteReportApiAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/report_api`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveReportApi } = reportApiSlide.actions;
export const showReportApiresponse = (state) => state.reportApi.response;
export default reportApiSlide.reducer;
