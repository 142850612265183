import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function FontFamily() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [fontFamilySelected, setFontFamilySelected] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        // if (styles.fontFamily === undefined) return
        styles?.fontFamily ?
            setFontFamilySelected(styles.fontFamily) : setFontFamilySelected('Poppins')
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleChange = e => {
        const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fontFamily: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    return (
        <div className="EditorRowContainer" >
            <p style={{ marginRight: "1rem" }}>Font</p>
            <select value={fontFamilySelected} className='text_editor_dropdown' onChange={handleChange}>
                {fontFamilies.map(fontFamily =>
                    <option key={fontFamily} value={fontFamily} > {fontFamily} </option>
                )}
            </select>
            {/* <img src='/assets/icons/down-arrow.svg' className='select-icon' /> */}
        </div>
    )
}


const fontFamilies = [
    'Poppins',
    'Open Sans',
    'Inter',
    'Sans Serif',
    'cursive',
    'Times New Roman',
    'Arial',
    'verdana',
    'Trebuchet MS',
    'Pacifico',
    'Sofia',
    'Audiowide',
    'Trirong',
    'monospace',
    'Tangerine',
    'Lobster',
    'Vollkorn',
    'Droid Sans',
    'Milonga',
]