import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { combineReducers } from 'redux'

import userActivityReducer from './slices/userActivity'
import DuonutReducer from './slices/DuonutReducer'
import userReducer from './slices/user'
import formDetailsReducer from './slices/formDetails'
import duonutSettingsReducer from './slices/duonutSettings'
import CreateFromScratchReducer from './slices/CreateFromScratchReducer';
import DuonutNameReducer from './slices/DuonutNameReducer';
import DemoReducer from './slices/DemoReducer';
import ProfileReducer from './slices/ProfileReducer';
import onboardingSlide from './slices/OnboardingReducer';
import duonutreportSlide from './slices/DuonutReportReducer';
import CopyStyleReducer from './slices/CopyStyleReducer';
import creatorAnalyticsSlide from './slices/CreatorAnalyticsReducer';
import ChannelReducer from './slices/ChannelReducer';
import NuggetsReducer from './slices/NuggetsReducer';
import ProMessageReducer from './slices/ProMessageReducer';
import duonutPageMappingSlide from './slices/DuonutPageMappingReducer';
import UserSettingReducer from './slices/UserSettingReducer';
import FlowChartSettingReducer from './slices/FlowChartSettingReducer';
import ReportApiReducer from './slices/ReportApiReducer';
import UserMappingReducer from './slices/UserMappingReducer';
import AiReducer from './slices/AiReducer';
import AiDuonutReducer from './slices/AiDuonutReducer';
import AiSettingReducer from './slices/AiSettingReducer';

const reducer = combineReducers({
    userActivity: userActivityReducer,
    duonut: DuonutReducer,
    user: userReducer,
    formDetails: formDetailsReducer,
    duonutSettings: duonutSettingsReducer,
    createFromScratch: CreateFromScratchReducer,
    DuonutName: DuonutNameReducer,
    demo: DemoReducer,
    profile: ProfileReducer,
    onboarding: onboardingSlide,
    duonutreport: duonutreportSlide,
    CopySetting: CopyStyleReducer,
    creatorAnalytics: creatorAnalyticsSlide,
    channel: ChannelReducer,
    nuggets: NuggetsReducer,
    ProMessage: ProMessageReducer,
    duonutPageMapping: duonutPageMappingSlide,
    userSetting: UserSettingReducer,
    FlowChartSetting: FlowChartSettingReducer,
    reportApi: ReportApiReducer,
    userMapping: UserMappingReducer,
    ai: AiReducer,
    aiDuonut: AiDuonutReducer,
    AiSetting: AiSettingReducer,
})

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
})

setupListeners(store.dispatch)
