import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./AnimationSetting.css";
import { updateAnimations, updateSlideAnimation } from '../../../redux/slices/userActivity';
import EditDuonutIcon from "../../../assets/icons/edit-duonut.svg";
import { updateBasecolor, updateBaseBgImage } from "../../../redux/slices/duonutSettings";
import { SketchPicker } from "react-color";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { toDataUrl } from "../../../utils";
import { domain } from "../../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ImageModal from "./imageModal";

export default function AnimationSetting() {
    const { selectedUserActivity, selectedComponent, userActivities, } = useSelector((state) => state.userActivity);
    const { base_color, base_bgimage } = useSelector((state) => state.duonutSettings.settings);

    const dispatch = useDispatch();
    const [animations, setAnimations] = useState("");
    const [slideAnimation, setSlideAnimation] = useState('');
    const [animationSelected, setAnimationSelected] = useState('')
    const [slideAnimationSelected, setSlideAnimationSelected] = useState('')
    const [selectedTab, setselectedTab] = useState(1)
    const [appliedSlideAnimation, setappliedSlideAnimation] = useState(false);
    const [isActiveColor, setIsActiveColor] = useState(false);
    const inputFileBaseRef = useRef(null);
    const colorPickerRef = useRef(null);
    const handleCloseColor = () => setIsActiveColor(false);
    useOutsideAlerter(colorPickerRef, handleCloseColor);
    const location = useLocation();
    const [paramsid, setparamsid] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);

    useEffect(() => {
        console.log(selectedComponent)
        if (selectedComponent.target) {
            setselectedTab(2);
        } else {
            setselectedTab(1);
        }
    }, [selectedComponent])

    useEffect(() => {
        // console.log(animations)
        if (animations === undefined) { setAnimationSelected('none'); return }
        animations ? setAnimationSelected(animations) : setAnimationSelected('none')
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id, animations])

    useEffect(() => {
        // console.log(slideAnimation)
        if (slideAnimation === undefined) { setSlideAnimationSelected('none'); return }
        slideAnimation ? setSlideAnimationSelected(slideAnimation) : setSlideAnimationSelected('none')
    }, [selectedUserActivity, userActivities[selectedUserActivity], slideAnimation])

    useEffect(() => {
        if (userActivities[selectedUserActivity]) {
            setSlideAnimation(userActivities[selectedUserActivity]?.slideAnimation);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity]]);

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAnimation = tempElement?.animation;
            setAnimations(elementAnimation);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleAnimationChange = (event) => {
        if (userActivities[selectedUserActivity]) {
            setAnimations(animations);
            dispatch(
                updateAnimations({
                    selectedElementIdx: selectedComponent.target_id,
                    animation: event.target.value,
                })
            );
        }
        // console.log(userActivities)
    };

    const handleSlideAnimationChange = (event) => {
        if ((userActivities[selectedUserActivity])) {
            setSlideAnimation(event.target.value);
            dispatch(
                updateSlideAnimation({
                    selectedActivityIdx: selectedUserActivity,
                    slideAnimation: event.target.value,
                })
            );
        }
        // console.log(userActivities)
    };

    const handelSelectTab = (num) => {
        // console.log("tab", num)
        setselectedTab(num);
    }

    const handleClickApplyAll = () => {
        userActivities.map((item, i) => {
            dispatch(
                updateSlideAnimation({
                    selectedActivityIdx: i,
                    slideAnimation: slideAnimation,
                })
            );
        })

        setappliedSlideAnimation(true);

        setTimeout(() => {
            setappliedSlideAnimation(false);
        }, 2000); //miliseconds
    };

    const handleColorChange = (e) => {
        const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        // console.log(e, updatedColor);
        dispatch(updateBasecolor(e.hex))
    }

    const handleColorChange1 = (e) => {
        const updatedColor = e.target.value;
        const hexPattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;

        if (hexPattern.test(updatedColor) || updatedColor === '') {
            dispatch(updateBasecolor(updatedColor))
        }
    }

    const handleBaseImageChange = (e) => {
        let baseImage = {};
        // console.log(e.target.files)
        toDataUrl(e.target.files[0], (res) => {
            let result = res;
            const image_data = {
                duonut_id: paramsid,
                name: e.target.files[0].name,
                uri: result,
            }
            axios({
                method: "POST",
                url: `${domain}/duonut/upload_files`,
                headers: {
                    "Content-Type": "application/json"
                },
                data: JSON.stringify(image_data),
            }).then((res) => {
                //console.log(res.data.file_url);
                dispatch(updateBaseBgImage({ base_bgimage: res.data.file_url }));

            }).catch((e) => {
                console.log(e.message, e);
            });

        });
    };

    const handleBaseImageDelete = (e) => {
        let baseImage = "";
        dispatch(updateBaseBgImage({ base_bgimage: baseImage }));
    };

    const handleClick1 = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (<>
        {isModalOpen && <ImageModal handleClose={handleCloseModal} />}
        <div style={{ padding: "10px", width: "100%" }}>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <label className="setting-header-label" style={{ color: selectedTab === 1 ? "#3184C9" : "" }} onClick={() => handelSelectTab(1)}>
                    Base
                    {selectedTab === 1 && <div className="setting-selected-line"></div>}
                </label>
                {selectedComponent.target && <label className="setting-header-label" style={{ color: selectedTab === 2 ? "#3184C9" : "" }} onClick={() => handelSelectTab(2)}>
                    Animation
                    {selectedTab === 2 && <div className="setting-selected-line"></div>}
                </label>}
                <label className="setting-header-label" style={{ color: selectedTab === 3 ? "#3184C9" : "" }} onClick={() => handelSelectTab(3)}>
                    Slide Transitions
                    {selectedTab === 3 && <div className="setting-selected-line"></div>}
                </label>
            </div>
            <hr style={{ marginBottom: "1rem" }}></hr>

            {selectedTab === 1 && <div>
                <div style={{ margin: "1rem auto" }}>
                    <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "2rem" }}>
                        Base Color
                        <div style={{ display: "flex" }}>
                            <div
                                onClick={() => setIsActiveColor(true)}
                                style={{
                                    width: "26px", height: "26px", boxShadow: "0px 0px 2px 0px",
                                    backgroundColor: base_color, position: "relative", borderRadius: "5px 0px 0px 5px"
                                }}
                            >
                                {isActiveColor && (
                                    <div
                                        ref={colorPickerRef}
                                        style={{
                                            position: "absolute",
                                            left: "0px",
                                            top: "30px",
                                            zIndex: "1"
                                        }}
                                    >
                                        <SketchPicker color={base_color} onChangeComplete={(e) => handleColorChange(e)} />
                                    </div>)}
                            </div>

                            <input type="text" value={base_color} id='color'
                                style={{ width: base_color !== "#f7f6f6" ? "8rem" : "9.8rem", boxShadow: "0px 0px 1.5px 0px", borderRadius: "0px 5px 5px 0px", textAlign: "left", padding: "5px", paddingLeft: "10px" }}
                                onChange={(e) => (handleColorChange1(e))} />

                            {base_color !== "#f7f6f6" && <i style={{ cursor: "pointer", width: "15px", padding: "5px", marginLeft: "4px", background: "white", borderRadius: "18px", textAlign: "center" }}
                                className="fa fa-trash edit-duonut-icon" aria-hidden="true" onClick={() => dispatch(updateBasecolor("#f7f6f6"))}></i>}
                        </div>

                    </label>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", margin: "1rem auto", marginRight: "2rem" }}>
                    <p>Base Image</p>
                    <div className="baseImage_container">
                        <img src={EditDuonutIcon} className="edit-duonut-icon" onClick={() => inputFileBaseRef.current.click()} />
                        {base_bgimage ? <img src={base_bgimage} /> : <img src="/assets/dummy-image-square.jpg" onClick={() => inputFileBaseRef.current.click()} />}
                        {base_bgimage && <i style={{ cursor: "pointer", top: "unset", bottom: "6px", right: "6px", width: "15px", padding: "5px", background: "white", borderRadius: "18px", textAlign: "center" }}
                            className="fa fa-trash edit-duonut-icon" aria-hidden="true" onClick={(e) => handleBaseImageDelete(e)}></i>}
                    </div>

                    <input ref={inputFileBaseRef} type="file" onChange={(e) => handleBaseImageChange(e)} style={{ display: "none" }} accept="image/*" />
                </div>
                <button className="ApplyAllBgBtn" onClick={() => handleClick1()}>
                    <i className="fa fa-picture-o" aria-hidden="true"
                        style={{ marginRight: "0.6rem", fontSize: "1rem", opacity: "0.8" }}></i>
                    Upload from Gallary
                </button>

            </div>}

            {selectedTab === 2 && <>
                {/* <h4>Effects</h4> */}
                <div className="AS1effectContainer">
                    <label>
                        <input type="radio" value="none"
                            checked={animationSelected === "none"}
                            onChange={handleAnimationChange}
                        />
                        None
                    </label>
                    <label>
                        <input type="radio" value="animation-left-to-right"
                            checked={animationSelected === "animation-left-to-right"}
                            onChange={handleAnimationChange}
                        />
                        Slide-left-to-right
                    </label>
                    <label>
                        <input type="radio" value="animation-right-to-left"
                            checked={animationSelected === "animation-right-to-left"}
                            onChange={handleAnimationChange}
                        />
                        Slide-right-to-left
                    </label>
                    <label>
                        <input type="radio" value="animation-top-to-bottom"
                            checked={animationSelected === "animation-top-to-bottom"}
                            onChange={handleAnimationChange}
                        />
                        Slide-top-to-bottom
                    </label>
                    <label>
                        <input type="radio" value="animation-bottom-to-top"
                            checked={animationSelected === "animation-bottom-to-top"}
                            onChange={handleAnimationChange}
                        />
                        Slide-bottom-to-top
                    </label>
                    <label>
                        <input type="radio" value="animation-fade-in"
                            checked={animationSelected === "animation-fade-in"}
                            onChange={handleAnimationChange}
                        />
                        Fade-in
                    </label>
                    <label>
                        <input type="radio" value="animation-zoom-in"
                            checked={animationSelected === "animation-zoom-in"}
                            onChange={handleAnimationChange}
                        />
                        Zoom
                    </label>
                    <label>
                        <input type="radio" value="animation-bounceInLeft"
                            checked={animationSelected === "animation-bounceInLeft"}
                            onChange={handleAnimationChange}
                        />
                        BounceInLeft
                    </label>
                    <label>
                        <input type="radio" value="animation-bounceInRight"
                            checked={animationSelected === "animation-bounceInRight"}
                            onChange={handleAnimationChange}
                        />
                        BounceInRight
                    </label>
                    <label>
                        <input type="radio" value="animation-rotate"
                            checked={animationSelected === "animation-rotate"}
                            onChange={handleAnimationChange}
                        />
                        Rotate
                    </label>
                    <label>
                        <input type="radio" value="animation-swirl"
                            checked={animationSelected === "animation-swirl"}
                            onChange={handleAnimationChange}
                        />
                        Swirl
                    </label>
                    <label>
                        <input type="radio" value="animation-rollInLeft"
                            checked={animationSelected === "animation-rollInLeft"}
                            onChange={handleAnimationChange}
                        />
                        RollInLeft
                    </label>
                    <label>
                        <input type="radio" value="animation-rollInRight"
                            checked={animationSelected === "animation-rollInRight"}
                            onChange={handleAnimationChange}
                        />
                        RollInRight
                    </label>
                </div>
            </>}

            {selectedTab === 3 && <>
                {/* <h4> Transition Style</h4> */}

                <div style={{ display: "flex", justifyContent: "center", margin: "1rem auto" }}>
                    <div style={{ width: "150px", height: "100px", position: "relative", overflow: "hidden" }}>
                        <div className={`${slideAnimationSelected}`} style={{
                            display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontWeight: "800",
                            width: "150px", height: "100px", backgroundColor: "gray", borderRadius: "6px", position: "absolute"
                        }}>Slide</div>
                    </div>
                </div>

                <div className="AS1effectContainer">
                    <label>
                        <input type="radio" value="none"
                            checked={slideAnimationSelected === "none"}
                            onChange={handleSlideAnimationChange}
                        />
                        None
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-rotateCarouselLeftIn"
                            checked={slideAnimationSelected === "slide-animation-rotateCarouselLeftIn"}
                            onChange={handleSlideAnimationChange}
                        />
                        RotateCarouselLeftIn
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-moveFromRight"
                            checked={slideAnimationSelected === "slide-animation-moveFromRight"}
                            onChange={handleSlideAnimationChange}
                        />
                        MoveFromRight
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-right-reveal"
                            checked={slideAnimationSelected === "slide-animation-right-reveal"}
                            onChange={handleSlideAnimationChange}
                        />
                        Right-reveal
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-rotateCubeLeftIn"
                            checked={slideAnimationSelected === "slide-animation-rotateCubeLeftIn"}
                            onChange={handleSlideAnimationChange}
                        />
                        RotateCubeLeftIn
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-rotateRoomLeftIn"
                            checked={slideAnimationSelected === "slide-animation-rotateRoomLeftIn"}
                            onChange={handleSlideAnimationChange}
                        />
                        RotateRoomLeftIn
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-scaleUp"
                            checked={slideAnimationSelected === "slide-animation-scaleUp"}
                            onChange={handleSlideAnimationChange}
                        />
                        ScaleUp
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-rotateSlideIn"
                            checked={slideAnimationSelected === "slide-animation-rotateSlideIn"}
                            onChange={handleSlideAnimationChange}
                        />
                        RotateSlideIn
                    </label>
                    <label>
                        <input type="radio" value="slide-animation-PopIn"
                            checked={slideAnimationSelected === "slide-animation-PopIn"}
                            onChange={handleSlideAnimationChange}
                        />
                        Pop
                    </label>
                </div>

                <div style={{ height: "2rem" }}>
                    <button type='submit' className='ApplyAllBtn' onClick={() => handleClickApplyAll()}>
                        {appliedSlideAnimation ? "Applied" : "Apply To All Steps"}</button>
                </div>

            </>}

        </div>
    </>
    );
}
