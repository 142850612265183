import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flowChartModalSetting: {
    active: false,
    edgeId: ""
  }
};

const FlowChartSetting = createSlice({
  name: "FlowChartSetting",
  initialState,
  reducers: {
    toggleFlowChartModal: (state, { payload }) => {
      state.flowChartModalSetting.active = payload;
    },
    updateFlowChartId: (state, { payload }) => {
      state.flowChartModalSetting.edgeId = payload;
    },
  },
});

export const { toggleFlowChartModal, updateFlowChartId } = FlowChartSetting.actions;
export default FlowChartSetting.reducer;
