import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";

export default function SingleOption({ item, idx }) {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [option, setOption] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        setOption(item);
    }, [item]);

    const handleOptionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;
        setOption(newText)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of the options array and update the text of the option at index 'indx'
            const updatedOptions = elementAttrib.options.map((option, index) => {
                if (index === indx) {
                    return newText;
                }
                return option;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };

            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const removeOption = (indx) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of options array and remove the option at index 'indx'
            const updatedOptions = elementAttrib.options.filter((option, index) => index !== indx);

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px"
        }} key={idx}  >
            <div style={{ marginRight: "1rem" }}>Option{idx + 1}</div>
            <EditText
                id={`option-input-${idx}`}
                value={option}
                style={{
                    border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                    display: "flex", justifyContent: "flex-start", alignItems: "center",
                    width: "13rem", height: "1.8rem", padding: "5px"
                }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#option-input-${idx}`).select(), 100); }}
                onChange={(e) => (e.target.value.length < 0 ? null : handleOptionTextChange(e, idx))}
            />

            <i className="fa fa-trash-o" aria-hidden="true"
                style={{ marginLeft: "0.6rem", fontSize: "1rem", color: "#DB615C", cursor: "pointer" }} onClick={() => removeOption(idx)}></i>
        </div>
    )
}

