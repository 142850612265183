import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Reorder = ({
    id,
    elementAttr,
    elementHoverAttr,
    conditions,
    setShowModel,
    setCorrectAnswer
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const { text, options, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor, fieldPosition,
    } = elementAttr;

    const { viewType } = useSelector((state) => state.duonutSettings);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];

    const [hover, setHover] = useState(-1);
    const [reorderOption, updateReorderOption] = useState([]);
    const [answer, setAnswer] = useState("");

    function shuffle(array) {
        let shuffledArray = [];
        let usedIndexes = [];
        let i = 0;
        while (i < array.length) {
            let randomNumber = Math.floor(Math.random() * array.length);
            // console.log(randomNumber, i);
            if (!usedIndexes.includes(randomNumber) && i !== randomNumber) {
                shuffledArray.push(array[randomNumber]);
                usedIndexes.push(randomNumber);
                i++;
            } else if (!usedIndexes.includes(randomNumber) && i === array.length - 1) {
                shuffledArray.push(shuffledArray[0]);
                shuffledArray[0] = array[randomNumber];
                i++;
            }
        }
        // console.log(shuffledArray);

        return shuffledArray;
    }

    useEffect(() => {
        if (location.pathname.includes("/duonut/")) {
            const objCopy = [...options];
            updateReorderOption(shuffle(objCopy));
        } else {
            updateReorderOption(options);
        }

        var answerText = "";
        options.map(option => {
            if (answerText === "") {
                answerText = option;
            } else {
                answerText = answerText + ', ' + option;
            }
        })
        setAnswer(answerText);
    }, [options]);


    const getItemStyle = (isDragging, draggableStyle) => ({

        // background: isDragging ? elementHoverAttr?.backgroundColor : '',

        ...draggableStyle,
    });

    function handleOnDragEnd(result) {
        // console.log(result);
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const items = Array.from(reorderOption);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateReorderOption(items);
        // console.log(reorderOption);

        if (JSON.stringify(options) === JSON.stringify(items)) {
            setCorrectAnswer(answer);
            setShowModel(1);

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 1,
                question: text ? text : "",
                answered_text: "",
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "Reorder",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                type: "Reorder",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: "",
            }
            dispatch(saveQuizResponse(save_response));

            var nextPage = selectedSlide + 1;

            setTimeout(() => {
                setShowModel(0);
                dispatch(updateGoToSlide(nextPage));
            }, 3100);

        }

    }

    return (
        <><div style={{
            borderRadius, display: "flex", flexDirection: "column", gap: gap
        }}>
            <div
                style={{
                    width: "100%", paddingBottom: '10px',
                    color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing,
                }}>{text}</div>

            <div className='remove-gutter' style={{
                display: "flex", justifyContent: textAlign, alignItems: 'center',
                flexDirection: "column"
            }}>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="dragOpt" type='dragOpt' direction='vertical'>
                        {(provided, snapshot) => (
                            <div style={{ display: "flex", flexDirection: "inherit", width: "100%" }} {...provided.droppableProps} ref={provided.innerRef}>

                                {reorderOption.map((item, i) => {
                                    return (
                                        <Draggable key={i} draggableId={(i).toString()} index={i} isDragDisabled={(location.pathname).includes("/duonut/") ? false : true}>
                                            {(provided, snapshot) => (
                                                <div className='dragstyle'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style),

                                                        backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                                        border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                                        color: hover === i ? elementHoverAttr?.color : color,
                                                        borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                                        textTransform, textAlign, letterSpacing, padding, margin, lineHeight,
                                                        cursor: (location.pathname).includes("/duonut/") ? "grab" : "default"
                                                    }}
                                                    onMouseEnter={() => setHover(i)}
                                                    onMouseLeave={() => setHover(-1)}
                                                >
                                                    <div style={{}}>{item}</div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
        </>
    );
}

export default Reorder;
