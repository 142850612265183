import React, { useRef, useEffect, useState } from 'react';
import "../../assets/bootstrap.min.css";
import './FlowChart.css';
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { useSelector, useDispatch } from 'react-redux'
import { toggleFlowChartModal, updateFlowChartId } from "../../redux/slices/FlowChartSettingReducer";
import { updateCondition, deleteCondition } from "../../redux/slices/userActivity";
import Select from 'react-select';

const ConditionModel = (props) => {
    // console.log(props);
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const { edgeId } = useSelector((state) => state.FlowChartSetting.flowChartModalSetting);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const ref1 = useRef(null);
    const edgeRef = useRef(null);
    const handleClose = () => dispatch(toggleFlowChartModal(false));
    useOutsideAlerter(edgeRef, handleClose);

    const [selectedEdge, setselectedEdge] = useState({});
    const [inputValue, setinputValue] = useState([]);
    const [inputValueSelected, setinputValueSelected] = useState([]);
    const [checkboxValue, setcheckboxValue] = useState(false);
    const [alwaysActive, setalwaysActive] = useState(true);
    const [surveyInputValue, setsurveyInputValue] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        props?.edges?.map((item, i) => {
            if (item.id === edgeId) {
                console.log(item)
                setselectedEdge(item);
                setinputValue(item.condition ? item.condition : []);
                setinputValueSelected(item.condition?.map(item => ({ value: item, label: item })));
                if (userActivities[item.source - 1]?.elements.some(element => element.elementType === "Rating")) {
                    var surveyNub = (item.condition && item.condition[1]) ? parseInt(item.condition[1]) : 0;
                    setsurveyInputValue(surveyNub);
                }
                setcheckboxValue(item.always ? item.always : false);
                if (item.always) {
                    dispatch(
                        updateCondition({
                            selectedActivityIdx: parseInt(item.source) - 1,
                            condition: { ...item, condition: [], always: true }
                        })
                    );
                }
                var count = 0;
                props?.edges?.map((item1, j) => {
                    if (item1.source === item.source) {
                        count++;
                    }
                })
                if (count > 2) {
                    setalwaysActive(false);
                }
            }
        })
    }, []);

    const handleInputValueChange = inputValueSelected => {
        // console.log("inputValueSelected", inputValueSelected);
        setinputValueSelected(inputValueSelected);

        var inputValueArray = inputValueSelected.map(item => item.value)
        // console.log(inputValueArray);

        setinputValue(inputValueArray);
    };

    const handleSurveyInputValueChange = inputValueSelected => {
        // console.log("inputValueSelected", inputValueSelected);
        setinputValueSelected(inputValueSelected);

        var inputValueArray = [inputValueSelected.value]
        // console.log(inputValueArray);

        setinputValue(inputValueArray);
    };

    const handleSurveyInputBoxValue = value => {
        // console.log("value", value);
        setsurveyInputValue(value);

        const inputValueArray = [...inputValue];
        inputValueArray[1] = value;
        // console.log(inputValueArray);

        setinputValue(inputValueArray);
    };

    const cancelbtn = () => {
        ref1.current.click();
        setinputValue([]);
        dispatch(toggleFlowChartModal(false));
    }

    const savebtn = () => {
        ref1.current.click();
        setinputValue([]);
        dispatch(toggleFlowChartModal(false));

        // console.log(selectedEdge);
        if (checkboxValue) {
            dispatch(
                updateCondition({
                    selectedActivityIdx: parseInt(selectedEdge.source) - 1,
                    condition: { ...selectedEdge, condition: [], always: true }
                })
            );
        } else {
            dispatch(
                updateCondition({
                    selectedActivityIdx: parseInt(selectedEdge.source) - 1,
                    condition: { ...selectedEdge, condition: inputValue, always: false }
                })
            );
        }
    }

    const deletebtn = () => {
        ref1.current.click();
        setinputValue([]);
        dispatch(toggleFlowChartModal(false));

        console.log(selectedEdge);
        dispatch(
            deleteCondition({
                selectedActivityIdx: parseInt(selectedEdge.source) - 1,
                condition: { ...selectedEdge }
            })
        );
    }

    const handelChangeCheckbox = () => {
        setcheckboxValue(!checkboxValue);
        setinputValue([]);
    }

    // Function to strip HTML tags and keep only text content
    const stripHtml = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };


    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#conditionModal">
        </button>
            <div className="modal fade" id="conditionModal" tabIndex="-1" aria-labelledby="conditionModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div ref={edgeRef} className="modal-content" style={{ backgroundColor: "white", color: "black", textAlign: "center", marginTop: "22vh", boxShadow: "rgba(25, 47, 89, 0.38) 0px 22px 44px -12px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className="condition-close-icon" onClick={() => cancelbtn()}>
                                <img src="/assets/icons/close.svg" />
                            </div>

                            <h3>Edit Logic for slide {selectedEdge.source} to {selectedEdge.target}</h3>
                            <br></br>

                            {alwaysActive && <div>
                                <div style={{ display: "flex", justifyContent: "center" }}><b>Always go to slide  {selectedEdge.target} </b>
                                    <input type="checkbox" style={{ width: "25px", height: "22px", marginLeft: "2rem", cursor: "pointer" }}
                                        checked={checkboxValue ? true : false} onChange={() => handelChangeCheckbox()} />
                                </div>

                            </div>}
                            {alwaysActive && (userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "CheckboxGroup") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "RadioGroup") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Mcq") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Rating")) &&
                                <div style={{ margin: "1rem 0rem" }}><b>OR</b></div>}
                            {(userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "CheckboxGroup") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "RadioGroup") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Mcq") ||
                                userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Rating")) &&
                                <div style={{ opacity: checkboxValue ? "0.5" : "1" }}>
                                    <div> <b>If {selectedEdge.source}. </b>
                                        {userActivities[selectedEdge.source - 1]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text ?
                                            stripHtml(userActivities[selectedEdge.source - 1]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text)
                                            : userActivities[selectedEdge.source - 1]?.name}</div>
                                    <br></br>
                                    <div><b>is</b>
                                        {userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "CheckboxGroup") ?
                                            <Select
                                                className=''
                                                isMulti
                                                options={userActivities[selectedEdge.source - 1]?.elements
                                                    .find(element => element.elementType === "CheckboxGroup")
                                                    ?.elementAttributes.options.map((item, i) => ({
                                                        value: item,
                                                        label: item,
                                                        isDisabled: userActivities[selectedEdge.source - 1]?.condition.some(cond => (cond.condition?.includes(item)))
                                                    }))}
                                                value={inputValueSelected}
                                                onChange={handleInputValueChange}
                                                isDisabled={checkboxValue ? true : false}
                                            />
                                            : userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "RadioGroup") ?
                                                <Select
                                                    className=''
                                                    isMulti
                                                    options={userActivities[selectedEdge.source - 1]?.elements
                                                        .find(element => element.elementType === "RadioGroup")
                                                        ?.elementAttributes.options.map((item, i) => ({
                                                            value: item,
                                                            label: item,
                                                            isDisabled: userActivities[selectedEdge.source - 1]?.condition.some(cond => (cond.condition?.includes(item)))
                                                        }))}
                                                    value={inputValueSelected}
                                                    onChange={handleInputValueChange}
                                                    isDisabled={checkboxValue ? true : false}
                                                />
                                                : userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Rating") ?
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <Select
                                                            className='surveyOptionSelect'
                                                            // isMulti
                                                            options={[{
                                                                value: "equal to",
                                                                label: "equal to",
                                                            },
                                                            {
                                                                value: "not equal to",
                                                                label: "not equal to",
                                                            },
                                                            {
                                                                value: "lower than",
                                                                label: "lower than",
                                                            },
                                                            {
                                                                value: "greater than",
                                                                label: "greater than",
                                                            },
                                                            {
                                                                value: "lower or equal to",
                                                                label: "lower or equal to",
                                                            },
                                                            {
                                                                value: "greater or equal to",
                                                                label: "greater or equal to",
                                                            }]}
                                                            value={inputValueSelected}
                                                            onChange={handleSurveyInputValueChange}
                                                            isDisabled={checkboxValue ? true : false}
                                                        />
                                                        <input className='conditionInput'
                                                            style={{ width: "45%" }}
                                                            type='number'
                                                            value={surveyInputValue}
                                                            onChange={e => handleSurveyInputBoxValue(e.target.value)}
                                                            required
                                                            disabled={checkboxValue ? true : false}
                                                        />
                                                    </div>
                                                    : userActivities[selectedEdge.source - 1]?.elements.some(element => element.elementType === "Mcq") ?
                                                        <Select
                                                            className=''
                                                            isMulti
                                                            options={userActivities[selectedEdge.source - 1]?.elements
                                                                .find(element => element.elementType === "Mcq")
                                                                ?.elementAttributes.options.map((item, i) => ({
                                                                    value: item,
                                                                    label: item,
                                                                    isDisabled: userActivities[selectedEdge.source - 1]?.condition.some(cond => (cond.condition?.includes(item)))
                                                                }))}
                                                            value={inputValueSelected}
                                                            onChange={handleInputValueChange}
                                                            isDisabled={checkboxValue ? true : false}
                                                        />
                                                        : <input className='conditionInput'
                                                            value={inputValue}
                                                            onChange={e => setinputValue(e.target.value)}
                                                            required
                                                            disabled={checkboxValue ? true : false}
                                                        />
                                        }
                                    </div>
                                    <br></br>
                                    <div><b>Then Go to</b></div>
                                    <br></br>
                                    <div><b>{selectedEdge.target}. </b>{userActivities.length < selectedEdge.target ?
                                        "End Page"
                                        : userActivities[selectedEdge.target - 1]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text ?
                                            stripHtml(userActivities[selectedEdge.target - 1]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text)
                                            : userActivities[selectedEdge.target - 1]?.name}</div>
                                </div>}
                            <br></br>
                            <button className='SM1Continuebtn btn-secondary' onClick={() => savebtn()}>Save</button>
                            <button className='SM1Continuebtn btn-secondary' onClick={() => cancelbtn()}>Cancel</button>
                            <button className='SM1Continuebtn btn-secondary' onClick={() => deletebtn()}>Delete</button>
                        </div>
                    </div>
                </div>
            </div></>
    );

}

export default ConditionModel;