import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import "./form.css";
import EmailSignUp from "./forms/EmailSignUp";

import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";

import { closeModal, showSignInForm, toggleEnterCodeModal, updateEnterCodeEmail } from "../../../redux/slices/formDetails";

import Signin from "./forms/signin";
import Signup from "./forms/signup";
import EnterCode from "./enterCode";
import SuccessModal from "../SuccessModal/SuccessModal";

export default function Form() {
  const { modalOpen, signUpactive, enterCodeForm, changePasswordModalActive } = useSelector((state) => state.formDetails);
  const [emailSignUpActive, setEmailSignUpActive] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState(false);
  const [signupEmail, setSignupEmail] = useState(false);
  const [signInError, setSignInError] = useState('')
  const [successModalActive, setSuccessModalActive] = useState(false)

  const dispatch = useDispatch();

  useDisableBodyScroll(modalOpen);

  const handleClose = () => dispatch(closeModal());

  //signup first stage complete
  const onVerifyEmail = (email, isVerified) => {
    setSignupEmail(email);
    if (isVerified) return setEmailSignUpActive(true);

    dispatch(updateEnterCodeEmail(email));
    const payload = { fromRegister: true };
    dispatch(toggleEnterCodeModal(payload));
  };

  const onRegisterComplete = () => {
    dispatch(showSignInForm());
    setEmailSignUpActive(false)
    handleClose();

    setSuccessModalActive(true)
    setTimeout(() => {
      setSuccessModalActive(false)
    }, 2000);

  };

  const pageVariants = {
    initial: { y: "-100vh" },
    in: { y: 0 },
    out: { y: "-100vh" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.6,
  };

  return (
    <>
      <AnimatePresence>
        {modalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.6 }}
            className="modal_container"
            style={{ display: enterCodeForm.enterCodeModalActive || changePasswordModalActive ? "none" : "" }}
          >
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} className="form_modal row">
              {/* <div className="col-lg-6 img-column">
                <div className="form-img-container">
                  <img src="/assets/form.png" className="form-img" loading="lazy" />
                </div>
              </div> */}
              {/* <div className="col-lg-6 form-column">
                 <img style={{ width: "10rem", display: "flex", margin: "auto", paddingTop: "0.5rem" }} src="/assets/icons/duonut-logo.png" /> 
                {emailSignUpActive ? (
                  <EmailSignUp setEmailRegistered={setEmailRegistered} setEmailSignUpActive={setEmailSignUpActive} email={signupEmail} onRegisterComplete={onRegisterComplete} />
                ) : signUpactive ? (
                  <Signup handleClose={handleClose} onVerifyEmail={onVerifyEmail} setSignInError={setSignInError} />
                ) : (
                  <Signin handleClose={handleClose} signInError={signInError} setSignInError={setSignInError} />
                )}

              </div> */}
              <div className="form-column">
                {emailSignUpActive ? (
                  <EmailSignUp
                    setEmailRegistered={setEmailRegistered}
                    setEmailSignUpActive={setEmailSignUpActive}
                    email={signupEmail}
                    onRegisterComplete={onRegisterComplete} />
                ) : signUpactive ? (
                  <Signup
                    handleClose={handleClose}
                    onVerifyEmail={onVerifyEmail}
                    setSignInError={setSignInError} />
                ) : (
                  <Signin
                    handleClose={handleClose}
                    signInError={signInError}
                    setSignInError={setSignInError} />
                )
                }

                <div className="close-icon icon-button" onClick={handleClose}>
                  <img src="/assets/icons/close.svg" />
                </div>
              </div>
            </motion.div>
            <div className="modal-overlay" style={{ backdropFilter: "blur(3px)" }} onClick={handleClose}></div>
          </motion.div>
        )}
      </AnimatePresence>
      {enterCodeForm.enterCodeModalActive && <EnterCode setEmailSignUpActive={setEmailSignUpActive} />}
      {successModalActive &&
        <SuccessModal hideHeader={true} hideButtons={true} message={
          <>
            <img src='/assets/icons/tick.svg' />
            <p style={{ marginTop: '24px' }} >User Created</p>
          </>
        }
        />
      }
    </>
  );
}
