import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import "./UserMapping.css";
import { motion, AnimatePresence } from "framer-motion";
import { toggleUserMappingModal, getNuggetViewerAsync, showUserMappingresponse, createNuggetViewerMapperAsync, deleteNuggetViewerMapperAsync } from "../../../redux/slices/UserMappingReducer";
import { getMyDuonuts } from '../../../services/duonut'
import { frontendUrl, backendUrl } from "../../../services/constants";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";

export default function UserMapping() {
    const appId = localStorage.getItem("appId");
    const dispatch = useDispatch();
    const { modalActive, nuggetId } = useSelector((state) => state.userMapping);
    const [userMappingData, setuserMappingData] = useState([]);
    const [duonuts, setDuonuts] = useState([]);
    const [emailId, setemailId] = useState("");
    const [isedit, setisedit] = useState(false);
    const [editId, seteditId] = useState(0);
    const [message, setmessage] = useState("");
    const [ischange, setischange] = useState(false);
    const [removeUserData, setremoveUserData] = useState("");
    const [errorMessage, seterrorMessage] = useState("");

    const ref = useRef(null);
    const ref1 = useRef(null);
    const removeRef = useRef(null);
    const removeRef1 = useRef(null);
    // to close duonut options menu
    const modalRef = useRef(null)
    const removemodalRef = useRef(null)
    const handleCloseModal = () => { setisedit(false); seteditId(0); }
    useOutsideAlerter(modalRef, handleCloseModal);

    const handleremoveModal = () => { setremoveUserData(""); }
    useOutsideAlerter(removemodalRef, handleremoveModal);

    const handleClose = () => {
        dispatch(toggleUserMappingModal(false));
    };

    useEffect(() => {
        dispatch(getNuggetViewerAsync(nuggetId));
        // console.log("inside");
    }, [ischange]);

    const mappingData = useSelector(showUserMappingresponse);
    const mappingData1 = mappingData[0];
    // console.log(mappingData1);

    useEffect(() => {
        if (mappingData1) {
            // console.log(data);
            if (mappingData1.empty_message) {
                setmessage("No data available.");
            } else {
                var data = mappingData1.nugget_user
                setuserMappingData(data);
            }
        }
    }, [mappingData1])

    useEffect(() => {
        getMyDuonuts((err, res) => {
            if (err) console.log(err.response)
            else {
                if (res.data.length === 0) return
                setDuonuts(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))
                // console.log(res)
            }
        })
    }, [])

    const handelClickNew = () => {
        setemailId("")
        ref.current.click();
    }

    const submit = (event) => {
        event.preventDefault();

        var mappingData = {
            emailId: emailId,
            nuggetId: nuggetId
        }
        dispatch(createNuggetViewerMapperAsync(mappingData));
        setmessage("User Mapping created successfully.");

        setTimeout(() => {
            setmessage("");
            setischange(!ischange);
            ref1.current.click();
            setemailId("");
        }, 3000); //miliseconds

    }

    const handelClickEdit = (item) => {
        setisedit(true);
        seteditId(item.id);
        setemailId(item.type_id);
        ref.current.click();

    }

    const handelClickCopy = (item) => {
        if (item.enable) {
            var copyText = `${frontendUrl}/pagemapping/${item.type_id}?app_id=${appId} `;
            // copyText.select();
            // copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText);
        } else {
            seterrorMessage("Duonut is not Mapped.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const handelClickView = (item) => {
        if (item.enable) {
            window.open('/pagemapping/' + item.type_id + '?app_id=' + appId);
        } else {
            seterrorMessage("Duonut is not Mapped.");
            setTimeout(() => {
                seterrorMessage("");
            }, 3000); //miliseconds
        }
    }

    const handleokbtn = () => {
        var removemappingData = {
            userId: removeUserData,
            nuggetId: nuggetId
        }
        dispatch(deleteNuggetViewerMapperAsync(removemappingData));

        setTimeout(() => {
            setischange(!ischange);
            setremoveUserData("");
            removeRef1.current.click();
        }, 500); //miliseconds
    }
    const handlecancelbtn = () => {
        setremoveUserData("");
        removeRef1.current.click();

    }

    const handelClickDelete = (id) => {
        setremoveUserData(id);
        removeRef.current.click();
    }

    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userSettingModal">
        </button>
            <div className="modal fade" ref={modalRef} style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="userSettingModal" tabIndex="-1" aria-labelledby="userSettingModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content userSettingmodalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='userSettingModalTitle'> Map user to this Workflows</div>
                            {/* <div>Duonut mapping can be done if you want to keep the same QR code or link, but wants to change the activity from backend. </div> */}
                            {/* <br></br> */}
                            <form onSubmit={submit}>
                                <div className='usersettingInputbox'>
                                    <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Email Id</div>
                                    <input type="email" value={emailId} onChange={(e) => setemailId(e.target.value)} />
                                </div>
                                {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                                <button type="submit" className="usersettingModalSubmitbtn" disabled={message ? true : false} style={{ margin: "2vh", backgroundColor: message ? "rgba(7, 168, 7, 0.897)" : "" }} >{message ? "Submitted" : "Submit"}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <button ref={removeRef} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#userSettingRemoveModal">
            </button>
            <div className="modal fade" ref={removemodalRef} style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="userSettingRemoveModal" tabIndex="-1" aria-labelledby="userSettingRemoveModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content userSettingmodalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={removeRef1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='userSettingModalTitle'> Are you sure want to remove this Mapping User?</div>
                            <br></br>

                            {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                            <button className="usersettingModalSubmitbtn" style={{ margin: "2vh" }} onClick={() => handleokbtn()}>OK</button>
                            <button className="usersettingModalSubmitbtn" style={{ margin: "2vh" }} onClick={() => handlecancelbtn()}>cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {modalActive && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                        <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="form_modal row">
                            <div className="usersettingContainer">


                                <h1 style={{ textAlign: "center" }}>Workflows To User Mapping Page</h1>
                                <div className="usersettingDetails" style={{ justifyContent: 'center' }}>
                                    <div>
                                        {/* <div>App Id - {appId}</div>
                                        <div>Email - {localStorage.getItem("userEmail")}</div> */}
                                    </div>
                                    <button className="usersettingNewbtn" style={{ margin: "2vh" }} onClick={() => handelClickNew()}>+ NEW</button>
                                </div>
                                {errorMessage && <div style={{ color: "red", textAlign: "center" }}>{errorMessage}</div>}
                                <div className="userSettingTable" key={userMappingData}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Action</th>
                                            </tr>
                                            {userMappingData?.map((val, key) => {
                                                return (
                                                    <tr key={key} style={key % 2 === 0 ? { backgroundColor: "#F4F9FC", color: "black" } : { backgroundColor: "white", color: "black" }}>
                                                        <td>{val.viewer.name}</td>
                                                        <td>{val.viewer.email}</td>
                                                        <td className="userSettingTableAction">
                                                            {/* <div className="userSettingTableActionBtn" data-tip="Edit" onClick={() => handelClickEdit(val)}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="Copy" onClick={() => handelClickCopy(val)}><i className="fa fa-files-o" aria-hidden="true"></i></div>
                                                            <div className="userSettingTableActionBtn" data-tip="View" onClick={() => handelClickView(val)}><i className="fa fa-eye" aria-hidden="true"></i></div> */}
                                                            <div className="userSettingTableActionBtn" data-tip="Remove" onClick={() => handelClickDelete(val.viewer.id)}><i className="fa fa-trash" aria-hidden="true"></i></div>
                                                            <ReactTooltip place="top" effect="solid" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                <div className="close-icon icon-button" onClick={handleClose}>
                                    <img src="/assets/icons/close.svg" />
                                </div>
                            </div>

                        </motion.div>
                        <div className="modal-overlay" onClick={handleClose}></div>
                    </motion.div>
                )}
            </AnimatePresence></>
    );
}
