import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aiModalSetting: {
    active: false,
    aiData: {}
  }
};

const AiSetting = createSlice({
  name: "AiSetting",
  initialState,
  reducers: {
    toggleAiModal: (state, { payload }) => {
      state.aiModalSetting.active = payload;
    },
    updateAiData: (state, { payload }) => {
      state.aiModalSetting.aiData = payload;
    },
  },
});

export const { toggleAiModal, updateAiData } = AiSetting.actions;
export default AiSetting.reducer;
