import React, { useRef, useEffect } from 'react';
import './WrongModel.css';
import wrongans from '../../assets/audio/Wrong-answer.mp3';

const WrongModel = (props) => {
    const openref1 = useRef(null);
    const closeref1 = useRef(null);
    const audioRef = useRef(null);
    const openref2 = useRef(null);
    const closeref2 = useRef(null);

    const randNo = Math.floor(Math.random() * 2) + 1;
    // const randNo = 2;

    useEffect(() => {
        audioRef.current.play();
        audioRef.current.volume = 0.1;
        setTimeout(() => {
            if (randNo === 1) {
                openref1.current.click();
            } else if (randNo === 2) {
                openref2.current.click();
            } else {
                openref1.current.click();
            }
        }, 100); //miliseconds

        setTimeout(() => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            if (randNo === 1) {
                closeref1.current.click();
            } else if (randNo === 2) {
                closeref2.current.click();
            } else {
                closeref1.current.click();
            }
        }, 3000); //miliseconds
    }, []);

    return (
        <>
            <audio
                src={wrongans}
                ref={audioRef}
            ></audio>
            <button ref={openref1} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#wrongModal1">
            </button>
            <div className="modal fade" id="wrongModal1" tabIndex="-1" aria-labelledby="wrongModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M2modalBody">
                        <div className="modal-body">
                            <button ref={closeref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <img className='M2correctimg' src={require('../../assets/Images/wrong.gif')} alt="nice" />
                            <div className='M2text'>
                                <div className='M2cong'> Oops!</div>
                                <div className='M2msg'>Your Answer is Incorrect.</div>
                                {props.answer && <><br></br>
                                    <div className='M2explain'><u>Correct Answer:</u></div>
                                    <div className='M2explain'>{props.answer}</div></>}
                                {props.mtfAnswer && <><br></br>
                                    {props.mtfAnswer.map((item, i) => {
                                        return <div className='M2mtfAnswer'>
                                            {item.l_image ? <img className='M2mtfAnswerLeftImg' src={item.l_image} alt="" />
                                                : <div className='M2mtfAnswerLeft'>{item.l_option}</div>}
                                            <div className='M2mtfAnswerRight'>{item.r_option}</div>
                                        </div>
                                    })}
                                </>}
                                {props.crosswordAnswer && <><br></br>
                                    {props.crosswordAnswer.question_id.crosswordoption_set.map((item, i) => {
                                        var temp = false;
                                        props.crosswordAnswer.details.remaining.map((item1, j) => {
                                            if (item1 === item.answer) temp = true;
                                        })
                                        if (temp) return null;
                                        return <div className='M2mtfAnswer'>
                                            <div className='M2mtfAnswerLeft'>{item.clue}</div>
                                            <div className='M2mtfAnswerRight'>{item.answer}</div>
                                        </div>
                                    })}
                                </>}
                                {props.reorderAnswer && <><br></br>
                                    {props.reorderAnswer.map((item, i) => {
                                        return <div className=''>{item.option}</div>
                                    })}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button ref={openref2} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#wrongModal2">
            </button>
            <div className="modal fade" id="wrongModal2" tabIndex="-1" aria-labelledby="wrongModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M2modalBody2">
                        <div className="modal-body">
                            <button ref={closeref2} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <img className='M2correctimg2' src={require('../../assets/Images/wrong.gif')} alt="nice" />
                            <div className='M2text2'>
                                <div className='M2cong2'> OOPS!</div>
                                {props.answer && <><br></br>
                                    <div className='M2explain'><u>Correct Answer:</u></div>
                                    <div className='M2explain'>{props.answer}</div></>}
                                {props.mtfAnswer && <><br></br>
                                    {props.mtfAnswer.map((item, i) => {
                                        return <div className='M2mtfAnswer'>
                                            {item.l_image ? <img className='M2mtfAnswerLeftImg' src={item.l_image} alt="" />
                                                : <div className='M2mtfAnswerLeft'>{item.l_option}</div>}
                                            <div className='M2mtfAnswerRight'>{item.r_option}</div>
                                        </div>
                                    })}
                                </>}
                                {props.crosswordAnswer && <><br></br>
                                    {props.crosswordAnswer.question_id.crosswordoption_set.map((item, i) => {
                                        var temp = false;
                                        props.crosswordAnswer.details.remaining.map((item1, j) => {
                                            if (item1 === item.answer) temp = true;
                                        })
                                        if (temp) return null;
                                        return <div className='M2mtfAnswer'>
                                            <div className='M2mtfAnswerLeft'>{item.clue}</div>
                                            <div className='M2mtfAnswerRight'>{item.answer}</div>
                                        </div>
                                    })}
                                </>}
                                {props.reorderAnswer && <><br></br>
                                    {props.reorderAnswer.map((item, i) => {
                                        return <div className=''>{item.option}</div>
                                    })}
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default WrongModel;