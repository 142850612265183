import React, { useState, useRef, useEffect } from 'react'
import "./Sites.css"
import { frontendUrl, backendUrl } from "../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createChannelAsync, showChannelresponse, saveChannelAsync, deleteChannelAsync } from "../../redux/slices/ChannelReducer";
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";

const Sites = () => {
    const userId = localStorage.getItem("userId");
    const { isLoggedIn, userProAccess, userAccountType } = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    useEffect(() => {
        if (!userProAccess) {
            window.location = '/';
        }
    }, []);

    const [createChannel, setcreateChannel] = useState(false);
    const [channelAllData, setchannelAllData] = useState([]);
    const [channelId, setchannelId] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const delref = useRef(null);
    const delref1 = useRef(null);

    useEffect(() => {
        dispatch(saveChannelAsync());
        // console.log("inside");
    }, []);
    const channelData = useSelector(showChannelresponse);
    const channelData1 = channelData[0];

    console.log(channelData1);

    useEffect(() => {
        if (channelData1) {
            // console.log(channelData1);
            if (channelData1.empty_message) {
                setcreateChannel(true);

            } else {
                setchannelAllData(channelData1.channels);
                setcreateChannel(false);
            }
        }
    }, [channelData1])

    const onClickDeleteButton = (id) => {
        delref.current.click();
        setchannelId(id);
    }

    const confirmDelete = () => {
        delref1.current.click();
        setchannelId(null);

        var channelData = {
            id: channelId
        }
        dispatch(deleteChannelAsync(channelData));
        setTimeout(() => {
            dispatch(saveChannelAsync());
        }, 1000); //miliseconds
    }

    const cancelDelete = () => {
        delref1.current.click();
        setchannelId(null);
    }

    return (<>
        <button ref={delref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#delModal`}>
        </button>
        <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`delModal`} tabIndex="-1" aria-labelledby="delModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                    <div className="modal-body">
                        <button ref={delref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        <div className='SM1shareTitle'> Confirm delete Channel? </div>
                        <br></br>
                        <div>Do you really want to delete this Channel? </div>
                        <br></br>
                        <button className='SM1Continuebtn' onClick={() => confirmDelete()}>Confirm Delete</button>
                        <button className='SM1Continuebtn' onClick={() => cancelDelete()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        {channelAllData.length > 0 && <div className='SitesChannelApp'>
            <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <button type='submit' className='SitesChannelSavebtn' onClick={() => navigate('/createworkflow')}>
                    <i className="fa fa-plus" aria-hidden="true"></i> Create New Workflow</button>
            </div>
            <>{channelAllData.map(channel => {
                return (<div className='SitesChannelContainer'>
                    <img
                        style={{ margin: "auto", paddingRight: "1.5rem", width: "5rem" }}
                        src={"/assets/duonut_icon.png"} alt="duonut"
                        loading="lazy" />
                    <div>
                        <div className='WorkflowChannelRow'>
                            <h1 style={{ paddingBottom: "5px" }}>{channel?.channel?.name}</h1>
                            <a href={frontend_url + '/viewworkflow/' + channel?.channel?.code} target="_blank">{frontend_url}</a>
                        </div>
                        <div style={{ width: "100%" }}>
                            <button type='submit' className='SitesChannelbtn' onClick={() => onClickDeleteButton(channel?.channel?.id)}>
                                <i className="fa fa-trash" aria-hidden="true"></i> Delete Channel</button>
                            <button className='SitesChannelbtn' onClick={() => navigate(`/workflow/${channel?.channel?.id}`)} >View Dashboard</button>
                        </div>
                    </div>
                </div>
                )
            })
            }</>

        </div>}
        {createChannel && <div className='SitesChannelApp1'>
            <div className='SitesChannelContainer' style={{ flexDirection: "column", alignItems: "center" }}>
                <div className='WorkflowChannelRow'>
                    <h1 style={{ paddingBottom: "5px", textAlign: "center" }}>No any Workflow yet</h1>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <button className='SitesChannelSavebtn' onClick={() => navigate('/createworkflow')} >Create new</button>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <button className='SitesChannelSkipbtn' onClick={() => navigate('/myduonuts')} >Skip for now</button>
                </div>
            </div>
        </div>}
    </>
    );

}

export default Sites;