import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("token", token);
  return header;
};


export const channelSlide = createSlice({
  name: "channel",
  initialState: {
    response: [],
    data: []
  },
  reducers: {
    saveChannel: (state, action) => {
      state.response = [action.payload];
    },
    saveChannelData: (state, action) => {
      state.data = [action.payload];
    }
  }
});

export const saveChannelAsync = () => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_channel_detail`;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveChannel(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const createChannelAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_channel`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editChannelAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_channel`;
    const response = await axios.patch(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editChannelLogoAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_channel_logo`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteChannelAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_channel`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteChannelLogoAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_channel_logo`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const getChannelAsync = (paramsid) => async (dispatch) => {
  try {
    const code = paramsid.id;
    // console.log(code)
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/get_channel_detail_bycode/` + code;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveChannelData(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveChannel, saveChannelData } = channelSlide.actions;
export const showChannelresponse = (state) => state.channel.response;
export const showChannelData = (state) => state.channel.data;
export default channelSlide.reducer;
