import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from "react-tooltip";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function FontStyles() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [italicActive, setItalicActive] = useState(false)
    const [underlineActive, setUnderlineActive] = useState(false)
    const [alignmentActive, setAlignmentActive] = useState(1)
    const [uppercaseActive, setUppercaseActive] = useState(false)
    const [letterSpacingActive, setLetterSpacingActive] = useState(false)
    const [lineHeightActive, setLineHeightActive] = useState(false)
    const [letterSpacing, setLetterSpacing] = useState(0)
    const [lineHeight, setLineHeight] = useState(1.4)

    const dispatch = useDispatch()

    const optionsRef1 = useRef(null)
    const optionsRef2 = useRef(null)
    const handleClose1 = () => { setLineHeightActive(false); }
    useOutsideAlerter(optionsRef1, handleClose1);
    const handleClose2 = () => { setLetterSpacingActive(false); }
    useOutsideAlerter(optionsRef2, handleClose2);

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    useEffect(() => {
        if (styles) {
            styles.fontStyle === 'italic' ? setItalicActive(true) : setItalicActive(false)
            styles.textDecoration === 'underline' ? setUnderlineActive(true) : setUnderlineActive(false)
            styles.textAlign === 'left' ? setAlignmentActive(1) :
                styles.textAlign === 'center' ? setAlignmentActive(2) :
                    styles.textAlign === 'right' ? setAlignmentActive(3) :
                        styles.textAlign === 'justify' ? setAlignmentActive(4) : setAlignmentActive(1)
            styles.textTransform === 'uppercase' ? setUppercaseActive(true) : setUppercaseActive(false)
            styles.lineHeight ? setLineHeight(styles.lineHeight) : setLineHeight(1.4)
            if (styles.letterSpacing) {
                var string = styles.letterSpacing;
                // Remove the "px" unit from the string.
                const numberString = string.replace("px", "");
                // Convert the string to an integer.
                const number = parseInt(numberString);
                setLetterSpacing(number);
            } else { setLetterSpacing(0); }

        }
    }, [selectedComponent, selectedUserActivity, userActivities[selectedUserActivity], styles])

    const handleTextUnderline = () => {
        let underlined = false
        if (styles.textDecoration === 'underline') {
            underlined = true
        }
        let value = underlined ? 'none' : 'underline';

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    textDecoration: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }

    const handleTextItalic = () => {
        let isItalic = false
        if (styles.fontStyle === 'italic') {
            isItalic = true
        }

        let value = isItalic ? 'normal' : 'italic';
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fontStyle: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }

    const handleTextAlignment = () => {
        let alignment = 0
        if (styles.textAlign === 'left') {
            alignment = 1
        } else if (styles.textAlign === 'center') {
            alignment = 2
        } else if (styles.textAlign === 'right') {
            alignment = 3
        } else if (styles.textAlign === 'justify') {
            alignment = 4
        }
        let value = alignment === 1 ? 'center' : alignment === 2 ? 'right' : alignment === 3 ? 'justify' : alignment === 4 ? 'left' : 'left'

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    textAlign: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }


    const handleTextUppercase = () => {
        let isUppercase = false
        if (styles.textTransform === 'uppercase') {
            isUppercase = true
        }
        let value = isUppercase ? 'none' : 'uppercase'

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    textTransform: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }

    const handleTextLineheight = () => {
        setLineHeightActive(!lineHeightActive);
    }

    const handleTextLetterspacing = () => {
        setLetterSpacingActive(!letterSpacingActive);
    }

    const handleLineHeightIncrement = () => {
        if (lineHeight >= 100) return;
        const newLineHeight = lineHeight + 0.1;
        setLineHeight(Math.round(newLineHeight * 10) / 10);

        let value = Math.round(newLineHeight * 10) / 10;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    lineHeight: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };

    const handleLineHeightDecrement = () => {
        if (lineHeight <= 0) return;
        const newLineHeight = lineHeight - 0.1;
        setLineHeight(Math.round(newLineHeight * 10) / 10);

        let value = Math.round(newLineHeight * 10) / 10;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    lineHeight: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };

    const handleLetterSpacingIncrement = () => {
        if (letterSpacing >= 100) return;
        setLetterSpacing(letterSpacing + 1);
        let value = `${letterSpacing + 1}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    letterSpacing: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };

    const handleLetterSpacingDecrement = () => {
        if (letterSpacing <= 0) return;
        setLetterSpacing(letterSpacing - 1);
        let value = `${letterSpacing - 1}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    letterSpacing: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };

    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Style</p>
            <div className='StyleEditorContainer'>
                <div data-tooltip-id="my-tooltip" data-tooltip-content="Italic" className='font-styles-editor-icon' onClick={handleTextItalic}
                    id={italicActive ? 'active' : ''} >
                    <img src='/assets/icons/italic.svg' />
                </div>
                <div data-tooltip-id="my-tooltip" data-tooltip-content="Underline" className='font-styles-editor-icon' onClick={handleTextUnderline}
                    id={underlineActive ? 'active' : ''} >
                    <img src='/assets/icons/underlined.svg' />
                </div>
                <div data-tooltip-id="my-tooltip" data-tooltip-content="Alignment" className='font-styles-editor-icon' onClick={handleTextAlignment}
                    id={(alignmentActive > 0 && alignmentActive < 5) ? 'active' : ''} >
                    {alignmentActive === 1 && <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path d="M10,13a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2Zm5-4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,9ZM10,5a1,1,0,0,1,0,2H1A1,1,0,0,1,1,5ZM1,3A1,1,0,0,1,1,1H15a1,1,0,0,1,0,2Z"></path>
                    </g></svg>}
                    {alignmentActive === 2 && <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path d="M12,13a1,1,0,0,1,0,2H4a1,1,0,0,1,0-2Zm3-4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,9ZM12,5a1,1,0,0,1,0,2H4A1,1,0,0,1,4,5ZM1,3A1,1,0,0,1,1,1H15a1,1,0,0,1,0,2Z"></path>
                    </g></svg>}
                    {alignmentActive === 3 && <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path d="M15,13a1,1,0,0,1,0,2H6a1,1,0,0,1,0-2Zm0-4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,9Zm0-4a1,1,0,0,1,0,2H6A1,1,0,0,1,6,5ZM1,3A1,1,0,0,1,1,1H15a1,1,0,0,1,0,2Z"></path>
                    </g></svg>}
                    {alignmentActive === 4 && <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path d="M15,13a1,1,0,0,1,0,2H1a1,1,0,0,1,0-2Zm0-4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,9Zm0-4a1,1,0,0,1,0,2H1A1,1,0,0,1,1,5ZM1,3A1,1,0,0,1,1,1H15a1,1,0,0,1,0,2Z"></path>
                    </g></svg>}
                </div>
                <div data-tooltip-id="my-tooltip" data-tooltip-content="Uppercase" className='font-styles-editor-icon' onClick={handleTextUppercase}

                    id={uppercaseActive ? 'active' : ''}>
                    <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="16px" width="16px" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path d="M6.2,4v8.41a1,1,0,0,0,.52.88L8,14v1H2V14l1.29-.72a1,1,0,0,0,.51-.87V4H2.41a1,1,0,0,0-.7.29L1,5H0V2H10V5H9l-.71-.71A1,1,0,0,0,7.59,4ZM8,7h8v3H15l-.81-.81A.66.66,0,0,0,13.73,9H13v3.59a1,1,0,0,0,.29.7L14,14v1H10V14l.71-.71a1,1,0,0,0,.29-.7V9h-.73a.66.66,0,0,0-.46.19L9,10H8Z"></path>
                    </g></svg>
                </div>
                <div style={{ position: "relative" }}>
                    <div data-tooltip-id="my-tooltip" data-tooltip-content="Line height" className='font-styles-editor-icon' onClick={handleTextLineheight}
                        id={lineHeightActive ? 'active' : ''}>
                        <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="16px" width="16px" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}>
                            <g><path d="M4,5v6H5.76a.09.09,0,0,1,.07,0,.1.1,0,0,1,0,.14L3,14,.17,11.17a.09.09,0,0,1,0-.07.1.1,0,0,1,.1-.1H2V5H0L3,2,6,5ZM15,7a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7ZM8,5A1,1,0,0,1,8,3h7a1,1,0,0,1,0,2Zm7,6a1,1,0,0,1,0,2H8a1,1,0,0,1,0-2Z"></path></g>
                        </svg>
                    </div>
                    {lineHeightActive && <div className='text-editor-modal' ref={optionsRef1}>
                        <button className="text-editor-modal-button" onClick={handleLineHeightDecrement}>
                            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="16px" width="16px" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M2,7H14a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H2A1,1,0,0,1,1,8H1A1,1,0,0,1,2,7Z"></path></g></svg>
                        </button>
                        <input max="100" min="0" step="0.1" type="number" disabled className="text-editor-modal-input" value={lineHeight}></input>
                        <button className="text-editor-modal-button" onClick={handleLineHeightIncrement}>
                            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="16px" width="16px" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M14,7H9V2A1,1,0,0,0,7,2V7H2A1,1,0,0,0,2,9H7v5a1,1,0,0,0,2,0V9h5a1,1,0,0,0,0-2"></path></g></svg>
                        </button>
                    </div>}
                </div>
                <div style={{ position: "relative" }}>
                    <div data-tooltip-id="my-tooltip" data-tooltip-content="Letter spacing" className='font-styles-editor-icon' onClick={handleTextLetterspacing}
                        id={letterSpacingActive ? 'active' : ''}>
                        <svg fill="#344054" preserveAspectRatio="xMidYMid meet" height="16px" width="16px" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}>
                            <g><path d="M3,12H13V10.24a.09.09,0,0,1,0-.07.1.1,0,0,1,.14,0L16,13l-2.83,2.83a.09.09,0,0,1-.07,0,.1.1,0,0,1-.1-.1V14H3v2L0,13l3-3ZM4.75,9.5a.76.76,0,0,1-.69-.45l-3-7a.75.75,0,1,1,1.38-.6l2.31,5.4,2.31-5.4a.75.75,0,0,1,1.38.6l-3,7A.76.76,0,0,1,4.75,9.5ZM14.94,8.45a.75.75,0,0,1-1.38.6L12.68,7H9.82L8.94,9.05a.75.75,0,0,1-1.38-.6l3-7a.75.75,0,0,1,1.38,0ZM10.24,6h2l-1-2.35Z"></path></g>
                        </svg>
                    </div>
                    {letterSpacingActive && <div className='text-editor-modal' ref={optionsRef2}>
                        <button className="text-editor-modal-button" onClick={handleLetterSpacingDecrement}>
                            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M2,7H14a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H2A1,1,0,0,1,1,8H1A1,1,0,0,1,2,7Z"></path></g></svg>
                        </button>
                        <input max="100" min="0" step="1" type="number" disabled className="text-editor-modal-input" value={letterSpacing}></input>
                        <button className="text-editor-modal-button" onClick={handleLetterSpacingIncrement}>
                            <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M14,7H9V2A1,1,0,0,0,7,2V7H2A1,1,0,0,0,2,9H7v5a1,1,0,0,0,2,0V9h5a1,1,0,0,0,0-2"></path></g></svg>
                        </button>
                    </div>}
                </div>
                <ReactTooltip id="my-tooltip" place="top" effect="solid" />
            </div >
        </div >
    )
}
