import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";

import axios from 'axios';
// const axios = require("axios");

export const duonutPageMappingSlide = createSlice({
    name: "duonutpagemapping",
    initialState: {
        data: []
    },
    reducers: {
        getDuonutPageMapping: (state, action) => {
            state.data = [action.payload];
        }
    }
});

export const getDuonutPageMappingAsync = (typeId,appId) => async (dispatch) => {
    try {
        // console.log(typeId,appId);
        var API_URL;
        API_URL = `${domain}/duonut/duonut_page_mapping/?type_id=${typeId}&app_id=${appId}`;
        const response = await axios.get(`${API_URL}`);
        // console.log(response.data);
        dispatch(getDuonutPageMapping(response.data));
    } catch (err) {
        throw new Error(err);
    }
};

export const { getDuonutPageMapping } = duonutPageMappingSlide.actions;
export const showDuonutPageMapping = (state) => state.duonutPageMapping.data;
export default duonutPageMappingSlide.reducer;
