import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveQuizAnsAsync, saveQuizResponse, showDuonutresponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { updateElementInputFocus } from '../../redux/slices/userActivity';

const RadioImage = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition
    },
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);
    const [isOther, setisOther] = useState(false);

    useEffect(() => {
        setisOther(false);
    }, []);


    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)

            if (item === "other") {
                setisOther(true);
            } else {
                setisOther(false);
            }
            setSelectedAnswer(item); // Update selectedAnswer state

            // if (location.pathname.includes("/duonut/")) {
            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: item,
                nickname: viewer_nick_name,
                step: slideId + 1,
                type: "RadioImage",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id,
                type: "RadioImage",
                step: slideId + 1,
                question: text || "",
                answered_text: item,
            };
            dispatch(saveQuizResponse(save_response));
        }
    }
    const handleInputFocus = () => dispatch(updateElementInputFocus(true));
    const handleInputBlur = () => dispatch(updateElementInputFocus(false));

    return (
        <div style={{ display: "flex", flexDirection: "column", gap }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {text}
            </div>

            <div style={{
                display: "flex",
                justifyContent: textAlign,
                alignItems: 'center',
                flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition
            }}>
                {options.map((item, i) => (
                    <div key={i} style={{ display: "flex", alignItems: "center", lineHeight }}>
                        <input
                            type="radio"
                            id={`radio_option${i}`}
                            name="radio_option"
                            style={{
                                width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: color
                            }}
                            checked={selectedAnswer.includes(item)}
                            disabled={maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)}
                            onChange={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(item))) {
                                    submitResponse(item);
                                }
                            }}
                        />
                        <div
                            style={{
                                display: "flex", flexDirection: "column",
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border || "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin, cursor: location.pathname.includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%",
                            }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => {
                                if (!(maxLength && maxLength === selectedAnswer.length && !selectedAnswer.includes(item))) {
                                    submitResponse(item);
                                }
                            }}
                        >
                            <img
                                src={optionsImage[i] || imgPreview}
                                style={{ width: imageWidth, height: imageWidth, objectFit: "cover", padding: imagePadding }}
                                alt=""
                            />
                            <div>{item}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RadioImage;
