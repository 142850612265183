import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Input/input";
import PasswordInput from "../../Input/passwordInput";

import { togglechangePasswordModal } from "../../../redux/slices/formDetails";
import { updateUserCredentials } from "../../../services/auth";
import { validateEmail } from "./utils";

export default function ChangePasswordModal() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [message, setmessage] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const { resetPasswordEmail } = useSelector((state) => state.formDetails);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(togglechangePasswordModal());
  };

  const handleClick = () => {
    setPasswordError("");
    setConfirmPasswordError("");

    if (password == "" || confirmPassword === "") {
      password === "" && setPasswordError("This field cant be empty");
      confirmPassword === "" && setConfirmPasswordError("This field cant be empty");
      return;
    }

    if (password !== confirmPassword) return setConfirmPasswordError("Password don’t match. Please re-enter the passwords. ");

    updateUserCredentials({ email: resetPasswordEmail, password }, (err, res) => {
      if (err) {
        if (Object.keys(err.response.data)[0] === "detail") {
          if (err.response.data.detail === "Please enter valid password!") return setPasswordError('New password cannot be same as old password')
        } else {
          if (err.response.data.password.length >= 1) {
            if (err.response.data.password.includes('This password is too short. It must contain at least 8 characters.')) return setPasswordError('Password must be 8 characters long.')
            if (err.response.data.password.includes('This password is too common.')) return setPasswordError('This password is too common.')
            if (err.response.data.password.includes('This password is entirely numeric.')) return setPasswordError('This password is entirely numeric.')
          }
          return setPasswordError("Password must be 8 characters long.");
          // setPasswordError("Password must be 8 characters long.");
        }
        return;
      }
      // alert("Password reset successfully!");
      setmessage("Password reset successfully!")
      setTimeout(() => {
        setmessage("")
        handleClose();
      }, 1000); //miliseconds
    });
  };

  return (
    <div className="modal_container forgot_password_modal_container change_password_modal">
      <div className="modal_basic">
        <div>
          <div className="modal_header">
            <div className="modal_back_btn icon-button" onClick={handleClose}>
              {`<`}
            </div>
            Enter a new password
          </div>

          <PasswordInput placeholder="New password" value={password} setValue={setPassword} errorMsg={passwordError} />
          <PasswordInput placeholder="Confirm password" value={confirmPassword} setValue={setConfirmPassword} errorMsg={confirmPasswordError} />

          <button className="btn-primary full-width modal_submit_btn" onClick={handleClick}>
            Continue
          </button>
          {message && <div style={{ marginTop: "5px", textAlign: "center" }}>{message}</div>}
        </div>
        <div className="close-icon icon-button" onClick={handleClose}>
          <img src="/assets/icons/close.svg" />
        </div>
      </div>
      <div className="modal-overlay"></div>
    </div>
  );
}
