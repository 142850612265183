import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("token", token);
  return header;
};


export const aiSlide = createSlice({
  name: "ai",
  initialState: {
    response: [],
    data: []
  },
  reducers: {
    saveAi: (state, action) => {
      state.response = [action.payload];
    },
    saveAiData: (state, action) => {
      state.data = [action.payload];
    }
  }
});

export const saveAiAsync = () => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_ai_detail`;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveAi(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const createAiAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_ai_data`;
    const response = await axios.post(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editAiAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_ai_data`;
    const response = await axios.patch(API_URL, data, header);
    return response.data;
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};


export const deleteAiAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_ai_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};


export const { saveAi, saveAiData } = aiSlide.actions;
export const showAiresponse = (state) => state.ai.response;
export const showAiData = (state) => state.ai.data;
export default aiSlide.reducer;
