import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';

const Nps = ({
  id,
  elementAttr,
  elementHoverAttr,
  conditions,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
  const { fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign, border, color,
    lineHeight, letterSpacing, margin, padding, borderRadius, maxLength, backgroundColor, gap,
    labelFontSize, labelFontWeight, labelColor, size,
  } = elementAttr;

  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const p_type = queryParams.get('type');

  const duonutDataMain = useSelector(showduonutData);
  const duonutData = duonutDataMain[0];
  // console.log(duonutData)
  const [inputText, setInputText] = useState(text);
  const [isHovered, setIsHovered] = useState(-1);
  const [clickedNum, setClickedNum] = useState(-1);

  useEffect(() => {
    setInputText(text);
  }, [text]);

  const handleButtonClick = (num) => {
    if (location.pathname.includes("/duonut/")) {
      setClickedNum(num);

      var save_ans = {
        display_id: viewer_display_id,
        watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
        result: 0,
        question: text ? text : "",
        answered_text: num + 1,
        // end: lastQue ? "1" : "0",
        nickname: viewer_nick_name,
        step: selectedSlide + 1,
        type: "Nps",
        // country: location[0] ? location[0].address?.country : "",
        // city: location[0] ? location[0].address?.state_district : "",
        // location: location[0] ? location[0].display_name : "",
        // url_params: props.details.queryParamsObject
      }
      // console.log(save_ans);
      const formData = new FormData();
      Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

      dispatch(saveQuizAnsAsync(formData));

      var save_response = {
        type: "Nps",
        step: selectedSlide + 1,
        question: text ? text : "",
        answered_text: num + 1,
      }
      dispatch(saveQuizResponse(save_response));

      var nextPage = selectedSlide + 1;
      duonutData?.user_activity[selectedSlide]?.condition?.map(node => {
        if (node.condition.length === 2) {
          if ((node.condition[0] === "equal to" && num + 1 === parseInt(node.condition[1])) ||
            (node.condition[0] === "not equal to" && num + 1 !== parseInt(node.condition[1])) ||
            (node.condition[0] === "lower than" && num + 1 < parseInt(node.condition[1])) ||
            (node.condition[0] === "greater than" && num + 1 > parseInt(node.condition[1])) ||
            (node.condition[0] === "lower or equal to" && num + 1 <= parseInt(node.condition[1])) ||
            (node.condition[0] === "greater or equal to" && num + 1 >= parseInt(node.condition[1]))) {
            nextPage = node.target - 1;
          }
        } else if (node.always) {
          nextPage = node.target - 1;
        }
      })

      setTimeout(() => {
        if (p_type === "1") {
          var act_len = duonutData.user_activity.length;
          console.log(act_len);
          dispatch(updateGoToSlide(act_len));
          return null;
        }
        dispatch(updateGoToSlide(nextPage));
      }, 1000);
    }
  }

  return (
    <div style={{
      display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column',
      gap: gap, color, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform,
      textAlign, lineHeight, letterSpacing,
    }} >
      <div
        style={{
          width: "100%", paddingBottom: '8px', color: labelColor, fontSize: labelFontSize, fontWeight: labelFontWeight
        }}>{text}</div>

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {Array.from({ length: maxLength }, (_, i) => {
          return <div key={i} id={`option-id${i}`}
            style={{
              display: "flex", justifyContent: "center", alignItems: "center",
              border: border ? border : "0", margin, padding, borderRadius,
              width: `${size}px`, height: `${size}px`,
              backgroundColor: isHovered < (i + 1) ? clickedNum < i ? backgroundColor : elementHoverAttr?.selectedBackgroundColor : elementHoverAttr?.backgroundColor
            }}
            onMouseEnter={() => setIsHovered(i + 1)}
            onMouseLeave={() => setIsHovered(-1)}
            onClick={() => handleButtonClick(i)}>{i}
          </div>
        })}
      </div>
    </div>
  );
}

export default Nps;
