import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';

const Mcq = ({
    id,
    elementAttr,
    elementHoverAttr,
    conditions,
    setShowModel,
    setCorrectAnswer
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const p_type = queryParams.get('type');

    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const { text, options, correctAnswer, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor,
    } = elementAttr;

    const { viewType } = useSelector((state) => state.duonutSettings);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    console.log(duonutData)

    const [hover, setHover] = useState(-1);

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            var correct = item === options[correctAnswer - 1]
            if (correct) {
                setShowModel(1);
            } else {
                setShowModel(2);
                setCorrectAnswer(options[correctAnswer - 1]);
            }
            // console.log("ans", item, correct)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: correct ? 1 : 0,
                question: text ? text : "",
                answered_text: item,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "Mcq",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                type: "Mcq",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item,
            }
            dispatch(saveQuizResponse(save_response));

            var nextPage = selectedSlide + 1;
            duonutData?.user_activity[selectedSlide]?.condition?.map(node => {
                if (node?.condition?.some(condition => condition.toLowerCase() === (item).toLowerCase()) || node?.always) {
                    nextPage = node.target - 1;
                }
            })

            setTimeout(() => {
                setShowModel(0);
                setCorrectAnswer("");
                if (p_type === "1") {
                    var act_len = duonutData.user_activity.length;
                    console.log(act_len);
                    dispatch(updateGoToSlide(act_len));
                    return null;
                }
                dispatch(updateGoToSlide(nextPage));
            }, 3100);

        }
    }

    const handelCorrectAnswer = (item) => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    correctAnswer: item,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    }

    return (
        <>
            <div style={{
                display: "flex", flexDirection: "column", gap: gap
            }}>
                <div
                    style={{
                        width: "100%", paddingBottom: '10px',
                        color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing,
                    }}>{text}</div>

                <div>
                    {options.map((item, i) => {
                        return <div
                            style={{
                                display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight,
                            }}>
                            {!(location.pathname).includes("/duonut/") && <input type="radio" id={"correct_answer" + i} name="correct_answer"
                                checked={correctAnswer === i + 1 ? true : false}
                                style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: "green" }}
                                onChange={() => handelCorrectAnswer(i + 1)} />}
                            <label for={"correct_answer" + i}
                                style={{
                                    backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                    border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                    color: hover === i ? elementHoverAttr?.color : color,
                                    borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                    textTransform, textAlign, letterSpacing, padding, margin,
                                    cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                                    width: "100%", height: "100%"
                                }} key={i}
                                onMouseEnter={() => setHover(i)}
                                onMouseLeave={() => setHover(-1)}
                                onClick={() => { submitResponse(item) }}
                            >
                                <div style={{}}>{item}</div>
                            </label>
                        </div>
                    })}
                </div>
            </div>
        </>
    );
}

export default Mcq;
