import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";

import axios from 'axios';
// const axios = require("axios");

const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};


export const userSettingSlide = createSlice({
  name: "userSetting",
  initialState: {
    response: [],
    modalActive: false
  },
  reducers: {
    saveUserSetting: (state, action) => {
      state.response = [action.payload];
    },
    toggleUserSettingModalDuonut: (state, { payload }) => {
      state.modalActive = payload;
    },
  }
});

export const saveUserSettingAsync = () => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/duonut_page_mapping_byuser`;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveUserSetting(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const saveDuonutMappingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_page_mapping_byuser`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editDuonutMappingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/duonut_page_mapping_byuser`;
    const response = await axios.patch(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveUserSetting, toggleUserSettingModalDuonut } = userSettingSlide.actions;
export const showUserSettingresponse = (state) => state.userSetting.response;
export default userSettingSlide.reducer;
