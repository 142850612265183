import React, { useEffect, useState } from "react";
import './DuonutReports.css';
import { showduonutreport, getduonutreportAsync } from '../../redux/slices/DuonutReportReducer';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx/xlsx.mjs';
import { frontendUrl, backendUrl } from "../../services/constants";
import { editReportApiAsync, deleteReportApiAsync, } from "../../redux/slices/ReportApiReducer";
import Chart from 'react-apexcharts';
import LineChart from "./LineChart";
import LineChart1 from "./LineChart1";
import LineChart2 from "./LineChart2";
import LineChart3 from "./LineChart3";
import LineChart4 from "./LineChart4";
import { toast } from "react-toastify";
import axios from "axios";

const DuonutReports = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const duonut_id = parseInt(paramsid.id);
    const navigate = useNavigate();
    const { google_sheet_integration } = useSelector(state => state.duonutSettings);

    useEffect(() => {
        dispatch(getduonutreportAsync(duonut_id));
        // console.log("inside", duonut_id);
    }, [duonut_id]);

    const duonutreportData = useSelector(showduonutreport);
    const reportData = duonutreportData[0];
    // console.log(reportData);

    const [tableData, settableData] = useState([]);
    const [duonutName, setduonutName] = useState("");
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [stepNum, setstepNum] = useState(0);
    const [filterNum, setfilterNum] = useState(-1);
    const [filteredItems, setfilteredItems] = useState([]);
    const [editingRowId, setEditingRowId] = useState(null);
    const [newSelectedAnswer, setNewSelectedAnswer] = useState('');
    const [transformedData, setTransformedData] = useState([]);
    const [columns1, setColumns1] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const [selectedDate, setselectedDate] = useState("CreatedAt");
    const [selectedColumn, setselectedColumn] = useState("");
    const [selectedColumn1, setselectedColumn1] = useState("Actual Production");
    const [selectedFilterColumn, setselectedFilterColumn] = useState("");

    // const filteredItems = tableData.filter(
    //     item => item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase()) &&
    //         item.step && item.step.includes(filterNum),
    // );

    // const filteredItems = tableData.filter(item => {
    //     const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
    //     const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
    //     return nicknameMatch && stepMatch;
    // });

    useEffect(() => {
        if (tableData.length > 0) {
            // console.log("aaa")
            var main_filter = tableData.filter(item => {
                const nicknameMatch = item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase());
                const stepMatch = filterNum > 0 ? item.step && item.step.includes(filterNum) : true;
                return nicknameMatch && stepMatch;
            });
            setfilteredItems(main_filter);
        }
    }, [tableData, filterNum, filterText]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText || filterNum) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setfilterNum(-1);
            }
        };

        return (
            <div key={filteredItems}>
                <select value={filterNum} className='text_editor_dropdown'
                    style={{ marginRight: "1rem" }}
                    onChange={e => setfilterNum(e.target.value)} >
                    <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled>
                        Filter By Step</option>
                    {Array.from({ length: stepNum }, (_, index) => index).map((item, idx) => {
                        return <option key={idx + 1} value={idx + 1} > {`Step ${idx + 1}`} </option>
                    }
                    )}
                </select>

                <input
                    type="text"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    placeholder="Filter By User"
                    style={{ border: "1px solid black", padding: "6px 10px" }}
                    list="browsers"
                />
                <datalist id="browsers">
                    {filteredItems?.filter((item, index, array) =>
                        array.findIndex((i) => i.nickname === item.nickname) === index)
                        .map((item, i) => {
                            return <option value={item.nickname}></option>
                        })}
                </datalist>
                <button type="button" onClick={handleClear} style={{ padding: "9px 20px", cursor: "pointer" }}>
                    X
                </button>
            </div>
        );
    }, [filterText, resetPaginationToggle, filteredItems]);

    // Function to split and create new entries
    function splitSelectedAnswer(dict) {
        const newDict = {};
        Object.keys(dict).forEach(key => {
            const entry = dict[key];
            const answers = entry.selected_option.split(';').map(option => option.trim());
            answers.forEach((answer, index) => {
                const newKey = `${key}-${index}`;
                newDict[newKey] = {
                    ...entry,
                    selected_option: answer
                };
            });
        });
        return newDict;
    }

    useEffect(() => {
        if (duonutreportData[0]) {
            if (parseInt(localStorage.getItem('userId')) !== parseInt(reportData.created_by)) {
                navigate('/')
                return
            }
            setduonutName(reportData.duonut_name);

            // console.log(reportData.duonut_report_dict);
            // Call the function and get the updated dictionary
            const updatedDict = splitSelectedAnswer(reportData.duonut_report_dict);

            // Output the updated dictionary
            // console.log(updatedDict);

            var tablerows = [];
            // Object.entries(reportData.duonut_report_dict).reverse().map(([key, value]) => {
            //     tablerows.push({ ...value });
            // })
            Object.entries(reportData.duonut_report_dict).map(([key, value]) => {
                tablerows.push({ ...value });
            })

            if (tablerows.length > 0) {
                var largestId = tablerows?.reduce((max, item) => parseInt(item.step) > max ? parseInt(item.step) : max, tablerows[0].step);
                setstepNum(largestId);
                console.log("The largest id is: " + largestId);
            }

            console.log(tablerows);
            settableData(tablerows);
        }
    }, [duonutreportData])

    const columns = [
        {
            name: "Date",
            selector: (row) => row.created_at,
            center: true,
            sortable: true,
        },
        // {
        //     name: "id",
        //     selector: (row) => row.id,
        //     center: true,
        // },
        {
            name: "User",
            selector: (row) => row.nickname,
            center: true,
        },
        // {
        //     name: "Query Params",
        //     selector: (row) => row.url_params,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Question",
            selector: (row) => row.question,
            center: true,
            wrap: true,
        },
        {
            name: "Selected Answer",
            selector: (row) => row.id === editingRowId ? (
                <input
                    type="text"
                    style={{ border: "1px solid black", padding: "8px 5px", borderRadius: "5px", width: "7rem" }}
                    value={newSelectedAnswer}
                    onChange={handleChange}
                />
            )
                : row.type === "FileUpload" ?
                    (
                        <a href={`${backendUrl}${row.file}`} target="_blank" rel="noopener noreferrer">
                            {`${backendUrl}${row.file}`}
                        </a>
                    ) : row.selected_option,
            center: true,
            wrap: true,
        },
        {
            name: "Result",
            selector: (row) => (row.type === "Mcq") ? row.result ? "Correct" : "Wrong" : "-",
            center: true,
        },
        {
            name: "Step",
            selector: (row) => row.step,
            center: true,
            sortable: true,
        },
        {
            name: "Type",
            selector: (row) => row.type,
            center: true,
        },
        // {
        //     name: "Location",
        //     selector: (row) => row.location,
        //     center: true,
        //     wrap: true,
        // },
        // {
        //     name: "Status",
        //     selector: (row) => row.status,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    {(row.type === "TextField" || row.type === "TextArea" || row.type === "Email" || row.type === "Password" || row.type === "Phone" || row.type === "NumberField" || row.type === "DateField") ?
                        row.id === editingRowId ? (
                            <button className="report_btn-edit" onClick={() => handleSave(row.id)}>Save</button>
                        ) : (
                            <button className="report_btn-edit" onClick={() => handleEdit(row)}>Edit</button>
                        )
                        : <div style={{ width: "4rem", display: "inline-block" }}></div>
                    }
                    <button className="report_btn-delete" onClick={() => handleDelete(row.id)}>Delete</button>
                </div>
            ),
            center: true,
        },
    ]

    const handleSave = (id) => {
        // Handle the save action here (e.g., updating the state or making an API call)
        console.log(`Saving row with id: ${id}, new value: ${newSelectedAnswer}`);

        var reportData = {
            id: id,
            selected_option: newSelectedAnswer,
        }
        dispatch(editReportApiAsync(reportData));

        var main_filter = filteredItems.map(item => {
            if (item.id === id) {
                return { ...item, selected_option: newSelectedAnswer };
            }
            return item;
        });
        setfilteredItems(main_filter);

        setEditingRowId(null);
    };

    const handleChange = (e) => {
        setNewSelectedAnswer(e.target.value);
    };

    const handleEdit = (row) => {
        setEditingRowId(row.id);
        setNewSelectedAnswer(row.selected_option || '');
    };

    const handleDelete = (id) => {
        console.log(`Deleting row with id: ${id}`);
        var reportData1 = {
            id: id
        }
        // console.log(reportData1)
        dispatch(deleteReportApiAsync(reportData1));
        // console.log(filteredItems)

        var main_filter = filteredItems.filter(item => item.id !== id);
        setfilteredItems(main_filter);
    };

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                // console.log("qqq", item[key]);
                var itemData = item[key] ? item[key].toString() : "";
                var itemData = itemData.replace(/,|\n/g, '');;
                if (ctr > 0) result += columnDelimiter;

                result += itemData;
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    function downloadCSV(Data) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(Data);
        if (csv == null) return;

        // Add heading to the CSV before the data
        const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        csv = `${heading}\n${csv}`;

        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.csv` : `${duonutName} reports.csv`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    function downloadXLSX(Data) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(Data);

        // Add heading to the worksheet
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        // XLSX.utils.sheet_add_aoa(ws, [[heading]], { origin: 'A1' });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob and create a download link
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    function downloadWordDoc(Data) {
        const heading = "";
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;

        // Create a table in HTML format
        const table = `<h2 style="text-align: center;">Activity Name - ${duonutName}</h2>
                <h3 style="text-align: center;">User - ${Data[0].nickname}</h3>
                    <table style="border-collapse: collapse; width: 100%;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #000; padding: 8px;">User</th>
                                <th style="border: 1px solid #000; padding: 8px;">Question</th>
                                <th style="border: 1px solid #000; padding: 8px;">Result</th>
                                <th style="border: 1px solid #000; padding: 8px;">Selected Answer</th>
                                <th style="border: 1px solid #000; padding: 8px;">Step</th>
                                <th style="border: 1px solid #000; padding: 8px;">Type</th>
                                <th style="border: 1px solid #000; padding: 8px;">Query Params</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${Data.map(item => `<tr>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.nickname}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.question}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.result}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.selected_option}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.step}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.type}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.url_params}</td>
                                            </tr>`).join('')}
                        </tbody>
                      </table>`;

        // Create a Blob containing the table HTML
        const blob = new Blob([heading, table], { type: 'application/msword' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterText ? `${duonutName} - ${Data[0].nickname} reports.doc` : `${duonutName} reports.doc`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    }



    useEffect(() => {
        if (tableData.length > 0) {
            var transformedData = tableData.reduce((acc, obj) => {
                // Find if an object with the same watch_id and nickname exists in acc array
                let existingObj = acc.find(item => item.WatchId === obj.watch_id && item.NickName === obj.nickname);

                if (!existingObj) {
                    // If no existing object, create a new one with watch_id and nickname
                    existingObj = {
                        WatchId: obj.watch_id,
                        CreatedAt: obj.created_at,
                        NickName: obj.nickname,
                    };
                    acc.push(existingObj);
                }

                // Assign each question and selected_option as properties to existingObj
                existingObj[obj.question] = obj.selected_option;

                return acc;
            }, []);

            console.log(transformedData);
            setTransformedData(transformedData);
        }
    }, [tableData]);

    useEffect(() => {
        if (transformedData.length > 0) {
            // Collect all unique keys across all objects in transformedData
            const allKeys = Array.from(
                new Set(transformedData.flatMap(Object.keys))
            );

            // Generate columns based on these unique keys
            const transformedData1 = allKeys.map(key => ({
                name: key,
                selector: (row) => row[key],
                sortable: true,
                wrap: true,
                center: true,
                omit: false,
            }));

            console.log(transformedData1);

            setColumns1(transformedData1);
        }
    }, [transformedData]);


    const handleToggleColumn = (columnName) => {
        setColumns1(columns1.map(col => col.name === columnName ? { ...col, omit: !col.omit } : col));
    };


    useEffect(() => {
        if (transformedData.length > 0 && selectedColumn) {
            if (selectedFilterColumn) {
                // Group data by the selected filter column (e.g., Shift)
                const groupedData = transformedData.reduce((acc, item) => {
                    const key = item[selectedFilterColumn];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    });
                    return acc;
                }, {});

                // Create chart series for each group
                const seriesData = Object.keys(groupedData).map(key => ({
                    name: `${selectedFilterColumn}: ${key}`,
                    data: groupedData[key].sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }));

                setLineChartData(seriesData);
            } else {
                // Single series if no filter column is selected
                const singleSeriesData = [{
                    name: selectedColumn,
                    data: transformedData.map(item => ({
                        x: item[selectedDate],  // Date for x-axis
                        y: parseFloat(item[selectedColumn]) || 0  // Selected Column for y-axis
                    })).sort((a, b) => new Date(a.x) - new Date(b.x)) // Sort by date
                }];

                console.log(singleSeriesData)
                setLineChartData(singleSeriesData);
            }
        }
    }, [transformedData, selectedColumn, selectedFilterColumn, selectedDate]);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const createSheet = async (AccessToken, Data) => {
        console.log("Data", Data)
        try {
            const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${AccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    properties: {
                        title: filename,
                    },
                }),
            });

            if (response.ok) {
                const sheetData = await response.json();
                const spreadsheetId = sheetData.spreadsheetId;
                console.log('Spreadsheet created:', sheetData);
                // Step 2: Generate a comprehensive set of headers
                const headers = Data.reduce((acc, item) => {
                    Object.keys(item).forEach(key => {
                        if (!acc.includes(key)) {
                            acc.push(key);
                        }
                    });
                    return acc;
                }, []);

                // Step 3: Create rows of data that match the headers
                const values = [
                    headers, // Header row
                    ...Data.map(item => headers.map(header => item[header] || '')) // Data rows
                ];

                const range = `Sheet1!A1`; // Start from the first cell
                const updateResponse = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${AccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        range,
                        majorDimension: 'ROWS',
                        values: values,
                    }),
                });

                if (updateResponse.ok) {
                    console.log('Spreadsheet updated with data');
                    toast.success("Export Successfully in Google Sheet.");

                } else {
                    console.error('Error updating spreadsheet with data:', updateResponse.statusText);
                }
            } else {
                console.error('Error creating spreadsheet:', response.statusText);
                if (response && response.status === 401) { // Access token expired
                    try {
                        const newAccessToken = await refreshAccessToken(google_sheet_integration?.refresh_key);
                        await createSheet(newAccessToken, Data);
                    } catch (refreshError) {
                        console.error('Error refreshing access token', refreshError);
                    }
                } else {
                    console.error('Error sending email', response);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <div className="chartContainer">

            {duonut_id === 149 && <div className="CATTP1templateblur">
                <div className='CATTP1leftContainer'>
                    <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Production")}>
                        <div style={selectedColumn1 === "Actual Production" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Production</div>
                    </div>
                    <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Prod/hr as per cycle time")}>
                        <div style={selectedColumn1 === "Prod/hr as per cycle time" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Prod/hr as per cycle time </div>
                    </div>
                    <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Machine Utilization in %")}>
                        <div style={selectedColumn1 === "Machine Utilization in %" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Machine Utilization in %</div>
                    </div>
                    <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Actual Output Hrs")}>
                        <div style={selectedColumn1 === "Actual Output Hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Actual Output Hrs </div>
                    </div>
                    <div className='CATTP1activitytype' onClick={() => setselectedColumn1("Available M/C hrs")}>
                        <div style={selectedColumn1 === "Available M/C hrs" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>Available M/C hrs</div>
                    </div>
                </div>

                <div className='CATTP1rightContainer' id="scrollableDiv">
                    <h2 style={{ padding: "2rem" }}>{"Activity Name - " + duonutName}</h2>
                    <br></br>

                    {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                        <LineChart transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>



                    {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                        <LineChart3 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                        <LineChart4 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                        <LineChart1 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {duonut_id === 149 && transformedData.length > 0 && columns1.length > 0 &&
                        <LineChart2 transformedData={transformedData} columns1={columns1} selectedColumn={selectedColumn1} />}
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                </div>
            </div>}

            <br></br>
            <br></br>

            <h2 style={{ padding: "1rem" }}>Form Data</h2>
            <br></br>

            <div>
                <label style={{ fontSize: "1.2rem", fontWeight: "700", display: "inline-block", margin: "1rem" }}>Filters: </label>
                {columns1.map(column => (
                    <label key={column.name} style={{ display: "inline-block", margin: "1rem" }}>
                        <input
                            type="checkbox"
                            checked={!column.omit}
                            onChange={() => handleToggleColumn(column.name)}
                        />
                        {column.name}
                    </label>
                ))}
            </div>
            <br></br>

            <DataTable
                title={"Form Data"}
                columns={columns1.filter(col => !col.omit)}
                data={transformedData}
                pagination
                paginationPerPage="50"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                fixedHeader
                persistTableHead
                highlightOnHover
                actions={<>
                    {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, transformedData)}>Export in Google Sheet</button>}
                    <button className="btn-primary" onClick={() => downloadCSV(transformedData)}>Export Csv</button>
                    <button className="btn-primary" onClick={() => downloadXLSX(transformedData)}>Export Xlsx</button>
                </>}
            />

            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className="DR1linecharfilter">
                <div>Select Date Field to use in Chart</div>
                <select value={selectedDate} className='text_editor_dropdown'
                    onChange={e => setselectedDate(e.target.value)} >
                    <option key={"CreatedAt"} value="CreatedAt" selected>CreatedAt</option>
                    {columns1.map((column, idx) => {
                        if (column.name.toLowerCase().includes('date')) {
                            return (
                                <option key={idx + 1} value={column.name}>
                                    {column.name}
                                </option>
                            );
                        }
                        // If the column does not contain a date, do not render anything
                        return null;
                    }
                    )}
                </select>
            </div>
            <br></br>

            <div className="DR1linecharfilter">
                <div>Select Number Field to show in Chart</div>
                <select value={selectedColumn} className='text_editor_dropdown'
                    onChange={e => setselectedColumn(e.target.value)} >
                    <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                        Select Field</option>
                    {columns1.map((column, idx) => {
                        return <option key={idx + 1} value={column.name} > {column.name} </option>
                    }
                    )}
                </select>
            </div>
            <br></br>

            <div className="DR1linecharfilter">
                <div>Select Field to Filter wise show in Chart</div>
                <select value={selectedFilterColumn} className='text_editor_dropdown'
                    onChange={e => setselectedFilterColumn(e.target.value)} >
                    <option key={"select option"} value="" style={{ display: "none", color: "#44474d" }} selected disabled>
                        Select Field</option>
                    {columns1.map((column, idx) => {
                        return <option key={idx + 1} value={column.name} > {column.name} </option>
                    }
                    )}
                </select>
            </div>

            <div className="DR1linechart">
                <Chart
                    type="line"
                    width={800}
                    height={400}

                    series={lineChartData}

                    options={{
                        title: {
                            text: "Graphs",
                            style: { fontSize: 30 }
                        },

                        // colors: ['#f90000'],
                        // theme: { mode: "light" },

                        xaxis: {
                            type: 'datetime',
                            labels: {
                                format: 'dd MMM', // Customize the date format here
                                // show: false,
                            },
                            title: {
                                text: "Date",
                                // style: { color: "#f90000" }
                            },
                        },

                        yaxis: {
                            title: {
                                text: selectedColumn,
                                // style: { color: "#f90000" }
                            },
                        }

                    }}

                >

                </Chart>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>


            <DataTable
                title={"Submitted Data"}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                paginationPerPage="50"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                highlightOnHover
                actions={<>
                    {/* <button className="btn-primary" onClick={() => window.location.open(`duonut/`)} > Add</button> */}
                    {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, filteredItems)}>Export in Google Sheet</button>}
                    <button className="btn-primary" onClick={() => downloadCSV(filteredItems)}>Export Csv</button>
                    <button className="btn-primary" onClick={() => downloadXLSX(filteredItems)}>Export Xlsx</button>
                    <button className="btn-primary" onClick={() => downloadWordDoc(filteredItems)}>Export Word</button></>}
            />

            <br></br>
            <br></br>
            <br></br>

        </div>
    );
}

export default DuonutReports;