import axios from "axios";
import { domain } from "./constants";

const headers = {
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, x-requested-with",
  "Content-Type": "application/json",
  "Access-Control-Allow-origin": "*",
};
const getAuthHeaders = () => {
  const token = localStorage.getItem("access") || localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};

export const createDuonut = async (body, controller, cb) => {
  const header = getAuthHeaders();

  axios
    .post(`${domain}/duonut/`, body, header, { signal: controller.signal })
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const editDuonut = async (id, activities, cb) => {
  const header = getAuthHeaders();

  axios
    .patch(`${domain}/duonut/${id}`, activities, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const fetchDuonutCategories = async (cb) => {
  axios
    .get(`${domain}/duonut/category`, headers)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};

export const defaultDuonut = async (activities, cb) => {
  const header = getAuthHeaders();

  axios
    .post(`${domain}/duonut/create_default_duonut`, activities, header)
    .then((res) => cb(null, res))
    .catch((err) => cb(err, null));
};