import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";

const maxAnswerChar = 2;

export default function Gap() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [gap, setgap] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        if (styles?.gap) {
            setgap(styles.gap.slice(0, -2))
        } else {
            setgap(0);
        }
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity], selectedComponent.target_id])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleGap = e => {
        setgap(e.target.value);
        const value = `${e.target.value}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    gap: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Gap</p>

            <div style={{ marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <input type="number" min="0" value={gap} id='gap'
                    style={{ border: "1px solid #11111333", borderRadius: "5px", textAlign: "center", width: "2.5rem", height: "2rem", padding: "0" }}
                    onChange={(e) => (e.target.value.length > maxAnswerChar ? null : handleGap(e))} />
                <label style={{ marginLeft: "5px" }}>px</label>
            </div>
        </div>
    )
} 
