import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./Nuggets.css"
import { frontendUrl, backendUrl } from "../../services/constants";

const Nuggets = ({ nuggets }) => {

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const navigate = useNavigate();
    const handleNugget = (e, id) => {
        navigate(`/workflows/${id}`)
        e.stopPropagation();
    }

    return (
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "inherit" }}>
            {nuggets.length >= 1 ?
                nuggets.map(nugget => {
                    // console.log(duonut)
                    // if (nugget.nugget_duonut.length <= 0) return;
                    return (
                        <div style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => handleNugget(e, nugget.id)}>
                            <div style={{ display: "flex", position: "relative" }}>
                                {nugget.thumbnail ?
                                    <img style={{ width: "290px", height: "210px", borderRadius: "8px" }} src={`${backendUrl}${nugget.thumbnail}`} loading="lazy"></img>
                                    : <img style={{ width: "290px", height: "210px", borderRadius: "8px" }} src='/assets/thumbnail.jpg' loading="lazy"></img>}
                                <div style={{
                                    position: "absolute", fontSize: "12px", bottom: "0", left: "0", right: "0", padding: "5px",
                                    backgroundColor: "rgba(60, 89, 85, 0.8)", color: "white", borderRadius: "0px 0px 10px 10px"
                                }}>{nugget.nugget_duonut.length} Duonuts</div>
                            </div>
                            <div style={{ padding: "6px" }}>{nugget.name}</div>
                        </div>
                    )
                }) : <div style={{ textAlign: "center" }}>No Any Workflows Created!</div>}
        </div>
    );

}

export default Nuggets;