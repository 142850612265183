import React, { useEffect, useState } from "react";
import './DuonutAnalytics.css';
import { showduonutanalytics, getduonutanalyticsAsync } from '../../redux/slices/DuonutReportReducer';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import * as XLSX from 'xlsx/xlsx.mjs';
import { toast } from "react-toastify";
import axios from "axios";

const DuonutAnalytics = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const duonut_id = parseInt(paramsid.id);
    const navigate = useNavigate();
    const { google_sheet_integration } = useSelector(state => state.duonutSettings);

    useEffect(() => {
        dispatch(getduonutanalyticsAsync(duonut_id));
        // console.log("inside", duonut_id);
    }, [duonut_id]);

    const duonutreportData = useSelector(showduonutanalytics);
    const reportData = duonutreportData[0];
    // console.log(reportData);

    const [tableData, settableData] = useState([]);
    const [duonutName, setduonutName] = useState("");
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = tableData.filter(
        item => item.nickname && item.nickname.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div key={filteredItems}>
                <input
                    type="text"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                    placeholder="Filter By User"
                    style={{ border: "1px solid black", padding: "6px 10px" }}
                    list="browsers"
                />
                <datalist id="browsers">
                    {filteredItems?.filter((item, index, array) =>
                        array.findIndex((i) => i.nickname === item.nickname) === index)
                        .map((item, i) => {
                            return <option value={item.nickname}></option>
                        })}
                </datalist>
                <button type="button" onClick={handleClear} style={{ padding: "9px 20px", cursor: "pointer" }}>
                    X
                </button>
            </div>
        );
    }, [filterText, resetPaginationToggle, filteredItems]);

    useEffect(() => {
        if (duonutreportData[0]) {
            if (parseInt(localStorage.getItem('userId')) !== parseInt(reportData.created_by)) {
                navigate('/')
                return
            }
            setduonutName(reportData.duonut_name);

            var tablerows = [];
            // Object.entries(reportData.duonut_report_dict).reverse().map(([key, value]) => {
            //     tablerows.push({ ...value });
            // })
            Object.entries(reportData.duonut_report_dict).map(([key, value]) => {
                tablerows.push({ ...value });
            })
            // console.log(tablerows);
            settableData(tablerows);
        }
    }, [duonutreportData])

    const columns = [
        {
            name: "Date",
            selector: (row) => row.created_at,
            center: true,
        },
        {
            name: "User",
            selector: (row) => row.nickname,
            center: true,
        },
        {
            name: "Step",
            selector: (row) => row.step,
            center: true,
        },
        {
            name: "Query Params",
            selector: (row) => row.url_params,
            center: true,
            wrap: true,
        },
        {
            name: "Location",
            selector: (row) => row.location,
            center: true,
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) => row.status,
            center: true,
            wrap: true,
        },
    ]

    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(array[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                // console.log("qqq", item[key]);
                var itemData = item[key] ? item[key].toString() : "";
                var itemData = itemData.replace(/,|\n/g, '');;
                if (ctr > 0) result += columnDelimiter;

                result += itemData;
                // eslint-disable-next-line no-plusplus
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }


    function downloadCSV(Data) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(Data);
        if (csv == null) return;

        // Add heading to the CSV before the data
        const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        csv = `${heading}\n${csv}`;

        const filename = filterText ? `${duonutName} - ${Data[0].nickname} analyticsReports.csv` : `${duonutName} analyticsReports.csv`;

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    function downloadXLSX(Data) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(Data);

        // Add heading to the worksheet
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;
        // XLSX.utils.sheet_add_aoa(ws, [[heading]], { origin: 'A1' });

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        // Create a Blob and create a download link
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const filename = filterText ? `${duonutName} - ${Data[0].nickname} analyticsReports.xlsx` : `${duonutName} analyticsReports.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Utility function to convert string to array buffer
    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    }

    function downloadWordDoc(Data) {
        const heading = "";
        // const heading = filterText ? `Activity Name - ${duonutName}, User - ${Data[0].nickname}` : `Activity Name - ${duonutName}`;

        // Create a table in HTML format
        const table = `<h2 style="text-align: center;">Activity Name - ${duonutName}</h2>
                <h3 style="text-align: center;">User - ${Data[0].nickname}</h3>
                    <table style="border-collapse: collapse; width: 100%;">
                        <thead>
                            <tr>
                                <th style="border: 1px solid #000; padding: 8px;">User</th>
                                <th style="border: 1px solid #000; padding: 8px;">step</th>
                                <th style="border: 1px solid #000; padding: 8px;">Query Params</th>
                                <th style="border: 1px solid #000; padding: 8px;">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${Data.map(item => `<tr>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.nickname}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.step}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.url_params}</td>
                                                <td style="border: 1px solid #000; padding: 8px;">${item.status}</td>
                                            </tr>`).join('')}
                        </tbody>
                      </table>`;

        // Create a Blob containing the table HTML
        const blob = new Blob([heading, table], { type: 'application/msword' });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterText ? `${duonutName} - ${Data[0].nickname} analyticsReports.doc` : `${duonutName} analyticsReports.doc`;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
    }

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            // console.log(response.data)
            return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    const createSheet = async (AccessToken, Data) => {
        console.log("Data", Data)
        try {
            const filename = filterText ? `${duonutName} - ${Data[0].nickname} reports.xlsx` : `${duonutName} reports.xlsx`;
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${AccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    properties: {
                        title: filename,
                    },
                }),
            });

            if (response.ok) {
                const sheetData = await response.json();
                const spreadsheetId = sheetData.spreadsheetId;
                console.log('Spreadsheet created:', sheetData);
                // Step 2: Generate a comprehensive set of headers
                const headers = Data.reduce((acc, item) => {
                    Object.keys(item).forEach(key => {
                        if (!acc.includes(key)) {
                            acc.push(key);
                        }
                    });
                    return acc;
                }, []);

                // Step 3: Create rows of data that match the headers
                const values = [
                    headers, // Header row
                    ...Data.map(item => headers.map(header => item[header] || '')) // Data rows
                ];

                const range = `Sheet1!A1`; // Start from the first cell
                const updateResponse = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${AccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        range,
                        majorDimension: 'ROWS',
                        values: values,
                    }),
                });

                if (updateResponse.ok) {
                    console.log('Spreadsheet updated with data');
                    toast.success("Export Successfully in Google Sheet.");

                } else {
                    console.error('Error updating spreadsheet with data:', updateResponse.statusText);
                }
            } else {
                console.log('Error creating spreadsheet:', response);
                console.error('Error creating spreadsheet:', response.statusText);
                if (response && response.status === 401) { // Access token expired
                    try {
                        const newAccessToken = await refreshAccessToken(google_sheet_integration?.refresh_key);
                        await createSheet(newAccessToken, Data);
                    } catch (refreshError) {
                        console.error('Error refreshing access token', refreshError);
                    }
                } else {
                    console.error('Error sending email', response);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <div className="chartContainer">

            {/* {reportData && Object.keys(reportData.referral_report).length > 0 && <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "1rem" }}>
                <div className="DRC1table">
                    <div style={{ fontSize: "22px", fontWeight: "bold", paddingBottom: "1rem" }}>Referral Report</div>
                    <table>
                        <tbody>
                            <tr>
                                <th>Referral Name</th>
                                <th>Count of Referral Views</th>
                            </tr>
                            {Object.entries(reportData.referral_report)
                                .map(([key, value]) => {
                                    return <tr key={key}>
                                        <td>{key}</td>
                                        <td> {value.count}</td>
                                    </tr>
                                })}
                        </tbody>
                    </table>
                </div>
            </div>} */}
            <br></br>
            <DataTable
                title={"Activity Name - " + duonutName}
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                paginationPerPage="50"
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                fixedHeader
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                highlightOnHover
                actions={<>
                    {google_sheet_integration?.access_key && <button className="btn-primary" onClick={() => createSheet(google_sheet_integration?.access_key, filteredItems)}>Export in Google Sheet</button>}
                    <button className="btn-primary" onClick={() => downloadCSV(filteredItems)}>Export Csv</button>
                    <button className="btn-primary" onClick={() => downloadXLSX(filteredItems)}>Export Xlsx</button>
                    <button className="btn-primary" onClick={() => downloadWordDoc(filteredItems)}>Export Word</button></>}
            />

        </div>
    );
}

export default DuonutAnalytics;