import React, { useEffect, useState, useRef } from "react";
import Duonut from "../../Components/Duonut/Duonut";
import { getAllDuonuts, getMyDuonuts } from "../../services/duonut";

import "./myduonut.css"
import "swiper/css"
import "swiper/css/scrollbar"
import useHorizontalScroll from '../../hooks/useHorizontalScroll'
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ConfirmDeleteDuonut from "../../Components/Modals/ConfirmDeleteDuonut/ConfirmDeleteDuonut";
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import { useSelector, useDispatch } from "react-redux";
import { toggleProMessageModalDuonut, updateProMessageModalMessage } from "../../redux/slices/ProMessageReducer";
import { useNavigate } from 'react-router-dom';

const MyDuonut = ({ isMyDuonuts }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useHorizontalScroll();
  const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
  const [drafts, setDrafts] = useState([])
  const [recentDuonut, setRecentDuonut] = useState([])
  const [duonuts, setDuonuts] = useState([])
  const [filteredDuonuts, setFilteredDuonuts] = useState([])
  const { width, height } = useWindowDimensions();
  const [selectedDuonutTab, setselectedDuonutTab] = useState("1")

  // console.log(duonuts);
  const fetchDuonut = () => {
    if (isMyDuonuts) {
      getMyDuonuts((err, res) => {
        if (err) console.log(err.response);
        else {
          if (res.data.length === 0) {
            setDrafts([])
            setRecentDuonut([])
            setDuonuts([])
            return
          };
          // console.log('res', res.data);
          const tempdrafts = res.data.filter(item => item.published === false).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          console.log("tempdrafts", tempdrafts)
          setDrafts(tempdrafts)
          const temprecent = res.data.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))[0];
          console.log("temprecent", temprecent)
          setRecentDuonut([temprecent])
          const publishedDuonuts = res.data.filter(item => item.published === true).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          console.log("publishedDuonuts", publishedDuonuts)
          setFilteredDuonuts(publishedDuonuts);
          setDuonuts(
            publishedDuonuts.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
          );
        }
      });
    } else {
      getAllDuonuts(1, (err, res) => {
        if (err) console.log(err.response);
        else {
          setDuonuts(res.data);
        }
      });
    }
  }

  useEffect(() => {
    fetchDuonut()
  }, [isMyDuonuts]);

  const handleChannelClick = () => {
    if (!userProAccess) {
      dispatch(toggleProMessageModalDuonut(true));
      dispatch(updateProMessageModalMessage('workspace access'));
      return null;
    }
    navigate("/workflow");
  };

  return (
    <div className='duonutContainer'>
      <div className='duonutContainerBlur'>
        <div className="main" style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedDuonutTab === "1" ? "700" : "400" }}
              onClick={() => setselectedDuonutTab("1")}>
              {selectedDuonutTab === "1" && <div className="navbar-selected-line"></div>}
              Published </div>
            <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedDuonutTab === "2" ? "700" : "400" }}
              onClick={() => setselectedDuonutTab("2")}>
              {selectedDuonutTab === "2" && <div className="navbar-selected-line"></div>}
              Drafts </div>
          </div>
          <div style={{ display: width > 800 ? "flex" : "none" }}>
            <div className="demoBtnNav" style={{}}
              onClick={() => dispatch(toggleCreateFromScratchModalDuonut(true))}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>  ADD NEW </div>
            <div className="btn-primary" style={{ marginLeft: "1rem", marginRight: "7rem" }} onClick={() => handleChannelClick()}> WORKSPACE </div>
          </div>
        </div>

        {recentDuonut.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Recent</div>
          <div className="duonuts2">
            {recentDuonut.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })}
          </div>
        </>}

        {selectedDuonutTab === "1" && filteredDuonuts.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Published</div>
          <div className="duonuts2">
            {filteredDuonuts.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })
            }
          </div></>}

        {selectedDuonutTab === "2" && drafts.length >= 1 && <>
          <div style={{ margin: "1rem", paddingLeft: "2.5rem", fontSize: "1.2rem" }}>Drafts</div>
          <div className="duonuts2">
            {drafts.map(duonut => {
              // console.log(duonut)
              return (
                <Duonut {...duonut} key={duonut.id} isFiltered={true} isMyDuonuts={isMyDuonuts} fetchDuonut={fetchDuonut} />
              )
            })
            }
          </div></>}

      </div>

      <ConfirmDeleteDuonut setDuonuts={setDuonuts} fetchDuonut={fetchDuonut} />
    </div >
  );
};

export default MyDuonut;
