import React, { useEffect, useRef, useState } from "react";
import "./RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import {
    deleteUserActivityElements, updateSelectedComponent, updateUserActivity,
    updateUserActivityBackground, updateUserActivityElements
} from '../../redux/slices/userActivity';
import ColorEditor from "./Styles/ColorEditor/ColorEditor";
import FontFamily from "./Styles/fontFamily";
import FontSize from "./Styles/fontSize";
import FontWeight from "./Styles/fontWeight";
import FontStyles from "./Styles/fontStyles";
import BGColorEditor from "./Styles/BGColorEditor/BGColorEditor";
import Border from "./Styles/border";
import Margin from "./Styles/margin";
import Padding from "./Styles/padding";
import Upload from "./Styles/upload";
import BorderRadius from "./Styles/borderRadius";
import { frontendUrl } from "../../services/constants";
import ButtonAction from "./Properties/buttonAction";
import Requiredfield from "./Properties/requiredfield";
import MinMaxLength from "./Properties/minMaxLength";
import SlideBGColorEditor from "./Styles/SlideBGColorEditor/SlideBGColorEditor";
import AnimationSetting from "./AnimationSetting/AnimationSetting";
import HoverColorEditor from "./Styles/HoverColorEditor/HoverColorEditor";
import HoverBGColorEditor from "./Styles/HoverBGColorEditor/HoverBGColorEditor";
import HoverBorder from "./Styles/Hoverborder";
import UploadImages from "./Styles/uploadImages";
import Layers from "./Styles/Layers";
import OptionsProperty from "./Properties/optionsProperty";
import MaxLength from "./Properties/maxLength";
import ImageWidth from "./Styles/imageWidth";
import ImagePadding from "./Styles/imagePadding";
import ImagePosition from "./Styles/imagePosition";
import RatingType from "./Properties/ratingType";
import SelectedBGColorEditor from "./Styles/SelectedBGColorEditor/SelectedBGColorEditor";
import VideoUrl from "./Properties/VideoUrl";
import Timer from "./Properties/timer";
import FieldPosition from "./Properties/fieldPosition";
import LabelText from "./Properties/labelText";
import AudioUpload from "./Properties/audioUpload";
import Placeholder from "./Properties/placeholder";
import BottomBorder from "./Styles/bottomBorder";
import LinkUrl from "./Properties/linkUrl";
import Gap from "./Styles/gap";
import LabelFontSize from "./Styles/labelFontSize";
import LabelFontWeight from "./Styles/labelFontWeight";
import LabelColorEditor from "./Styles/LabelColorEditor/LabelColorEditor";
import Size from "./Properties/size";
import ImageOptionsProperty from "./Properties/imageOptionsProperty";
import Height from "./Styles/height";
import FrontText from "./Properties/FrontText";
import BackText from "./Properties/BackText";

const RightSidebar = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);

    const [selectedTab, setselectedTab] = useState(2);
    const [appliedSlideBackground, setappliedSlideBackground] = useState(false);
    const [appliedButton, setappliedButton] = useState(false);

    const handelSelectTab = (num) => {
        // console.log("tab", num)
        setselectedTab(num);
    }
    const handelElementDelete = () => {
        dispatch(
            deleteUserActivityElements({
                selectedActivityIdx: selectedUserActivity,
                selectedElementIdx: selectedComponent.target_id,
            })
        );
        dispatch(
            updateSelectedComponent({
                type: "Slide",
                name: "Container",
                target: "",
                target_id: null,
            })
        );
    }

    const handelBgImageDelete = () => {
        dispatch(
            updateUserActivity({
                backgroundImage: "",
            })
        );
    }

    const handelApplyAllBackground = () => {
        const bgImg = userActivities[selectedUserActivity].backgroundImage;
        const bgColor = userActivities[selectedUserActivity].backgroundColor;

        userActivities.map((item, i) => {
            dispatch(
                updateUserActivityBackground({
                    selectedActivityIdx: i,
                    backgroundImage: bgImg,
                    backgroundColor: bgColor,
                })
            );
        })

        setappliedSlideBackground(true);

        setTimeout(() => {
            setappliedSlideBackground(false);
        }, 2000); //miliseconds
    }

    const handelApplyAllButton = () => {
        let copyTempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
        const copyElementAttrib = copyTempElement?.elementAttributes;
        const copyElementHoverAttrib = copyTempElement?.elementHoverAttributes;

        console.log(copyTempElement)

        userActivities.map((activity, i) => {
            activity.elements.map((item, j) => {
                let tempElement = item;
                console.log(tempElement)
                if (item.elementType === "Button") {

                    console.log("Button")

                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...copyElementAttrib,
                            text: tempElement?.elementAttributes?.text,
                        },
                        elementHoverAttributes: {
                            ...copyElementHoverAttrib,
                            text: tempElement?.elementHoverAttributes?.text,
                        },
                    };

                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: i,
                            selectedElementIdx: j,
                            element: tempElement,
                        })
                    );
                }

            })
        })

        setappliedButton(true);

        setTimeout(() => {
            setappliedButton(false);
        }, 2000); //miliseconds
    }

    const handelApplyAllText = () => {
        let copyTempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
        const copyElementAttrib = copyTempElement?.elementAttributes;

        console.log(copyTempElement)

        userActivities.map((activity, i) => {
            activity.elements.map((item, j) => {
                let tempElement = item;
                console.log(tempElement)
                if (item.elementType === "Text") {

                    console.log("Text")

                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...copyElementAttrib,
                            text: tempElement?.elementAttributes?.text,
                            formattedText: tempElement?.elementAttributes?.formattedText,
                        },
                    };

                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: i,
                            selectedElementIdx: j,
                            element: tempElement,
                        })
                    );
                }

            })
        })

        setappliedButton(true);

        setTimeout(() => {
            setappliedButton(false);
        }, 2000); //miliseconds
    }

    return (
        <>
            <div className="RightSidebarMainContainer">
                <div className="RightSidebarElementProperties">
                    <div><b style={{ color: "#6c7589", paddingRight: "5px" }}>Element:</b> {selectedComponent.name}</div>

                    {selectedComponent.type !== "Slide" && <button className="ElementDelete"
                        onClick={() => handelElementDelete()}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i> Delete</button>}
                </div>
                <div className="LeftSidebarContainer" style={{ borderTop: "2px solid #dbdfe8" }}>
                    <div className="LeftSidebarTab" onClick={() => handelSelectTab(1)}>Properties
                        {selectedTab === 1 && <div className="selected-line"></div>}
                    </div>
                    <div className="LeftSidebarTab" onClick={() => handelSelectTab(2)}>Styles
                        {selectedTab === 2 && <div className="selected-line"></div>}
                    </div>
                    <div className="LeftSidebarTab" onClick={() => handelSelectTab(3)}>Animations
                        {selectedTab === 3 && <div className="selected-line"></div>}
                    </div>
                </div>
                <div className="RightSidebarContainer">

                    {selectedTab === 1 && <>
                        {selectedComponent.type === "Slide" && (
                            <>
                                <Timer />

                            </>
                        )}
                        {selectedComponent.type === "Button" && (
                            <>
                                <ButtonAction />

                            </>
                        )}

                        {selectedComponent.type === "Image" && (
                            <>
                                <LinkUrl />
                            </>
                        )}

                        {(selectedComponent.type === "TextField" || selectedComponent.type === "TextArea" || selectedComponent.type === "Email" || selectedComponent.type === "Password" || selectedComponent.type === "Phone" || selectedComponent.type === "NumberField") && (
                            <>
                                <Requiredfield />
                                <MinMaxLength />
                                <FieldPosition />
                                <Placeholder />

                            </>
                        )}
                        {(selectedComponent.type === "CheckboxGroup") && (
                            <>
                                <MaxLength />
                                <LabelText />
                                <OptionsProperty />
                            </>
                        )}
                        {(selectedComponent.type === "RadioGroup") && (
                            <>
                                <Requiredfield />
                                <LabelText />
                                <OptionsProperty />

                            </>
                        )}
                        {(selectedComponent.type === "CheckboxImage") && (
                            <>
                                <MaxLength />
                                <LabelText />
                                <ImageOptionsProperty />
                                <ImageWidth />
                                <ImagePadding />
                                <FieldPosition />

                            </>
                        )}
                        {(selectedComponent.type === "RadioImage") && (
                            <>
                                <Requiredfield />
                                <MaxLength />
                                <LabelText />
                                <ImageOptionsProperty />
                                <ImageWidth />
                                <ImagePadding />
                                <FieldPosition />

                            </>
                        )}
                        {(selectedComponent.type === "Rating") && (
                            <>
                                <LabelText />
                                <RatingType />
                                <MaxLength />
                                <Size />

                            </>
                        )}
                        {(selectedComponent.type === "Nps") && (
                            <>
                                <LabelText />
                                <MaxLength />
                                <Size />

                            </>
                        )}
                        {(selectedComponent.type === "Mcq") && (
                            <>
                                <LabelText />
                                <OptionsProperty />

                            </>
                        )}
                        {(selectedComponent.type === "McqImage") && (
                            <>
                                <LabelText />
                                <ImageOptionsProperty />
                                <ImageWidth />
                                <ImagePadding />
                                <FieldPosition />

                            </>
                        )}
                        {(selectedComponent.type === "Reorder") && (
                            <>
                                <LabelText />
                                <OptionsProperty />

                            </>
                        )}
                        {(selectedComponent.type === "ReorderImage") && (
                            <>
                                <LabelText />
                                <ImageOptionsProperty />
                                <ImageWidth />
                                <ImagePadding />
                                <FieldPosition />

                            </>
                        )}
                        {(selectedComponent.type === "Video") && (
                            <>
                                <VideoUrl />

                            </>
                        )}
                        {(selectedComponent.type === "Audio") && (
                            <>
                                <AudioUpload />

                            </>
                        )}

                        {(selectedComponent.type === "Dropdown" || selectedComponent.type === "DropdownMultiselect") && (
                            <>
                                <Requiredfield />
                                <OptionsProperty />
                                <FieldPosition />

                            </>
                        )}

                        {(selectedComponent.type === "DateField" || selectedComponent.type === "FileUpload") && (
                            <>
                                <Requiredfield />
                                <FieldPosition />

                            </>
                        )}

                        {(selectedComponent.type === "FlipCard") && (
                            <>
                                <LabelText />
                                <FrontText />
                                <BackText />

                            </>
                        )}

                    </>}
                    {selectedTab === 2 && <>
                        {selectedComponent.type === "Slide" && (
                            <>
                                <div style={{
                                    width: "100%", textAlign: "left", margin: "1rem", fontSize: "1rem", fontWeight: "700",
                                    display: "flex", justifyContent: "space-between", alignItems: "center"
                                }}>Slide Background
                                    {(selectedComponent.type === "Slide" && userActivities[selectedUserActivity]?.backgroundImage) &&
                                        <button className="ElementDelete"
                                            onClick={() => handelBgImageDelete()}>
                                            <i className="fa fa-trash-o" aria-hidden="true"></i>  Delete Background
                                        </button>}
                                </div>

                                <Upload />
                                <UploadImages />
                                <SlideBGColorEditor />

                                <div style={{}}>
                                    <button type='submit' className='ApplyAllBgBtn' onClick={() => handelApplyAllBackground()}>
                                        {appliedSlideBackground ? "Applied" : "Apply To All Steps"}</button>
                                </div>

                            </>
                        )}
                        {selectedComponent.type === "Text" && (
                            <>
                                <div style={{}}>
                                    <button type='submit' className='ApplyAllBgBtn' onClick={() => handelApplyAllText()}>
                                        {appliedButton ? "Applied" : "Apply To All Text"}</button>
                                </div>

                                <ColorEditor />
                                <BGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <FontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <BorderRadius />
                                <Margin />
                                <Padding />

                            </>
                        )}
                        {selectedComponent.type === "Image" && (
                            <>
                                <Upload />
                                <UploadImages />
                                <Border />
                                <BorderRadius />
                                <Layers />
                                <Margin />
                                <Padding />
                            </>
                        )}
                        {selectedComponent.type === "Video" && (
                            <>
                                <Border />
                                <Layers />
                                <Margin />
                                <Padding />
                            </>
                        )}
                        {selectedComponent.type === "Audio" && (
                            <>
                                <Border />
                                <Layers />
                                <Margin />
                                <Padding />
                            </>
                        )}
                        {selectedComponent.type === "Button" && (
                            <>
                                <div style={{}}>
                                    <button type='submit' className='ApplyAllBgBtn' onClick={() => handelApplyAllButton()}>
                                        {appliedButton ? "Applied" : "Apply To All Buttons"}</button>
                                </div>

                                <ColorEditor />
                                <BGColorEditor />
                                <HoverColorEditor />
                                <HoverBGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <FontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <HoverBorder />
                                <BorderRadius />
                                <Margin />
                                <Padding />

                                <div style={{ width: "100%", textAlign: "left", margin: "1rem", fontSize: "1.2rem", fontWeight: "800" }}>Image</div>

                                <Upload />
                                <UploadImages />
                                <ImageWidth />
                                <ImagePadding />
                                <ImagePosition />

                            </>
                        )}
                        {(selectedComponent.type === "TextField" || selectedComponent.type === "TextArea" || selectedComponent.type === "Email" || selectedComponent.type === "Password" || selectedComponent.type === "Phone" || selectedComponent.type === "NumberField" || selectedComponent.type === "DateField" || selectedComponent.type === "FileUpload") && (
                            <>
                                <Height />
                                <ColorEditor />
                                <BGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <FontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <BottomBorder />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />

                            </>
                        )}

                        {(selectedComponent.type === "Rating") && (
                            <>

                                <ColorEditor />
                                <FontFamily />
                                <FontSize />
                                <FontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />
                                <BGColorEditor />
                                <HoverBGColorEditor />
                                <SelectedBGColorEditor />
                            </>
                        )}

                        {(selectedComponent.type === "Nps") && (
                            <>

                                <ColorEditor />
                                <LabelColorEditor />
                                <FontFamily />
                                <FontSize />
                                <LabelFontSize />
                                <FontWeight />
                                <LabelFontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />
                                <BGColorEditor />
                                <HoverBGColorEditor />
                                <SelectedBGColorEditor />
                            </>
                        )}

                        {(selectedComponent.type === "CheckboxGroup" || selectedComponent.type === "CheckboxImage" || selectedComponent.type === "RadioGroup" || selectedComponent.type === "RadioImage" || selectedComponent.type === "Mcq" || selectedComponent.type === "McqImage" || selectedComponent.type === "Reorder" || selectedComponent.type === "ReorderImage") && (
                            <>
                                <ColorEditor />
                                <LabelColorEditor />
                                <BGColorEditor />
                                <HoverColorEditor />
                                <HoverBGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <LabelFontSize />
                                <FontWeight />
                                <LabelFontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <HoverBorder />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />

                            </>
                        )}

                        {(selectedComponent.type === "Dropdown" || selectedComponent.type === "DropdownMultiselect") && (
                            <>

                                <ColorEditor />
                                <BGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <FontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />

                            </>
                        )}

                        {(selectedComponent.type === "FlipCard") && (
                            <>
                                <ColorEditor />
                                <LabelColorEditor />
                                <BGColorEditor />
                                <HoverColorEditor />
                                <HoverBGColorEditor />
                                <FontFamily />
                                <FontSize />
                                <LabelFontSize />
                                <FontWeight />
                                <LabelFontWeight />
                                <FontStyles />
                                <Layers />
                                <Border />
                                <HoverBorder />
                                <BorderRadius />
                                <Gap />
                                <Margin />
                                <Padding />

                            </>
                        )}

                    </>}
                    {selectedTab === 3 && <AnimationSetting />}
                </div>
            </div>
        </>
    );
}

export default RightSidebar;