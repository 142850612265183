import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function Requiredfield() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [required, setRequired] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const required = tempElement?.elementAttributes?.required;
            if (required) {
                setRequired(required);
            } else {
                setRequired(false);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleRequiredChange = () => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    required: !required
                },
            };
            console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className="EditorRowContainer" style={{ margin: "1rem 0px" }}>
            <input type='checkbox' id='requiredField' name='requiredField'
                style={{ width: "20px", height: "20px", margin: "2px 8px 2px 2px" }}
                checked={required ? true : false}
                onChange={() => handleRequiredChange()} />

            <label style={{ marginRight: "1rem" }} for="requiredField">Mandatory?</label>

        </div>
    )
}

