import { createSlice } from "@reduxjs/toolkit";

//user can create any number of activities so 'activities' is an array of objects.
//activityType and subtype will be there in every activity object other keys will depend on activity type.
//keys will be based on the activity type.
//isActive ie. selected activity will be displayed in the activity design box

const initialState = {
    selectedUserActivity: 0,
    selectedSlide: 0,
    goToSlide: -1,
    elementInputFocus: false,
    userActivities: [{
        elements: [],
        backgroundColor: "white",
        backgroundImage: "",
        thumbnail: "",
        slideAnimation: "none",
    }],
    elements: [],
    selectedComponent: {
        type: "Slide",
        name: "Container",
        target: "",
        target_id: null,
    },
    publishModal: {
        active: false,
        message: '',
        displayId: "",
        duonutName: "",
        qrCode: "",
        isEdited: false
    },
    historyUserActivities: {
        historyActivity: [],
        historyActivityIndex: -1,
    },
    textFormData: {},
};

// activity slice which the user is designing
const activity = createSlice({
    name: "activity",
    initialState,
    reducers: {
        selectUserActivity: (state, { payload }) => {
            state.selectedUserActivity = payload;
        },
        selectSlide: (state, { payload }) => {
            state.selectedSlide = payload;
        },
        updateGoToSlide: (state, { payload }) => {
            state.goToSlide = payload;
        },
        updateElementInputFocus: (state, { payload }) => {
            state.elementInputFocus = payload;
        },
        addElementInUserActivity: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === payload.selectedActivityIdx) {
                        // Create a new array with the existing elements and the payload
                        const userActivityElements = userActivity.elements;
                        return {
                            ...userActivity,
                            elements: [...userActivityElements, payload.activity],
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        addUserActivity: (state, { payload }) => {
            state.userActivities = [...state.userActivities, { ...payload, }];

            state.selectedUserActivity = state.userActivities.length - 1;
        },
        addUserActivity1: (state, { payload }) => {
            const newElement = { ...payload };

            const updatedUserActivities = [
                ...state.userActivities.slice(0, state.selectedUserActivity + 1),
                newElement,
                ...state.userActivities.slice(state.selectedUserActivity + 1)
            ];

            state.userActivities = updatedUserActivities;

            state.selectedUserActivity = state.selectedUserActivity + 1;
        },
        removeUserActivity: (state, { payload }) => {
            state.userActivities = state.userActivities.filter((activity, idx) => idx !== payload.idx);
            if (state.selectedUserActivity > 0) {
                state.selectedUserActivity = state.selectedUserActivity - 1;
            }
        },
        duplicateUserActivity: (state, { payload }) => {
            const newElement = { ...payload };

            const updatedUserActivities = [
                ...state.userActivities.slice(0, state.selectedUserActivity + 1),
                newElement,
                ...state.userActivities.slice(state.selectedUserActivity + 1)
            ];

            state.userActivities = updatedUserActivities;

            state.selectedUserActivity = state.selectedUserActivity + 1;
        },
        updateUserActivity: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === state.selectedUserActivity) {
                        // console.log(payload)
                        return {
                            ...userActivity,
                            ...payload,
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        updateUserActivityBackground: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === payload.selectedActivityIdx) {
                        // console.log(payload)
                        return {
                            ...userActivity,
                            backgroundImage: payload.backgroundImage,
                            backgroundColor: payload.backgroundColor,
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        updateUserActivityElements: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === payload.selectedActivityIdx) {
                        const updatedElements = userActivity.elements.map((element, index) =>
                            index === payload.selectedElementIdx ? payload.element : element
                        );
                        return {
                            ...userActivity,
                            elements: updatedElements,
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        deleteUserActivityElements: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === state.selectedUserActivity) {
                        const updatedElements = userActivity.elements.filter((element, idx) => idx !== payload.selectedElementIdx);
                        return {
                            ...userActivity,
                            elements: updatedElements,
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        updateSelectedComponent: (state, { payload }) => {
            // console.log(payload)
            state.selectedComponent = payload;
        },
        setUserActivity: (state, { payload }) => {
            return {
                ...state,
                userActivities: payload.activities,
            };
        },

        updatePublishModal: (state, { payload }) => {
            state.publishModal = {
                ...state.publishModal,
                ...payload
            };
        },

        updateAnimations: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) => {
                    // Check if this is the selected user activity
                    if (idx === state.selectedUserActivity) {
                        const updatedElements = userActivity.elements.map((element, index) =>
                            index === payload.selectedElementIdx ? { ...element, animation: payload.animation } : element
                        );
                        return {
                            ...userActivity,
                            elements: updatedElements,
                        };
                    } else {
                        // Return the user activity unchanged for other indexes
                        return userActivity;
                    }
                }),
            };
        },
        updateSlideAnimation: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx === payload.selectedActivityIdx
                        ? {
                            ...userActivity,
                            slideAnimation: payload.slideAnimation,
                        }
                        : userActivity
                ),
            };
        },
        updateThumbnailImg: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx === payload.selectedActivityIdx
                        ? {
                            ...userActivity,
                            thumbnail: payload.thumbnail,
                        }
                        : userActivity
                ),
            };
        },
        /////////////history undo redo////////////////////
        setHistoryUserActivities: (state, { payload }) => {
            // console.log("payload1", payload)
            state.historyUserActivities.historyActivity = payload;
        },
        setHistoryUserActivitiesIndex: (state, { payload }) => {
            state.historyUserActivities.historyActivityIndex = payload;
        },
        ///////////////////////////flow condition///////
        addCondition: (state, { payload }) => {
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx === payload.selectedActivityIdx
                        ? {
                            ...userActivity,
                            condition: userActivity.condition?.some(condition =>
                                JSON.stringify(condition.target) === JSON.stringify(payload.condition.target)
                            )
                                ? userActivity.condition
                                : userActivity.condition ? [...userActivity.condition, payload.condition] : [payload.condition],
                        }
                        : userActivity
                ),
            };
        },
        updateCondition: (state, { payload }) => {
            // console.log(payload.condition)
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx === payload.selectedActivityIdx
                        ? {
                            ...userActivity,
                            condition: userActivity.condition?.map(condition =>
                                JSON.stringify(condition.id) === JSON.stringify(payload.condition.id)
                                    ? payload.condition
                                    : condition,
                            )
                        }
                        : userActivity
                ),
            };
        },
        updateAllCondition: (state, { payload }) => {
            // console.log(payload.condition)
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx !== -1
                        ? {
                            ...userActivity,
                            condition: userActivity.condition?.map(condition =>
                                JSON.stringify(condition.source) !== JSON.stringify(idx + 1)
                                    ? { ...condition, source: JSON.stringify(idx + 1), id: `e${JSON.stringify(idx + 1)}-${condition.target}` }
                                    : condition,
                            )
                        }
                        : userActivity
                ),
            };
        },
        deleteCondition: (state, { payload }) => {
            // console.log(payload.condition)
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx !== -1
                        ? {
                            ...userActivity,
                            condition: userActivity.condition?.filter(condition =>
                                JSON.stringify(condition.id) !== JSON.stringify(payload.condition.id)
                            )
                        }
                        : userActivity
                ),
            };
        },
        deleteAllCondition: (state, { payload }) => {
            // console.log(payload.condition)
            return {
                ...state,
                userActivities: state.userActivities.map((userActivity, idx) =>
                    idx === payload.selectedActivityIdx
                        ? {
                            ...userActivity,
                            condition: []
                        }
                        : userActivity
                ),
            };
        },

        setTextFormData: (state, { payload }) => {
            var mergeFormData = { ...state.textFormData, ...payload }
            // console.log(mergeFormData);

            // Filter out duplicate values
            var filteredMergeFormData = Object.fromEntries(
                Object.entries(mergeFormData)
                    .reverse()  // Reverse the order to keep the latest values
                    .filter(([key, value], index, self) => self.findIndex(([k, v]) => v === value) === index)
            );

            state.textFormData = filteredMergeFormData;
        },


    },
});

export const { selectUserActivity, selectSlide, updateGoToSlide, updateSelectedComponent, updateUserActivityBackground,
    addElementInUserActivity, addUserActivity, updateUserActivity, updateUserActivityElements, setUserActivity,
    deleteUserActivityElements, removeUserActivity, duplicateUserActivity, addUserActivity1, updateElementInputFocus,
    updatePublishModal, updateAnimations, updateSlideAnimation, updateThumbnailImg,
    setHistoryUserActivities, setHistoryUserActivitiesIndex, addCondition, updateCondition,
    updateAllCondition, deleteCondition, deleteAllCondition, setTextFormData } = activity.actions;
export default activity.reducer;
