import React, { useState, useEffect } from 'react';
import './Integrations.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import SlackIntegration from "../SlackIntegration/SlackIntegration";
import { useSelector, useDispatch } from "react-redux";
import HubSpotIntegration from '../HubSpotIntegration/HubSpotIntegration';
import GoogleSheetsIntegration from '../GoogleSheetsIntegration/GoogleSheetsIntegration';
import GmailIntegration from '../GmailIntegration/GmailIntegration';
import { useLocation } from 'react-router-dom';

const Integrations = () => {
    const { width, height } = useWindowDimensions();
    const [selected, setSelected] = useState(0);
    const [isSlack, setisSlack] = useState(false);
    const [isHubspot, setisHubspot] = useState(false);
    const [isGmail, setisGmail] = useState(false);
    const [isGoogleSheet, setisGoogleSheet] = useState(false);
    const navigate = useNavigate();
    const { userProAccess } = useSelector((state) => state.user);
    const { slack_integration, hubspot_integration, gmail_integration, google_sheet_integration } = useSelector(state => state.duonutSettings);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // useEffect(() => {
    //     if (!userProAccess) {
    //         // Extract the current URL
    //         const currentURL = window.location.href;

    //         // Remove the last segment (in this case, 'reports') from the URL
    //         const newURL = currentURL.substring(0, currentURL.lastIndexOf('/'));

    //         // Navigate to the new URL
    //         window.location = newURL;
    //     }
    // }, []);

    useEffect(() => {
        if (slack_integration?.access_key) {
            setisSlack(true);
        } else {
            setisSlack(false);
        }
        if (hubspot_integration?.access_key) {
            setisHubspot(true);
        } else {
            setisHubspot(false);
        }

        if (gmail_integration?.access_key) {
            setisGmail(true);
        } else {
            setisGmail(false);
        }

        if (google_sheet_integration?.access_key) {
            setisGoogleSheet(true);
        } else {
            setisGoogleSheet(false);
        }
    }, [slack_integration, hubspot_integration, google_sheet_integration]);

    useEffect(() => {
        if (queryParams.get('slack')) {
            setSelected(1);

            queryParams.delete('slack');

            navigate({
                search: queryParams.toString(),
            }, { replace: true });
        } else if (queryParams.get('hubspot')) {
            setSelected(2);

            queryParams.delete('hubspot');

            navigate({
                search: queryParams.toString(),
            }, { replace: true });
        } else if (queryParams.get('gmail')) {
            setSelected(3);

            queryParams.delete('gmail');

            navigate({
                search: queryParams.toString(),
            }, { replace: true });
        }
    }, [queryParams]);

    return (
        <div className='IGP1App'>
            <div className='IGP1template'>
                {selected === 0 && <div className='IGP1Container'>
                    <div className='IGP1InnerContainer' onClick={() => setSelected(1)}>
                        {isSlack && <img src="/assets/successTickMark.png" style={{ width: "1.2rem", padding: "0.5rem", position: "absolute", left: "0", top: "0" }} />}
                        <img src="/assets/icons/slack.svg" style={{ width: "3rem", paddingBottom: "1.5rem" }} alt="duonut" loading="lazy" />
                        <div style={{ fontSize: "1.2rem", fontWeight: "700", paddingBottom: "1rem" }}>Slack </div>
                        <div style={{ fontSize: "1rem" }}>Sync form submissions to Slack channels </div>
                    </div>
                    <div className='IGP1InnerContainer' onClick={() => setSelected(2)}>
                        {isHubspot && <img src="/assets/successTickMark.png" style={{ width: "1.2rem", padding: "0.5rem", position: "absolute", left: "0", top: "0" }} />}
                        <img src="/assets/icons/hubspot.svg" style={{ width: "3rem", paddingBottom: "1.5rem" }} alt="duonut" loading="lazy" />
                        <div style={{ fontSize: "1.2rem", fontWeight: "700", paddingBottom: "1rem" }}>HubSpot </div>
                        <div style={{ fontSize: "1rem" }}>Send new contacts to your CRM</div>
                    </div>

                    <div className='IGP1InnerContainer' onClick={() => setSelected(3)}>
                        {isGmail && <img src="/assets/successTickMark.png" style={{ width: "1.2rem", padding: "0.5rem", position: "absolute", left: "0", top: "0" }} />}
                        <svg style={{ paddingBottom: "1rem" }} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="52 42 88 66">
                            <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6"></path><path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15"></path><path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2"></path><path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92"></path><path fill="#c5221f" d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"></path>
                        </svg>
                        <div style={{ fontSize: "1.2rem", fontWeight: "700", paddingBottom: "1rem" }}>Gmail </div>
                        <div style={{ fontSize: "1rem" }}>Send automatic emails</div>
                    </div>

                    <div className='IGP1InnerContainer' onClick={() => setSelected(4)}>
                        {isGoogleSheet && <img src="/assets/successTickMark.png" style={{ width: "1.2rem", padding: "0.5rem", position: "absolute", left: "0", top: "0" }} />}
                        <svg style={{ paddingBottom: "1rem" }} height="64" width="60" preserveAspectRatio="xMinYMin meet" role="img" viewBox="0 0 212 283">
                            <g fill="none" fill-rule="evenodd"><path d="M17 0h124l71 71v195c0 9.389-7.611 17-17 17H17c-9.389 0-17-7.611-17-17V17C0 7.611 7.611 0 17 0z" fill="#13A562"></path><g fill="#F1F1F1"><path d="M55 158h103v12H55z"></path><path d="M101 138h12v83h-12z"></path><path d="M55 188h103v12H55z"></path><path d="M60 142v75h93v-75H60zm-12-12h117v99H48v-99z" fill-rule="nonzero"></path></g><path d="M146 66l66 67V70.992z" fill="#049052"></path><path d="M141 0l71 71h-54c-9.389 0-17-7.611-17-17V0z" fill="#8BD1AF"></path></g>
                        </svg>
                        <div style={{ fontSize: "1.2rem", fontWeight: "700", paddingBottom: "1rem" }}>Google Sheets </div>
                        <div style={{ fontSize: "1rem" }}>Send your Duonut responses to Sheet.</div>
                    </div>
                </div>}

                {selected === 1 && <div>
                    <SlackIntegration setSelected={setSelected}> </SlackIntegration>
                </div>}
                {selected === 2 && <div>
                    <HubSpotIntegration setSelected={setSelected}></HubSpotIntegration>
                </div>}
                {selected === 3 && <div>
                    <GmailIntegration setSelected={setSelected}> </GmailIntegration>
                </div>}
                {selected === 4 && <div>
                    <GoogleSheetsIntegration setSelected={setSelected}></GoogleSheetsIntegration>
                </div>}
            </div>
        </div>
    );

}

export default Integrations;