import { createSlice } from "@reduxjs/toolkit";

//sliders in design page
//when the user changes activity categories, activity subtypes and themes of each subtype of its activity type will be fetched
//each subtype will have key 'activityTypeId'

//trending themes will be received from backend
//We will also get activityType and subtype of each trending Theme.

const initialState = {
  proMessageModalDuonut: {
    active: false,
    message: "",
  }
};

//updateSubtypes will be dispatched whenever the category is changed or the selected activity types changes.

const ProMessage = createSlice({
  name: "ProMessage",
  initialState,
  reducers: {
    toggleProMessageModalDuonut: (state, { payload }) => {
      state.proMessageModalDuonut.active = payload;
    },
    updateProMessageModalMessage: (state, { payload }) => {
      state.proMessageModalDuonut.message = payload;
    },
  },
});

export const { toggleProMessageModalDuonut, updateProMessageModalMessage } = ProMessage.actions;
export default ProMessage.reducer;
