import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function BottomBorder() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [borderBottom, setborderBottom] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const borderBottom = tempElement?.elementAttributes?.borderBottom;
            if (borderBottom) {
                setborderBottom(borderBottom);
            } else {
                setborderBottom(false);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleBottomBorderChange = () => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    borderBottom: !borderBottom
                },
            };
            console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className="EditorRowContainer" style={{ margin: "1rem 0px" }}>
            <input type='checkbox' id='borderBottom' name='borderBottom'
                style={{ width: "16px", height: "16px", margin: "2px 8px 2px 2px" }}
                checked={borderBottom ? true : false}
                onChange={() => handleBottomBorderChange()} />

            <label style={{ marginRight: "1rem", fontSize: "14px" }} for="borderBottom">Only Bottom Border</label>

        </div>
    )
}

