import React from 'react';
import './PrivacyPolicy.css';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';

const PrivacyPolicy = () => {

    return (
        <div className='TC1App'>
            <div className='TC1template'>
                <h1>Privacy Policy</h1>
                <br></br>
                <br></br>
                <div>Effective Date: 28/05/2024</div>
                <br></br>
                <div>
                    Respecting your privacy is critically important to achieving our mission of motivating every student. These are our guiding privacy principles:
                </div>
                <br></br>
                <li>
                    We don't ask you for personal information unless we truly need it.
                </li>
                <br></br>
                <li>
                    We don't keep your personal information longer than is necessary to provide our services to you.
                </li>
                <br></br>
                <li>
                    We don't share your personal information with anyone except to comply with the law, provide our services, or protect our rights.
                </li>
                <br></br>
                <li>
                    We don't rent, sell or exchange your personal information.
                </li>
                <br></br>
                <li>
                    We aim to make it as simple as possible for you to control what’s visible to the public, seen by search engines, kept private, and permanently deleted.
                </li>
                <br></br>
                <div>
                    This Privacy Policy describes how Duonut collects, uses, shares, stores and protects your personally identifiable information and how you can access, update, delete, limit, and otherwise halt the use of this information. We offer you choices regarding the collection, use, and sharing of your personal information, including the option to use services without providing personal information.
                </div>
                <br></br>
                <div>
                    This privacy policy (“Policy”) explains how Duonut Inc or any of its affiliates or subsidiaries (“We”, “Us”, “Our”) Processes Personal Data collected from You.
                </div>

                <br></br>
                <h3>DEFINITIONS</h3>
                <br></br>
                <li>
                    “Controller” means the natural or legal person, public authority, agency, or other body which alone or jointly with others, determines the purposes and means of the processing of Personal Data.
                </li>
                <br></br>
                <li>
                    “End-User” means any person or entity with whom the Subscriber interacts using the Service(s).
                </li>
                <br></br>
                <li>
                    “Personal Data” means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
                </li>
                <br></br>
                <li>
                    “Usage Data” means data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                </li>
                <br></br>
                <li>
                    “Process” means any operation or set of operations which is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
                </li>
                <br></br>
                <li>
                    “Processor” means a natural or legal person, public authority, agency, or other body which Processes Personal Data on behalf of the Controller.
                </li>
                <br></br>
                <li>
                    “Service(s)” shall have the meaning ascribed to it in the Terms of Service.
                </li>
                <br></br>
                <li>
                    “Subscriber” means the natural or legal person that has subscribed to the Service(s) by agreeing to the Terms of Service.
                </li>
                <br></br>
                <li>
                    “Terms of Service” means the binding contract between Us and You which governs the access and use of the Service(s) by You available at https://duonut.com/termsandconditions
                </li>
                <br></br>
                <li>
                    “Website(s)” means the websites that We operate.
                </li>
                <br></br>
                <li>
                    “You” and “Your” means an identified or identifiable natural person whose Personal Data We process as a Controller.
                </li>

                <br></br>
                <br></br>
                <h2>1. Who are we?</h2>
                <br></br>
                <div>
                    As our name suggests Duonut is a platform under Duonut Inc , a company which was born in 2019 and since then our mission has been to help you create all kinds of audiovisual and interactive content in an easy and fast way. We are committed to protecting your privacy, security and being transparent with the personal information you share with us, as well as ensuring that you can exercise your rights as the owner of the data you provide us with.
                </div>
                <br></br>
                <div>
                    Additionally, you can consult our T&C, we assure you that after this you will only want to take a good nap.
                </div>
                <br></br>
                <li><b>Company:</b> Duonut Inc</li>
                <li><b>E-mail:</b> hello@duonut.com</li>

                <br></br>
                <br></br>
                <h2>2. What is the purpose of this Privacy Policy?</h2>
                <br></br>
                <div>
                    We have the objective and the legal obligation to inform you clearly and precisely of the way in which we process your personal information when you access the websites hosted under the domain names and subdomains dependent on our Website.
                </div>
                <br></br>
                <div>
                    We also inform you that access to the Website is permitted whether you are an individual or a legal entity, provided that you have successfully completed registration and meet the following requirements:
                </div>
                <br></br>
                <div>
                    <b>Individuals:</b> access to the Website is permitted to the extent that you comply with our  User Policy, subject to any additional access requirements under the applicable laws of the country in which you reside:
                </div>
                <br></br>
                <li>You must be at least thirteen (13) years of age or have the express consent of your parent or legal guardian.
                </li>
                <br></br>
                <div>
                    In order to ensure that the age criteria are met, Duonut reserves the right to make appropriate verifications and to prohibit access to those Users whom it knows or suspects do not meet these requirements.
                </div>
                <br></br>
                <div>Here are some examples of safety and privacy measures on Duonut:
                </div>
                <div>1. Behaviorally targeted advertising is not permitted on Duonut.</div>
                <div>2. Users can join games without creating an account.</div>
                <div>3. Users  who have not or cannot provide consent have a limited product experience.</div>
                <div>4. The platform does not facilitate direct messaging between users.</div>
                <div>5. Quizzes/activites are subject to proactive content filters and reactive reporting tools. </div>


                <br></br>
                <br></br>
                <h2>3. Which data do we collect?</h2>
                <br></br>
                <div>
                    The provision of your data through the registration form and while browsing through our Website is voluntary. However, it is essential for the provision of our Services that you provide us with certain personal information, otherwise it would be impossible for us to provide them to you.
                </div>
                <br></br>
                <div>
                    You also guarantee that the information you provide us is truthful, exact, current and authentic and you agree that Duonut reserves the right to exclude from the Web Site those Users that it considers that they are providing false, erroneous or incorrect information.
                </div>
                <br></br>
                <div>
                    In this sense, Duonut processes the following personal data:
                </div>
                <li>Identifying information, such as first and last name.</li>
                <li>Contact data, such as email address and/or postal address.</li>
                <li>Sector and role data, such as the field in which the User works or studies and his/her position in the organization to which he/she belongs.</li>
                <li>Navigation data for purposes of improving the service and the available content.</li>
                <li>Data about Users' preferences and interests in relation to the digital content offered on Duonut's Website.</li>
                <li>Data that Duonut's Web Site servers can automatically detect, such as the IP addresses of Users.</li>
                <li>Data about Users' preferred language.</li>
                <br></br>
                <div>
                    Duonut will never require a user to share more information than is reasonably necessary for using Duonut. While Duonut will assign persistent identifiers to all Duonut users to recognize repeat visitors and improve their Duonut experience, Duonut does not knowingly collect personally identifiable information from children younger than the age of consent without a parent or guardian's consent. If we become aware, despite our intention not to gather this information, that we collected personally identifiable information from a child younger than the age of consent without permission then we will either delete this information or seek to obtain verifiable parental consent from the child’s parent or guardian to collect and use that data to provide the child with Duonut services in the future.
                </div>
                <br></br>
                <div>
                    3.1.  You directly provide Us with most of the data We collect. We collect Personal Data from You directly as follows: a) When You subscribe for any of Our Service(s) by agreeing to the Terms of Service, We collect sign-up and account information including Your first and last name, e-mail address, phone number, address, State, Province, ZIP/Postal code, city; b) When You submit web forms on Our Website(s) or as You use interactive features of the Website(s), including providing feedback or suggestions, making requests, or participation in surveys, contests, webinars, events, podcasts, promotions, sweepstakes, requesting customer support, or otherwise communicating with Us; c) When You provide testimonials, forms or information to Us in other contexts; or d) When You authorize Us to connect with a third-party service, We will access and store Your Personal Data that the third-party service makes available to Us, which may include  Your email address, location or profile information.
                </div>
                <br></br>
                <div>
                    3.2.  We may also receive Your Personal Data indirectly as follows: a) from third party sources like marketing lists, databases and social media but only where We have checked that these third parties either have Your consent or are otherwise legally permitted or required to disclose Your Personal Data to Us; b)When You download and/or use the Service(s), We automatically collect Usage Data such as Your device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data, to performOur agreement with You; e) When You use or view Our Website(s), information is collected via Your browser’s cookies as described in clause 9 herein; f) When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile internet browser You use, unique device identifiers and other diagnostic data; g) We may also collect or receive Your Personal Data from other sources such as Our business or channel partners through whom You create or access Your account, publicly available sources, email add-ons and/ or through the combining of information We obtain from third parties along with the Personal Data You provide to Us.
                </div>
                <br></br>
                <div>
                    3.3.  If you decide to register through or otherwise grant access to a third-party social networking or integrated service, such as Facebook, Google or similar single sign-on service, we may also collect Personal Data that is already associated with your integrated service account. You may also have the option of sharing additional information such as profile pictures with us through an integrated service, as controlled through your settings on that integrated service. If you choose to provide such information, during registration or otherwise, we will treat the information as Personal Data and will use it in the ways described in this Privacy Policy.
                </div>
                <br></br>
                <div>
                    3.4.  The Website(s) includes social media features and widgets that are either hosted by a third-party or hosted directly on the Website(s) and Your interaction with these social media features and widgets is governed by the privacy statement of the companies that provide them. You should check Your privacy settings on these third-party services to understand and change the information sent to Us through these services.
                </div>


                <br></br>
                <br></br>
                <h2>4. For what purposes do we process your data?</h2>
                <br></br>
                <div>
                    The processing that we carry out on your data corresponds to a purpose provided for by the law (legitimate basis), as detailed below:
                </div>
                <br></br>
                <h3>Duonut's legitimate interest</h3>
                <li>To process, manage and resolve your possible complaints, suggestions or queries or those of other Users.</li>
                <li>To carry out studies to improve the quality of our Services, we use data on the preferences of the Users.</li>
                <li>In case you have completed the registration process, to send you commercial communications about products and services offered by Duonut that could be of your interest, related to the ones you have previously contracted, by any means including electronic.
                </li>

                <br></br>
                <h3>Consent</h3>
                <li>Consent is requested for the processing of data obtained through cookies and similar devices or mechanisms that allow the storage and retrieval of data, such as pixels or code fragments. This treatment is carried out in accordance with the applicable regulations and the guidelines issued by the Spanish Data Protection Agency on the date of drafting this Privacy Policy, in order to ensure the proper functioning of all the functionalities of the Website.
                </li>

                <br></br>
                <h3>To manage a pre-contractual relationship</h3>
                <li>To manage the reception of candidacies and the possible participation in Duonut's selection processes.</li>

                <br></br>
                <h3>To manage a contractual relationship</h3>
                <li>For the provision of Services, such as making available to you different functionalities of Duonut so that you can elaborate in an easy and simple way the creations you need.
                </li>

                <br></br>
                <h3>To comply with a legal obligation</h3>
                <li>For the purpose of communicating information to public authorities, regulators or governmental bodies in those cases where it is necessary to do so by law, local regulation or in compliance with regulatory obligations.
                </li>
                <br></br>
                <div>
                    We Process Your Personal Data to: a) facilitate Your access to the Website(s) and Service(s); b) process and complete payment transactions; c) provide customer service and support; d) send You communication on Your use of the Service(s), updates on Our Terms of Service or other policies; e) send You communication on new features in the Service(s) or new service offerings, news, special offers and general information about other goods, services and events, in compliance with applicable laws, unless You have opted not to receive such information.; f) conduct analytics and/or to improve Our Service(s) for Your benefit; e) organize events or for other marketing/ promotional activities; f) investigate and prevent fraudulent transactions, unauthorized access to the Websites and Service(s), and other illegal activities; g) personalize the Websites and Service(s); and for other purposes for which We obtain Your consent.
                </div>


                <br></br>
                <br></br>
                <h2>5. Why do we need to process your personal data?</h2>
                <br></br>
                <div>
                    Please note that in order you may enjoy our Web Site it is necessary for us to process your personal data so that, by registering on it, you declare that you have been informed of the privacy conditions and you authorize us to collect your data in accordance with the purposes that we have indicated above, otherwise we will not be able to provide you the Services.
                </div>
                <br></br>
                <div>
                    In addition, you agree to inform us of any changes and/or updates to the data you have provided us, ensuring that they are true and accurate for as long as you are our User. For security reasons of our Web Site, Duonut reserves the right of admission of Users.
                </div>


                <br></br>
                <br></br>
                <h2>6. With whom do we share your data?</h2>
                <br></br>
                <div>For sensitive information (i.e., personal information specifying medical or health conditions, racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership or information specifying the sex life of the individual), Duonut Inc must obtain affirmative express consent (i.e., opt in) from individuals if such information is to be (i) disclosed to a third party or (ii) used for a purpose other than those for which it was originally collected or subsequently authorized by the individuals through the exercise of opt-in choice. If an individual would like to opt out of their personal information being disclosed to a third party or be used for a purpose that is materially different from the purpose(s) for which it was originally collected, the individual can reach out to <b>hello@duonut.com</b> after receiving the notice.</div>
                <br></br>
                <div>
                    As we mentioned earlier, transparency is very important to us, consequently, we will not share the personal data you provide us with any third party for other purposes than to guarantee the functioning of our Web Site, that is, in order to provide you with our Services through our Web Site, certain suppliers need access to the personal data of our Users, so it is essential that we share certain information with them.
                </div>
                <br></br>
                <div>
                    We also guarantee that we only share data with third parties within the framework established by law and for the purpose of receiving maintenance, support and operational services regarding our Web Site.
                </div>
                <br></br>
                <div>
                    6.1.  You acknowledge that We will share Your Personal Data with Our group companies and third-party service providers so that they may offer You Our Service(s) and/or to send information or updates on the Service(s) if You are a Subscriber.
                </div>
                <br></br>
                <div>
                    6.2.  When We Process Your order where You are a Subscriber, we may send Your Personal Data to and also use the resulting information from credit reference agencies to prevent fraudulent purchases.
                </div>
                <br></br>
                <div>
                    6.3.  We share Personal Data with Our third-party service providers that host and maintain the Our Website(s), applications, backup, storage, payment processing, analytics and other services. These third-party service providers may have access to or Process Your Personal Data for the purpose of providing these services to Us.
                </div>
                <br></br>
                <div>
                    6.4.  We may share Your Personal Data with third-party providers who assist Us in marketing and promotions in compliance with applicable laws.
                </div>
                <br></br>
                <div>
                    6.5.  We may share or transfer Your Personal Data in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                </div>
                <br></br>
                <div>
                    6.6.  We may disclose Your Personal Data for any other purpose with Your consent.
                </div>
                <br></br>
                <div>
                    6.7.  We may be required to disclose Your Personal Data in response to: a) lawful requests by public authorities, including to meet national security or law enforcement requirements and/or b) subpoenas, court orders, or legal process, or to establish or exercise Our legal rights or defend against legal claims.
                </div>
                <br></br>
                <div>
                    6.8.  We may also share Personal Data to assist investigation and prevention of illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Our Terms of Service, or as otherwise required by law.
                </div>
                <br></br>
                <div>
                    Please note that we only use third party services whose policies are aligned with ours, therefore, we make available to you the privacy policies of each of our providers in order you can understand how your information will be processed within the framework of the services provided to Duonut:
                </div>

                <br></br>
                <table className='TC1table'>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Data</th>
                        <th>Privacy Policy</th>
                    </tr>
                    <tr>
                        <td>AWS</td>
                        <td>Hosting Services</td>
                        <td>All customer data</td>
                        <td>https://aws.amazon.com/es/privacy/</td>
                    </tr>
                    <tr>
                        <td>Mailchimp</td>
                        <td>Mailchimp is the integrated marketing platform companies</td>
                        <td>User's email address, role, sector, language, plan, number of Duonuts, last access, subscription type, registration date, country</td>
                        <td>https://mailchimp.com/legal/privacy/</td>
                    </tr>
                    <tr>
                        <td>Freshdesk</td>
                        <td>Comprehensive customer service software with omnichannel service and fast amortization</td>
                        <td>Email, Plan, Subscription period, Subscription end date, Subscription start date, Subscription ID, Duonuts number, Company name, LoginRRSS, Country, City, Industry, Role, Last login date, Registration date, User ID, Time zone, Language</td>
                        <td>https://www.freshworks.com/privacy/</td>
                    </tr>
                    <tr>
                        <td>Facebook</td>
                        <td>Social Network</td>
                        <td>Allows users to share information and access the Web Site through Social Login</td>
                        <td>https://www.facebook.com/policy.php</td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td>Social Network</td>
                        <td>Allows users to share information and access the Web Site through Social Login</td>
                        <td>https://twitter.com/en/privacy</td>
                    </tr>
                    <tr>
                        <td>Linkedin</td>
                        <td>Social Network</td>
                        <td>Allows users to share information and access the Web Site through Social Login</td>
                        <td>https://www.linkedin.com/legal/privacy-policy</td>
                    </tr>
                    <tr>
                        <td>Office 365</td>
                        <td>Line of subscription services offered by Microsoft</td>
                        <td>Allows users to access the Web Site through Social Login</td>
                        <td>https://privacy.microsoft.com/es-es/privacystatement</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>Internet-related services and product</td>
                        <td>Allows users to access the Web Site through Social Login</td>
                        <td>https://policies.google.com/privacy?hl=en-US</td>
                    </tr>
                    <tr>
                        <td>Razorpay</td>
                        <td>A payment API package for companies operating on the Internet, including fraud prevention and subscription management</td>
                        <td>User's purchase data (email, card, country)</td>
                        <td>https://razorpay.com/privacy/</td>
                    </tr>
                    <tr>
                        <td>Google Analytics</td>
                        <td>Free web analytics tool from Google</td>
                        <td>User ID</td>
                        <td>https://policies.google.com/privacy?hl=en-US</td>
                    </tr>
                </table>

                <br></br>
                <br></br>
                <div>
                    As a general rule, our suppliers are located in countries with an adequate level of protection, so we do not carry out international data transfers.
                </div>


                <br></br>
                <br></br>
                <h2>7. International Transfer</h2>
                <br></br>
                <div>
                    We mainly Process Personal Data in the USA & India. However, We may transfer Personal Data outside the USA for the purposes referred to in Section 6. We will ensure that the recipient of Your Personal Data offers an adequate level of protection that is at least comparable to that which is provided under applicable data protection laws.
                </div>


                <br></br>
                <br></br>
                <h2>8. How long do we store your data?</h2>
                <br></br>
                <div>You have the right to request that we delete the Personal Information that we have collected about you. To do so, please email <b>hello@duonut.com</b> with your account information and request.
                </div>
                <br></br>
                <div>
                    We store your personal data to the extent that they are necessary for the purpose for which they were collected, that is, we will only keep your data while there is a contractual and / or business relationship with you, unless you wish to exercise your rights of opposition or deletion, in which case, they will be retained, without giving them any use, while they may be necessary for the exercise of claims or may arise some kind of legal or contractual responsibility that must be addressed and for which recovery is necessary.
                </div>
                <br></br>
                <div>
                    In the absence of a need to retain Personal Data, We will either delete it or aggregate it, or, if this is not possible then We will securely store Your Personal Data and isolate it from any further Processing until deletion is possible.
                </div>


                <br></br>
                <br></br>
                <h2>9. Security of Personal Data</h2>
                <br></br>
                <div>
                    We use appropriate technical and organizational measures to protect the Personal Data that We collect and Process. The measures We use are designed to provide a level of security appropriate to the risk of Processing Your Personal Data. If You have questions about the security of Your Personal Data, please contact Us immediately as described in this Policy.
                </div>


                <br></br>
                <br></br>
                <h2>10. What are your rights as a User?</h2>
                <br></br>
                <div>
                    As a User, you can withdraw your consent at any time when it has been granted for the processing of your data and, in no case, such withdrawal will condition the execution of the service contract or the relationships generated previously.
                </div>
                <br></br>
                <div>
                    Likewise, at any time you can exercise your rights of Access, Rectification, Cancellation, Opposition, Portability and/or to be forgotten by writing to the contact email that we have enabled for this purpose: hello@duonut.com; indicating in the subject expressly the request you wish to make or by writing to the postal address indicated in paragraph 1, indicating the reference "Personal Data", specifying the right to be exercised and with respect to what personal data.
                </div>
                <br></br>
                <div>
                    If we are unable to verify your identity correctly, we may request additional information from you.
                </div>
                <br></br>
                <div> Below, we briefly explain what your rights consist of:</div>
                <li><b>Access:</b> through the exercise of this right you may know which personal data we are processing; for what purpose or its possible transfer to third parties.
                </li>
                <li><b>Rectification:</b> you can modify your personal data that are inaccurate or incomplete, specifying in your request which data you want to be modified.
                </li>
                <li><b>Cancellation:</b> allows the cancellation of your personal data.
                </li>
                <li><b>Opposition:</b> you can oppose the processing of your data for a specific purpose, for example in the hypothetical case that your data is used for advertising and commercial prospecting activities.
                </li>
                <li><b>Portability:</b> you may receive your personal data provided in a structured, commonly used and machine-readable format, and be able to transmit it to another data controller, provided that it is technically possible.
                </li>
                <li><b>Right to be forgotten:</b> you may request the deletion of personal data without due delay when any of the events contemplated by law have occurred. For example, unlawful processing of data, or when the purpose for which the data was collected or processed has disappeared.
                </li>


                <br></br>
                <br></br>
                <h2>11. Where do we store your data?</h2>
                <br></br>
                <div>
                    Duonut maintains strict organizational and technical measures to protect Personal Information stored on our, and our trusted third parties’, servers. Access to any personal information we process in order to provision the Services is limited through login credentials, multi-factor authentication, access on a need-to-know basis and restricted access to administrative accounts for the employees who require access in order to perform their duties towards you, such as providing support services.
                </div>

                <br></br>
                <br></br>
                <h2>12. Cookie Policy</h2>
                <br></br>
                <div>
                    Cookies are text files that are placed on Your computer to collect standard internet log information and visitor behaviour information by Us. When You visit the Website(s), We may collect Personal Data automatically from You through cookies or similar technology. We also set cookies to collect information that is used either in aggregate form to help Us understand how our Website(s) are being used or how effective Our marketing campaigns are, to help customize the Website(s) for You or to make advertising messages more relevant to You.
                </div>

                <br></br>
                <br></br>
                <h2>13. Privacy of Children</h2>
                <br></br>
                <div>
                    We recognize the importance of children's safety and privacy. We do not request, or knowingly collect, any Personal Data from children under the age of 18. If a parent or guardian becomes aware that his or her child has provided Us with Personal Data, they should write to us at the email address provided below.
                </div>
                <br></br>
                <div>
                    If we learn we have collected Personal Information from a child under 13 years of age, we will delete the information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Information to us, please contact us at <b>hello@duonut.com</b>.
                </div>


                <br></br>
                <br></br>
                <h2>14. Modifications to the Privacy Policy</h2>
                <br></br>
                <div>
                    It is possible that due to functional, legal or jurisprudential changes we may have to modify our Privacy Policy adapting it to such changes, for such reasons we reserve the right to modify this Privacy Policy. However, you should not worry because we will inform you of any changes we make to this Privacy Policy and, if necessary, you will be asked for your consent. To this end, we will inform you of any changes thirty (30) days in advance.
                </div>


                <br></br>
                <br></br>
                <h2>15. Notice to end-user and other exclusions</h2>
                <br></br>
                <div>
                    15.1.  Where the Services are made available to an End-User through a Subscriber, that Subscriber is the Controller of the End-User’s Personal Data. In such a case, the End-User’s data privacy questions and requests should be submitted to the Subscriber in its capacity as the End-User’s Controller. If the End-User is an individual who interacts with a Subscriber using Our Services, the End-User will be directed to contact Our Subscriber for assistance with any requests or questions relating to their Personal Data. We are not responsible for Subscribers’ privacy or security practices which may be different from this notice. Subscribers to Our Services are solely responsible for establishing policies for and ensuring compliance with all applicable laws and regulations, as well as any and all privacy policies, agreements, or other obligations, relating to the collection of Personal Data in connection with the use of Our Services by End-Users.
                </div>
                <br></br>
                <div>
                    15.2.  We will not send emails to End-Users of our Subscribers’ websites. However, Our Subscribers may use Our Service to send commercial e-mails to End-Users. Under Our policies, Subscribers may only use the Service to send commercial emails to End-Users who have opted in to receiving such e-mails. Each email sent by a Subscriber through Our Service contains an opt-out mechanism through which You may tell a Subscriber You do not wish to receive future marketing emails from the Subscriber. However, We are not responsible for the emails sent by Our Subscribers, through Our Services, and We cannot guarantee that Subscribers will follow Our policies or comply with applicable laws. End-Users can report suspected abuse to hello@duonut.com.
                </div>
                <br></br>
                <div>
                    15.3.  Our Website(s) contain links to other websites. Our Policy applies only to our Website(s), so if You click on a link to another website, You should read their privacy policy. We encourage You to review the privacy statements of any such other websites to understand their Personal Data practices.
                </div>
                <br></br>
                <div>
                    15.4.  This Policy does not apply to aggregated information which summarises statistical information about groups of members, and which does not include name, contact information, or any other information that would allow any particular individual to be identified.
                </div>
                <br></br>
                <div>
                    15.5.  When You share Personal Data or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a third-party social media service, Your contacts on the third-party social media service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
                </div>


                <br></br>
                <br></br>
                <h2>16. Change of Control</h2>
                <br></br>
                <div>
                    Over time, Duonut may grow and reorganize. We may share your information, including personal information with affiliates such as a parent company, subsidiaries, joint venture partners or other companies that we control or that are under common control with us, in which case we will require those companies to agree to use your personal information in a way that is consistent with this Privacy Policy.
                </div>
                <br></br>
                <div>
                    In the event of a change to our organizations such that all or a portion of Duonut or its assets are acquired by or merged with a third-party, or in any other situation where personal information that we have collected from users would be one of the assets transferred to or acquired by that third-party, this Privacy Policy will continue to apply to your information, and any acquirer would only be able to handle your personal information as per this policy (unless you give consent to a new policy). We will provide you with notice of an acquisition within thirty (30) days following the completion of such a transaction, by posting on our homepage and by email to your email address that you provided to us. If you do not consent to the use of your personal information by such a successor company, subject to applicable law, you may request its deletion from the company.
                </div>
                <br></br>
                <div>
                    In the unlikely event that Duonut goes out of business, or files for bankruptcy, we will protect your personal information, and will not sell it to any third-party.
                </div>


                <br></br>
                <br></br>
                <h2>17. How do we protect your data?</h2>
                <br></br>
                <div>
                    Finally, we have to tell you that your data are safe with us since we will process them in an absolutely confidential way and we implement adequate technical and organizational measures to guarantee their security and avoid their destruction, loss, illicit access or illicit alteration.
                </div>
                <br></br>
                <div>
                    More specifically, taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, Duonut shall implement appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including inter alia as appropriate:
                </div>
                <br></br>
                <li>the ability to ensure the ongoing confidentiality, integrity, availability and resilience of processing systems and services.
                </li>
                <li>the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident.
                </li>
                <li>a process for regularly testing, assessing and evaluating the effectiveness of technical and organisational measures for ensuring the security of the processing.
                </li>
                <br></br>
                <div>
                    In assessing the appropriate level of security account shall be taken in particular of the risks that are presented by processing, in particular from accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to personal data transmitted, stored or otherwise processed. If Duonut becomes aware of a systems security breach by an unauthorized party or that any user data was used for an unauthorized purpose, we will comply with relevant state and other data breach laws. We will notify users of any breach resulting in unauthorized release of data electronically, at minimum, and without unreasonable delay so that you can take appropriate steps. The notification will include: date of the breach, the types of information that were subject to the breach, general description of what occurred, and steps Duonut is taking to address the breach.
                </div>


                <br></br>
                <br></br>
                <h2>18. GOOGLE USER DATA</h2>
                <br></br>
                <div>
                    Notwithstanding anything else in this Privacy Policy, consumer Gmail account information obtained via the Gmail APIs, is subject to these additional restrictions:
                </div>
                <br></br>
                <div>
                    The Services may read, write, modify, delete or control Gmail message bodies (including attachments), metadata, headers, and settings to provide an email client that allows users to compose, send, read, delete and process emails and will not transfer this Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part of a merger, acquisition, or sale of assets.
                </div>
                <br></br>
                <div>
                    The Services will not use this Gmail data for serving advertisements.
                </div>
                <br></br>
                <div>
                    The Services will not allow humans to read this data unless we have your afﬁrmative agreement for speciﬁc messages, including when doing so is necessary for security purposes such as investigating abuse, to comply with applicable law, or for the Services’ internal operations and even then only when the data have been aggregated and de-identified.
                </div>
                <br></br>
                <div>
                    These restrictions <b> do not apply </b>if you have created an App password for the Services with your Google account with the 2-step verification process.
                </div>
                <br></br>
                <div>
                    The Services’ use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'> Google API Services User Data Policy</a>,  including the Limited Use requirements.
                </div>



                <br></br>
                <br></br>
                <h2>19. Who can answer my questions?</h2>
                <br></br>
                <div>
                    You may contact us if You have any enquiries or feedback on Our personal data protection policies and procedures, or if You wish to make any request, in the following manner:
                </div>
                <br></br>
                <div>
                    Kind Attention: Ramya Bhaskar
                </div>
                <br></br>
                <div>
                    Email Address: ramya@duonut.com
                </div>
                <br></br>
                <div>
                    Address: Duonut Inc
                    1111B S Governors Ave STE 6614
                    Dover, DE 19904
                </div>

                <br></br>
                <br></br>
            </div>
            <HomePageFooter />

        </div>
    );

}

export default PrivacyPolicy;