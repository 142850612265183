import React, { useState } from 'react';
import './Typeform.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Typeform = () => {
    const { width, height } = useWindowDimensions();
    const [selected, setSelected] = useState(0);

    const onClickTab = (tab) => {
        if (selected !== tab) {
            setSelected(tab);
        } else {
            setSelected(0);
        }
    };
    return (
        <div className='HTF1App'>
            <div className='HTF1template'>
                <div className='HTF1section-1'>
                    <div className='HTF1section-1-inner'>
                        <div className='HTF1section-1-left'>
                            <h1 style={{ fontSize: "3rem" }}>DuoNut, a free Typeform alternative</h1>
                            <p style={{ padding: "1rem 0rem", lineHeight: "32px", opacity: "0.8" }}>Typeform can help you launch beautiful forms fast, but for complete customization and the most flexibility forms without an expensive paywall, you need <a href="/"> DuoNut</a>.</p>
                            <button className='btn-secondary' style={{ padding: "1rem", marginTop: "2rem", marginRight: "1rem" }} onClick={(e) => window.open("/design")}>CREATE A FREE FORM</button>(no sign-up needed)
                        </div>
                        <div className='HTF1section-1-right'>
                            <img className='HTF1section-1-right-image' src="/assets/help/help-right.jpg" alt="" />
                        </div>
                    </div>
                </div>

                <div className="home_page_integration_section">
                    <div className="home_page_section_header">Trusted by Startups & Large Companies</div>
                    <div className="home_page_integration_section_images">
                        <img src="/assets/company/Narayana.png" alt="message" />
                        <img src="/assets/company/xe.png" alt="message" />
                        <img src="/assets/company/mintorie.png" alt="message" />
                        <img src="/assets/company/ourlearn.png" alt="message" />
                        <img src="/assets/company/uleash.png" alt="message" />
                        <img src="/assets/company/vivahit.png" alt="message" />

                    </div>
                </div>

                <div style={{ padding: "4rem 0rem" }}>
                    <h1 style={{ display: "flex", fontSize: width > 800 ? "35px" : "28px", justifyContent: "center", gridGap: "15px", fontWeight: "600" }}>Say Hello to DuoNut! <img style={{ width: "40px", height: "40px" }} src="/assets/hand.webp" alt="message" /></h1>
                    <h3 style={{ fontSize: "25px", fontWeight: "400" }}>The Fresh Alternative to Typeform</h3>
                    <p style={{ paddingTop: "1.2rem", lineHeight: "32px", fontWeight: "500", opacity: "0.8", maxWidth: width > 800 ? "70%" : "100%", margin: "auto" }}>Duonut provides right templates to create personalized forms that fit your business & brand. Choose the Best Alternative to Typeform with unlimited access to templates, most intuitive builder, and numerous customization options at a way affordable pricing.</p>
                    <button className='btn-secondary' style={{ padding: "1rem", marginTop: "2rem" }} onClick={(e) => window.open("/design")}>CREATE A FREE FORM</button>
                </div>

                <div>
                    <h1>DuoNut Vs Typeform</h1>
                    <table className='HTF1table' style={{ width: width > 800 ? "80%" : "100%", margin: "auto" }}>
                        <tbody>
                            <tr>
                                <th>Features</th>
                                <th style={{ textAlign: "center" }}>DuoNut</th>
                                <th style={{ textAlign: "center" }}>Typeform</th>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Unlimited Access to Templates</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Unlimited Question</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td style={{ display: "flex", justifyContent: "center" }}><img style={{ margin: "0px 10px" }} src="/assets/cross.webp" alt="message" />(10/form)</td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Column Layout</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Multi Page Forms</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Embedding</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Conditional Logic</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Redirect on Completion</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td style={{ display: "flex", justifyContent: "center" }}><img style={{ margin: "0px 10px" }} src="/assets/cross.webp" alt="message" />($59/mo)</td>
                            </tr>
                            <tr>
                                <td>Custom Thank You page</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Custom CTA</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Custom Domain</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Code Injection</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Custom Css</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Password Protection</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr>
                                <td>Add Background, Music, Logo, Etc.</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <td>Add Animation</td>
                                <td><img src="/assets/right.webp" alt="message" /></td>
                                <td><img src="/assets/cross.webp" alt="message" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row helpmesssage">
                    <div className="col-xs-12 col-md-12 col-lg-6 imgcontainer">
                        <img src="/assets/help/help-typform1.png" alt="create a quiz" loading="lazy" />
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-6 message__column" style={{ paddingLeft: "2rem" }}>
                        <div >
                            <h1> Everything you need to know about DuoNut: </h1>
                            <p>
                                DuoNut is an all-in-one form & userflow builder that blends ease of use with advanced features to support your business. Our no-code designs are simple to build & publish. But that doesn’t mean they’re any less powerful. Whether you’re creating a form, a user flow, a quiz, or conducting a complex survey, you can build them in seconds!
                            </p>

                            <a href="/templates" style={{ cursor: "pointer" }}> View Templates <span style={{ fontSize: "20px", paddingLeft: "5px" }}>&#8594;</span></a>

                        </div>
                    </div>
                </div>

                <div className="row helpmesssage">
                    <div className="col-xs-12 col-md-12 col-lg-6 message__column" style={{ paddingRight: "2rem" }}>
                        <div >
                            <h1> Is DuoNut beginner - friendly? </h1>
                            <p>
                                When you build a form or userflow using DuoNut, you’ll notice it’s as simple as creating a presentation or PPT. You will find templates for all the use cases you want on the left-hand side grid. You can bring it to canvas just by clicking on the templates and start customizing it. We also have advanced blocks like conditional logic to show or hide parts of your form based on a user’s answer. You can also use our <a href="/design">templates</a> to get started.
                            </p>

                            <a href="/design" style={{ cursor: "pointer" }}>Go to the design page <span style={{ fontSize: "20px", paddingLeft: "5px" }}>&#8594;</span></a>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-6 imgcontainer">
                        <img src="/assets/help/help-typform2.png" alt="create a quiz" loading="lazy" />
                    </div>
                </div>

                <div className="row helpmesssage">
                    <div className="col-xs-12 col-md-12 col-lg-6 imgcontainer">
                        <img src="/assets/help/help-typform3.png" alt="create a quiz" loading="lazy" />
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-6 message__column" style={{ paddingLeft: "2rem" }}>
                        <div >
                            <h1> Can I customize DuoNut forms to fit my brand? </h1>
                            <p>
                                We know how important it is to create content that matches your style or brand. That’s why our forms are fully customizable, even for free users. Change background colors, button styles, text fonts, and formatting, upload your logo, embed videos, images, or almost any other online content.
                            </p>

                            {/* <a href="/design" style={{ cursor: "pointer" }}>View Partner Job Boards <span style={{ fontSize: "20px", paddingLeft: "5px" }}>&#8594;</span></a> */}
                        </div>
                    </div>
                </div>

                <div>
                    <h1 style={{ fontSize: "2.5rem" }}>Questions? Look here.</h1>
                    <div style={{ textAlign: "left", maxWidth: width > 800 ? "80%" : "100%", margin: "auto", padding: "2rem 0rem" }}>
                        <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(1)} >{selected === 1 ? `- ` : `+ `} What is Typeform used for?</h2>
                        {selected === 1 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                            Typeform is built primarily for gathering conducting market research, and generating leads. You can also use Typeform to fully customize user flows matching to your business needs, but that requires a paid plan.
                        </div>}
                        <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(2)} >{selected === 2 ? `- ` : `+ `} Is Typeform completely free?</h2>
                        {selected === 2 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                            You can sign up with Typeform for free. The free plan lets you build unlimited forms, with up to ten questions per form and ten submission responses monthly. Limited customization and templates are available, but you can’t add a logo without upgrading. Free users also can’t add more questions or get more responses.
                        </div>}
                        <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(3)} >{selected === 3 ? `- ` : `+ `} Are Typeform forms fully customizable?</h2>
                        {selected === 3 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                            Typeform’s free account includes access to templates. However, premium themes, brand kits, and adding a logo need a paid plan. For customization, you can add one image per question and choose from six desktop and four mobile layout options.
                        </div>}
                        <h2 style={{ cursor: "pointer", fontWeight: "600", padding: "1rem 0rem" }} onClick={() => onClickTab(4)} >{selected === 4 ? `- ` : `+ `} What are the Pros and cons of using Typeform?</h2>
                        {selected === 4 && <div style={{ lineHeight: "30px", paddingLeft: "1rem", opacity: "0.8" }}>
                            Typeform offers an intuitive, user-friendly form builder with aesthetically pleasing templates. The free plan works for a limited number of short, basic forms. However, if you need more responses, branding options, and responsive support, Typeform requires paid upgrades.
                        </div>}
                    </div>
                </div>

                <div style={{ border: "1px solid gray", padding: "1rem", margin: "2rem auto", backgroundColor: "#f5f5f5", maxWidth: width > 800 ? "80%" : "100%", lineHeight: "30px", textAlign: "center" }}>
                    Typeform offers dynamic form building, while DuoNut provides unlimited, fully customizable forms with <br /> advanced features like conditional logic and workflows — all for free.
                    <button className='btn-secondary' style={{ display: "block", padding: "1rem", margin: "2rem auto" }} onClick={(e) => window.open("/design")}>Create your first form for free </button>
                </div>

                <div style={{ padding: "1rem", margin: "2rem auto", backgroundColor: "#f5f5f5", maxWidth: width > 800 ? "80%" : "100%", lineHeight: "30px", textAlign: "left" }}>
                    The names and trademarks appearing on this page are the property of their respective owners. The information provided on this page is published by the respective owner and is subject to change without prior notice. The information provided is for general informational purposes only and should not be construed as approved or endorsed by the competitor. DuoNut disclaims any liability for potential errors, omissions, or consequential losses resulting from reliance on the information provided on this page.
                </div>

            </div>
            <HomePageFooter />
        </div>
    );

}

export default Typeform;