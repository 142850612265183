import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: {
    viewer_sliding: false,
    show_viewer_vertical: false,
    show_leaderboard: false,
    show_startpage: false,
    show_like_button: false,
    show_share_button: false,
    show_recommendation: false,
    show_progressbar: false,
    show_custom_button: false,
    custom_button_message: "",
    custom_button_url: "",
    show_endpage: true,
    leaderboard_title: "Thank you",
    leaderboard_description: "",
    leaderboard_fontcolor: "#000000",
    leaderboard_bgcolor: "#FFFFFF",
    coupon_code: "",
    startpage_text: "",
    startpage_text2: "",
    referral: false,
    show_location: false,
    show_feedback: false,
    show_report: true,
    startpage_title: "",
    startpage_description: "",
    startpage_fontcolor: "#000000",
    startpage_bgcolor: "#FFFFFF",
    base_color: "#f7f6f6",
    keywords: [],
    show_confetti: false,
    play_audio: false,
    redirect_url: "",
    is_duonut_close_date: false,
    duonut_close_date: "",
    is_duonut_close_limit: false,
    duonut_close_limit: 0,
    show_animation: false,
    base_bgimage: "",
    slide_logo: "",
    slide_logo_alignment: "top left",
    leaderboard_logo: "",
    startpage_logo: "",
    audio: "",
    coverPhoto: "",
    coupon_code_file: {},
  },
  title: "",
  description: "",
  isPublic: true,
  isPrivate: false,
  password: "",
  published: false,
  slack_integration: {},
  hubspot_integration: {},
  gmail_integration: {},
  scheduled_workflow: {},
  google_sheet_integration: {},
  lock: false,

  display_id: "",
  duonutToEdit: {},
  selectedTab: 0,
  viewType: 2,
  collapsSidePannel: false,
  collapsPagination: true,
};

// activity slice which the user is designing
const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateViewerSliding: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          viewer_sliding: !state.settings.viewer_sliding,
        },
      };
    },
    updateShowViewerVertical: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_viewer_vertical: !state.settings.show_viewer_vertical,
        },
      };
    },
    updateTitle: (state, { payload }) => {
      state.title = payload;
    },
    updateDescription: (state, { payload }) => {
      state.description = payload;
    },
    updatePassword: (state, { payload }) => {
      state.password = payload;
    },
    updateIsPublic: (state, { payload }) => {
      state.isPublic = payload;
    },
    updateIsPrivate: (state, { payload }) => {
      state.isPrivate = payload;
    },
    updatePublished: (state, { payload }) => {
      state.published = payload;
    },
    updateLock: (state, { payload }) => {
      state.lock = payload;
    },
    updateShowLeaderboard: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_leaderboard: !state.settings.show_leaderboard,
        },
      };
    },
    updateShowStartpage: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_startpage: !state.settings.show_startpage,
        },
      };
    },
    updateShowLikeButton: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_like_button: !state.settings.show_like_button,
        },
      };
    },
    updateShowShareButton: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_share_button: !state.settings.show_share_button,
        },
      };
    },
    updateShowRecommendation: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_recommendation: !state.settings.show_recommendation,
        },
      };
    },
    updateShowProgressbar: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_progressbar: !state.settings.show_progressbar,
        },
      };
    },
    updateShowCustomButton: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_custom_button: !state.settings.show_custom_button,
        },
      };
    },
    updateCustomButtoMessage: (state, { payload }) => {
      state.settings.custom_button_message = payload;
    },
    updateCustomButtonUrl: (state, { payload }) => {
      state.settings.custom_button_url = payload;
    },
    updateShowEndpage: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_endpage: !state.settings.show_endpage,
        },
      };
    },
    updateLeaderboardTitle: (state, { payload }) => {
      state.settings.leaderboard_title = payload;
    },
    updateLeaderboardDescription: (state, { payload }) => {
      state.settings.leaderboard_description = payload;
    },
    updateLeaderboardFontcolor: (state, { payload }) => {
      state.settings.leaderboard_fontcolor = payload;
    },
    updateLeaderboardBgcolor: (state, { payload }) => {
      state.settings.leaderboard_bgcolor = payload;
    },
    updateCouponCode: (state, { payload }) => {
      state.settings.coupon_code = payload;
    },
    updateStartpageText: (state, { payload }) => {
      state.settings.startpage_text = payload;
    },
    updateStartpageText2: (state, { payload }) => {
      state.settings.startpage_text2 = payload;
    },
    updateStartpageTitle: (state, { payload }) => {
      state.settings.startpage_title = payload;
    },
    updateStartpageDescription: (state, { payload }) => {
      state.settings.startpage_description = payload;
    },
    updateStartpageFontcolor: (state, { payload }) => {
      state.settings.startpage_fontcolor = payload;
    },
    updateStartpageBgcolor: (state, { payload }) => {
      state.settings.startpage_bgcolor = payload;
    },
    updateBasecolor: (state, { payload }) => {
      state.settings.base_color = payload;
    },
    updateShowLocation: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_location: !state.settings.show_location,
        },
      };
    },
    updateShowFeedback: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_feedback: !state.settings.show_feedback,
        },
      };
    },
    updateShowReport: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_report: !state.settings.show_report,
        },
      };
    },
    updateReferral: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          referral: !state.settings.referral,
        },
      };
    },
    updatePrivacy: (state, { payload }) => {
      state.isPublic = !state.isPublic;
      state.isPrivate = !state.isPrivate;
    },
    updateSettings: (state, { payload }) => {
      state.settings = payload.settings;
    },
    updateDuonutToEdit: (state, { payload }) => {
      state.duonutToEdit = payload.duonutToEdit;
    },
    updateCoverPhoto: (state, { payload }) => {
      state.settings.coverPhoto = payload.coverPhoto;
    },
    updateAudio: (state, { payload }) => {
      state.settings.audio = payload.audio;
    },
    updateSlideLogo: (state, { payload }) => {
      state.settings.slide_logo = payload.slide_logo;
    },
    updateSlideLogoAlignment: (state, { payload }) => {
      state.settings.slide_logo_alignment = payload;
    },
    updateBaseBgImage: (state, { payload }) => {
      state.settings.base_bgimage = payload.base_bgimage;
    },
    updateLeaderboardLogo: (state, { payload }) => {
      state.settings.leaderboard_logo = payload.leaderboard_logo;
    },
    updateStartpageLogo: (state, { payload }) => {
      state.settings.startpage_logo = payload.startpage_logo;
    },
    updateCouponCodeFile: (state, { payload }) => {
      state.settings.coupon_code_file = payload.coupon_code_file;
    },
    updateSelectedTab: (state, { payload }) => {
      state.selectedTab = payload;
    },
    updateViewType: (state, { payload }) => {
      state.viewType = payload;
    },
    updateCollapsSidePannel: (state, { payload }) => {
      state.collapsSidePannel = payload;
    },
    updateCollapsPagination: (state, { payload }) => {
      state.collapsPagination = !state.collapsPagination;
    },
    updateKeywords: (state, { payload }) => {
      state.settings.keywords = payload.split(",");
    },
    updateSlackIntegration: (state, { payload }) => {
      state.slack_integration = payload;
    },
    updateHubspotIntegration: (state, { payload }) => {
      state.hubspot_integration = payload;
    },
    updateGmailIntegration: (state, { payload }) => {
      state.gmail_integration = payload;
    },
    updateScheduledWorkflow: (state, { payload }) => {
      state.scheduled_workflow = payload;
    },
    updateGoogleSheetIntegration: (state, { payload }) => {
      state.google_sheet_integration = payload;
    },
    updateShowConfetti: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_confetti: !state.settings.show_confetti,
        },
      };
    },
    updatePlayAudio: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          play_audio: !state.settings.play_audio,
        },
      };
    },
    updateRedirectUrl: (state, { payload }) => {
      state.settings.redirect_url = payload;
    },
    updateIsDuonutCloseDate: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          is_duonut_close_date: !state.settings.is_duonut_close_date,
        },
      };
    },
    updateDuonutCloseDate: (state, { payload }) => {
      state.settings.duonut_close_date = payload;
    },
    updateIsDuonutCloseLimit: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          is_duonut_close_limit: !state.settings.is_duonut_close_limit,
        },
      };
    },
    updateDuonutCloseLimit: (state, { payload }) => {
      state.settings.duonut_close_limit = payload;
    },
    updateShowAnimation: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          show_animation: !state.settings.show_animation,
        },
      };
    },
    updateDisplayId: (state, { payload }) => {
      state.display_id = payload;
    },


  },
});

export const { updateTitle, updateDescription, updatePassword, updatePublished, updateIsPublic, updateIsPrivate,
  updatePrivacy, updateSettings, updateDuonutToEdit, updateCoverPhoto, updateViewType,
  updateShowStartpage, updateShowLeaderboard, updateShowReport, updateKeywords, updateSlackIntegration,
  updateShowLikeButton, updateShowRecommendation, updateShowProgressbar, updateCollapsSidePannel, updateCollapsPagination,
  updateShowCustomButton, updateCustomButtoMessage, updateCustomButtonUrl, updateShowEndpage,
  updateLeaderboardTitle, updateLeaderboardDescription, updateLeaderboardFontcolor,
  updateLeaderboardBgcolor, updateLeaderboardLogo, updateShowShareButton, updateStartpageText, updateStartpageText2, updateReferral, updateShowLocation,
  updateAudio, updateCouponCode, updateCouponCodeFile, updateShowFeedback, updateShowViewerVertical,
  updateStartpageTitle, updateStartpageDescription, updateStartpageFontcolor, updateBasecolor,
  updateStartpageBgcolor, updateStartpageLogo, updateSlideLogo, updateBaseBgImage, updateSelectedTab,
  updateShowConfetti, updatePlayAudio, updateHubspotIntegration, updateRedirectUrl, updateShowAnimation,
  updateIsDuonutCloseDate, updateIsDuonutCloseLimit, updateDuonutCloseDate, updateDuonutCloseLimit,
  updateViewerSliding, updateGmailIntegration, updateSlideLogoAlignment, updateScheduledWorkflow,
  updateDisplayId, updateGoogleSheetIntegration, updateLock } = settings.actions;
export default settings.reducer;
