import React, { useState } from 'react';
import './EffectiveOnboardingProgram.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";

const EffectiveOnboardingProgram = () => {
    const { width, height } = useWindowDimensions();
    const [selected, setSelected] = useState(0);
    const navigate = useNavigate();
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            playsinline: 1
        }
    };

    return (
        <div className='BTD1App'>
            <div className='BTD1template'>
                <h1 style={{ fontSize: "2.5rem", padding: "2rem 0rem" }}>
                    How to Create an Effective Onboarding Program[SOP]
                </h1>
                <div>
                    Effective onboarding can have a serious impact on employee productivity. It definitely takes more than going through the motions. Here's how to effectively onboard new hires and complete new-hire training.
                </div>
                <br></br>
                <div className='BTD1section-1'>
                    <img className='BTD1section-1-image' src="/assets/blogs/eop1.jpg" alt="" />
                </div>
                <br></br>
                <div>Congratulations — you're bringing a new employee onboard!</div>
                <br></br>
                <div>You have already set up their email address and logins. You are ready with a pile of paperwork they’ll need to wade through. You are ready to showcase your company’s mission, vision, and values.</div>
                <br></br>
                <div>Once you make it through those administration-heavy first few days and check the boxes on your standard onboarding process, that employee should be ready to hit the ground running, right?
                </div>
                <br></br>
                <div>Not quite. Effective employee onboarding takes more than this - unfortunately. Research has shown that only 12 percent of employees think their company does a great job of onboarding new employees.</div>
                <br></br>
                <div>That lukewarm experience not only leaves a sour taste for your newest team members, but it can also mean bad news for your retention, especially when 20 percent of turnover reportedly happens within the first 45 days of employment.</div>
                <br></br>
                <div>Taking the time to create an effective onboarding process — one that goes beyond formal HR policies and procedures and stuffy employee training—can set the right tone for your new joiners and help them feel like an integrated part of your organization. <a href='https://duonut.com' target="_blank" >Duonut</a> provides hundreds of templates for all HR and L&D departments.</div>
                <br></br>
                <br></br>
                <h2 id='BlogTD1'>Sample interactive template:</h2>
                <br></br>
                <div>
                    <iframe id="iframeid" style={{ width: "100%", height: "80vh" }} src="https://duonut.com/duonut/6175257377" title="Duonut Iframe"></iframe>
                </div>
                <br></br>
                <br></br>

                <h1>5 tips to make the most of employee onboarding</h1>
                <br></br>
                <br></br>

                <div>
                    It’s easy to think about onboarding as nothing more than processes and paperwork. But, when you think about it, effective onboarding is actually about something different: it's always about <b>people</b>.</div>
                <br></br>
                <div>
                    Your onboarding process will be successful if you give them the information, resources, and confidence they need to thrive in their roles.
                </div>
                <br></br>
                <div>
                    But how do you do that? Here are five tips to create an employee onboarding process that’s way more than standard checklists and employee manuals.
                </div>
                <br></br>

                <h2 id='BlogTD1'>1. Personalized Welcome: </h2>
                <br></br>
                <div className='BTD1section-1'>
                    <img className='BTD1section-1-image' src="/assets/blogs/eop2.jpg" alt="" />
                </div>
                <br></br>
                <div>
                    Tailor the onboarding experience to each new employee to involve sending a personalized welcome email before their start date, assigning them a mentor or buddy within the organization, and providing them with a detailed itinerary for their first week.
                </div>
                <br></br>

                <div>
                    Personalization shows that you value them as an individual and helps them feel more connected to the company from day one. This can also include introducing the team members to the new joiners, making them feel comfortable, arranging a buddy for a first-day tour inside the office, showing them the cafeteria, restrooms, etc.
                </div>
                <br></br>
                <br></br>


                <h2 id='BlogTD2'>2. Clear Expectations and Goals:</h2>
                <br></br>
                <div>
                    Providing clarity on the role, responsibilities, and expectations right from the start is necessary for your new hires for success. Set clear goals and objectives for the new employee, both short-term and long-term. This helps them understand what success looks like in their role and gives them a roadmap to follow. Regular check-ins to review progress against these goals can also be beneficial.
                </div>
                <br></br>
                <br></br>

                <h2 id='BlogTD3'>3. Comprehensive Training: </h2>
                <br></br>
                <div className='BTD1section-1'>
                    <img className='BTD1section-1-image' src="/assets/blogs/eop3.jpg" alt="" />
                </div>
                <br></br>
                <div>
                    Offer thorough training on company policies, procedures, and tools/software relevant to their role. This could include both formal training sessions and hands-on learning opportunities. Make sure to cover not only the technical aspects of their job but also the cultural aspects of the organization, such as values, mission, and company history. Having a checklist like <a href='https://duonut.com/duonut/6175257377' target="_blank" >this</a> will help the HR team in conducting the training easily.
                </div>
                <br></br>
                <br></br>

                <h2 id='BlogTD2'>4. Engagement and Integration:</h2>
                <br></br>
                <div>
                    Foster a sense of belonging and connection to the team and company culture. Encourage interactions with coworkers, whether through team-building activities, social events, or collaborative projects. Introduce them to key stakeholders and team members across different departments to help them understand how their role fits into the bigger picture. The <a href='https://duonut.com/templates/education_&_learning' target="_blank" >templates</a> section can referred to for effective, interactive employee engagement.
                </div>
                <br></br>
                <br></br>

                <h2 id='BlogTD2'>5. Feedback and Support: </h2>
                <br></br>
                <div>
                    Establish an open feedback loop where new employees feel comfortable asking questions, seeking clarification, and providing input. Encourage regular check-ins with their manager to discuss progress, address any concerns, and provide constructive feedback. Additionally, it is good to offer ongoing support and resources to help them succeed in their role, such as access to training materials, mentoring programs, or professional development opportunities.
                </div>
                <br></br>
                <br></br>
                <div>
                    Of course, there will always be logistical and administrative tasks that need to be taken care of during employee onboarding— everything from tech logins to tax forms.
                </div>
                <br></br>
                <div>
                    But, if you focus too much on the paperwork and the procedures, you lose focus on the piece of onboarding that matters most: <b> the people</b>. More specifically, your brand new employee.
                </div>
                <br></br>
                <div>
                    The onboarding period is a time when you not only equip them with all of the information they need to thrive but also get them excited about contributing to the success of their team and your organization as a whole.
                </div>
                <br></br>
                <div>
                    <b>The right process documentation can take your employee onboarding to the next level. Get started with this <a href='https://duonut.com/duonut/6175257377' target="_blank" >templates</a> today
                    </b> </div>
                <br></br>
                <br></br>
                <br></br>
                <div>
                    #sop #enterprise #nocode #builder #workflow #customflow
                </div>
                <br></br>
                <br></br>
                <br></br>

                <h2>Related Blogs</h2>
                <br></br>
                <div className='BLG1blogs'>
                    <div className='BLG1blog' onClick={() => navigate('/blog/feedback-forms')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/1.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Feedback Forms: The Right Way to Ask the Right Questions!</h2>
                        <div>
                            Asking the right questions is an art, especially when it comes to collecting valuable feedback...
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/cut-patient-onboarding')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bcpo2.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Cut Patient onboarding time by 25 minutes</h2>
                        <div>
                            To make patient paperwork more convenient.
                        </div>
                    </div>

                    <div className='BLG1blog' onClick={() => navigate('/blog/improve-healthcare-workflows')}>
                        <div className='BLG1blogImgContainer'>
                            <img className='BLG1blogimage' src="/assets/blogs/bihw1.png" alt="" />
                        </div>
                        <h2 style={{ padding: "10px 0px" }}>Improve Healthcare Workflows and Save more than $35,000</h2>
                        <div>
                            Healthcare services requires the employers to collect a lot of patient data, which focuses on building better systems...
                        </div>
                    </div>


                </div>
                <br></br>
                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default EffectiveOnboardingProgram;