import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CheckboxImage = ({
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor, optionsImage, imageWidth, imagePadding, fieldPosition
    },
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const { selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const [selectedAnswer, setselectedAnswer] = useState([]);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);

    useEffect(() => {
        setselectedAnswer([]);
    }, []);

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)
            var selectedAnswerCopy = selectedAnswer;
            if (!selectedAnswerCopy.includes(item)) {
                selectedAnswerCopy = [...selectedAnswerCopy, item];
            }
            else {
                const index = selectedAnswerCopy.indexOf(item);
                if (index > -1) {
                    selectedAnswerCopy.splice(index, 1);
                }
            }
            setselectedAnswer(selectedAnswerCopy);

            var ans = "";
            selectedAnswerCopy.map((selectedans, i) => {
                if (ans) {
                    ans = ans + "; " + selectedans;
                }
                else {
                    ans = selectedans;
                }
                return null;
            })
            // console.log("ans", item)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: ans,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "CheckboxImage",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "CheckboxImage",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: ans,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }

    return (
        <div style={{
            display: "flex", flexDirection: "column", gap: gap
        }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>{text}</div>

            <div style={{ display: "flex", justifyContent: textAlign, alignItems: 'center', flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition }}>
                {options.map((item, i) => {
                    return <div style={{
                        display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight,
                    }} key={i} >
                        <input type="checkbox" id={"checkbox_option" + i} name="checkbox_option"
                            style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: `${color}` }}
                            checked={selectedAnswer.includes(item) ? true : false}
                            disabled={(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) ? true : false}
                            onChange={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) && submitResponse(item) }} />
                        <div style={{
                            display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: "column",
                            backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                            color: hover === i ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin,
                            cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%"
                        }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) && submitResponse(item) }}>
                            <img src={optionsImage[i] ? optionsImage[i] : imgPreview} style={{
                                width: imageWidth, height: imageWidth, objectFit: "cover",
                                padding: imagePadding
                            }} alt="" />
                            <div style={{}}>{item}</div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    );
}

export default CheckboxImage;
