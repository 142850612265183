import React from 'react'

export default function ImageList({ data }) {

   return (
      <div className="images-div">
         {data.map((item) => {
            let imgData = item.uri;
            return (
               <div className="image-div">
                  <img src={imgData} />
               </div>
            )
         })}

      </div>

   )
}
