import React from 'react'
import './input.css'

export default function Input({ placeholder, value, setValue, errorMsg, isOtpCode }) {

    const handleChange = (e) => {
        if (!isOtpCode) {
            if (placeholder === "email") {
                setValue((e.target.value).toLowerCase())
            } else {
                setValue(e.target.value)
            }
        } else {
            e.target.value <= 999999 && setValue(e.target.value)
        }
    }

    return (
        <div className='form_modal__input_container'
            id={errorMsg !== '' ? 'error' : ''} >
            <input placeholder={placeholder}
                value={value}
                onChange={e => handleChange(e)}
            />
            {
                errorMsg &&
                <p className='error_message'>
                    <img src='/assets/icons/error-icon.svg' />
                    {errorMsg}
                </p>
            }
        </div>
    )
}
