import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';

const CheckboxGroup = ({
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, other, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor,
    },
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const [selectedAnswer, setselectedAnswer] = useState([]);
    const [otherText, setOtherText] = useState('');
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [hover, setHover] = useState(-1);

    useEffect(() => {
        setselectedAnswer([]);
    }, []);

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)
            var selectedAnswerCopy = selectedAnswer;
            if (!selectedAnswerCopy.includes(item)) {
                selectedAnswerCopy = [...selectedAnswerCopy, item];
            }
            else {
                const index = selectedAnswerCopy.indexOf(item);
                if (index > -1) {
                    selectedAnswerCopy.splice(index, 1);
                }
            }
            setselectedAnswer(selectedAnswerCopy);

            var ans = "";
            selectedAnswerCopy.map((selectedans, i) => {
                if (ans) {
                    ans = ans + "; " + selectedans;
                }
                else {
                    ans = selectedans;
                }
                return null;
            })
            // console.log("ans", item)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: ans,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "CheckboxGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "CheckboxGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: ans,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const submitTextResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)
            var ans = "";
            selectedAnswer.map((selectedans, i) => {
                if (ans) {
                    if (selectedans === "other") {
                        ans = ans + "; " + selectedans + " - " + otherText;
                    } else {
                        ans = ans + "; " + selectedans;
                    }
                }
                else {
                    if (selectedans === "other") {
                        ans = selectedans + " - " + otherText;
                    } else {
                        ans = selectedans;
                    }
                }
                return null;
            })
            // console.log("ans", item)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: ans,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "CheckboxGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "CheckboxGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: ans,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const handleOtherInputChange = (e) => {
        if (location.pathname.includes("/duonut/")) {
            setOtherText(e.target.value);
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }

    return (
        <div style={{
            display: "flex", flexDirection: "column", gap: gap
        }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing
            }}>{text}</div>
            <div>
                {options.map((item, i) => {
                    return <div style={{
                        display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight
                    }} key={i} >
                        <input type="checkbox" id={"checkbox_option" + i} name="checkbox_option"
                            style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: `${color}` }}
                            checked={selectedAnswer.includes(item) ? true : false}
                            disabled={(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) ? true : false}
                            onChange={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) && submitResponse(item) }} />
                        <div style={{
                            backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                            color: hover === i ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin,
                            cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%"
                        }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) && submitResponse(item) }}>
                            <div style={{}}>{item}</div>
                        </div>
                    </div>
                })}
                {other && <div style={{
                    display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight
                }}>
                    <input type="checkbox" id={"checkbox_option_other"} name="checkbox_option"
                        style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px", accentColor: `${color}` }}
                        checked={selectedAnswer.includes("other") ? true : false}
                        disabled={(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes("other")) ? true : false}
                        onChange={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes("other")) && submitResponse("other") }} />
                    <div style={{
                        backgroundColor: hover === options.length ? elementHoverAttr?.backgroundColor : backgroundColor,
                        border: hover === options.length ? elementHoverAttr?.border : border ? border : "0",
                        color: hover === options.length ? elementHoverAttr?.color : color,
                        borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                        textTransform, textAlign, letterSpacing, padding, margin,
                        cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                        width: "100%", height: "100%"
                    }}
                        onMouseEnter={() => setHover(options.length)}
                        onMouseLeave={() => setHover(-1)}
                        onClick={() => { !(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes("other")) && submitResponse("other") }}>
                        <div style={{}}>Other</div>
                    </div>
                    {(location.pathname.includes("/duonut/") && selectedAnswer.includes("other")) && <input type='text' style={{
                        minWidth: "2rem", marginLeft: "6px", padding: "10px", width: "100%",
                        color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing,
                        backgroundColor, borderBottom: "1px solid gray", borderRadius,
                    }}
                        onChange={(e) => handleOtherInputChange(e)}
                        onBlur={() => {
                            submitTextResponse("other");
                        }}
                        readOnly={(location.pathname.includes("/duonut/") && selectedAnswer.includes("other")) ? false : true}
                    />}
                </div>}
            </div>
        </div>
    );
}

export default CheckboxGroup;
