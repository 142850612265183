import Loader from '../../assets/Images/loader.gif';

const MiniLoader = (props) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }} >
            <div>Powered by </div>
            <h2 style={{ marginBottom: "1rem" }}>Duonut </h2>
            <img alt="loader" src={Loader} height={props.height ? props.height : 100} width={props.width ? props.width : 100} />
        </div>
    )
}

export default MiniLoader;