import React, { useEffect } from 'react'
import './Payment.css';
export default function PaymentSuccess() {

    useEffect(() => {
        setTimeout(() => {
            window.location = "/";
            // navigate('/');
            //     window.location.reload();
        }, 3000); //miliseconds
    }, []);

    return (
        <div className='payment_container'>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "91.5vh" }}>
                <img alt="loader" src="/assets/psuccess.gif" height={350} width={400} />
            </div>
        </div>
    )
}
