import React from 'react'

export default function ImageSlider({ duonutthumbnail_set, currentIndex, previousIndex }) {
    // console.log(duonutthumbnail_set)
    return (
        <>
            {duonutthumbnail_set.map((set, idx) => {
                const thumbnailUrl = set?.uri ? set?.uri : set;
                return <> <img src={thumbnailUrl} key={`${idx}`}
                    alt="duonut" loading="lazy"
                    className={currentIndex === idx ? 'active' :
                        idx === previousIndex ? 'previous' : 'in-active'}
                />
                    <div style={{ position: "absolute", right: "5px", bottom: "5px", fontSize: "10px", backgroundColor: "#1E1E1E", color: "white", padding: "2px 4px", borderRadius: "4px" }}>{currentIndex + 1} of {duonutthumbnail_set.length}</div>
                </>
            })}
        </>
    )
}
