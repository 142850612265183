import React, { useState, useRef } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { toggleFlowChartModal, updateFlowChartId } from "../../redux/slices/FlowChartSettingReducer";
import { useSelector, useDispatch } from 'react-redux'

import './FlowChart.css';

export default function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) {
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const dispatch = useDispatch();
    // console.log("id", id);

    const onEdgeClick = (evt, id) => {
        evt.stopPropagation();
        dispatch(toggleFlowChartModal(true));
        dispatch(updateFlowChartId(id));
        // alert(`remove ${id}`);
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                        E
                    </button>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}
