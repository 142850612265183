import React, { useState } from 'react'

export default function PasswordInput({ placeholder, value, setValue, errorMsg }) {

    const [passwordVisible, setPasswordVisible] = useState(false)

    return (
        <div className='form_modal__input_container password_input_container'
            id={errorMsg !== '' ? 'error' : ''} >

            <div style={{position: 'relative' }}>
                <input placeholder={placeholder}
                    value={value}
                    type={passwordVisible ? 'text' : 'password'}
                    onChange={(e) => setValue(e.target.value)}
                />
                {!passwordVisible ?
                    <img src='/assets/icons/showPassword.svg' className='eye-icon' onClick={() => setPasswordVisible(!passwordVisible)} />
                    :
                    <img src='/assets/icons/eye-icon-hidden.svg' className='eye-icon' onClick={() => setPasswordVisible(!passwordVisible)} />

                }
            </div>

            {
                errorMsg &&
                <p className='error_message'>
                    <img src='/assets/icons/error-icon.svg' />
                    {errorMsg}
                </p>
            }
        </div>
    )
}
