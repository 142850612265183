import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { EditText, EditTextarea } from "react-edit-text";

export default function Placeholder() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [text, settext] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const text = tempElement?.elementAttributes?.placeholder;

            if (text) {
                settext(text);
            } else {
                settext(null);
            }
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleTextChange = (e) => {
        console.log(e)
        const value = e.target.value;
        settext(value);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    placeholder: value,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className="EditorRowContainer">
            <div style={{ marginRight: "1rem" }}>Placeholder</div>
            <EditText
                id="placeholder-input-field"
                value={text}
                style={{ fontSize: "14px", backgroundColor: "white", border: "1px solid #11111333", borderRadius: "5px", display: "flex", justifyContent: "flex-start", alignItems: "center", width: "13rem", height: "28px", paddingLeft: "5px" }}
                onEditMode={() => { setTimeout(() => document.querySelector(`#placeholder-input-field`).select(), 100); }}
                onChange={(e) => (handleTextChange(e))}
                placeholder="Write your placeholder."
            />
        </div>
    )
}

