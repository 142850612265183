import React, { useEffect, useState } from "react";
import './DuonutReports.css';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
import { groupBy, meanBy, uniq } from 'lodash';

// Extend dayjs with necessary plugins
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

const LineChart3 = ({ transformedData, columns1, selectedColumn }) => {
    const processData = (data) => {
        // Initialize an object to store shift data by date
        const chartData = {};

        // Process the data
        data.forEach(item => {
            const date = item.Date;
            const shift = item.Shift;
            const actualProduction = parseInt(item[selectedColumn]) || 0;

            // Initialize date entry if it doesn't exist
            if (!chartData[date]) {
                chartData[date] = { First: [], Second: [] };
            }

            // Push the actual production value to the appropriate shift array
            if (shift === 'First') {
                chartData[date].First.push(actualProduction);
            } else if (shift === 'Second') {
                chartData[date].Second.push(actualProduction);
            }
        });

        // Calculate the average for each shift on each date
        const dates = Object.keys(chartData);
        const firstShiftSeries = dates.map(date => {
            const firstShiftData = chartData[date].First;
            const total = firstShiftData.reduce((sum, val) => sum + val, 0);
            return firstShiftData.length ? total / firstShiftData.length : 0;
        });

        const secondShiftSeries = dates.map(date => {
            const secondShiftData = chartData[date].Second;
            const total = secondShiftData.reduce((sum, val) => sum + val, 0);
            return secondShiftData.length ? total / secondShiftData.length : 0;
        });

        return {
            dates,
            series: [
                {
                    name: 'First Shift',
                    data: firstShiftSeries
                },
                {
                    name: 'Second Shift',
                    data: secondShiftSeries
                }
            ]
        };
    };

    const { dates, series } = processData(transformedData);

    const options = {
        xaxis: {
            categories: dates,
            labels: {
                format: 'dd/MM/yyyy'
            }
        },
        yaxis: {
            title: {
                text: selectedColumn
            }
        },
        title: {
            text: 'First Shift and Second Shift',
            align: 'left'
        },
        colors: ['#008FFB', '#FF4560']
    };


    return (
        <div className="DR1linechart">
            <h2 style={{ paddingBottom: "1rem" }}>{`${selectedColumn} Shift wise Chart`}</h2>
            <br></br>

            <Chart options={options} series={series} type="line" width={800} height={400} />

        </div>
    );
}

export default LineChart3;