import React from 'react';
import './Contact.css';
// import background from '../../assets/backgroundImg/mcqWithTimer.svg';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';

const Contact = (props) => {

    return (
        <div className='CU1App'>
            <div className='CU1template'>
                <div className="CU1container">
                    <h1 style={{ paddingBottom: "1rem" }}>CONTACT US</h1>
                    <div className='CU1text'>
                        <div className='CU1textHeading'>Address:</div>
                        <div>Duonut Inc</div>
                        <div>1111B S Governors Ave STE 6614</div>
                        <div>Dover, DE 19904</div>
                    </div>
                    <div className='CU1text'>Phone: (615) 823-6064</div>
                    <div className='CU1text'>
                        Email: hello@duonut.com
                    </div>

                </div>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default Contact;