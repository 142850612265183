import React, { useState, useEffect } from "react";
import './DuonutPageMapping.css';
import { showDuonutPageMapping, getDuonutPageMappingAsync } from '../../redux/slices/DuonutPageMappingReducer';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { getDuonut } from "../../services/duonut";
import { backendUrl, frontendUrl } from "../../services/constants";
import { useNavigate } from 'react-router-dom';
import MiniLoader from '../../Components/Loader/MiniLoader';


const DuonutPageMapping = () => {
    const paramsid = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [duonutId, setduonutId] = useState(0);
    const [displayId, setdisplayId] = useState("");
    const [appId, setappId] = useState("");

    // console.log(paramsid);
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const app_Id = searchParams.get('app_id');
        setappId(app_Id);
        // console.log(appId); // output: AEC54321
    }, []);

    useEffect(() => {
        if (appId) {
            dispatch(getDuonutPageMappingAsync(paramsid.id, appId));
            // console.log("inside");
        }
    }, [appId]);

    const DuonutPageMappingData = useSelector(showDuonutPageMapping);
    const allDuonutPageMappingData = DuonutPageMappingData[0];
    // console.log(allDuonutPageMappingData);

    useEffect(() => {
        if (allDuonutPageMappingData) {
            var data = allDuonutPageMappingData.duonut_mapping_data
            // console.log(data[0].duonut);
            setduonutId(data[0].duonut);
        }
    }, [allDuonutPageMappingData])

    const fetchDuonut = () => {
        getDuonut(duonutId, async (err, res) => {
            if (err) {
                console.log(err);
                return
            }

            // console.log(res.data)
            setdisplayId(res.data.display_id)
            navigate(`/duonut/${res.data.display_id}`);
        });
    };

    useEffect(() => {
        if (duonutId) return fetchDuonut()
    }, [duonutId])

    return (
        <div className="">
            {
                !displayId && <MiniLoader />
            }
        </div>
    );
}

export default DuonutPageMapping;