import React, { useState, useEffect, useRef } from 'react';
import './AiDuonutPage.css';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAiDuonutAsync, showAiDuonutData, saveAiAnsAsync, showAiDuonutresponse, getAiAnsAsync } from '../../redux/slices/AiDuonutReducer';
import axios from "axios";

const AiDuonutPage = () => {
    const paramsid = useParams();
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(-1);
    const [isLoading, setisLoading] = useState(false);
    const [clickedNum, setClickedNum] = useState(-1);
    const [aiConversation, setaiConversation] = useState([]);
    const [userQuestion, setuserQuestion] = useState("");
    const [pageAcess, setpageAcess] = useState(true);
    const get_watch_id = localStorage.getItem(paramsid.id);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const rating = queryParams.get('rating');
    // console.log(rating)
    const inputRef = useRef(null);

    useEffect(() => {
        if (paramsid && paramsid.id && paramsid.id !== "0" && paramsid.id !== "undefined") {
            dispatch(getAiDuonutAsync(paramsid)).then((response) => {
                console.log(response);
                console.log(response?.question);

                var userIsPro = false;
                if (response?.created_by.pro_user) {
                    userIsPro = true;
                } else if (response?.created_by.expire_date) {
                    const currentDate = new Date();
                    const dateToCompare = new Date(response?.created_by.expire_date);
                    const comparisonResult = currentDate < dateToCompare;
                    if (comparisonResult) {
                        userIsPro = true;
                    } else {
                        userIsPro = false;
                    }
                }


                if (userIsPro && response?.created_by.user_type === "pro" && response.totalInsights >= 25) {
                    setpageAcess(false);
                    return null;
                } else if (userIsPro && response?.created_by.user_type === "plus" && response.totalInsights >= 50) {
                    setpageAcess(false);
                    return null;
                } else if (!userIsPro && response.totalInsights >= 10) {
                    setpageAcess(false);
                    return null;
                }

                if (get_watch_id) {
                    const formData = new FormData();
                    formData.append('watch_id', get_watch_id);
                    dispatch(getAiAnsAsync(formData)).then((response1) => {
                        console.log(response1);
                        setClickedNum(parseInt(response1.answer));
                        setaiConversation(response1.ai_conversation);
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    if (rating > 0 && rating <= 5) {
                        setClickedNum(parseInt(rating));
                        var payload = {
                            messages: [{
                                "role": "user", "content": `
                               I want to collect feedback from my customer for an NPS survey with a 1-5 rating scale. After receiving the feedback, I aim to engage in a genuine, human-like conversation to better understand their feelings about the product, tailored to the rating they give. If I receive a rating of ${rating} on an NPS survey (scale 1-5) for the question ${response?.question}, how would you respond in a warm, empathetic, and human-like manner in 1-2 sentences, along with asking a related question at the end of conversation?`
                            }],
                            max_tokens: 200,
                            model: "gpt-3.5-turbo",
                        }

                        axios({
                            method: "POST",
                            url: "https://api.openai.com/v1/chat/completions",
                            data: payload,
                            headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                    "Bearer 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"
                            }
                        }).then((res) => {
                            console.log(res);
                            if (res?.status === 200) {
                                console.log(res.data.choices[0]?.message.content);

                                setaiConversation([
                                    {
                                        "sender": "bot",
                                        "message": `${res.data.choices[0]?.message.content}`
                                    }
                                ])

                                var save_ans = {
                                    display_id: paramsid.id,
                                    watch_id: "",
                                    answer: rating,
                                    name: "",
                                    ai_conversation: [
                                        {
                                            "sender": "bot",
                                            "message": `${res.data.choices[0]?.message.content}`
                                        }
                                    ],
                                }
                                console.log(save_ans);

                                dispatch(saveAiAnsAsync(save_ans)).then((res) => {
                                    console.log(res);
                                    localStorage.setItem(paramsid.id, res?.watch_id);
                                }).catch((e) => {
                                    console.log(e.message, e);
                                });
                            }
                        }).catch((e) => {
                            console.log(e.message, e);
                        });


                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }

        // eslint - disable - next - line
    }, []);

    const duonutDataMain = useSelector(showAiDuonutData);
    const duonutData = duonutDataMain[0];
    console.log(duonutData)

    const ai_analytics_response = useSelector(showAiDuonutresponse);


    const handleButtonClick = (num) => {
        // console.log(duonutData)
        if (clickedNum !== -1) {
            return null;
        }

        setClickedNum(num);

        var payload = {
            messages: [{
                "role": "user", "content": `
                I want to collect feedback from my customer for an NPS survey with a 1-5 rating scale. After receiving the feedback, I aim to engage in a genuine, human-like conversation to better understand their feelings about the product, tailored to the rating they give. If I receive a rating of ${num} on an NPS survey (scale 1-5) for the question ${duonutData?.question}, how would you respond in a warm, empathetic, and human-like manner in 1-2 sentences, along with asking a related question at the end of conversation?`
            }],
            max_tokens: 200,
            model: "gpt-3.5-turbo",
        }

        axios({
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            data: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer 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"
            }
        }).then((res) => {
            console.log(res);
            if (res?.status === 200) {
                console.log(res.data.choices[0]?.message.content);

                setaiConversation([
                    {
                        "sender": "bot",
                        "message": `${res.data.choices[0]?.message.content}`
                    }
                ])

                var save_ans = {
                    display_id: paramsid.id,
                    watch_id: "",
                    answer: num,
                    name: "",
                    ai_conversation: [
                        {
                            "sender": "bot",
                            "message": `${res.data.choices[0]?.message.content}`
                        }
                    ],
                }
                console.log(save_ans);

                dispatch(saveAiAnsAsync(save_ans)).then((res) => {
                    console.log(res);
                    localStorage.setItem(paramsid.id, res?.watch_id);
                }).catch((e) => {
                    console.log(e.message, e);
                });
            }
        }).catch((e) => {
            console.log(e.message, e);
        });


    }

    const handleUserQuestion = (event) => {
        setuserQuestion(event.target.value);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("questionBtn").click();
        }
    };

    const handleUserQuestionSubmit = () => {
        setisLoading(true);

        axios({
            method: "GET",
            url: "https://s.jina.ai/" + userQuestion.replace(/[^a-zA-Z ]/g, "") + "?site=" + duonutData?.aiData?.websiteUrl,
            headers: {
                "Authorization": "Bearer jina_31eefa5028044206b1be8a3711a25b6cG7jW1JF2sLvL7Vko44A6DRMnt53Y",
                // "X-With-Links-Summary": "true",
                // "X-No-Cache": "true",
            },
        }).then((res) => {
            console.log(res);
            if (res?.status === 200) {
                var payload = {
                    messages: [
                        {
                            role: 'system',
                            // You can change the text below to include any additional instructions to the model
                            content: "You are a helpful assistant that answers the user's question based only on the relevant information you are provided along with the question.",
                        },
                        {
                            role: 'user',
                            content: `## RELEVANT INFORMATION: \n\n ${res.data}\n\n` +
                                `## Previous Conversation: \n\n ${JSON.stringify(aiConversation)}\n\n` +
                                `## ANSWER THE FOLLOWING USER'S QUESTION: \n\n ${userQuestion}`,
                        },
                    ],
                    max_tokens: 2000,
                    model: "gpt-4o",
                }

                axios({
                    method: "POST",
                    url: "https://api.openai.com/v1/chat/completions",
                    data: payload,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer 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"
                    }
                }).then((res) => {
                    console.log(res);
                    if (res?.status === 200) {
                        console.log(res.data.choices[0]?.message.content);
                        var userQue = {
                            "sender": "user",
                            "message": `${userQuestion}`
                        }
                        var botAns = {
                            "sender": "bot",
                            "message": `${res.data.choices[0]?.message.content}`
                        }

                        var save_ans = {
                            display_id: paramsid.id,
                            watch_id: get_watch_id,
                            answer: clickedNum,
                            name: "",
                            ai_conversation: [...aiConversation, userQue, botAns],
                        }
                        console.log(save_ans);

                        dispatch(saveAiAnsAsync(save_ans)).then((res) => {
                            console.log(res);
                            setaiConversation([...aiConversation, userQue, botAns]);
                            setuserQuestion("");
                            setisLoading(false);
                            document.getElementById("user-input_box").value = "";
                            document.getElementById("user-input_box").focus();
                            if (inputRef.current) {
                                inputRef.current.scrollIntoView({ behavior: 'smooth' });
                                inputRef.current.focus();
                            }

                        }).catch((e) => {
                            console.log(e.message, e);
                        });
                    }
                }).catch((e) => {
                    console.log(e.message, e);
                    var payload = {
                        messages: [
                            {
                                role: 'system',
                                // You can change the text below to include any additional instructions to the model
                                content: "You are a helpful assistant that answers the user's question based only on the relevant information you are provided along with the question.",
                            },
                            {
                                role: 'user',
                                content: `## RELEVANT INFORMATION: \n\n ${duonutData?.aiData?.websiteDataText}\n\n` +
                                    `## Previous Conversation: \n\n ${JSON.stringify(aiConversation)}\n\n` +
                                    `## ANSWER THE FOLLOWING USER'S QUESTION: \n\n ${userQuestion}`,
                            },
                        ],
                        max_tokens: 2000,
                        model: "gpt-4o",
                    }

                    axios({
                        method: "POST",
                        url: "https://api.openai.com/v1/chat/completions",
                        data: payload,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization:
                                "Bearer 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"
                        }
                    }).then((res) => {
                        console.log(res);
                        if (res?.status === 200) {
                            console.log(res.data.choices[0]?.message.content);
                            var userQue = {
                                "sender": "user",
                                "message": `${userQuestion}`
                            }
                            var botAns = {
                                "sender": "bot",
                                "message": `${res.data.choices[0]?.message.content}`
                            }

                            var save_ans = {
                                display_id: paramsid.id,
                                watch_id: get_watch_id,
                                answer: clickedNum,
                                name: "",
                                ai_conversation: [...aiConversation, userQue, botAns],
                            }
                            console.log(save_ans);

                            dispatch(saveAiAnsAsync(save_ans)).then((res) => {
                                console.log(res);
                                setaiConversation([...aiConversation, userQue, botAns]);
                                setuserQuestion("");
                                setisLoading(false);
                                document.getElementById("user-input_box").value = "";
                                document.getElementById("user-input_box").focus();
                                if (inputRef.current) {
                                    inputRef.current.scrollIntoView({ behavior: 'smooth' });

                                    inputRef.current.focus();
                                }

                            }).catch((e) => {
                                console.log(e.message, e);
                            });
                        }
                    }).catch((e) => {
                        console.log(e.message, e);
                    });
                });
            }
        }).catch((e) => {
            console.log(e.message, e);
            var payload = {
                messages: [
                    {
                        role: 'system',
                        // You can change the text below to include any additional instructions to the model
                        content: "You are a helpful assistant that answers the user's question based only on the relevant information you are provided along with the question.",
                    },
                    {
                        role: 'user',
                        content: `## RELEVANT INFORMATION: \n\n ${duonutData?.aiData?.websiteDataText}\n\n` +
                            `## Previous Conversation: \n\n ${JSON.stringify(aiConversation)}\n\n` +
                            `## ANSWER THE FOLLOWING USER'S QUESTION: \n\n ${userQuestion}`,
                    },
                ],
                max_tokens: 2000,
                model: "gpt-4o",
            }

            axios({
                method: "POST",
                url: "https://api.openai.com/v1/chat/completions",
                data: payload,
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer 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"
                }
            }).then((res) => {
                console.log(res);
                if (res?.status === 200) {
                    console.log(res.data.choices[0]?.message.content);
                    var userQue = {
                        "sender": "user",
                        "message": `${userQuestion}`
                    }
                    var botAns = {
                        "sender": "bot",
                        "message": `${res.data.choices[0]?.message.content}`
                    }

                    var save_ans = {
                        display_id: paramsid.id,
                        watch_id: get_watch_id,
                        answer: clickedNum,
                        name: "",
                        ai_conversation: [...aiConversation, userQue, botAns],
                    }
                    console.log(save_ans);

                    dispatch(saveAiAnsAsync(save_ans)).then((res) => {
                        console.log(res);
                        setaiConversation([...aiConversation, userQue, botAns]);
                        setuserQuestion("");
                        setisLoading(false);
                        document.getElementById("user-input_box").value = "";
                        document.getElementById("user-input_box").focus();
                        if (inputRef.current) {
                            inputRef.current.scrollIntoView({ behavior: 'smooth' });
                            inputRef.current.focus();
                        }

                    }).catch((e) => {
                        console.log(e.message, e);
                    });
                }
            }).catch((e) => {
                console.log(e.message, e);
            });
        });

    }

    return (
        <div className='AIDVP1App'>
            {pageAcess && duonutData && <div className='AIDVP1template'>
                <div style={{ marginTop: "2rem" }}>
                    <img style={{ width: "3rem", height: "3rem", borderRadius: '15px' }}
                        src={"/assets/duonut_icon.png"} alt="duonut" loading="lazy" /><br /><br />
                    <h2>You are chatting with Duonut AI</h2>
                    {/* <h5 style={{ color: "#3498db" }}>Powered by Duonut AI</h5> */}
                    <div style={{
                        margin: "1rem",
                        lineHeight: "1.2rem",
                        width: "auto",
                        maxWidth: "30rem",
                        fontSize: "15px",
                        fontWeight: "300"
                    }}>
                        Like all AI systems, Duonut cannot guarantee complete accuracy in every response.
                    </div>
                </div>

                <div className="AIDVP1container">
                    <h3>{duonutData?.question}</h3>
                    <div style={{ width: "fit-content", margin: "2rem auto" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {Array.from({ length: 5 }, (_, i) => {
                                return <div key={i} id={`option-id${i + 1}`}
                                    style={{
                                        display: "flex", justifyContent: "center", alignItems: "center",
                                        border: "1px solid #e5e7eb",
                                        margin: "8px",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        width: `30px`,
                                        height: `30px`,
                                        backgroundColor: clickedNum === (i + 1) ? "#7ED957" : isHovered === (i + 1) ? "#e5e7eb" : "white",
                                        cursor: "pointer"
                                    }}
                                    onMouseEnter={() => setIsHovered(i + 1)}
                                    onMouseLeave={() => setIsHovered(-1)}
                                    onClick={() => handleButtonClick(i + 1)}>{i + 1}
                                </div>
                            })}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: "0 10px", fontSize: "0.8rem" }}>
                            <div>NEGATIVE</div>
                            <div>POSITIVE</div>
                        </div>
                    </div>

                </div>
                {clickedNum >= 0 && <>
                    <div style={{
                        backgroundColor: "#f5f5f5",
                        padding: "1rem",
                        borderRadius: "10px",
                        marginTop: "2rem"
                    }}>Duonut AI is trained on content from {duonutData?.aiData?.websiteData?.company_name}. Feel free to ask any questions you have.</div>
                    <div className='AIDVP1Aicontainer'>
                        {/* <h2>Duonut Ai</h2>
                        <br></br> */}
                        <div className='AIDVP1chat-container'>
                            {aiConversation.map((item, index) => (
                                <div key={index} className={`AIDVP1message-row ${item.sender}`}>
                                    <img
                                        src={item.sender === "bot" ? "/assets/duonut_icon.png" : "/assets/user.png"}
                                        alt={`${item.sender}-avatar`}
                                        className="AIDVP1avatar"
                                    />
                                    <div className="AIDVP1message">
                                        {item.message.split('\n').map((str, idx, arr) => {
                                            // Regex to capture [text](url), plain URLs, and **bold text** excluding links within **
                                            const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)|(\*\*(?!\[.*\]\(.*\))([^*]+)\*\*)/g;
                                            const parts = [];
                                            let lastIndex = 0;
                                            let match;

                                            while ((match = linkRegex.exec(str)) !== null) {
                                                // Add text before the link
                                                if (match.index > lastIndex) {
                                                    parts.push(str.substring(lastIndex, match.index));
                                                }

                                                if (match[1] && match[2]) {
                                                    // Markdown style link [text](url)
                                                    parts.push(
                                                        <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
                                                            {match[1]}
                                                        </a>
                                                    );
                                                } else if (match[3]) {
                                                    // Plain URL
                                                    parts.push(
                                                        <a key={match.index} href={match[3]} target="_blank" rel="noopener noreferrer">
                                                            {match[3]}
                                                        </a>
                                                    );
                                                } else if (match[4]) {
                                                    // Bold text **text** (only when it's not around a link)
                                                    parts.push(<strong key={match.index}>{match[4]}</strong>);
                                                }
                                                lastIndex = match.index + match[0].length;
                                            }

                                            // Add any remaining text after the last link
                                            if (lastIndex < str.length) {
                                                parts.push(str.substring(lastIndex));
                                            }

                                            // Check if the parts array is empty (i.e., no text or links), skip rendering <p> if true
                                            if (parts.length === 0) return null;

                                            // return <p key={idx}>{parts}</p>;
                                            return (
                                                <React.Fragment key={idx}>
                                                    <p>{parts}</p>
                                                    {idx < arr.length - 1 && <br />}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                            <input className='AIDVP1Ai-input_box'
                                style={{ padding: "10px" }}
                                id="user-input_box"
                                type="text"
                                placeholder="Ask me something..."
                                autoFocus
                                onKeyUp={e => handleUserQuestion(e)}
                                ref={inputRef}
                            />
                            <button type='submit' id="questionBtn" className='GPT-submitBtn' disabled={isLoading}
                                onClick={() => handleUserQuestionSubmit()}>{!isLoading ? "Submit" : "Submitting..."}</button>
                        </div>
                    </div>
                </>}

                <div style={{ margin: "2rem" }}>

                    <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "5px", textAlign: "center", color: "rgb(156,163,175)" }}>Powered by</p>
                    <img style={{ width: "auto", height: "2rem" }}
                        src="https://duonut.com/assets/duonut_logo.png" alt="duonut" loading="lazy" /><br />

                    <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "10px", textAlign: "center", color: "rgb(156,163,175)" }}>
                        Sign up <a href="https://duonut.com" style={{ color: "inherit", textDecoration: "none", userSelect: "none", textDecorationLine: "underline" }} target="_blank">here</a> to send your first survey.</p>
                </div>
            </div>}
            {!pageAcess && <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "40rem", width: "auto", height: "100vh", margin: 'auto' }}>
                Oops! Looks like you can not view this Duonut. Please contact the creator of this Duonut for more information.
            </h2>}
        </div>
    );

}

export default AiDuonutPage;