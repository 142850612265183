import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";

import axios from 'axios';
// const axios = require("axios");

export const demoSlide = createSlice({
    name: "demo",
    initialState: {
        response: [],
        modalActive: false
    },
    reducers: {
        saveDemo: (state, action) => {
            state.response = [action.payload];
        },
        toggleDemoModalDuonut: (state, { payload }) => {
            state.modalActive = payload;
        },
    }
});

export const saveDemoAsync = (data) => async (dispatch) => {
    try {
        // console.log(data);
        const API_URL = `${domain}/duonut/save_demo`;
        const response = await axios.post(API_URL, data);
        // console.log(response);
        dispatch(saveDemo(response.data));
    } catch (err) {
        throw new Error(err);
    }
};

export const { saveDemo, toggleDemoModalDuonut } = demoSlide.actions;
export const showdemoresponse = (state) => state.demo.response;
export default demoSlide.reducer;
