import React, { useState, useRef, useEffect } from 'react'
import "./AboutChannel.css"
import { frontendUrl, backendUrl } from "../../services/constants";
import { saveChannelAsync, editChannelAsync, editChannelLogoAsync, deleteChannelLogoAsync } from "../../redux/slices/ChannelReducer";
import { useSelector, useDispatch } from "react-redux";

const AboutChannel = ({ channelData, handelSelectTab }) => {

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const [editChannelData, seteditChannelData] = useState(false);
    const [channelName, setchannelName] = useState(channelData.name);
    const [channelCode, setchannelCode] = useState(channelData.code);
    const [channelDescription, setchannelDescription] = useState(channelData.description);
    const [channelContact_no, setchannelContact_no] = useState(channelData.contact_no);
    const [channelwebsite, setchannelwebsite] = useState(channelData.website);
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const onClickEditButton = () => {
        seteditChannelData(true);
    }

    const onClickSaveButton = () => {
        // console.log(channelData)
        seteditChannelData(false);
        var CData = {
            id: channelData.id,
            name: channelName,
            code: channelCode,
            description: channelDescription,
            contact_no: channelContact_no,
            website: channelwebsite,
        }
        dispatch(editChannelAsync(CData)).then((res) => {
            // console.log(res);
            dispatch(saveChannelAsync());

        }).catch((e) => {
            console.log(e.message, e);
        });;

    }

    const onClickChangeImage = () => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
    }
    const onClickRemoveImage = () => {
        // console.log("111")
        var logoData = {
            id: channelData.id,
        }
        dispatch(deleteChannelLogoAsync(logoData));
        setTimeout(() => {
            handelSelectTab(3);
        }, 500); //miliseconds
    }
    const updateChannelImage = (e) => {
        // console.log(e)
        const formData = new FormData();
        formData.append('id', channelData.id);
        formData.append('logo', e.target.files[0], e.target.files[0].name);

        dispatch(editChannelLogoAsync(formData));
        setTimeout(() => {
            handelSelectTab(3);
        }, 500); //miliseconds
    }

    return (
        <div className='aboutChannelApp'>
            <div className='aboutChannelContainer'>
                <div className='aboutChannelRow' style={{ flexDirection: "column" }}>
                    <div className="channelLogo" >
                        {channelData?.logo ? <img className="channelLogoImg" src={`${backendUrl}${channelData.logo}`} /> : <img className="channelLogoImg" src='/assets/user.png' />}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div style={{ margin: "2px 10px", cursor: "pointer" }} onClick={() => onClickChangeImage()}>Change
                            <input type="file" ref={inputRef}
                                onChange={(e) => updateChannelImage(e)}
                                accept="image/*"
                                onClick={(e) => { e.target.value = null }} name="postImages"
                                style={{ display: "none" }} />
                        </div>
                        {channelData?.logo && <div style={{ margin: "2px 10px", cursor: "pointer" }} onClick={() => onClickRemoveImage()}>Remove</div>}
                    </div>
                </div>
                <div className='aboutChannelRow'>
                    <label>Name : </label>
                    <input className='aboutChannelInput'
                        style={{ borderBottom: editChannelData ? "1px solid black" : "none" }}
                        disabled={editChannelData ? false : true} value={channelName === null ? "" : channelName}
                        onChange={(e) => (e.target.value.length > 100 ? null : setchannelName(e.target.value))} />
                </div>
                <div className='aboutChannelRow'>
                    <label>Description : </label>
                    <input className='aboutChannelInput'
                        style={{ borderBottom: editChannelData ? "1px solid black" : "none" }}
                        disabled={editChannelData ? false : true} value={channelDescription === null ? "" : channelDescription}
                        onChange={(e) => (e.target.value.length > 100 ? null : setchannelDescription(e.target.value))} />
                </div>
                <div className='aboutChannelRow'>
                    <label>Contact no : </label>
                    <input className='aboutChannelInput'
                        style={{ borderBottom: editChannelData ? "1px solid black" : "none" }}
                        disabled={editChannelData ? false : true} value={channelContact_no === null ? "" : channelContact_no}
                        onChange={(e) => (e.target.value.length > 12 ? null : setchannelContact_no(e.target.value))} />
                </div>
                <div className='aboutChannelRow'>
                    <label>Website : </label>
                    <input className='aboutChannelInput'
                        style={{ borderBottom: editChannelData ? "1px solid black" : "none" }}
                        disabled={editChannelData ? false : true} value={channelwebsite === null ? "" : channelwebsite}
                        onChange={(e) => (e.target.value.length > 100 ? null : setchannelwebsite(e.target.value))} />
                </div>
                <div style={{ width: "100%" }}>
                    <button className='aboutChannelbtn' onClick={() => onClickEditButton()} >Edit</button>
                    <button className='aboutChannelbtn' onClick={() => onClickSaveButton()} >Save</button>
                </div>
            </div>
        </div>
    );

}

export default AboutChannel;