import React, { useEffect } from "react";
import "./Activity.css";
import { useSelector, useDispatch } from "react-redux";
import Editors from "./Editors";

const Activity = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const activity = userActivities[selectedUserActivity];

    // console.log("activity", userActivities, activity, selectedUserActivity);

    return (
        <>
            {activity?.elements && ((activity?.elements).length < 1 || !activity?.elements) ? (
                <div className="designMainContainer">
                    <div className="elementsContainer"></div>
                </div>
            ) :
                (
                    <Editors master={activity} />
                )}
        </>
    );
}

export default Activity;