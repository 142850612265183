import React, { useEffect, useState, useRef } from "react";
import "../../RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { updateUserActivityElements, updateUserActivity } from '../../../../redux/slices/userActivity';

const BGColorPicker = ({ isActive, handleOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const [color, setColor] = useState("#ffffff");
    const colorPickerRef = useRef(null);
    const [styles, setStyles] = useState({});


    useOutsideAlerter(colorPickerRef, handleClose);

    useEffect(() => {
        styles?.backgroundColor ?
            setColor(styles.backgroundColor) : setColor("#ffffff")
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleChange = e => {
        // const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        const updatedColor = e.hex;
        setColor(updatedColor);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    backgroundColor: updatedColor,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleColorChange = e => {
        // const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        const updatedColor = e.target.value;

        const hexPattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;

        if (hexPattern.test(updatedColor) || updatedColor === '') {
            setColor(updatedColor);

            if (userActivities[selectedUserActivity].elements.length >= 1) {
                let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
                const elementAttrib = tempElement?.elementAttributes;
                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...elementAttrib,
                        backgroundColor: updatedColor,
                    },
                };
                // console.log("tempElement", tempElement)
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            }
        }
    }

    return (<>
        <div
            className="color-select"
            onClick={handleOpen}
            style={{ backgroundColor: color, borderRadius: "5px 0px 0px 5px" }}
        >
            {isActive && (
                <div
                    className="color-picker"
                    ref={colorPickerRef}
                // style={{
                //     ...(width <= 1275 && { position: "fixed" }),
                //     ...(width <= 1275 && { top: colorEditorPos.y + 50 }),
                //     ...(width <= 1275 && { left: colorEditorPos.x - 220 }),
                //     ...(width <= 1275 && { position: "fixed" }),
                // }}
                >
                    <SketchPicker
                        color={color}
                        onChangeComplete={(e) => handleChange(e)}
                    />
                </div>
            )}
        </div>
        <div>
            <input type="text" value={color} id='bg-color'
                onFocus={() => { setTimeout(() => document.querySelector(`#bg-color`).select(), 100); }}
                style={{ boxShadow: "0px 0px 1.5px 0px", borderRadius: "0px 5px 5px 0px", textAlign: "left", width: "100%", padding: "5px", paddingLeft: "10px" }}
                onChange={(e) => (handleColorChange(e))} />
        </div>
    </>
    );
}

export default BGColorPicker;