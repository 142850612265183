import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  copyStyleSetting: {
    active: false,
    copyType: "",
    copyStyle: {},
    copyHoverStyle: {},
  }
};

const CopySetting = createSlice({
  name: "CopySetting",
  initialState,
  reducers: {
    toggleCopySetting: (state, { payload }) => {
      state.copyStyleSetting.active = payload;
    },
    updateCopyType: (state, { payload }) => {
      state.copyStyleSetting.copyType = payload;
    },
    updateCopyStyle: (state, { payload }) => {
      state.copyStyleSetting.copyStyle = payload;
    },
    updateCopyHoverStyle: (state, { payload }) => {
      state.copyStyleSetting.copyHoverStyle = payload;
    },
  },
});

export const { toggleCopySetting, updateCopyType, updateCopyStyle, updateCopyHoverStyle } = CopySetting.actions;
export default CopySetting.reducer;
