import React, { useRef, useEffect, useState } from 'react';
import './NuggetsModal.css';
import "../../../assets/bootstrap.min.css";
// import "../../../assets/bootstrap.bundle.min.js";
import { saveNuggetsMappingAsync, editNuggetsMappingAsync, deleteNuggetsMappingAsync } from "../../../redux/slices/NuggetsReducer";
import { saveChannelAsync } from "../../../redux/slices/ChannelReducer";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

const NuggetsModal = (props) => {
    // console.log(props);
    const displayId = props.shareData.display_id;
    const duonutId = props.shareData.duonut_id;
    const duonutName = props.shareData.duonut_name;
    const nuggets = props.shareData.nuggets;
    const ref = useRef(null);
    const ref1 = useRef(null);
    const dispatch = useDispatch();
    const [selectedNugget, setselectedNugget] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            ref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            ref1.current.click();
        }, 30000000); //miliseconds
    }, []);

    useEffect(() => {
        var data = []
        nuggets?.map((item, i) => {
            item.nugget_duonut.map((item1, j) => {
                if (item1.duonut.display_id === displayId) {
                    data.push(item.name)
                }

            })
        })
        setselectedNugget([...selectedNugget, ...data])
        // console.log([...selectedNugget, ...data])

    }, []);

    const continuebtn = () => {
        ref1.current.click();
    }

    const submitNuggetDuonut = (item) => {
        // console.log(item);
        if (!selectedNugget.includes(item.name)) {
            setselectedNugget([...selectedNugget, item.name]);
            var nuggetMappingData = {
                nugget: item.id,
                duonut: duonutId,
            }
            dispatch(saveNuggetsMappingAsync(nuggetMappingData));
            toast.success(`Duonut Added to ${item.name}`);
            dispatch(saveChannelAsync());

        }
        else {
            const index = selectedNugget.indexOf(item.name);
            if (index > -1) {
                selectedNugget.splice(index, 1);
                var nuggetMappingData1 = {
                    nugget: item.id,
                    duonut: duonutId,
                }
                dispatch(deleteNuggetsMappingAsync(nuggetMappingData1));
                toast.success(`Duonut Removed from ${item.name}`);
                dispatch(saveChannelAsync());

            }
        }

    }

    return (
        <><button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#qrcodeModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "999" }} id={`qrcodeModal${displayId}`} tabIndex="-1" aria-labelledby="qrcodeModalLabel" aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: "300px" }}>
                    <div className="modal-content" style={{ marginTop: "22vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='SM1shareTitle'> Add {duonutName} to ...</div>
                            {/* <br></br> */}

                            <div key={selectedNugget} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "12rem", margin: "1rem auto", maxHeight: "15rem", overflow: "auto" }}>
                                {nuggets?.map((item, i) => {
                                    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} key={i}>
                                        <input type="checkbox" id={"nuggets" + i} className="" style={{ width: "25px", height: "25px", margin: "2px 8px 2px 2px", accentColor: "#03ab55" }}
                                            checked={selectedNugget.includes(item.name) ? true : false} onChange={() => submitNuggetDuonut(item)} />
                                        <div className="" style={{}}
                                            onClick={() => submitNuggetDuonut(item)}>
                                            <div style={{}}>{item.name}</div>
                                        </div>
                                    </div>
                                })}
                            </div>
                            <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div></>
    );

}

export default NuggetsModal;