import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
const maxAnswerChar = 2;

export default function Margin() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [top, setTop] = useState(0)
    const [right, setRight] = useState(0)
    const [bottom, setBottom] = useState(0)
    const [left, setLeft] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        if (styles?.margin) {
            var parts = styles.margin.split(' ');
            // console.log("parts", parts, parts[0].slice(0, -2))
            setTop(parseInt(parts[0].slice(0, -2)));
            setRight(parseInt(parts[1].slice(0, -2)));
            setBottom(parseInt(parts[2].slice(0, -2)));
            setLeft(parseInt(parts[3].slice(0, -2)));
        } else {
            setTop(0);
            setRight(0);
            setBottom(0);
            setLeft(0);
        }
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity], selectedComponent.target_id])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleTopChange = e => {
        // console.log(e)
        setTop(parseInt(e.target.value));
        const value = `${parseInt(e.target.value)}px ${right}px ${bottom}px ${left}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    margin: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const handleRightChange = e => {
        setRight(parseInt(e.target.value));
        const value = `${top}px ${parseInt(e.target.value)}px ${bottom}px ${left}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    margin: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const handleBottomChange = e => {
        setBottom(parseInt(e.target.value));
        const value = `${top}px ${right}px ${parseInt(e.target.value)}px ${left}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    margin: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }
    const handleLeftChange = e => {
        setLeft(parseInt(e.target.value));
        const value = `${top}px ${right}px ${bottom}px ${parseInt(e.target.value)}px`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    margin: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div className='EditorRowContainer' style={{ margin: "1rem" }}>
            <p style={{ marginRight: "1rem" }}>Margin</p>

            <div className="styles_innerInput__ekrSk">
                <div className="styles_innerBackdrop__MyVPh">
                </div>
                <div className="styles_centerpiece__VYEvl"></div>
                <input type="number" className="no-arrows" min="0" value={top} id='top'
                    style={{ gridArea: "1 / 2 / 2 / 3" }} onChange={e => handleTopChange(e)} />
                <input type="number" className="no-arrows" min="0" value={right} id='right'
                    style={{ gridArea: "2 / 3 / 3 / 4" }} onChange={e => handleRightChange(e)} />
                <input type="number" className="no-arrows" min="0" value={bottom} id='bottom'
                    style={{ gridArea: "3 / 2 / 4 / 3" }} onChange={e => handleBottomChange(e)} />
                <input type="number" className="no-arrows" min="0" value={left} id='left'
                    style={{ gridArea: "2 / 1 / 3 / 2" }} onChange={e => handleLeftChange(e)} />

            </div>
        </div>
    )
}

