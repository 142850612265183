import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function FontSize() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [fontSize, setFontSize] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        styles?.fontSize ?
            setFontSize(styles.fontSize) : setFontSize(16)
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleFontSizeChange = e => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fontSize: parseInt(e.target.value),
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleFontSizeIncrement = () => {
        if (fontSize >= 1000) return;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fontSize: parseInt(fontSize + 1),
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };

    const handleFontSizeDecrement = () => {
        if (fontSize <= 0) return;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    fontSize: parseInt(fontSize - 1),
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

    };



    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Size</p>

            <div style={{ marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <label style={{ boxShadow: "0 1px 0.25rem 0 #000f3333", borderRadius: "5px", display: "flex" }}>
                    <button className="text-editor-modal-button" onClick={handleFontSizeDecrement}>
                        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M2,7H14a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H2A1,1,0,0,1,1,8H1A1,1,0,0,1,2,7Z"></path></g></svg>
                    </button>
                    <input type="number" min="0" value={fontSize} id='borderRadius'
                        style={{ textAlign: "center", width: "2.5rem", height: "2rem", padding: "0" }}
                        onChange={(e) => (e.target.value.length > 3 ? null : handleFontSizeChange(e))} />
                    <button className="text-editor-modal-button" onClick={handleFontSizeIncrement}>
                        <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M14,7H9V2A1,1,0,0,0,7,2V7H2A1,1,0,0,0,2,9H7v5a1,1,0,0,0,2,0V9h5a1,1,0,0,0,0-2"></path></g></svg>
                    </button>
                </label>
                <label style={{ marginLeft: "5px" }}>px</label>
            </div>

            {/* <select value={fontSize} className='text_editor_dropdown' onChange={e => handleFontSizeChange(e)} >
                {fontSizes.map(fontSize =>
                    <option key={fontSize.px} value={fontSize.px} > {fontSize.px} </option>
                )}
            </select> */}
            {/* <img src='/assets/icons/down-arrow.svg' className='select-icon' /> */}
        </div >
    )
}

const fontSizes = [
    {
        px: 16,
        rem: '1rem'
    },
    {
        px: 17,
        rem: '1.063rem'
    },
    {
        px: 18,
        rem: '1.125rem'
    },
    {
        px: 19,
        rem: '1.188rem'
    },
    {
        px: 20,
        rem: '1.25rem'
    },
    {
        px: 22,
        rem: '1.375rem'
    },
    {
        px: 24,
        rem: '1.5rem'
    },
    {
        px: 26,
        rem: '1.625rem'
    },
    {
        px: 28,
        rem: '1.625rem'
    },
    {
        px: 30,
        rem: '1.875rem'
    },
    {
        px: 32,
        rem: '2rem'
    },
    {
        px: 40,
        rem: '2.5rem'
    },

]
