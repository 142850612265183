import React, { useEffect, useCallback, useState } from "react";
import './FlowChart.css';
import { useSelector, useDispatch } from "react-redux";
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { addCondition, deleteAllCondition, updateAllCondition } from "../../redux/slices/userActivity";
import ButtonEdge from './ButtonEdge';
import ConditionModel from "./ConditionModel";
import { toggleFlowChartModal, updateFlowChartId } from "../../redux/slices/FlowChartSettingReducer";
import ConnectionLine from './ConnectionLine';

const initialNodes = [
    { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
    { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
    { id: '3', position: { x: 0, y: 200 }, data: { label: '3' } },
];

const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

const edgeTypes = {
    buttonedge: ButtonEdge,
};

const FlowChart = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const { active: isShowModal, edgeId } = useSelector((state) => state.FlowChartSetting.flowChartModalSetting);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const { show_custom_button } = useSelector(state => state.duonutSettings.settings);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { userProAccess } = useSelector((state) => state.user);

    // useEffect(() => {
    //     if (!userProAccess) {
    //         // Extract the current URL
    //         const currentURL = window.location.href;

    //         // Remove the last segment (in this case, 'reports') from the URL
    //         const newURL = currentURL.substring(0, currentURL.lastIndexOf('/'));

    //         // Navigate to the new URL
    //         window.location = newURL;
    //     }
    // }, []);

    const onConnect = useCallback((params) => {
        // console.log(params, edges);

        var isTargetAlreadyConnected = false;
        setEdges((eds) => {
            // Check if the target node already has a connection
            isTargetAlreadyConnected = eds.some(element => element.source === params.source && element.always);
            // console.log(isTargetAlreadyConnected);

            // If the target node already has a connection, prevent the connection
            if (isTargetAlreadyConnected) {
                return eds;
            }

            const updatedEdges = eds.map(edge => {
                if (edge.source === params.source && edge.target === params.target) {
                    return { ...edge, id: `e${params.source}-${params.target}`, type: 'buttonedge' }; // Replace the existing edge with the new one
                }
                return edge;
            });

            // console.log(userActivities[params.source - 1], userActivities[params.source - 1]?.elements?.some(element => element?.elementType === "RadioGroup"))
            const isNewEdge = !eds.some(edge => (edge.source === params.source && edge.target === params.target));
            return isNewEdge ? [...updatedEdges, {
                ...params, id: `e${params.source}-${params.target}`,
                type: 'buttonedge',
                always: (userActivities[params.source - 1]?.elements?.some(element => element?.elementType === "CheckboxGroup") ||
                    userActivities[params.source - 1]?.elements?.some(element => element?.elementType === "RadioGroup") ||
                    userActivities[params.source - 1]?.elements?.some(element => element?.elementType === "Mcq") ||
                    userActivities[params.source - 1]?.elements?.some(element => element?.elementType === "Rating")) ? false : true
            }] : updatedEdges;
        });
        if (!isTargetAlreadyConnected) {

            dispatch(
                addCondition({
                    selectedActivityIdx: parseInt(params.source) - 1,
                    condition: { ...params, id: `e${params.source}-${params.target}`, type: 'buttonedge' }
                })
            );

            dispatch(toggleFlowChartModal(true));
            dispatch(updateFlowChartId(`e${params.source}-${params.target}`));
        }
    }, []);

    const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

    // console.log(nodes, edges, userActivities, selectedUserActivity);

    // Function to strip HTML tags and keep only text content
    const stripHtml = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    useEffect(() => {
        var activityNodes = [];
        var activityEdges = [];
        userActivities.map((item, i) => {
            if (Object.keys(userActivities[i]).length > 1) {
                var activityNode = {
                    id: `${i + 1}`,
                    position: { x: i === 0 ? 300 : ((i + 1) % 2 == 0) ? ((300 - 100) - 20 * i) : ((300 + 100) + 20 * i), y: i * 120 },
                    data: {
                        label: (<div>{i + 1}. {userActivities[i]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text
                            ? stripHtml(userActivities[i]?.elements?.find(element => element?.elementType === "Text")?.elementAttributes.text) :
                            item.name}</div>)
                    },

                };
                activityNodes.push(activityNode);
            }
        })

        var activityNodeEndPage = {
            id: `${userActivities.length + 1}`,
            position: { x: 300, y: userActivities.length * 120 },
            data: { label: (<div>{userActivities.length + 1}. {show_custom_button ? "End Page" : "Thank You"}</div>) },
            type: "output"
            // style: {
            //     visibility: 'visible',
            // }
        };
        activityNodes.push(activityNodeEndPage);

        userActivities.map((item, i) => {
            if (Object.keys(userActivities[i]).length > 1) {
                if (i >= 1) {
                    var activityEdge = {
                        id: `e${i}-${i + 1}`,
                        source: `${i}`,
                        target: `${i + 1}`,
                    }
                    activityEdges.push(activityEdge);
                }
            }
        })

        var activityEdgeEndPage = {
            id: `e${userActivities.length}-${userActivities.length + 1}`,
            source: `${userActivities.length}`,
            target: `${userActivities.length + 1}`,
        }
        activityEdges.push(activityEdgeEndPage);

        userActivities.map((item, i) => {
            if (Object.keys(userActivities[i]).length > 1) {

                item.condition?.map((cond, j) => {
                    if (cond.always) {
                        activityEdges = activityEdges.filter(edge =>
                            edge.source !== cond.source
                        )
                    }

                    const existingIndex = activityEdges.findIndex(edge => edge.id === cond.id);

                    if (existingIndex !== -1) {
                        activityEdges[existingIndex] = cond;
                    } else {
                        activityEdges.push(cond);

                    }

                })
            }
        })

        userActivities.map((item, i) => {
            if (Object.keys(userActivities[i]).length > 1) {
                if (item?.elements?.some(element => element?.elementType === "CheckboxGroup")) {
                    let isOptionUsed = true;
                    for (let j = 0; j < item?.elements?.find(element => element?.elementType === "CheckboxGroup")?.elementAttributes.options.length; j++) {
                        isOptionUsed = item.condition?.some(cond => (cond.condition?.includes(item?.elements?.find(element => element?.elementType === "CheckboxGroup")?.elementAttributes.options[j])))
                        if (!isOptionUsed) {
                            break;
                        }
                    }
                    if (isOptionUsed) {
                        activityEdges = activityEdges.filter(edge => {
                            if (JSON.stringify(edge.id) === JSON.stringify(`e${i + 1}-${i + 2}`) && (edge.type === "buttonedge")) {
                                return true;
                            }
                            return JSON.stringify(edge.id) !== JSON.stringify(`e${i + 1}-${i + 2}`)
                        })
                    }
                } else if (item?.elements?.some(element => element?.elementType === "RadioGroup")) {
                    let isOptionUsed = true;
                    for (let j = 0; j < item?.elements?.find(element => element?.elementType === "RadioGroup")?.elementAttributes.options.length; j++) {
                        isOptionUsed = item.condition?.some(cond => (cond.condition?.includes(item?.elements?.find(element => element?.elementType === "RadioGroup")?.elementAttributes.options[j])))
                        if (!isOptionUsed) {
                            break;
                        }
                    }
                    if (isOptionUsed) {
                        activityEdges = activityEdges.filter(edge => {
                            if (JSON.stringify(edge.id) === JSON.stringify(`e${i + 1}-${i + 2}`) && (edge.type === "buttonedge")) {
                                return true;
                            }
                            return JSON.stringify(edge.id) !== JSON.stringify(`e${i + 1}-${i + 2}`)
                        })
                    }
                } else if (item?.elements?.some(element => element?.elementType === "Mcq")) {
                    let isOptionUsed = true;
                    for (let j = 0; j < item?.elements?.find(element => element?.elementType === "Mcq")?.elementAttributes.options.length; j++) {
                        isOptionUsed = item.condition?.some(cond => (cond.condition?.includes(item?.elements?.find(element => element?.elementType === "Mcq")?.elementAttributes.options[j])))
                        if (!isOptionUsed) {
                            break;
                        }
                    }
                    if (isOptionUsed) {
                        activityEdges = activityEdges.filter(edge => {
                            if (JSON.stringify(edge.id) === JSON.stringify(`e${i + 1}-${i + 2}`) && (edge.type === "buttonedge")) {
                                return true;
                            }
                            return JSON.stringify(edge.id) !== JSON.stringify(`e${i + 1}-${i + 2}`)
                        })
                    }
                } else if (item?.elements?.some(element => element?.elementType === "Rating")) {
                    if (item.condition?.length >= 1) {
                        activityEdges = activityEdges.filter(edge => {
                            if (JSON.stringify(edge.id) === JSON.stringify(`e${i + 1}-${i + 2}`) && (edge.type === "buttonedge")) {
                                return true;
                            }
                            return JSON.stringify(edge.id) !== JSON.stringify(`e${i + 1}-${i + 2}`)
                        })
                    }
                }
            }
        })

        setNodes(activityNodes);
        setEdges(activityEdges);
        // console.log(activityNodes, activityEdges);
    }, [userActivities]);

    useEffect(() => {
        dispatch(
            updateAllCondition({})
        )
    }, []);

    const [animatedEdge, setAnimatedEdge] = useState(null);

    const onEdgeDrag = useCallback((event, edge) => {
        setAnimatedEdge(edge);
    }, []);

    const onEdgeDragEnd = useCallback(() => {
        setAnimatedEdge(null);
    }, []);

    const resetbtn = () => {
        userActivities.map((item, i) => {
            if (Object.keys(userActivities[i]).length > 1) {
                if (item.condition?.length >= 1) {
                    dispatch(
                        deleteAllCondition({
                            selectedActivityIdx: i,
                        })
                    );
                }
            }
        })
    }

    // console.log("useactivity", userActivities);

    return (
        <div style={{ position: "relative", width: "100%", height: '89vh', marginTop: "11vh" }}>
            {isShowModal && <ConditionModel edges={edges} />}
            <ReactFlow
                nodes={nodes}
                edges={edges}
                edgeTypes={edgeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onEdgeDrag={onEdgeDrag}
                onEdgeDragEnd={onEdgeDragEnd}
                // connectionLineType="smoothstep"
                connectionLineComponent={ConnectionLine}
                // onInit={onInit}
                fitView
                attributionPosition="top-right"
            >
                {animatedEdge && (
                    <div style={{ position: 'absolute', top: animatedEdge.y, left: animatedEdge.x }}>
                        <div className="animated-edge" style={{ width: animatedEdge.width, transform: `rotate(${animatedEdge.angle}rad)` }}></div>
                    </div>
                )}
                {/* <MiniMap style={minimapStyle} zoomable pannable /> */}
                <Controls />
                <Background color="#aaa" gap={16} />
            </ReactFlow>
            <div onClick={() => resetbtn()} className="btn-secondary" style={{ position: "absolute", top: "0rem", right: "1rem", cursor: "pointer" }}>Reset</div>
        </div>
    );
}

export default FlowChart;