import React, { useState } from 'react';
import './Education.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
import { useSelector, useDispatch } from "react-redux";
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";

const Education = () => {
    const { width, height } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.user);

    const handleClick = () => dispatch(openModal());
    const handleClickSignUp = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignUpForm());
        } else {
            navigate("/design");
        }

    };

    const handleClickDemo = () => {
        dispatch(toggleDemoModalDuonut(true));
    };

    return (
        <div className='UCI1App'>
            <div className='UCI1template'>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'contain' }}
                            src="/assets/usecase/duonjut_education.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        {/* <h1 style={{ fontSize: "3rem", lineHeight: "50px" }}>Build advanced insurance forms and workflows</h1> */}
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Simplify education data management, improve student engagement, and streamline work across departments with digital workflows
                        </div>
                        <br></br>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div className="home_page_integration_section">
                    <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                    <div className="home_page_integration_section_images">
                        <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>
                        <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                        <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                        <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                    </div>
                </div>


                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_education.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1>Manage your course and Classroom</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Use an intuitive drag-and-drop visual editor to build a teacher questionnaire, online quiz, student enrollment form,  student assignments, or collect parent permissions with ease.
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingRight: "4rem" }}>
                        <h1>Flexible form builder for educators </h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Create forms or courses with conditional logic, custom flow and 100% customization. Stitch multiple courses together to create and share courses securely.
                        </div>
                    </div>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_flexibleform.png" alt="" />
                    </div>
                </div>


                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", paddingTop: "8rem" }}>
                    <div className='UCI1section-1' style={{ width: width < 800 ? "100%" : "40%", padding: "0" }}>
                        <img className='UCI1section-1-image' style={{ width: "100%", border: "none", borderRadius: "15px", objectFit: 'cover' }}
                            src="/assets/usecase/duonut_insurance_1.png" alt="" />
                    </div>
                    <div style={{ width: width < 800 ? "100%" : "60%", margin: "auto", paddingLeft: "4rem" }}>
                        <h1>Increase Conversion</h1>
                        <br></br>
                        <div style={{ fontSize: "1.3rem" }}>
                            Diagnose drop off with our in-product analytics, easily make changes and run AB tests to increase conversion. Design great UX to increase student registration and engagement rates.
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "8rem" }}>
                    <h1 style={{ fontSize: "2.5rem", paddingBottom: "3rem" }}>Featured Education Templates</h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/signup-flow")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/edu1.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Signup/Signin Flow</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/onboarding-template")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/edu2.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Onboarding Template</div>
                        </div>
                        <div className='UCI1template_container'
                            onClick={() => window.open("https://duonut.com/templates/general/contact-us-form-ii")}>
                            <img style={{ width: "280px", height: "185px", borderRadius: "15px", objectFit: "cover" }}
                                src="/assets/usecase/templates/contact_us.png" alt="" />
                            <div style={{
                                display: "flex", alignItems: "center", height: "6rem",
                                fontSize: "1.3rem", fontWeight: "700"
                            }}>Contact us Form</div>
                        </div>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1 style={{ fontSize: "3rem", padding: "1rem" }}>Get started today.</h1>
                    <div>
                        <button onClick={() => handleClickDemo()} className="UCI1Button" style={{ marginRight: "1rem" }}>
                            Book a demo
                        </button>
                        <button className="button" style={{ padding: "16px 40px", fontSize: "1.2rem" }} onClick={() => handleClickSignUp()} >
                            Sign up now
                        </button>
                    </div>
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

            </div>
            <HomePageFooter />
        </div>
    );

}

export default Education;