import React, { useState, useEffect } from 'react';
import './AiDuonutPage.css';
import { useParams, useSearchParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAiDuonutAsync, showAiDuonutData, saveAiAnsAsync, showAiDuonutresponse, getAiAnsAsync } from '../../redux/slices/AiDuonutReducer';

const EmbedAiDuonutPage = () => {
    const paramsid = useParams();
    const dispatch = useDispatch();
    const [isHovered, setIsHovered] = useState(-1);

    useEffect(() => {
        if (paramsid && paramsid.id && paramsid.id !== "0" && paramsid.id !== "undefined") {
            dispatch(getAiDuonutAsync(paramsid))
        }

        // eslint - disable - next - line
    }, []);

    const duonutDataMain = useSelector(showAiDuonutData);
    const duonutData = duonutDataMain[0];
    console.log(duonutData)

    const handleButtonClick = (num) => {
        window.open(`/survey/${duonutData.display_id}?rating=${num}`)
    }


    return (
        <div className='AIDVP1App'>
            {duonutData && <div className='AIDVP1template' style={{ minHeight: "auto" }}>
                <div className="AIDVP1container" style={{ margin: "1rem" }}>
                    <h3>{duonutData?.question}</h3>
                    <div style={{ width: "fit-content", margin: "2rem auto" }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {Array.from({ length: 5 }, (_, i) => {
                                return <div key={i} id={`option-id${i + 1}`}
                                    style={{
                                        display: "flex", justifyContent: "center", alignItems: "center",
                                        border: "1px solid #e5e7eb",
                                        margin: "8px",
                                        padding: "10px",
                                        borderRadius: "5px",
                                        width: `30px`,
                                        height: `30px`,
                                        backgroundColor: isHovered === (i + 1) ? "#e5e7eb" : "white",
                                        cursor: "pointer"
                                    }}
                                    onMouseEnter={() => setIsHovered(i + 1)}
                                    onMouseLeave={() => setIsHovered(-1)}
                                    onClick={() => handleButtonClick(i + 1)}>{i + 1}
                                </div>
                            })}
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', margin: "0 10px", fontSize: "0.8rem" }}>
                            <div>NEGATIVE</div>
                            <div>POSITIVE</div>
                        </div>
                    </div>
                    <div style={{ margin: "2rem" }}>
                        <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "5px", textAlign: "center", color: "rgb(156,163,175)" }}>Powered by</p>
                        <img style={{ width: "auto", height: "2rem" }}
                            src="https://duonut.com/assets/duonut_logo.png" alt="duonut" loading="lazy" /><br />

                        <p style={{ fontSize: "0.85rem", lineHeight: "1rem", margin: "10px", textAlign: "center", color: "rgb(156,163,175)" }}>
                            Sign up <a href="https://duonut.com" style={{ color: "inherit", textDecoration: "none", userSelect: "none", textDecorationLine: "underline" }} target="_blank">here</a> to send your first survey.</p>
                    </div>
                </div>

            </div>}
        </div>
    );

}

export default EmbedAiDuonutPage;