import React from 'react'

export default function Down({ fill }) {


    return (
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.51922 0.508709C1.21151 -0.16957 2.33394 -0.16957 3.02623 0.508709L12.4808 9.77187C13.1731 10.4501 13.1731 11.5499 12.4808 12.2281L3.02623 21.4913C2.33394 22.1696 1.21151 22.1696 0.51922 21.4913C-0.173073 20.813 -0.173073 19.7133 0.51922 19.035L8.72026 11L0.51922 2.96497C-0.173073 2.2867 -0.173073 1.18699 0.51922 0.508709Z" 
            fill={fill ? fill : '#92909A'} />
        </svg>
    )
}
