import React, { useEffect, useState, useRef } from "react";
import "../../RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { updateUserActivityElements, updateUserActivity } from '../../../../redux/slices/userActivity';

const HoverColorPicker = ({ isActive, handleOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const [color, setColor] = useState("#000000");
    const [borderWidth, setBorderWidth] = useState(0)
    const [pattern, setPattern] = useState("solid")
    const colorPickerRef = useRef(null);
    const [styles, setStyles] = useState({});
    const [positionTop, setpositionTop] = useState(false)
    const colorModalRef = useRef(null);

    useOutsideAlerter(colorPickerRef, handleClose);

    useEffect(() => {
        if (styles?.border) {
            // Split the border property into width, pattern, and color
            var parts = styles.border.split(' ');
            setBorderWidth(parseInt(parts[0].slice(0, -2))); // First part is the width
            setPattern(parts[1]); // Second part is the pattern
            setColor(parts[2]); // Third part is the color
        } else {
            setBorderWidth(0);
            setPattern("solid");
            setColor("#000000");
        }
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity], selectedComponent.target_id])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementHoverAttrib = tempElement?.elementHoverAttributes;
            setStyles(elementHoverAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleChange = e => {
        // const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        const updatedColor = e.hex;
        setColor(updatedColor);
        const value = `${borderWidth}px ${pattern} ${updatedColor}`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementHoverAttrib = tempElement?.elementHoverAttributes;
            tempElement = {
                ...tempElement,
                elementHoverAttributes: {
                    ...elementHoverAttrib,
                    border: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    useEffect(() => {
        if (isActive) {
            let pos = colorModalRef.current.getBoundingClientRect()
            console.log(pos.top)
            if (pos.top < 300) {
                setpositionTop(true);
            } else {
                setpositionTop(false);
            }
        }
    }, [isActive]);

    return (
        <div
            className="color-select"
            onClick={handleOpen}
            style={{ backgroundColor: color }}
            ref={colorModalRef}
        >
            {isActive && (
                <div
                    className="color-picker"
                    ref={colorPickerRef}
                    style={positionTop ? { right: 0 } : { right: 0, top: "-305px" }}
                >
                    <SketchPicker
                        color={color}
                        onChangeComplete={(e) => handleChange(e)}
                    />
                </div>
            )}
        </div>
    );
}

export default HoverColorPicker;