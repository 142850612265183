import React, { useEffect, useState } from "react";
import "../../RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import ColorPicker from "./HoverColorPicker";

const HoverBorderColorEditor = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);

    const [isActive, setIsActive] = useState(false);

    const handleOpen = () => {
        setIsActive(true);
    };

    const handleClose = () => {
        setIsActive(false);
    };

    return (
        <div style={{ marginLeft: "1rem" }}>
            <ColorPicker isActive={isActive}
                handleOpen={handleOpen}
                handleClose={handleClose}
            // colorEditorPos={colorEditorPos}
            // width={width}
            // gridBackground={gridBackground}
            // gridText={gridText}
            // hover={hover} 
            />
        </div>
    );
}

export default HoverBorderColorEditor;