import React, { useState, useEffect, useRef } from 'react';
import './AiData.css';
import { domain } from "../../services/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { saveAiAsync, showAiresponse, createAiAsync } from "../../redux/slices/AiReducer";
import { saveAiDuonutAsync, showAiDuonutresponse, createAiDuonutAsync, editAiDuonutAsync } from "../../redux/slices/AiDuonutReducer";
import { toggleAiModal, updateAiData } from "../../redux/slices/AiSettingReducer";
import AiSetting from "./AiSetting/AiSetting";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import { toast } from "react-toastify";
import { updateLogoutModalActive } from "../../redux/slices/formDetails";

const AiData = () => {
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const { settings } = useSelector(state => state.duonutSettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    const [selectedPage, setselectedPage] = useState(1);
    const [websiteData, setwebsiteData] = useState('');
    const [websiteDataText, setwebsiteDataText] = useState('');
    const [websiteDataQuestions, setwebsiteDataQuestions] = useState([]);
    const [selectedAnalyticsQue, setselectedAnalyticsQue] = useState(0);
    const [showConversation, setshowConversation] = useState(0);
    const [toatlAverage, settoatlAverage] = useState(0);
    const [dashboardSummary, setdashboardSummary] = useState('');
    const [ratingData, setratingData] = useState("");
    const [editQuestionId, seteditQuestionId] = useState(0);
    const [editQuestionText, seteditQuestionText] = useState('');
    const [userQuestionText, setuserQuestionText] = useState('');
    const [openLogout, setopenLogout] = useState(false);
    const [aiDataNub, setaiDataNub] = useState(0);
    const [hoverQuestion, sethoverQuestion] = useState(0);
    const [topbar, settopbar] = useState(true);

    const handelClose = () => {
        setopenLogout(false);
    }
    const logoutRef = useRef(null);
    useOutsideAlerter(logoutRef, handelClose);

    const inputRef = useRef(null);
    const handleSave = () => {
        console.log(editQuestionId);
        if (editQuestionId !== 0) {
            var aiDuonutData = {
                id: editQuestionId,
                question: editQuestionText,
            }
            dispatch(editAiDuonutAsync(aiDuonutData)).then((res) => {
                // console.log(res);
                if (res.status === 201) {
                    // toast.success(`updating this Duonut`);
                    dispatch(saveAiAsync());
                    seteditQuestionId(0);
                    seteditQuestionText("");
                }

            }).catch((e) => {
                console.log(e.message, e);
            });
        }

    }

    useEffect(() => {
        dispatch(saveAiAsync());
    }, []);
    const aiData = useSelector(showAiresponse);
    const aiData1 = aiData[0];

    useEffect(() => {
        if (aiData1) {
            if (aiData1.empty_message) {
                navigate('/home');
            } else {
                console.log(aiData1);
                setgptQuestionInput(aiData1[aiDataNub].ai_data.websiteUrl);
                setwebsiteData(aiData1[aiDataNub].ai_data.websiteData);
                setwebsiteDataText(aiData1[aiDataNub].ai_data.websiteDataText);
                const aiQueData = aiData1[aiDataNub].ai_duonut_data;
                const sortedData = [...aiQueData].sort((a, b) => a.id - b.id);
                setwebsiteDataQuestions(sortedData);

                // setselectedPage(1);

                // average for dashboard
                var totalAnswers = 0;
                var totalEntries = 0;
                let average;

                aiData1[aiDataNub].ai_duonut_data?.forEach(question => {
                    question.analytics.forEach(analytic => {
                        totalAnswers += parseInt(analytic.answer);
                        totalEntries++;
                    });
                });

                if (totalEntries === 0) {
                    average = 0;
                } else {
                    average = (totalAnswers / totalEntries).toFixed(1);
                }
                // console.log(average, totalAnswers, totalEntries);
                settoatlAverage(average);

                const allratingData = calculateRatingPercentages(aiData1[aiDataNub].ai_duonut_data);
                console.log(allratingData);
                setratingData(allratingData);
            }
        }
    }, [aiData1, aiDataNub])

    const aiDuonutAnalytics = websiteDataQuestions?.find((data) => data.id === selectedAnalyticsQue);

    const handelSelectTab = (tab) => {
        setselectedPage(tab);
        setselectedAnalyticsQue(0);
        setshowConversation(0);
    }

    const handelGenerateSummary = () => {
        //  Extract and concatenate AI conversation messages
        const allConversations = websiteDataQuestions.flatMap(item =>
            item.analytics.flatMap(analytic =>
                analytic.ai_conversation ? analytic.ai_conversation.map(conv => conv.message).join(' ') : ''
            )
        ).join(' ');

        console.log(allConversations);

        var newQuestion = `give summary of this conversation what customer wants? 
                ${allConversations}`;

        var payload = {
            messages: [{ "role": "user", "content": newQuestion }],
            max_tokens: 500,
            temperature: 0.7,
            n: 1,
            model: "gpt-3.5-turbo",
        }

        axios({
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            data: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer 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"
            }
        }).then((res) => {
            console.log(res);
            if (res?.status === 200) {
                console.log(res.data.choices[0]?.message.content);
                setdashboardSummary(res.data.choices[0]?.message.content)
            }
        }).catch((e) => {
            console.log(e.message, e);
        });
    }

    // Function to calculate rating percentages
    const calculateRatingPercentages = (data) => {
        const ratings = [1, 2, 3, 4, 5]; // Include all ratings from 1 to 5
        const totalAnswers = data.reduce((acc, item) => acc + item.analytics.length, 0);

        // Count each rating
        const ratingCounts = data.flatMap(item => item.analytics)
            .reduce((acc, { answer }) => {
                acc[answer] = (acc[answer] || 0) + 1;
                return acc;
            }, {});

        // Calculate percentages ensuring the total is 100%
        return ratings.map((rating) => {
            const count = ratingCounts[rating] || 0;
            const percentage = totalAnswers ? ((count / totalAnswers) * 100).toFixed(2) : 0; // Calculate percentage
            return { rating, count, percentage };
        }).sort((a, b) => b.rating - a.rating); // Sort by rating in descending order
    };

    const handleAddUserQuestion = () => {
        let jsonData = [
            [userQuestionText],
        ];

        var ai_Duonut_Data = {
            question_data: jsonData,
            aiData_id: aiData1[aiDataNub]?.ai_data?.id,
        }
        dispatch(createAiDuonutAsync(ai_Duonut_Data)).then((res) => {
            // console.log(res);
            if (res.status === 201) {
                dispatch(saveAiAsync());
                setuserQuestionText("");
            }

        }).catch((e) => {
            console.log(e.message, e);
        });
    }

    return (
        <div className='AIDP1App'>
            <div className='AIDP1template'>

                <div className='AIDP1leftContainer'>
                    <div style={{ marginBottom: '1rem' }}>
                        <img style={{ width: "auto", height: "3rem", borderRadius: "10px", cursor: "pointer" }}
                            onClick={() => navigate("/home")}
                            src="/assets/duonut_icon.png" alt="duonut" loading="lazy" />
                    </div>
                    {(userProAccess && userAccountType !== "free") && ((aiData1?.length === 1 && userAccountType === "pro") ||
                        (aiData1?.length < 5 && userAccountType === "plus")) && <div className='AIDP1tabName' style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { navigate("/home") }}>
                            <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to home
                        </div>}
                    {aiData1 && aiData1?.length > 1 && <div className='AIDP1tabName'>
                        <div style={{ padding: "5px 0px" }}>Company Name</div>
                        <select value={aiDataNub} className='text_editor_dropdown'
                            style={{ width: "155px" }}
                            onChange={e => { setaiDataNub(parseInt(e.target.value)) }} >
                            <option key={"select option"} value={-1} style={{ display: "none", color: "#44474d" }} selected disabled> Select...</option>
                            {aiData1.map((item, idx) => {
                                return <option key={idx} value={idx} > {item?.ai_data?.websiteData?.company_name} </option>
                            }
                            )}
                        </select>
                    </div>}
                    <div className='AIDP1tabName' onClick={() => handelSelectTab(3)}
                        style={selectedPage === 3 ? { fontWeight: "800", backgroundColor: "#455c6e" } : {}}>
                        Dashboard
                    </div>
                    <div className='AIDP1tabName' onClick={() => handelSelectTab(1)}
                        style={selectedPage === 1 ? { fontWeight: "800", backgroundColor: "#455c6e" } : {}}>
                        Company Summary
                    </div>
                    <div className='AIDP1tabName' onClick={() => handelSelectTab(2)}
                        style={selectedPage === 2 ? { fontWeight: "800", backgroundColor: "#455c6e" } : {}}>
                        Questions
                    </div>
                    <div className='AIDP1tabName' onClick={() => handelSelectTab(4)}
                        style={selectedPage === 4 ? { fontWeight: "800", backgroundColor: "#455c6e" } : {}}>
                        Reports
                    </div>

                    <div ref={logoutRef} style={{ marginLeft: "10px", textAlign: "left", position: "absolute", bottom: "10px" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => setopenLogout(true)}>
                            <div style={{ fontWeight: "600", paddingBottom: "4px" }}>{localStorage.getItem("userName") !== "null" ? localStorage.getItem("userName") : ""}</div>
                            <div style={{ fontSize: "0.8rem" }}>{localStorage.getItem("userEmail")}</div>
                        </div>
                        {openLogout && <div style={{
                            fontWeight: "800", color: "black", padding: "8px 12px", cursor: "pointer", position: "absolute",
                            backgroundColor: "white", textAlign: "center", top: "-2.8rem", borderRadius: "6px"
                        }} onClick={() => dispatch(updateLogoutModalActive(true))}>Logout</div>}
                    </div>
                </div>

                <div className='AIDP1rightContainer' id="scrollableDiv">
                    {!userProAccess && topbar && <div style={{ position: "absolute", top: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "75.8vw", textAlign: "center" }}>
                        You've reached your limit. Upgrade now to continue enjoying our services without interruptions!
                        <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
                            onClick={() => navigate('/payment/pro')} >Upgrade Now</button>
                        <label onClick={() => settopbar(false)}
                            style={{ position: "absolute", right: "20px", top: '14px', cursor: "pointer" }}>
                            <img style={{ width: '14px' }} src="/assets/icons/close-white.svg" /></label>
                    </div>}

                    {selectedPage === 1 && <> <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDP1container1'>
                            <div><strong>Company:</strong> {websiteData.company_name}</div>
                            <div><strong>Category:</strong> {websiteData.category}</div>
                            <div><strong>Summary:</strong> {websiteData.summary}</div>
                            <div>
                                <div><strong>Products and Services</strong></div>
                                {websiteData?.products_services?.map((product, index) => (
                                    <div key={index}>
                                        <li>{product.name}</li>
                                        <p><strong>Features: </strong> {product.features.join(', ')}</p>
                                        <p><strong>Benefits: </strong>{product.benefits.join(', ')} </p>
                                        <p><strong>Unique Selling Points: </strong>{product.unique_selling_points.join(', ')} </p>

                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='AIDP1container1' style={{ margin: "0px 0px 5rem 0px", alignItems: "center" }}>

                            <button className='GPT-submitBtn' onClick={() => setselectedPage(2)}>Click here to review questions</button>
                        </div>
                    </div>
                        <div style={{ position: "fixed", bottom: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "75.8vw", textAlign: "center" }}>
                            Coming soon: Upload internal documents, PDFs, FAQs, and more as data sources to generate customer-focused questions.
                        </div>
                    </>}

                    {selectedPage === 2 && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDP1container2'>
                            <h2 style={{ margin: "auto", paddingBottom: "1rem" }}>Questions</h2>
                            <ul class="AIDP1question-list">
                                {websiteDataQuestions?.map((item, index) => (
                                    <li class="AIDP1question-item" key={index}
                                        onMouseEnter={() => sethoverQuestion(item.id)}
                                        onMouseLeave={() => sethoverQuestion(0)}>
                                        {editQuestionId === item.id ? <input type='text' ref={inputRef}
                                            id={`aiQuestionInput${item.id}`}
                                            value={editQuestionText}
                                            style={{
                                                width: "90%", minHeight: "30px",
                                                backgroundColor: "transparent",
                                                border: editQuestionId === item.id ? "1px solid black" : "0",
                                                borderRadius: "5px",
                                                fontSize: "1rem",
                                                padding: "5px"
                                            }}
                                            onChange={(e) => seteditQuestionText(e.target.value)}
                                            readOnly={editQuestionId === item.id ? false : true}
                                        /> : <label>{item.question}</label>}

                                        {(hoverQuestion === item.id || editQuestionId === item.id) && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                            {editQuestionId === item.id ? <span onClick={() => { handleSave(); }} class="AIDP1icon">
                                                <i className="fa fa-floppy-o" aria-hidden="true"></i>
                                            </span>
                                                : <span onClick={() => { seteditQuestionId(item.id); seteditQuestionText(item.question); }} class="AIDP1icon">
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </span>}
                                            <button className='AIDP1button' onClick={() => { dispatch(toggleAiModal(true)); dispatch(updateAiData(item)); }}>Share</button>
                                            <button className='AIDP1button' onClick={() => { handelSelectTab(4); setselectedAnalyticsQue(item?.id); }}>Report</button>
                                            {/* <span onClick={() => { dispatch(toggleAiModal(true)); dispatch(updateAiData(item)); }} class="AIDP1icon">
                                            <i className="fa fa-link" aria-hidden="true"></i>
                                        </span> */}
                                        </div>}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='AIDP1container2' style={{ flexDirection: "row", justifyContent: "space-between", margin: "0px 0px 5rem 0px", alignItems: "center" }}>
                            <input className='AIDVP1Ai-input_box'
                                style={{ padding: "8px", maxWidth: "55vw" }}
                                value={userQuestionText}
                                id="question-input_box"
                                type="text"
                                placeholder="Write your own question here"
                                onChange={e => setuserQuestionText(e.target.value)}
                            />
                            <button className='GPT-submitBtn' onClick={() => handleAddUserQuestion()}>Add Your Custom Question</button>
                        </div>

                    </div>}

                    {selectedPage === 3 && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDP1container2'>
                            <h2 style={{ margin: "auto", paddingBottom: "1rem" }}>Dashboard</h2>
                            <div style={{ display: "flex" }}>
                                <div className='AIDP1reportAnalyticsContainer'>
                                    <label><label style={{ marginRight: "10px" }}>Insights</label><h3 style={{ marginTop: "10px" }}>{
                                        websiteDataQuestions.reduce((total, question) => {
                                            return total + question.analytics.length;
                                        }, 0)
                                    }</h3></label>
                                </div>
                                <div className='AIDP1reportAnalyticsContainer'>
                                    <label><label style={{ marginRight: "10px" }}>Avg Score </label><h3 style={{ marginTop: "10px" }}>
                                        {toatlAverage}
                                    </h3></label>
                                </div>
                            </div>

                            <div className='AIDP1DashboardContainerItem' style={{ display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                    <div className="AS1row">
                                        <h3>Here's what Duonut</h3>
                                        <label style={{ textAlign: 'left', fontSize: "14px" }}>AI generated from your customers comments </label>
                                    </div>
                                    {!dashboardSummary && <button className='AIDP1button' onClick={() => handelGenerateSummary()}>Generate Summary</button>}
                                </div>
                                {dashboardSummary && <div>{dashboardSummary}</div>}
                            </div>

                            <div className='AIDP1DashboardContainerItem' style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                <h3>Ratings</h3>
                                {ratingData.map(({ rating, percentage }) => {
                                    if (percentage === '0.00') return null;

                                    return <div key={rating} style={{ marginBottom: '10px' }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <div> {rating} </div>
                                            <div> {percentage}% </div>
                                        </div>
                                        <div
                                            style={{
                                                width: `100%`,
                                                height: "10px",
                                                backgroundColor: "#f4f4f5",
                                                borderRadius: "4px",
                                                margin: "5px 0px"
                                            }}>
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: `${percentage}%`,
                                                    height: "10px",
                                                    backgroundColor: "#3184c9",
                                                    borderRadius: "4px",
                                                }}
                                                aria-valuenow={percentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>}

                    {selectedPage === 4 && <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className='AIDP1container2'>
                            <h2 style={{ margin: "auto", paddingBottom: "1rem" }}>Reports</h2>

                            {selectedAnalyticsQue === 0 && <div style={{ margin: "auto" }}>
                                {websiteDataQuestions && websiteDataQuestions.every(item => item.analytics?.length === 0) ? (
                                    <div style={{ margin: "4rem", display: "flex" , flexDirection: "column", alignItems: "center", gap: "10px"}}>
                                        <img style={{ width: '300px' }} src="/assets/report.gif" loading="lazy" />
                                        <div>Reports will be generated after you share questions with your users. Currently, no reports are available.</div>
                                    </div>
                                ) : (websiteDataQuestions?.map((item, index) => {

                                    if (item.analytics?.length === 0) return null;

                                    // Calculate the average NPS score
                                    const totalAnswers = item.analytics.reduce((acc, curr) => acc + parseInt(curr.answer), 0);
                                    let avgScore;
                                    if (item.analytics.length === 0) {
                                        avgScore = 0;
                                    } else {
                                        avgScore = (totalAnswers / item.analytics.length).toFixed(1);
                                    }

                                    return <div className='AIDP1reportContainer' onClick={() => setselectedAnalyticsQue(item?.id)}>
                                        <div>{item?.question}</div>
                                        <div className='AIDP1reportContainerItem'>
                                            <label style={{ display: "flex" }}><b style={{ marginRight: "10px" }}>Insights:</b><h3>{item.analytics.length}</h3></label>
                                            <label style={{ display: "flex" }}><b style={{ marginRight: "10px" }}>Avg Score: </b><h3>{avgScore}</h3></label>
                                        </div>
                                    </div>
                                }))}
                            </div>}
                            {selectedAnalyticsQue > 0 && <div style={{ width: "100%" }}>
                                <div className='ADP1backBtn' style={{ padding: "0", alignItems: "unset", margin: '1rem 0rem' }} onClick={() => { setselectedAnalyticsQue(0); setshowConversation(0); }}>
                                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back
                                </div>
                                <h2>{aiDuonutAnalytics.question}</h2>
                                <div style={{ display: "flex" }}>
                                    <div className='AIDP1reportAnalyticsContainer'>
                                        <label>Insights<h3>{aiDuonutAnalytics.analytics.length}</h3></label>
                                    </div>
                                    <div className='AIDP1reportAnalyticsContainer'>
                                        <label>Avg Score <h3>{aiDuonutAnalytics?.analytics?.length === 0 ? 0 : (aiDuonutAnalytics.analytics?.reduce((acc, curr) => acc + parseInt(curr.answer), 0) / aiDuonutAnalytics.analytics.length).toFixed(1)}</h3></label>
                                    </div>
                                </div>

                                <ul class="AIDP1question-list">
                                    {aiDuonutAnalytics?.analytics?.map((item, index) => (
                                        <li class="AIDP1analytics-item-container" key={index}>
                                            <div class="AIDP1analytics-item">
                                                <label>{item.name ? item.name : "Anonymous"}</label>
                                                <label style={{ display: "flex" }}><label style={{ marginRight: "10px" }}>Score </label><h3>{item.answer}</h3></label>
                                                <label style={{ cursor: "pointer" }} onClick={() => { (showConversation === 0 || showConversation !== item.id) ? setshowConversation(item.id) : setshowConversation(0) }}>
                                                    See full conversation
                                                    <svg aria-hidden="true" style={{ height: "1rem", padding: "0px 10px", verticalAlign: "middle", transform: (showConversation === 0 || showConversation !== item.id) ? "rotate(0deg)" : "rotate(180deg)" }} focusable="false" data-prefix="fal" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"></path></svg>
                                                </label>
                                            </div>
                                            {showConversation === item.id && <div className='AIDVP1chat-container'>
                                                {item?.ai_conversation?.map((item, index) => (
                                                    <div key={index} className={`AIDVP1message-row ${item.sender}`}>
                                                        <img
                                                            src={item.sender === "bot" ? "/assets/duonut_icon.png" : "/assets/user.png"}
                                                            alt={`${item.sender}-avatar`}
                                                            className="AIDVP1avatar"
                                                        />
                                                        <div className="AIDVP1message">
                                                            {item.message.split('\n').map((str, idx, arr) => {
                                                                // Regex to capture [text](url), plain URLs, and **bold text** excluding links within **
                                                                const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)|(https?:\/\/[^\s]+)|(\*\*(?!\[.*\]\(.*\))([^*]+)\*\*)/g;

                                                                const parts = [];
                                                                let lastIndex = 0;
                                                                let match;

                                                                while ((match = linkRegex.exec(str)) !== null) {
                                                                    // Add text before the link
                                                                    if (match.index > lastIndex) {
                                                                        parts.push(str.substring(lastIndex, match.index));
                                                                    }

                                                                    if (match[1] && match[2]) {
                                                                        // Markdown style link [text](url)
                                                                        parts.push(
                                                                            <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
                                                                                {match[1]}
                                                                            </a>
                                                                        );
                                                                    } else if (match[3]) {
                                                                        // Plain URL
                                                                        parts.push(
                                                                            <a key={match.index} href={match[3]} target="_blank" rel="noopener noreferrer">
                                                                                {match[3]}
                                                                            </a>
                                                                        );
                                                                    } else if (match[4]) {
                                                                        // Bold text **text** (only when it's not around a link)
                                                                        parts.push(<strong key={match.index}>{match[4]}</strong>);
                                                                    }

                                                                    lastIndex = match.index + match[0].length;
                                                                }

                                                                // Add any remaining text after the last link
                                                                if (lastIndex < str.length) {
                                                                    parts.push(str.substring(lastIndex));
                                                                }

                                                                // Check if the parts array is empty (i.e., no text or links), skip rendering <p> if true
                                                                if (parts.length === 0) return null;

                                                                // return <p key={idx}>{parts}</p>;
                                                                return (
                                                                    <React.Fragment key={idx}>
                                                                        <p>{parts}</p>
                                                                        {idx < arr.length - 1 && <br />}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>}
                                        </li>
                                    ))}
                                </ul>
                            </div>}


                        </div>

                    </div>}

                </div>

                <AiSetting />
            </div>
        </div>
    );

}

export default AiData;