import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";

// const axios = require("axios");
import axios from 'axios';

const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};

export const duonutSlide = createSlice({
  name: "duonut",
  initialState: {
    response: [],
    data: [],
    analyticsresponse: [],
    viewer_display_id: "",
    viewer_nick_name: "",
    save_response: [],
    save_form_data: {},
  },
  reducers: {
    saveDuonutData: (state, action) => {
      state.data = [action.payload];
    },
    saveQuizAns: (state, action) => {
      state.response = [action.payload];
    },
    saveAnalytics: (state, action) => {
      state.analyticsresponse = [action.payload];
    },
    viewerDisplayId: (state, { payload }) => {
      state.viewer_display_id = payload;
    },
    viewerNickName: (state, { payload }) => {
      state.viewer_nick_name = payload;
    },
    saveQuizResponse: (state, action) => {
      const { id, type, step, question, answered_text } = action.payload;
      const existingResponseIndex = state.save_response.findIndex(response =>
        response.id === id && response.type === type && response.step === step && response.question === question
      );

      if (existingResponseIndex !== -1) {
        // If entry already exists, update it
        state.save_response[existingResponseIndex] = { id, type, step, question, answered_text };
      } else {
        // Otherwise, add a new entry
        state.save_response = [...state.save_response, { id, type, step, question, answered_text }];
      }
    },
    saveFormData: (state, action) => {
      var mergeFormData = { ...state.save_form_data, ...action.payload }
      // console.log(mergeFormData);

      // Filter out duplicate values
      var filteredMergeFormData = Object.fromEntries(
        Object.entries(mergeFormData)
          .reverse()  // Reverse the order to keep the latest values
          .filter(([key, value], index, self) => self.findIndex(([k, v]) => k === key) === index)
      );

      state.save_form_data = filteredMergeFormData;
    },
  }
});

export const createDuonutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    // const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_duonut`;
    const response = await axios.post(API_URL, data);
    // console.log(response);
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const editDuonutAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    // const header = getAuthHeaders();
    const API_URL = `${domain}/channel/edit_channel`;
    const response = await axios.patch(API_URL, data);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const getDuonutAsync = (paramsid) => async (dispatch) => {
  try {
    const display_id = paramsid.id;
    // console.log(display_id)
    // const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/viewer/` + display_id;
    const response = await axios.get(API_URL);
    // console.log(response.data);
    dispatch(saveDuonutData(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postquizAsync = (data, paramsid) => async (dispatch) => {
  try {
    // console.log(data);
    const id = paramsid.id;
    // console.log(id)
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/viewer/` + id;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveDuonutData(response.data));
    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const saveQuizAnsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/save_answer_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveQuizAns(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const saveAnalyticsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/save_analytics_data`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveQuizAns(response.data)); ///because result and analytics have same watvh_id
    // dispatch(saveAnalytics(response.data));
  } catch (err) {
    throw new Error(err);
  }
};


export const { saveDuonutData, saveQuizAns, saveAnalytics, viewerDisplayId, viewerNickName, saveQuizResponse, saveFormData } = duonutSlide.actions;
export const showDuonutresponse = (state) => state.duonut.response;
export const showDuonutAnalyticsresponse = (state) => state.duonut.analyticsresponse;
export const showduonutData = (state) => state.duonut.data;
export default duonutSlide.reducer;
