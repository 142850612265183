import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';


const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};


export const nuggetsSlide = createSlice({
  name: "nuggets",
  initialState: {
    response: []
  },
  reducers: {
    saveNuggets: (state, action) => {
      state.response = [action.payload];
    },
  }
});

export const getNuggetsAsync = (paramsid) => async (dispatch) => {
  try {
    const id = paramsid.id;
    // console.log(id)
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_nugget_detail/` + id;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveNuggets(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const saveNuggetsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_nugget`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editNuggetsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_nugget`;
    const response = await axios.patch(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteNuggetsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_nugget`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const saveNuggetsMappingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_nugget_mapper`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editNuggetsMappingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_nugget_mapper`;
    const response = await axios.patch(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteNuggetsMappingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_nugget_mapper`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const editNuggetThumbnailAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/edit_nugget_thumbnail`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const updateNuggetPricingAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/nugget_pricing`;
    const response = await axios.patch(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveNuggets } = nuggetsSlide.actions;
export const showNuggetsresponse = (state) => state.nuggets.response;
export default nuggetsSlide.reducer;
