import { createSlice } from "@reduxjs/toolkit";

//sliders in design page
//when the user changes activity categories, activity subtypes and themes of each subtype of its activity type will be fetched
//each subtype will have key 'activityTypeId'

//trending themes will be received from backend
//We will also get activityType and subtype of each trending Theme.

const initialState = {
  changeDuonutNameModal: {
    active: false,
    dname: "",
    defaultdname: "",
    navigateurl: "",
  }
};

//updateSubtypes will be dispatched whenever the category is changed or the selected activity types changes.

const DuonutName = createSlice({
  name: "DuonutName",
  initialState,
  reducers: {
    toggleChangeDuonutNameModal: (state, { payload }) => {
      state.changeDuonutNameModal.active = payload;
    },
    updateDuonutNameToReplace: (state, { payload }) => {
      state.changeDuonutNameModal.dname = payload;
    },
    updateDuonutDefaultName: (state, { payload }) => {
      state.changeDuonutNameModal.defaultdname = payload;
    },
    updateDuonutNavigateUrl: (state, { payload }) => {
      state.changeDuonutNameModal.navigateurl = payload;
    },
  },
});

export const { toggleChangeDuonutNameModal, updateDuonutNameToReplace, updateDuonutDefaultName, updateDuonutNavigateUrl } = DuonutName.actions;
export default DuonutName.reducer;
