import React, { useRef, useEffect, useState } from 'react';
import { frontendUrl, backendUrl } from "../../../services/constants";
import './QrCodeModal.css';
import "../../../assets/bootstrap.min.css";
// import "../../../assets/bootstrap.bundle.min.js";
import { useNavigate } from 'react-router-dom';
import * as FileSaver from 'file-saver';

const QrCodeModal = (props) => {
    // console.log(props);
    const duonutName = props.shareData.duonut_name;
    const displayId = props.shareData.display_id;
    const password = props.shareData.password;
    const message = props.shareData.message;
    const qrcode = props.shareData.qrcode;
    const qrcodeUrl = `${backendUrl}${qrcode}`;
    const qrref = useRef(null);
    const qrref1 = useRef(null);
    const [iscopy, setiscopy] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            qrref.current.click();
        }, 100); //miliseconds

        setTimeout(() => {
            qrref1.current.click();
        }, 300000); //miliseconds
    }, []);


    const continuebtn = () => {
        qrref1.current.click();
        setiscopy(false);
    }
    const downloadbtn = () => {
        // const img = new Image();
        // // img.crossOrigin = 'Anonymous';
        // img.onload = function () {
        //     const canvas = document.createElement('canvas');
        //     canvas.width = img.width;
        //     canvas.height = img.height;
        //     const ctx = canvas.getContext('2d');
        //     ctx.drawImage(img, 0, 0);
        //     const link = document.createElement('a');
        //     link.download = `qrcode-${displayId}.png`;
        //     link.href = canvas.toDataURL('image/png');
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // };
        // img.src = qrcode;

        let url;
        if (qrcodeUrl?.toString().includes("https")) {
            url = qrcodeUrl;
        } else {
            url = qrcodeUrl.toString().replace("http", "https");
        }

        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                FileSaver.saveAs(blob, `qrcode-${displayId}.png`);
            })
            .catch(error => console.log(error));
    }

    const viewbtn = () => {
        qrref1.current.click();
        window.open('/duonut/' + displayId);
        // window.location.reload();
    }

    return (
        <><button ref={qrref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#qrcodeModal${displayId}`}>
        </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id={`qrcodeModal${displayId}`} tabIndex="-1" aria-labelledby="qrcodeModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content QRC1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={qrref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            {/* <div>{duonutName}{message}</div> */}

                            <div className='PM1shareTitle'> Scan QR Code and view this Duonut</div>
                            {/* <br></br> */}
                            <img className='qrcodeImage' src={qrcodeUrl} alt="" />
                            <button className='QRC1Continuebtn' onClick={() => downloadbtn()}>Download</button>
                            <button className='QRC1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div></>
    );

}

export default QrCodeModal;