import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';

export default function LabelFontWeight() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [labelfontWeight, setLabelFontWeight] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        styles?.labelFontWeight ?
            setLabelFontWeight(styles.labelFontWeight) : setLabelFontWeight(400)
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity]])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleFontWeightChange = e => {
        // const value = e.target.value

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    labelFontWeight: e.target.value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Label Font Weight</p>
            <select value={labelfontWeight} className='text_editor_dropdown' onChange={e => handleFontWeightChange(e)} >
                {labelFontWeights.map(labelfontWeight =>
                    <option key={labelfontWeight} value={labelfontWeight} > {labelfontWeight} </option>
                )}
            </select>
            {/* <img src='/assets/icons/down-arrow.svg' className='select-icon' /> */}
        </div>
    )
}

const labelFontWeights = [
    100, 200, 300, 400, 500, 600, 700, 800
]