import axios from "axios";
import { refreshUserToken } from "./auth";
import { updateIsLoggedIn } from "../redux/slices/user";
import { store } from '../redux/store';

const { dispatch } = store;
// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        // console.log(originalRequest);

        if (
            error.response.status === 401 &&
            originalRequest.url.includes("account/login/refresh")
        ) {
            // console.log("account/login/refresh")
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            dispatch(updateIsLoggedIn(false));
            // router.push("/login");
            // originalRequest.headers.Authorization = `Bearer ${null}`;
            return Promise.reject(error);
        }
        else if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const credentials = { refresh: localStorage.getItem("refresh") };
            refreshUserToken(credentials, (err, res) => {
                // if (err) return handleError(err.response);
                if (res !== null) {
                    // console.log(res.access);
                    originalRequest.headers.Authorization = `Bearer ${res.access}`;
                    localStorage.removeItem("access");
                    localStorage.setItem("access", res.access);
                    return axios(originalRequest);

                }
            });
        }
        return Promise.reject(error);
    }
);