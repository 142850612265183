import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { togglechangePasswordModal, toggleEnterCodeModal } from "../../../redux/slices/formDetails";
import { requestPasswordResetEmail, verifyEmailCode } from "../../../services/auth";
import Input from "../../Input/input";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export default function EnterCode({ setEmailSignUpActive }) {
  const [code, setCode] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isResending, setIsResending] = useState(false)
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const { email, fromRegister } = useSelector((state) => state.formDetails.enterCodeForm);
  // console.log(email);
  // console.log(fromRegister);
  const handleClose = () => {
    dispatch(toggleEnterCodeModal({ fromRegister: false }));
  };

  const handleClick = () => {
    if (code === '') {
      return setErrorMsg("This field can't be empty.");
    }
    setSuccessMsg("");
    verifyEmailCode({ email, code }, (err, res) => {
      if (err) {
        // console.log(err.response);
        setSuccessMsg("");
        setErrorMsg("Incorrect Code. Please check for typos.");
        return;
      }
      if (fromRegister) {
        setEmailSignUpActive(true);
      } else {
        dispatch(togglechangePasswordModal());
      }
      setSuccessMsg("");
      handleClose();
    });
  };

  const handleResend = () => {
    setIsResending(true)
    requestPasswordResetEmail({ email }, (err, res) => {
      setIsResending(false)
      //if == message, then registered
      if (Object.keys(res)[0] === "detail") {
        setSuccessMsg("Code resent to your email");
        setErrorMsg("");
      } else {
      }
    });
  };

  return (
    <div className="modal_container modal_container-enter-code">
      <div className="modal_basic">
        <div>
          <div className="modal_header" style={width > 800 ? { textAlign: "center" } : {}}>
            <div className="modal_back_btn icon-button" onClick={handleClose}>
              {`<`}
            </div>
            Check your email
          </div>

          {successMsg !== '' && <p className="modal_success_msg mb-3">{successMsg}</p>}

          <p className="modal_text flex" style={width > 800 ? { textAlign: "center" } : {}}>We've sent a <span style={{ color: "#3498db" }}>6-digit verification code</span> to <span style={{ color: "#3498db" }}> {email}</span>. If you don't see it, please check your spam folder and make sure your email address is spelled correctly.
            {/* Please enter the verification code we sent to your email id within 10 minutes. */}
          </p>
          <Input placeholder="Verification Code" value={code} setValue={setCode} errorMsg={errorMsg} isOtpCode={true} />
          <button className="btn-primary full-width modal_submit_btn" onClick={handleClick}>
            Continue
          </button>
        </div>
        <div className="close-icon icon-button" onClick={handleClose}>
          <img src="/assets/icons/close.svg" />
        </div>
        <div className="modal_footer d-flex mt-3">
          <p className="color-light-gray">Didn’t get the code?</p>
          <span className="resend-code-btn" style={{ color: "#3498db" }} onClick={handleResend} id={isResending ? 'disabled' : ''} >
            Resend Code
          </span>
        </div>
      </div>
      <div className="modal-overlay"></div>
    </div >
  );
}
