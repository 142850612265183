import React, { useEffect, useState, useRef } from "react";
import "../../RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import { SketchPicker } from "react-color";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import { updateUserActivity } from '../../../../redux/slices/userActivity';

const SlideBGColorPicker = ({ isActive, handleOpen, handleClose }) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const [color, setColor] = useState("#ffffff");
    const colorPickerRef = useRef(null);


    useOutsideAlerter(colorPickerRef, handleClose);

    useEffect(() => {
        if (userActivities[selectedUserActivity]) {
            let backgroundColor = userActivities[selectedUserActivity].backgroundColor;
            setColor(backgroundColor);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    // console.log(userActivities[selectedUserActivity].theme_id.styles.styles.question)
    const handleChange = e => {
        // const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        const updatedColor = e.hex;
        setColor(updatedColor);

        if (userActivities[selectedUserActivity]) {
            dispatch(
                updateUserActivity({
                    backgroundColor: updatedColor,
                })
            );
        }
    }

    const handleColorChange = e => {
        // const updatedColor = `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`;
        const updatedColor = e.target.value;
        const hexPattern = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;

        if (hexPattern.test(updatedColor) || updatedColor === '') {
            setColor(updatedColor);

            if (userActivities[selectedUserActivity]) {
                dispatch(
                    updateUserActivity({
                        backgroundColor: updatedColor,
                    })
                );
            }
        }

    }

    return (<>
        <div
            className="color-select"
            onClick={() => handleOpen()}
            style={{ backgroundColor: color, borderRadius: "5px 0px 0px 5px" }}
        >
            {isActive && (
                <div
                    className="color-picker"
                    ref={colorPickerRef}
                // style={{
                //     ...(width <= 1275 && { position: "fixed" }),
                //     ...(width <= 1275 && { top: colorEditorPos.y + 50 }),
                //     ...(width <= 1275 && { left: colorEditorPos.x - 220 }),
                //     ...(width <= 1275 && { position: "fixed" }),
                // }}
                >
                    <SketchPicker
                        color={color}
                        onChangeComplete={(e) => handleChange(e)}
                    />
                </div>
            )}
        </div>
        <div>
            <input type="text" value={color} id='slide-bg-color'
                onFocus={() => { setTimeout(() => document.querySelector(`#slide-bg-color`).select(), 100); }}
                style={{ boxShadow: "0px 0px 1.5px 0px", borderRadius: "0px 5px 5px 0px", textAlign: "left", width: "100%", padding: "5px", paddingLeft: "10px" }}
                onChange={(e) => (handleColorChange(e))} />
        </div>
    </>
    );
}

export default SlideBGColorPicker;