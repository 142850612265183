import React, { useEffect } from 'react'
import './Payment.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Payment() {
    const paramsid = useParams();
    const userPlan = paramsid.id

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lifetime = queryParams.get('lifetime');
    const monthly = queryParams.get('monthly');
    // console.log(monthly)
    // const stripePromise = loadStripe('pk_test_51NutiDEk8UBACGNAzKQkpS1J47USZhlp1RUXXxZ3xb0mw7FxDXAqFIcD0uGr9RSeVxIuPW9wE6DML7PBu8oeni2000CDZeI96t');
    const stripePromise = loadStripe('pk_live_51NutiDEk8UBACGNAdK130youGlffnDHS4eAKSgjwPFz5cuZu6cIS45kBYV8A7yASitlPEenrPoCrFN9Udo1Cd3Qr005vBgy6ZI');

    useEffect(() => {
        // console.log("userEmail", userEmail)
        if (userPlan === "pro" || userPlan === "plus") {
        } else {
            window.location = "/";
        }
    }, [userPlan]);

    const options = {
        mode: 'payment',
        amount: userPlan === "plus" ? lifetime ? 159900 : monthly === "true" ? 9900 : 106800 : lifetime ? 79900 : monthly === "true" ? 4900 : 52800,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };



    return (
        <Elements stripe={stripePromise} options={options}>
            <PaymentForm userPlan={userPlan} lifetime={lifetime} monthly={monthly} />
        </Elements>
    )
}
