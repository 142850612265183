import React, { useState, useEffect, useRef } from 'react';
import './Schedules.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateScheduledWorkflow } from "../../redux/slices/duonutSettings";
import { domain } from "../../services/constants";
import { useGoogleLogin } from '@react-oauth/google';
import { getUserDetails } from "../../services/auth";
import { updateGmailKey } from "../../redux/slices/user";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Schedules = () => {
    const dispatch = useDispatch();
    const { width, height } = useWindowDimensions();
    const navigate = useNavigate();
    const { userProAccess } = useSelector((state) => state.user);
    const { gmail_integration, scheduled_workflow } = useSelector(state => state.duonutSettings);
    const { gmail_key } = useSelector((state) => state.user);
    const { selectedUserActivity, userActivities, textFormData } = useSelector((state) => state.userActivity);
    const [clickAuthButton, setclickAuthButton] = useState(0);
    const [duonutConnected, setduonutConnected] = useState(false);
    const [accessKey, setaccessKey] = useState("");
    const [refreshKey, setrefreshKey] = useState("");
    const [isSave, setisSave] = useState(false);
    const [selectedTrigger, setselectedTrigger] = useState("");
    const [toEmail, settoEmail] = useState("");
    const [subject, setsubject] = useState("");
    const [message, setmessage] = useState("");
    const [suggestedVar, setSuggestedVar] = useState([]);
    const [suggestedVar2, setSuggestedVar2] = useState([]);
    const [suggestedVar3, setSuggestedVar3] = useState([]);
    const [popUpPositionX, setPopUpPositionX] = useState(null)
    const [popUpPositionY, setPopUpPositionY] = useState(null)
    const suggestionRef = useRef(null)
    const suggestionRef2 = useRef(null)
    const suggestionRef3 = useRef(null)
    const [startDate, setStartDate] = useState(new Date());
    const [run, setRun] = useState('');
    const [name, setName] = useState('');

    // useEffect(() => {
    //     if (!userProAccess) {
    //         // Extract the current URL
    //         const currentURL = window.location.href;

    //         // Remove the last segment (in this case, 'reports') from the URL
    //         const newURL = currentURL.substring(0, currentURL.lastIndexOf('/'));

    //         // Navigate to the new URL
    //         window.location = newURL;
    //     }
    // }, []);

    console.log("textFormData", textFormData);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(
                'https://oauth2.googleapis.com/token',
                new URLSearchParams({
                    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your client ID
                    client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET, // Replace with your client secret
                    refresh_token: refreshToken,
                    grant_type: 'refresh_token',
                })
            );
            console.log(response.data)
            setaccessKey(response.data.access_token);
            // return response.data.access_token;
        } catch (error) {
            console.error('Error refreshing access token', error);
            throw error;
        }
    };

    useEffect(() => {
        if (scheduled_workflow?.access_key) {
            // console.log("access")
            setaccessKey(scheduled_workflow.access_key);
            setrefreshKey(scheduled_workflow.refresh_key);
            setselectedTrigger(scheduled_workflow.trigger);
            settoEmail(scheduled_workflow.to);
            setsubject(scheduled_workflow.subject);
            setmessage(scheduled_workflow.message);
            setStartDate(scheduled_workflow.start_date);
            setRun(scheduled_workflow.run);
            setName(scheduled_workflow.name);
            setclickAuthButton(2);
            setduonutConnected(true);

        } else if (gmail_key) {
            refreshAccessToken(gmail_key);

            setrefreshKey(gmail_key);
            setselectedTrigger("");
            settoEmail("");
            setsubject("");
            setmessage("");
            setStartDate("");
            setRun("");
            setName("");
            setclickAuthButton(2);
        } else {
            setaccessKey("");
            setrefreshKey("");
            setselectedTrigger("");
            settoEmail("");
            setsubject("");
            setmessage("");
            setStartDate("");
            setRun("");
            setName("");
            setclickAuthButton(0);
            setduonutConnected(false);
        }
    }, []);


    const handleSaveGmail = (e) => {
        e.preventDefault();

        setisSave(true);
        setTimeout(() => {
            setisSave(false);
            setduonutConnected(true);
        }, 3000);

        const scheduledData = {
            access_key: accessKey,
            refresh_key: refreshKey,
            trigger: selectedTrigger,
            to: toEmail,
            subject: subject,
            message: message,
            start_date: startDate,
            run: run,
            name: name,
        }
        console.log(scheduledData);
        dispatch(updateScheduledWorkflow(scheduledData));

    };

    const handleDisconnect = () => {
        const scheduledData = {}
        dispatch(updateScheduledWorkflow(scheduledData));
        setselectedTrigger("");
        settoEmail("");
        setsubject("");
        setmessage("");
        setStartDate("");
        setRun("");
        setName("");
        setduonutConnected(false);
    }

    const handleClosesug = () => setSuggestedVar([])
    const handleClosesug2 = () => setSuggestedVar2([])
    const handleClosesug3 = () => setSuggestedVar3([])
    useOutsideAlerter(suggestionRef, handleClosesug);
    useOutsideAlerter(suggestionRef2, handleClosesug2);
    useOutsideAlerter(suggestionRef3, handleClosesug3);

    const handleChangeToEmail = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar([]);
        }

        settoEmail(e.target.value);
    };

    const handleChooseSuggestion = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = toEmail;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar([]);

        settoEmail(updatedValue);
    };

    const handleChangeSubject = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar2(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar2([]);
        }

        setsubject(e.target.value);
    };

    const handleChooseSuggestion2 = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = subject;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar2([]);

        setsubject(updatedValue);
    };

    const handleChangeMessage = (e) => {

        const inputValue = e.target.value;

        // Check if "@" is entered
        const atIndex = inputValue.indexOf("@");
        if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
            // Extract the text after "@" until the cursor position
            const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

            // Filter formData keys that match the entered text
            const matchingKeys = Object.keys(textFormData).filter((key) =>
                key.toLowerCase().includes(textAfterAt.toLowerCase())
            );

            const cursorRect = e.target.getBoundingClientRect();
            // console.log(e, cursorRect)

            setPopUpPositionX(cursorRect.width);
            setPopUpPositionY(cursorRect.height);

            // Display suggestions
            setSuggestedVar3(matchingKeys);
        } else {
            // Clear suggestions if "@" is not present
            setSuggestedVar3([]);
        }

        setmessage(e.target.value);
    };

    const handleChooseSuggestion3 = (selectedSuggestion) => {
        // console.log("selectedSuggestion", selectedSuggestion)
        // Get the current input value
        const currentValue = message;
        // console.log("currentValue", currentValue)

        // Find the position of "@" in the current value
        const atIndex = currentValue.indexOf("@");

        // Replace the text after "@" until the cursor position with the selected suggestion
        const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

        // Clear suggestions
        setSuggestedVar3([]);

        setmessage(updatedValue);
    };

    return (
        <div className='SP1App'>
            <div className='SP1template'>

                <div style={{ padding: "1rem", fontSize: '1.2rem', fontWeight: '600' }}>Run workflow on a scheduled date</div>


                {clickAuthButton === 0 && <div>Go to Integration and Please Authorize Gmail.</div>}

                {clickAuthButton === 2 && <form onSubmit={handleSaveGmail}>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Name the workflow </label>
                        <input type="text" className="gmail_textbox" value={name}
                            onChange={(e) => setName(e.target.value)} required />
                    </div>

                    <div className='custom-date-picker-container' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Start date and time </label>
                        <div>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                dateFormat="Pp"
                                required
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Run </label>
                        <select id="runSelect" required style={{ padding: "10px", width: "21.4rem", cursor: "pointer" }}
                            value={run} onChange={(e) => setRun(e.target.value)}>
                            <option selected disabled style={{ display: "none", color: "#44474d" }} value="">select when Run </option>
                            <option style={{ padding: "5px" }} value="once">Once</option>
                            <option style={{ padding: "5px" }} value="daily">Daily</option>
                            <option style={{ padding: "5px" }} value="weekly">Weekly</option>
                            <option style={{ padding: "5px" }} value="monthly">Monthly</option>
                            <option style={{ padding: "5px" }} value="yearly">Yearly</option>
                        </select>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Select Trigger </label>
                        <select id="mySelect" required style={{ padding: "10px", width: "21.4rem", cursor: "pointer" }}
                            value={selectedTrigger} onChange={(e) => setselectedTrigger(e.target.value)}>
                            <option selected disabled style={{ display: "none", color: "#44474d" }} value="">Select Trigger</option>
                            <option style={{ padding: "5px" }} value="Form Completed">Form Completed</option>
                            {/* <option style={{ padding: "5px" }} value="Step Loaded">Step Loaded</option>
                            <option style={{ padding: "5px" }} value="File Submitted">File Submitted</option> */}
                        </select>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>To </label>
                        <div style={{ position: "relative", display: "flex" }}>
                            <textarea
                                rows="2"
                                className="gmail_textbox"
                                value={toEmail === null ? "" : toEmail}
                                onChange={(e) => (handleChangeToEmail(e))}
                                required
                                placeholder="use @ for getting Field"
                            ></textarea>
                            {suggestedVar.length > 0 && (
                                <div ref={suggestionRef} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                    <div>Recall information from...</div>
                                    {suggestedVar.map((item, i) => {
                                        return <div key={i}
                                            style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                            onClick={() => handleChooseSuggestion(item)}>{item}</div>
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Subject </label>
                        <div style={{ position: "relative", display: "flex" }}>
                            <textarea
                                rows="2"
                                className="gmail_textbox"
                                value={subject === null ? "" : subject}
                                onChange={(e) => (handleChangeSubject(e))}
                                required
                                placeholder="use @ for getting Field"
                            ></textarea>
                            {suggestedVar2.length > 0 && (
                                <div ref={suggestionRef2} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                    <div>Recall information from...</div>
                                    {suggestedVar2.map((item, i) => {
                                        return <div key={i}
                                            style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                            onClick={() => handleChooseSuggestion2(item)}>{item}</div>
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "1rem" }}>
                        <label>Message </label>
                        <div style={{ position: "relative", display: "flex" }}>
                            <textarea
                                rows="3"
                                className="gmail_textbox"
                                value={message === null ? "" : message}
                                onChange={(e) => (handleChangeMessage(e))}
                                required
                                placeholder="use @ for getting Field"
                            ></textarea>
                            {suggestedVar3.length > 0 && (
                                <div ref={suggestionRef3} style={{ position: "absolute", backgroundColor: "white", transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`, padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999" }}>
                                    <div>Recall information from...</div>
                                    {suggestedVar3.map((item, i) => {
                                        return <div key={i}
                                            style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
                                            onClick={() => handleChooseSuggestion3(item)}>{item}</div>
                                    })}
                                </div>
                            )}
                        </div>
                    </div>

                    <button className="SlackIntegrationButton"
                        style={{ marginTop: "2rem", marginBottom: "2rem", float: "right", backgroundColor: "#DB615C", border: "1px solid #DB615C" }}
                        type="submit">{duonutConnected ? isSave ? "Updating" : "Update" : isSave ? "Saved" : "Save"}</button>
                </form>
                }

                {clickAuthButton === 2 && duonutConnected && <button type="submit" className="SlackIntegrationButton"
                    style={{ margin: "2rem", float: "right", color: "#e5251e", background: "transparent", border: "1px solid #DB615C" }}
                    onClick={() => handleDisconnect()}>
                    <i className="fa fa-trash-o" aria-hidden="true" style={{ marginRight: "10px" }}></i>
                    Disconnect</button>}
            </div>
        </div>
    );

}

export default Schedules;