import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleEnterCodeModal, toggleforgotPassword, updateEnterCodeEmail, updateResetPasswordEmail } from "../../../redux/slices/formDetails";
import { requestPasswordResetEmail } from "../../../services/auth";
import Input from "../../Input/input";
import { validateEmail } from "./utils";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = () => {
    dispatch(toggleforgotPassword());
  };

  const handleClick = () => {
    if (email === "") return setErrorMsg("Please enter your email address");
    const result = validateEmail(email);
    if (result === null) {
      setErrorMsg("Please enter your email address in format : xyz@gmail.com");
      return;
    }

    requestPasswordResetEmail({ email }, (err, res) => {
      //if == message, then registered
      if (err) return setErrorMsg("Email is not registered!");

      handleClose();
      dispatch(toggleEnterCodeModal({ fromRegister: false }));
      dispatch(updateEnterCodeEmail(email));
      dispatch(updateResetPasswordEmail(email));
    });
  };

  return (
    <div className="modal_container forgot_password_modal_container">
      <div className="modal_basic">
        <div>
          <div className="modal_header">
            <div className="modal_back_btn icon-button" onClick={handleClose}>
              {`<`}
            </div>
            Forgot Your Password
          </div>
          <p className="modal_text">Don’t worry, enter your email address and we will send you a mail to reset your password.</p>
          <Input placeholder="Email" value={email} setValue={setEmail} errorMsg={errorMsg} />
          <button className="btn-primary full-width modal_submit_btn" onClick={handleClick}>
            Continue
          </button>
        </div>
        <div className="close-icon icon-button" onClick={handleClose}>
          <img src="/assets/icons/close.svg" />
        </div>
      </div>
      <div className="modal-overlay"></div>
    </div>
  );
}
