import React, { useState } from 'react';
import './SlackHelp.css';
// import backgroundImg from '../../assets/Images/bg-bottom-hero.png';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import YouTube from "react-youtube";

const SlackHelp = () => {
    const { width, height } = useWindowDimensions();
    const [selected, setSelected] = useState(0);
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
            playsinline: 1
        }
    };

    return (
        <div className='HSK1App'>
            <div className='HSK1template'>
                <h1 style={{ fontSize: "2.5rem", padding: "2rem 0rem" }}>How to Integrate Duonut with Slack</h1>
                <div>
                    <a href='https://www.slack.com' target="_blank">Slack</a> is the most versatile collaboration platform that boasts impressive features that keep teams productive and up-to-date with their projects, tasks, and decisions.
                </div>
                <br></br>
                <div>
                    The integration for Slack on Duonut sends your form and workflow submissions to Slack automatically, instantly creating updates on your Slack workspace. Now you can focus on getting the work done.

                </div>
                <br></br>

                <div>
                    The integration for Slack is easy to set up. Let’s start!
                </div>
                <br></br>
                <div>
                    1.  After login to Duonut, go to My workflow option located on the right top or go to the link - <a href='https://duonut.com/workflow' target="_blank">https://duonut.com/workflow</a> -{'>'} Select the activity you want to get completion notification for in Slack or create a new activity -{'>'} Click on Edit.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp1.png" alt="" />
                </div>
                <br></br>
                <div>Clicking on Edit goes to the /design page. The page looks something like this:</div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp2.png" alt="" />
                </div>

                <br></br>
                <div>
                    2.  Click on the <b>Integrations</b> tab -{'>'} Click on the Slack.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp3.png" alt="" />
                </div>

                <br></br>
                <div>
                    3.  Click on the <b>Authenticate </b> button.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp4.png" alt="" />
                </div>

                <br></br>
                <div>
                    4.  You’ll be asked to log in to Slack and give Duonut permission to access your workspace. This is also where you'll also be able to select the Slack channel you'd like to send form/survey submission notifications to. Once you've chosen your channel from the dropdown menu, click Allow.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp5.png" alt="" />
                </div>

                <br></br>
                <div>
                    Now that you've given Slack permission to send notifications to your workplace, you'll be redirected back to your design page where you'll notice within the modal that Slack is actively connected to your form. Please note it <b>Autosaves</b> the configuration.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp6.png" alt="" />
                </div>

                <br></br>
                <div>
                    <b>Note:</b> if you ever want to turn off Slack notifications for this form or activity, simply click Slack within the Integrations tab and click on the <b>Revoke</b> button. You can also delete notifications to a particular channel by clicking on the <b>Disconnect Channel</b> button.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp7.png" alt="" />
                </div>

                <br></br>
                <div>
                    5.  Head over to your Slack app—you’ll see a notification from Duonut that someone has added the Duonut integration to the channel.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp8.png" alt="" />
                </div>

                <br></br>
                <div>
                    6.  Now sit back and watch the form submissions roll in.
                </div>
                <br></br>
                <div className='HSK1section-1'>
                    <img className='HSK1section-1-image' src="/assets/help/slackhelp9.png" alt="" />
                </div>
                <br></br>

                <div>Here is the detailed video which shows the steps of authenticating, till the user receives the updates on their Slack channel when the activity is taken by someone.
                </div>
                <br></br>
                <YouTube
                    videoId="2BurkXBwFOs"
                    id='player'
                    containerClassName="embed embed-youtube"
                    opts={opts}
                    className='HSK1section-1'
                    style={{ height: "20rem" }}
                />
                <br></br>
                <div>That’s it! Your Duonut will do all the work as your team members collaborate on getting things done.</div>
                <br></br>
            </div>
            <HomePageFooter />
        </div>
    );

}

export default SlackHelp;