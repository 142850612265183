import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';

// const axios = require("axios");

const getAuthHeaders = () => {
    const token = localStorage.getItem("access");
    let header = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return header;
};

export const duonutreportSlide = createSlice({
    name: "duonutreport",
    initialState: {
        data: [],
        analyticsdata: [],
    },
    reducers: {
        getduonutreport: (state, action) => {
            state.data = [action.payload];
        },
        getduonutanalytics: (state, action) => {
            state.analyticsdata = [action.payload];
        }
    }
});

export const getduonutreportAsync = (duonut_id) => async (dispatch) => {
    try {
        const header = getAuthHeaders();
        var API_URL;
        API_URL = `${domain}/duonut/report_per_duonut?duonut_id=${duonut_id}`;
        const response = await axios.get(API_URL, header);
        // console.log(response.data);
        dispatch(getduonutreport(response.data));
    } catch (err) {
        throw new Error(err);
    }
};

export const getduonutanalyticsAsync = (duonut_id) => async (dispatch) => {
    try {
        const header = getAuthHeaders();
        var API_URL;
        API_URL = `${domain}/duonut/analytics_per_duonut?duonut_id=${duonut_id}`;
        const response = await axios.get(API_URL, header);
        // console.log(response.data);
        dispatch(getduonutanalytics(response.data));
    } catch (err) {
        throw new Error(err);
    }
};

export const { getduonutreport, getduonutanalytics } = duonutreportSlide.actions;
export const showduonutreport = (state) => state.duonutreport.data;
export const showduonutanalytics = (state) => state.duonutreport.analyticsdata;
export default duonutreportSlide.reducer;
