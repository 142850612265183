import React, { useEffect, useState } from "react";
import "../../RightSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import ColorPicker from "./SlideBGColorPicker";

const SlideBGColorEditor = () => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);

    useEffect(() => {

    }, [selectedComponent, userActivities[selectedUserActivity]]);

    const [isActive, setIsActive] = useState(false);

    const handleOpen = () => {
        setIsActive(true);
    };

    const handleClose = () => {
        setIsActive(false);
    };

    return (
        <div className="EditorRowContainer">
            <p style={{ marginRight: "1rem" }}>Background</p>
            <ColorPicker isActive={isActive}
                handleOpen={handleOpen}
                handleClose={handleClose}
            // colorEditorPos={colorEditorPos}
            // width={width}
            // gridBackground={gridBackground}
            // gridText={gridText}
            // hover={hover} 
            />
        </div>
    );
}

export default SlideBGColorEditor;