import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import "./style.css";
import Close from "../../../assets/icons/back";
import ConfettiExplosion from 'react-confetti-explosion';
import leaderboardaudio from '../../../assets/audio/leaderboaard.m4a';

export default function SuccessModal({ message, description, displayId, secondaryButton, primaryButton, handleClose, hideHeader, hideButtons }) {
  const dispatch = useDispatch();
  const { selectedUserActivity } = useSelector((state) => state.userActivity);
  const audioRef = useRef(null);

  // useEffect(() => {
  //   if (!hideButtons) {
  //     audioRef.current.play();
  //     audioRef.current.volume = 0.2;
  //     setTimeout(() => {
  //       audioRef.current.pause();
  //       audioRef.current.currentTime = 0;
  //     }, 3000); //miliseconds
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const source = {
    position: "absolute",
    right: "50%",
    left: "50%",
    top: "-150px"
  };
  const bigExplodeProps = {
    force: 0.6,
    duration: 3000,
    particleCount: 200,
    floorHeight: 1600,
    floorWidth: 1600
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
        className="modal_container confirm_replace_modal success_modal"
      >
        <motion.div
          initial={{ scale: 0.7 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.7 }}
          transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
          className="modal_basic"
          onClick={(e) => e.stopPropagation()}
        >
          {!hideHeader &&
            <div className="menu_header">
              <div className="close_menu_btn" onClick={handleClose}>
                <Close />
              </div>
            </div>
          }

          <div className="modal_basic_content">
            <p className="success_message">
              {message}
            </p>
            <p className="description">
              {description}
            </p>
            {
              !hideButtons &&
              <div className="buttons">
                <div style={source}>
                  <ConfettiExplosion {...bigExplodeProps} />
                </div>
                <audio
                  src={leaderboardaudio}
                  ref={audioRef}
                ></audio>
                {displayId && <button className="success_modal_btn" onClick={() => window.open('/duonut/' + displayId)}>
                  View this Duonut
                </button>}
                <button className="success_modal_btn" onClick={() => { handleClose(); window.location.reload(); }} >
                  Edit
                </button>
                <button className="success_modal_btn" onClick={secondaryButton.onClick} >
                  {secondaryButton.text}
                </button>
                {/* <button style={{ marginLeft: "1rem" }} className="btn btn-primary" onClick={primaryButton.onClick}>
                  {primaryButton.text}
                </button> */}
              </div>
            }

          </div>

        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}
