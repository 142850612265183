import React, { useEffect, lazy } from "react";
import HomePage from "../../Components/HomePage/HomePage";
// const HomePageFooter = lazy(() => import('../../Components/HomePageFooter/HomePageFooter'));
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { updateIsLoggedIn } from "../../redux/slices/user";
import { useSelector } from "react-redux";

const Home = () => {
    const { isLoggedIn } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     function extractAuthCodeFromURL() {
    //         const urlParams = new URLSearchParams(window.location.search);
    //         return urlParams.get('code');
    //     }

    //     // Extract the authorization code from the URL
    //     const authCode = extractAuthCodeFromURL();

    //     if (authCode) {
    //         // Send a message to the main window with the authorization code
    //         window.opener.postMessage({ authCode }, window.location.origin);
    //     }
    // }, []);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/myduonuts")
        } else {
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            localStorage.removeItem("userName");
            localStorage.removeItem("userEmail");
            localStorage.removeItem("userId");
            localStorage.removeItem("withGoogle");
            localStorage.removeItem("withMicrosoft");
            localStorage.removeItem("appId");
            localStorage.removeItem("subDomain");
            localStorage.removeItem("proUser");
            localStorage.removeItem("userProfilePicture");
            localStorage.removeItem("userType");
            localStorage.removeItem("expireDate");

            dispatch(updateIsLoggedIn(false));

            navigate("/login")
        }
    }, []);

    return (
        <div>
            {/* <HomePage />
            <HomePageFooter /> */}
        </div>
    );
}

export default Home;