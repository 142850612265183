import React, { useRef, useState, useEffect } from "react";
import Resizer from 'react-image-file-resizer';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateUserActivityElements, updateUserActivity } from "../../../redux/slices/userActivity";
import InfiniteScroll from "react-infinite-scroll-component";
import { domain } from "../../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function UploadImages({ video }) {
    const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
    const dispatch = useDispatch();
    const [images, setImages] = useState([]);
    const [query, setQuery] = useState("");
    const [totalImage, settotalImage] = useState(50);
    const [pageNo, setPageNo] = useState(1);
    const [btnClick, setbtnClick] = useState(false);
    const imageref = useRef(null);
    const imageref1 = useRef(null);
    const location = useLocation();
    const [paramsid, setparamsid] = useState("");

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);


    const handleClick = () => {
        imageref.current.click();
    };

    useEffect(() => {
        imageApi();
    }, [btnClick]);

    const handleNext = () => {
        // console.log("api1111")
        setPageNo(pageNo + 1);
        setbtnClick(!btnClick);
        // imageApi();
    }

    const imageApi = async () => {
        // console.log("api")
        const Access_Key = "RIT18YfKd99l1UR79h-VAcGhgz9y98QWzeaXgIiby94"
        const txt = query ? query : "mountain";

        const data = await fetch(
            `https://api.unsplash.com/search/photos?page=${pageNo}&query=${txt}&client_id=${Access_Key}&per_page=20`
        );
        const dataJ = await data.json();
        const result = dataJ.results;
        // console.log(result);
        settotalImage(dataJ.total)
        setImages([...images, ...result]);

    };

    const handleChangeText = (e) => {
        // console.log(e)
        setQuery(e.target.value);
        if (e.keyCode === 13) {
            document.getElementById("submitBtn").click();
        }
        // setImages([]);
        // setPageNo(1);
    };

    const handleButtonClick = () => {
        // console.log("click")
        setImages([]);
        setPageNo(1);
        setbtnClick(!btnClick);
        // imageApi();
    };

    function convertT0DataUrl(url, callback) {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => {
                console.error('Error fetching the image:', error);
            });
    }

    const updateActivityImage = async (imgId, imgUrl) => {

        if (selectedComponent.name === "Image") {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            convertT0DataUrl(imgUrl, (res) => {
                let result = res;

                const image_data = {
                    duonut_id: paramsid,
                    name: imgId,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            url_data: {},
                            url: res.data.file_url,
                        },
                    };
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: selectedUserActivity,
                            selectedElementIdx: selectedComponent.target_id,
                            element: tempElement,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });

            });
        }
        if (selectedComponent.name === "Container") {
            convertT0DataUrl(imgUrl, (res) => {
                let result = res;
                const image_data = {
                    duonut_id: paramsid,
                    name: imgId,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    dispatch(
                        updateUserActivity({
                            backgroundImage: res.data.file_url,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });
            });
        }
        if (selectedComponent.name === "Button") {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            convertT0DataUrl(imgUrl, (res) => {
                let result = res;

                const image_data = {
                    duonut_id: paramsid,
                    name: imgId,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    tempElement = {
                        ...tempElement,
                        elementAttributes: {
                            ...elementAttrib,
                            url_data: {},
                            url: res.data.file_url,
                        },
                    };
                    dispatch(
                        updateUserActivityElements({
                            selectedActivityIdx: selectedUserActivity,
                            selectedElementIdx: selectedComponent.target_id,
                            element: tempElement,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });

            });
        }

        imageref1.current.click();

    };

    return (<>
        <button ref={imageref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#imageModal`}>
        </button>
        <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`imageModal`} tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content SM1modalMain" style={{ marginTop: "20vh", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                    <div className="modal-body">
                        <button ref={imageref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        <div className='SM1shareTitle'> Image Gallery </div>
                        <input
                            type="text"
                            name="query"
                            className="input"
                            style={{ border: "1px solid black", width: "70%", margin: "1rem auto", padding: "8px", borderRadius: "4px" }}
                            placeholder="search images"
                            // value={query}
                            onKeyUp={(e) => handleChangeText(e)}
                        />
                        <button id="submitBtn" style={{ marginLeft: "1rem", padding: "10px", border: "1px solid black", borderRadius: "6px", cursor: "pointer" }} onClick={() => handleButtonClick()} >Search</button>

                        <div id="scrollableDiv" style={{ display: "flex", flexWrap: "wrap", height: "22rem", overflow: "auto" }}>
                            <InfiniteScroll
                                dataLength={images.length}
                                next={handleNext}
                                hasMore={totalImage > images.length ? true : false}
                                loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDiv"
                            // endMessage={
                            //     <p style={{ textAlign: 'center' }}>
                            //         <b>Yay! You have seen it all</b>
                            //     </p>
                            // }
                            >
                                {images.length > 0 && <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {images.map((val, i) => {
                                        return (
                                            <div key={i} style={{ width: "45%", margin: "8px", cursor: "pointer" }}>
                                                <img
                                                    className="apiImage"
                                                    style={{
                                                        width: "100%",
                                                        height: "auto",
                                                        borderRadius: "8px",
                                                    }}
                                                    src={val.urls.small}
                                                    alt={val.alt_description}
                                                    onClick={() => updateActivityImage(val.id, val.urls.small)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="EditorRowContainer" style={{ margin: "6px 1rem", justifyContent: "center" }}>
            <div className="EditorRowButton" onClick={handleClick}>
                {/* <img src="/assets/icons/upload-image.svg" /> */}
                <i className="fa fa-picture-o" aria-hidden="true" style={{ marginRight: "5px", fontSize: "1rem", opacity: "0.8" }}></i>
                Image Gallery
            </div>
        </div>
    </>
    );
}