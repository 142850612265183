import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Down from "../../assets/icons/down";
// import { toggleCategoryDropdown } from "../../redux/slices/activityCategories";

export default function MenuItem({ text, linkTo, fill, setNavMenuActive }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { categoryDropdownActive } = useSelector((state) => state.activityCategories);

  const handleClick = () => {
    if (text === "Home") return navigate("/");
    if (text === "Create") {
      // dispatch(toggleCategoryDropdown({active: categoryDropdownActive ? false : true}));
    }
    if (linkTo === "./all_duonuts") {
      navigate(linkTo);
    }
    if (linkTo === "./education") {
      navigate(linkTo);
    }
    if (linkTo === "./myduonuts") {
      navigate(linkTo);
    }
    if (linkTo === "./aboutus") {
      navigate(linkTo);
    }
    if (linkTo === "./templates/all") {
      navigate(linkTo);
    }
    if (linkTo === "./contactus") {
      navigate(linkTo);
    }
    if (linkTo === "./mentalhealth") {
      navigate(linkTo);
    }
    if (linkTo === "./pricing") {
      navigate(linkTo);
    }
    setNavMenuActive(false)
  };

  return (
    <div className="menu_item" onClick={handleClick}>
      <p className="menu_text">{text} </p>
      {/* {text === 'Create' && <img src='/assets/icons/right.svg' className='icon' />} */}
      <div className="icon d-flex">{text === "Create" && <Down fill={fill} />}</div>
    </div>
  );
}
