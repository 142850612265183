import React, { useState, useRef, useEffect } from 'react'
import "./ChannelViewer.css"
import { frontendUrl, backendUrl } from "../../services/constants";
import { getChannelAsync, showChannelData } from "../../redux/slices/ChannelReducer";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

const ChannelViewer = () => {
    const paramsid = useParams();
    const channelCode = parseInt(paramsid.id);
    const { isLoggedIn } = useSelector((state) => state.user);
    const ref = useRef(null);
    const ref1 = useRef(null);
    const pricingref = useRef(null);
    const pricingref1 = useRef(null);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [channelAllData, setchannelAllData] = useState("");
    const [nuggetsAllData, setnuggetsAllData] = useState("");
    const [selectedNuggetData, setselectedNuggetData] = useState("");
    const [message, setmessage] = useState("");

    useEffect(() => {
        dispatch(getChannelAsync(paramsid));
        // console.log("inside");
    }, []);
    const channelData = useSelector(showChannelData);
    const channelData1 = channelData[0];

    // console.log(channelData1);

    useEffect(() => {
        if (channelData1) {
            // console.log(channelData1);
            if (channelData1.empty_message) {
                setmessage("No data available.");
            } else {
                setchannelAllData(channelData1.channel);
                setnuggetsAllData(channelData1.nuggets);
                localStorage.setItem("subDomain", channelData1.subdomain);
                localStorage.setItem("channelLogo", channelData1.channel.logo);
            }
        }
    }, [channelData1])

    const handleNugget = (e, id) => {
        window.open(`/viewworkflows/${id}`)
        e.stopPropagation();
    }

    const onClickAboutButton = (e) => {
        e.stopPropagation();
        ref.current.click();
    }

    const onClickAboutCloseButton = () => {
        ref1.current.click();
    }

    const onClickUnlockButton = (e, nugget) => {
        e.stopPropagation();
        setselectedNuggetData(nugget);
        pricingref.current.click();
    }

    const onClickUnlockCloseButton = () => {
        pricingref1.current.click();
    }

    return (
        <>
            <button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#nuggetsModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="nuggetsModal" tabIndex="-1" aria-labelledby="nuggetsModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ marginTop: "22vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button style={{ position: "absolute", right: "1rem", fontSize: "20px", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => onClickAboutCloseButton()}>x</button>
                            <div className='ChannelModalTitle'> About Channel</div>
                            <div style={{ padding: "0.5rem 0rem" }}>{channelAllData?.description}</div>
                            {channelAllData?.address && <div style={{ padding: "0.5rem 0rem" }}>Address - {channelAllData?.address}</div>}
                            {channelAllData?.website && <div style={{ padding: "0.5rem 0rem" }}>Website - {channelAllData?.website}</div>}
                            {channelAllData?.contact_no && <div style={{ padding: "0.5rem 0rem" }}>Contact No. - {channelAllData?.contact_no}</div>}
                        </div>
                    </div>
                </div>
            </div>

            <button ref={pricingref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#pricingModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="pricingModal" tabIndex="-1" aria-labelledby="pricingModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ marginTop: "22vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={pricingref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button style={{ position: "absolute", right: "1rem", fontSize: "20px", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => onClickUnlockCloseButton()}>x</button>
                            <div className='ChannelModalTitle'> {selectedNuggetData.pricing_title}</div>
                            <div style={{ padding: "0.5rem 0rem" }}>{selectedNuggetData?.pricing_description}</div>
                            <div style={{ padding: "1rem 0rem" }}>Price:
                                <label style={{ marginLeft: "8px" }}> {selectedNuggetData?.discounted_price}</label>
                                <del style={{ marginLeft: "12px" }}>{selectedNuggetData?.actual_price}</del></div>
                            <button className='nuggetPricingBtn' style={{ margin: "0.5rem", fontSize: "1rem", width: "11rem" }}
                                onClick={() => window.open(selectedNuggetData.button_url)} >{selectedNuggetData.button_text}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='channelApp'>
                <div className='channeltemplate'>
                    {message && <div style={{ paddingTop: "4rem" }}>{message}</div>}
                    {channelAllData && <div className='channelContainer'>
                        <div className='channelContainer'>
                            <div className="channelLogo" >
                                {channelAllData?.logo ? <img className="channelLogoImg" src={`${backendUrl}${channelAllData.logo}`} /> : <img className="channelLogoImg" src='/assets/user.png' />}
                            </div>
                            <div className='channelHeading' style={{}}>
                                <h2>{channelAllData?.name}</h2>
                                <div>{channelAllData?.code}</div>
                                <div style={{ paddingTop: "10px" }}>{channelAllData?.description}</div>
                            </div>
                        </div>
                        <div>
                            <button className='channelCreateButton' style={{ marginRight: "10px" }} onClick={(e) => onClickAboutButton(e)} >About Workflow</button>
                        </div>
                    </div>}
                    {nuggetsAllData && <div className='CV1nuggetsContainer'>
                        {nuggetsAllData.length >= 1 ?
                            nuggetsAllData.map(nugget => {
                                // console.log(duonut)
                                // if (!nugget.visibility && !isLoggedIn) return;

                                var isUserInNugget = false;
                                // Check if the logged-in user's email is in the nugget_user array
                                if (isLoggedIn) {
                                    isUserInNugget = nugget.nugget_user.some(user => user.user.email === localStorage.getItem("userEmail"));
                                }

                                return (
                                    <div style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => handleNugget(e, nugget.id)}>
                                        <div style={{ display: "flex", position: "relative" }}>
                                            {nugget.thumbnail ?
                                                <img style={{ width: "290px", height: "200px", borderRadius: "8px" }} src={`${backendUrl}${nugget.thumbnail}`}></img>
                                                : <img style={{ width: "290px", height: "200px", borderRadius: "8px" }} src='/assets/thumbnail.jpg'></img>}
                                            {/* <div style={{
                                                position: "absolute", fontSize: "12px", bottom: "0", left: "0", right: "0", padding: "5px",
                                                backgroundColor: "rgba(60, 89, 85, 0.8)", color: "white", borderRadius: "0px 0px 10px 10px"
                                            }}>{nugget.nugget_duonut.length} Duonuts</div> */}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", padding: "2px 4px" }}>
                                            <div style={{ padding: "2px 0px", fontSize: "1.2rem" }}>{nugget.name}</div>
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: '100%', padding: "2px 0px" }}>
                                                <div>{nugget.nugget_duonut.length} Duonuts</div>
                                                {!nugget.visibility && !isUserInNugget && (<button className='channelUnlockButton' style={{}} onClick={(e) => onClickUnlockButton(e, nugget)} >Unlock</button>)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <div style={{ textAlign: "center", width: "100%" }}>No Any Workflows Created!</div>}
                    </div>}
                </div>
            </div>
        </>
    );

}

export default ChannelViewer;