import React, { useState, useEffect } from 'react';
import './Element.css';
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showduonutData } from '../../redux/slices/DuonutReducer';

const FlipCard = ({
    id,
    elementAttr,
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const { text, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, margin, padding, borderRadius, color, backgroundColor, border, gap,
        labelFontSize, labelFontWeight, labelColor, f_text, b_text, f_image, b_image
    } = elementAttr;

    const { viewType } = useSelector((state) => state.duonutSettings);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];

    const [side, setSide] = useState(false);

    function handleClick() {
        // console.log("clicked!");
        setSide(!side);
        // show = false;
        // console.log(side);
    }

    return (<div style={{
        borderRadius, display: "flex", flexDirection: "column", alignItems: 'center', gap: gap
    }}>
        <div
            style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>{text}</div>

        <div className={`FC5card ${side ? "FC5side" : ""}`}
            style={{
                backgroundColor: side ? elementHoverAttr?.backgroundColor : backgroundColor,
                border: side ? elementHoverAttr?.border : border ? border : "0",
                color: side ? elementHoverAttr?.color : color,
                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                textTransform, textAlign, letterSpacing, padding, margin,
                cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
            }}
            onClick={handleClick}>
            <div className="FC5front">
                {f_image ? <div className="FC5cardImg">
                    <img className='FC5cardImgShow' style={{ borderRadius }} key={f_image} src={f_image} alt="" />
                </div>
                    : <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{f_text}</div>}
            </div>
            <div className="FC5back">
                {b_image ? <div className="FC5cardImg">
                    <img className='FC5cardImgShow' style={{ borderRadius }} key={b_image} src={b_image} alt="" />
                </div>
                    : <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{b_text}</div>}
            </div>

            <div className={!side ? "FC5msg" : "FC5msgnone"} style={{
                color: color, borderRadius,
                backgroundColor: side ? elementHoverAttr?.backgroundColor : backgroundColor
            }}>Click to flip</div>

        </div>
    </div>
    );
}

export default FlipCard;
