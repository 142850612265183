import React from 'react';
import './NotFound.css';
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className='notfoundApp'>
            <div className='notfoundAppTemplate'>
                <h2 style={{ padding: "4vh 0vh" }}>Oops! Page Not Found</h2>
                <img className='notfoundImage' src={require('../../assets/Images/notfound.png')} alt="404" />
                <button type='submit' className='notfoundBtn' onClick={() => navigate('/')}>Go to Home page</button>
            </div>
        </div>
    );

}

export default NotFound;