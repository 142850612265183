import React from 'react';
import './TermCondition.css';
// import background from '../../assets/backgroundImg/Screens.svg';
import HomePageFooter from '../../Components/HomePageFooter/HomePageFooter';

const TermCondition = (props) => {

    return (
        <div className='TC1App'
        // style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
            <div className='TC1template'>
                <h1>Terms of Service</h1>
                <br></br>
                <h2>1. Introduction</h2>
                <div>
                    Welcome to Duonut Inc  (“Company”, “we”, “our”, “us”)!
                    These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website located at duonut.com (together or individually “Service”) operated by Duonut Inc.
                </div>
                <br></br>
                <div>
                    At Duonut (“we”), we provide an Engagement Discovery & Execution Platform that allows you to create dynamic, interactive content which can be shared across any social media handle. Our mission is to democratize access to creating an engagement strategy, without coding or access to designers or programmers, yet engage users by customizing the templates. Please read these Terms of Service & our Privacy Policy. These Terms of Service set out the terms and conditions that apply when you use our Services.
                </div>
                <br></br>
                <div>
                    The “Service(s)” means any and all designs and development services provided by Duonut through its products, including, but not limited to: the Site; Duonut software-as-a-service platform;, data, reports, text, images, sounds, video, and content made available through any of the foregoing (collectively, the “Content”). Any new features added to or augmenting the Service are also subject to these Terms of Service.
                </div>
                <br></br>
                <div>
                    Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.
                    Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound by them.
                </div>
                <br></br>
                <div>
                    If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at hello@duonut.com so we can try to find a solution.These Terms apply to all visitors, users and others who wish to access or use Service.
                </div>


                <br></br>
                <h2>2. Communications</h2>
                <div>
                    By using our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at hello@duonut.com.
                </div>


                <br></br>
                <h2>3. Purchases</h2>
                <div>
                    If you wish to purchase any product or service made available through Service (“Purchase”), you may be asked to supply certain information relevant to your Purchase including but not limited to, your credit or debit card number, the expiration date of your card, your billing address, and your shipping information.
                </div>
                <br></br>
                <div>
                    You represent and warrant that: (i) you have the legal right to use any card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
                </div>
                <br></br>
                <div>
                    We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
                </div>
                <br></br>
                <div>
                    We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.
                </div>
                <br></br>
                <div>
                    We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
                </div>


                <br></br>
                <h2>4. Contests, Sweepstakes And Promotions</h2>
                <div>
                    Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through Service may be governed by rules that are separate from these Terms of Service. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply.
                </div>


                <br></br>
                <h2>5. Subscriptions</h2>
                <div>
                    Some parts of Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.
                </div>
                <br></br>
                <div>
                    At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Duonut Inc cancel it. You may cancel your Subscription renewal either through your online account management page or by contacting hello@duonut.com customer support team.
                </div>
                <br></br>
                <div>
                    A valid payment method is required to process the payment for your subscription. You shall provide Duonut Inc with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize Duonut Inc to charge all Subscription fees incurred through your account to any such payment instruments.
                </div>
                <br></br>
                <div>
                    Should automatic billing fail to occur for any reason, Duonut Inc reserves the right to terminate your access to the Service with immediate effect.
                </div>

                <br></br>
                <h2>6. Free Trial</h2>
                <div>
                    Duonut Inc may, at its sole discretion, offer a subscription with a free trial for a limited period of time ("free trial").
                </div>
                <br></br>
                <div>
                    You are not required to enter your billing information and card details when signing up for a free trial, you will not be charged by Duonut Inc until you decide you opt for a paid subscription during or post-trial expiry. On the last day of the free trial period, unless you opt for a paid subscription, your account will be automatically canceled.
                </div>
                <br></br>
                <div>
                    At any time and without notice, Duonut Inc reserves the right to (i) modify the terms of service of a free trial offer, or (ii) cancel such free trial offer.
                </div>


                <br></br>
                <h2>7. Fee Changes</h2>
                <div>
                    Duonut Inc, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
                </div>
                <br></br>
                <div>
                    Duonut Inc will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
                </div>
                <br></br>
                <div>
                    Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
                </div>


                <br></br>
                <h2>8. Refunds</h2>
                <div>
                    No refunds (partial/full) will be issued in case the subscription has started. You can cancel your subscription before the subscription's end date to avoid any further charges.
                </div>
                <br></br>
                <div>
                    Our Annual/Half yearly/ Quarterly commitment plans offer a lower monthly payment than our month-to-month plans in exchange for a committed period to our service. Customers needing to cancel the committed plan early may be charged a cancellation fee. The fee amount varies since it is based on the total amount of savings you received so far at the discounted price.
                    Here is the formula we use to calculate the fee:
                </div>
                <br></br>
                <li>[List Price] - [Month-to-month rate of your plan] = Your monthly savings amount.</li>
                <li>[Your monthly savings amount] x [Number of months you have used the plan] = Cancellation fee amount (plus tax, if applicable.)</li>


                <br></br>
                <h2>9. Content</h2>
                <div>
                    Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for Content that you post on or through Service, including its legality, reliability, and appropriateness.
                    By posting Content on or through Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
                </div>
                <br></br>
                <div>
                    You retain any and all of your rights to any Content you submit, post or display on or through Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through Service.
                </div>
                <br></br>
                <div>
                    Duonut Inc has the right but not the obligation to monitor and edit all Content provided by users.
                </div>
                <br></br>
                <div>
                    In addition, Content found on or through this Service are the property of Duonut Inc or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
                </div>


                <br></br>
                <h2>10. Prohibited Uses</h2>
                <div>
                    You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:
                </div>
                <br></br>
                <div>
                    10.1.  In any way that violates any applicable national or international law or regulation.
                </div>
                <div>
                    10.2. For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
                </div>
                <div>
                    10.3. To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
                </div>
                <div>
                    10.4. To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
                </div>
                <div>
                    10.5. In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
                </div>
                <div>
                    10.6. To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.
                </div>
                <div>
                    10.7. To send any unsolicited emails to any person or any email who have not opted in to receive your emails.
                </div>
                <br></br>
                <div>Additionally, you agree not to:</div>
                <br></br>
                <div>
                    10.1. Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
                </div>
                <div>
                    10.2. Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
                </div>
                <div>
                    10.3. Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
                </div>
                <div>
                    10.4. Use any device, software, or routine that interferes with the proper working of Service.
                </div>
                <div>
                    10.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
                </div>
                <div>
                    10.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
                </div>
                <div>
                    10.7. Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                </div>
                <div>
                    10.8. Take any action that may damage or falsify Company ratings.
                </div>
                <div>
                    10.9. Otherwise attempt to interfere with the proper working of Service.
                </div>


                <br></br>
                <h2>11. Analytics</h2>
                <div>
                    We may use third-party Service Providers to monitor and analyze the use of our Service.
                </div>


                <br></br>
                <h2>12. No Use By Minors</h2>
                <div>
                    Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.
                </div>


                <br></br>
                <h2>13. Accounts</h2>
                <div>
                    When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.
                </div>
                <br></br>
                <div>
                    You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
                </div>
                <br></br>
                <div>
                    You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene.
                </div>
                <br></br>
                <div>
                    We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
                </div>



                <br></br>
                <h2>14. Intellectual Property</h2>
                <div>
                    Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Duonut Inc and its licensors. Service is protected by copyright, trademark, and other laws of and foreign countries. Our trademarks may not be used in connection with any product or service without the prior written consent of Duonut Inc.
                </div>


                <br></br>
                <h2>15. Copyright Policy</h2>
                <div>
                    We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.
                </div>
                <br></br>
                <div>
                    If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to hello@duonut.com, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”.
                </div>
                <br></br>
                <div>
                    You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the infringement of any Content found on and/or through Service on your copyright.
                </div>


                <br></br>
                <h2>16. Acceptance of terms:</h2>
                <div>
                    Duonut provides its Service through its website located at duonut.com (the “Site”) or in other ways as determined by us in its sole discretion, subject to these Terms of Service. By accepting these Terms of Service or by accessing or using the Service or Site, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you are entering into these Terms of Service on behalf of a company, business enterprise or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these Terms of Service, in which case the terms “you” or “your” shall refer to such entity and its affiliates. If you do not have such authority, or if you do not agree with these Terms of Service, you must not accept these Terms of Service and may not use or access the Service or Site. Upon acceptance by you, these Terms of Service constitute a legally binding contract between Duonut and you, and you shall comply with any and all of their terms and conditions provided herein. Duonut may change any of these Terms of Service from time to time. You can review the most current version of the Terms of Service at any time via the Site. If any changes to these Terms of Service are not acceptable to you, your only remedy is to stop using the Service. When you use the Service, you may be required to provide certain information as reasonably requested by Duonut, including, but not limited to, your email address at which Duonut may contact you.
                </div>


                <br></br>
                <h2>17. Additional Terms for Designers and Customers</h2>
                <div>
                    The Service is designed to (i) let customers (“Customers”) create engagement templates on the Internet, (ⅱ) share with others, and (ⅲ) manage templates & get analytics. The Service may be subject to additional terms and conditions, including payment terms if you are a Customer.
                </div>


                <br></br>
                <h2>18. DMCA Notice And Procedure For Copyright Infringement Claims</h2>
                <div>
                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                </div>
                <br></br>
                <div>
                    18.1. an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest.
                </div>
                <div>
                    18.2.  a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.
                </div>
                <div>
                    18.3. identification of the URL or other specific location on Service where the material that you claim is infringing is located.
                </div>
                <div>
                    18.4.  your address, telephone number, and email address.
                </div>
                <div>
                    18.5. a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                </div>
                <div>
                    18.6.  a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
                </div>
                <br></br>
                <div>You can contact our Copyright Agent via email at hello@duonut.com</div>


                <br></br>
                <h2>19. Error Reporting And Feedback</h2>
                <div>
                    You may provide us either directly at hello@duonut.com or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
                </div>


                <br></br>
                <h2>20. Links To Other Websites</h2>
                <div>
                    Our Service may contain links to third party web sites or services that are not owned or controlled by Duonut Inc.
                </div>
                <br></br>
                <div>
                    Duonut Inc has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
                </div>
                <br></br>
                <div>
                    You acknowledge and agree that company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third party web sites or services. we strongly advise you to read the terms of service and privacy policies of any third party web sites or services that you visit.
                </div>


                <br></br>
                <h2>21. Disclaimer Of Warranty</h2>
                <div>
                    These services are provided by company on an “as is” and “as available” basis. Company makes no representations or warranties of any kind, express or implied, as to the operation of their services, or the information, content or materials included therein. You expressly agree that your use of these services, their content, and any services or items obtained from us is at your sole risk.
                </div>
                <br></br>
                <div>
                    Neither company nor any person associated with company makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of the services. Without limiting the foregoing, neither company nor anyone associated with company represents or warrants that the services, their content, or any services or items obtained through the services will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that the services or the server that makes it available are free of viruses or other harmful components or that the services or any services or items obtained through the services will otherwise meet your needs or expectations.
                </div>
                <br></br>
                <div>
                    Company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for particular purpose. The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.
                </div>


                <br></br>
                <h2>22. General Conditions / Access and Use of the Service</h2>
                <div>
                    Subject to the terms and conditions of these Terms of Service, you may access and use the Service only for lawful purposes. All rights, title and interest in and to the Service and its components will remain with and belong exclusively to Duonut. You shall not (a) sublicense, resell, rent, lease, transfer, assign, time share or otherwise commercially exploit or make the Service available to any third party; (b) use the Service in any unlawful manner (including without limitation in violation of any applicable data, privacy or export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Service or its components, or (c) modify, adapt or hack the Service to, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks. You shall comply with any codes of conduct, policies or other notices Duonut provides you or publishes in connection with the Service, and you shall promptly notify Duonut if you learn of a security breach related to the Service.
                </div>
                <br></br>
                <div>
                    Any software that is owned by Duonut and may be made available by Duonut through the Site in connection with the Service (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Subject to the terms and conditions of these Terms of Service, Duonut hereby grants you a non-transferable, non-sublicensable and non-exclusive right and license to use the object code of any Software on your device(s) solely in connection with the Service, provided that you shall not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code or sell, assign, sublicense or otherwise transfer any right in any Software. You agree not to access the Service by any means other than through the interface that is provided by Duonut for use in accessing the Service. Any rights not expressly granted herein are reserved and no license or right to use any trademark of Duonut or any third party is granted to you in connection with the Service.
                </div>
                <br></br>
                <div>
                    You are solely responsible for all data, information, feedback, suggestions, text, content and other materials that you upload, post, deliver, provide or otherwise transmit or store (hereafter “post(ing)”) in connection with or relating to the Service (“Your Content”). The ownership of the content remains with whoever it originally belongs to. You are responsible for maintaining the confidentiality of the information associated with or provided by you, and for all activities that occur in the course of your use of Service. Duonut reserves the right to access the information associated with or provided by you in order to respond to your requests for technical support. By providing Your Content on or through the Service, you hereby do and shall grant Duonut a worldwide, non-exclusive, perpetual license to use, modify, reproduce, distribute, display, publish and perform Your Content solely to provide the Services.
                </div>
                <div>
                    You further agree that Duonut may remove or disable any Content at any time for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content), or for no reason at all. You understand that the operation of the Service, including Your Content, may be unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices and (c) transmission to Duonut’s third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Service. Accordingly, you acknowledge that you bear sole responsibility for adequate security, protection and backup of Your Content. Duonut will have no liability to you for any unauthorized access or use of any of Your Content, or any corruption, deletion, destruction or loss of any of Your Content. You acknowledge that these Terms of Service constitute a legally binding contract between you and Duonut, even though it is electronic and is not physically signed by you and Duonut, and it governs your use of the Service.
                </div>


                <br></br>
                <h2>23. Representations and Warranties</h2>
                <div>
                    You represent and warrant to Duonut that (i) you have full power and authority to enter into these Terms of Service; (ii) you own all Your Content or have obtained all permissions, releases, rights or licenses required to engage in your posting and other activities (and allow Duonut to perform its obligations) in connection with the Services without obtaining any further releases or consents; (iii) Your Content and other activities in connection with the Service, and Duonut’s exercise of all rights and license granted by you herein, do not and will not violate, infringe, or misappropriate any third party’s copyright, trademark, right of privacy or publicity, or other personal or proprietary right, nor does Your Content contain any matter that is defamatory, obscene, unlawful, threatening, abusive, tortious, offensive or harassing; and (iv) you are eighteen (18) years of age or older.
                </div>


                <br></br>
                <h2>24. Disclaimer of Warranties</h2>
                <div>
                    The Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Duonut or by third-party providers, or because of other causes beyond our reasonable control. However, the Service, including the Site and Content, and all server and network components are provided on an “as is” and “as available” basis without any warranties of any kind, and Duonut expressly disclaims any and all warranties, whether express or implied, including, but not limited to, the implied warranties of merchantability, title, fitness for a particular purpose, and non-infringement. you acknowledge that Duonut does not warrant that the Service will be uninterrupted, timely, secure, error-free or virus-free, nor does it make any warranty as to the results that may be obtained from the use of the Services, and no information, advice or services obtained by you from Duonut or through the Service shall create any warranty not expressly stated in these Terms of Service.
                </div>


                <br></br>
                <h2>25. Limitation Of Liability</h2>
                <div>
                    Except as prohibited by law, you will hold us and our officers, directors, employees, and agents harmless for any indirect, punitive, special, incidental, or consequential damage, however it arises (including attorneys’ fees and all related costs and expenses of litigation and arbitration, or at trial or on appeal, if any, whether or not litigation or arbitration is instituted), whether in an action of contract, negligence, or other tortious action, or arising out of or in connection with this agreement, including without limitation any claim for personal injury or property damage, arising from this agreement and any violation by you of any federal, state, or local laws, statutes, rules, or regulations, even if company has been previously advised of the possibility of such damage. Except as prohibited by law, if there is liability found on the part of company, it will be limited to the amount paid for the products and/or services, and under no circumstances will there be consequential or punitive damages. Some states do not allow the exclusion or limitation of punitive, incidental or consequential damages, so the prior limitation or exclusion may not apply to you.
                </div>


                <br></br>
                <h2>26. Termination</h2>
                <div>
                    We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.
                </div>
                <br></br>
                <div>
                    If you wish to terminate your account, you may simply discontinue using Service.
                    All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                </div>


                <br></br>
                <h2>27. Governing Law</h2>
                <div>
                    These Terms shall be governed and construed in accordance with the laws of the India, which governing law applies to agreement without regard to its conflict of law provisions.
                </div>
                <br></br>
                <div>
                    Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.
                </div>


                <br></br>
                <h2>28. Service Agreement</h2>
                <div>
                    All services provided by Duonut may be used for lawful purposes only. Transmission or storage of any information, data or material in violation of any Country, State or City law is prohibited.
                </div>
                <br></br>
                <div>
                    This includes, but is not limited to: copyrighted material, material legally judged to be threatening or obscene, or material protected by trade secret and other statute. The subscriber agrees to indemnify and hold harmless Duonut from any claims resulting from the use of the service which damages the subscriber or any other parties.
                </div>
                <br></br>
                <div>
                    Spamming, or the sending of unsolicited email, using an email address, URL that is maintained on an Duonut machine, or directing traffic to a webpage that contains any reference to Duonut is STRICTLY prohibited. Duonut will be the sole arbiter as to what constitutes a violation of this provision. This action WILL RESULT in immediate termination of your account without refund. Any service interruptions as a result of subscribers spamming will be billed to the subscriber at $50.00 per hour until service is restored. Subscriber will also be in violation of the Duonut Service Agreement and subject to legal action.
                </div>
                <br></br>
                <div>
                    Importing or in any way using purchased leads with a Duonut account is strictly prohibited. If you paid money or in any way purchased a group of pre-existing leads these may not be used with Duonut. This includes co-registration services, "safe lists" or any type of free leads given to you. Only people that have specifically requested information directly from you may be emailed through Duonut.
                </div>
                <br></br>
                <div>
                    Batching or in any way trying to script the addition of new subscribers to the web form subscribe methods is strictly forbidden. A script must not be used to auto submit a web form subscriber on behalf of a website visitor. The visitor who desires to subscribe to your list must be the one to activate that subscription process.
                </div>
                <br></br>
                <div>
                    No individual under the age of sixteen (16) may use the Services, provide any personal information to Duonut, or otherwise submit personal information through Duonut Services (including, for example, a name, address, telephone number, or email address).
                </div>
                <br></br>
                <div>
                    Pornography and sex-related merchandising are prohibited on any Duonut server. Duonut will be the sole arbiter as to what constitutes a violation of this provision.
                </div>
                <br></br>
                <div>
                    The collecting of personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade-union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation is prohibited. Duonut will be the sole arbiter as to what constitutes a violation of this provision.
                </div>
                <br></br>
                <div>
                    The subscriber agrees that the company has the right to delete all data, files or other information that is stored in the subscriber's account if the subscriber's account with the company is terminated, for any reason, by either Duonut or the subscriber. System accounts can not be transferred or used by anyone other than the subscriber. Accounts which have been transferred to other parties, or show other activity in violation of this paragraph, are subject to immediate cancellation.
                </div>
                <br></br>
                <div>
                    Duonut shall have the right to suspend service to the subscriber at any time, and for any reason, without notice. If such a suspension is to last for more than 20 days, the subscriber will be notified as to the reason.
                </div>
                <br></br>
                <div>
                    Service provided by Duonut may be canceled in writing at any time with no penalty. Customer Account Login/Email and Response to Security Question must be included in the cancellation request. Cancellation will take effect only when the receipt of the cancellation request is confirmed by Duonut. Duonut reserves the right to change the rate by notifying the subscriber 15 days in advance of the effective date of the change.
                </div>
                <br></br>
                <div>
                    Duonut exercises no control whatsoever over the content of the information passing through Duonut. All leads (email addresses) generated by subscribers are held strictly confidential. The leads are never released to any other parties for any purpose. The lead information is only used on Duonut servers to deliver the follow up messages designated by the subscriber.
                </div>
                <br></br>
                <div>
                    Customers are prohibited from transmitting on or through any of Duonut services, any material that is, in Duonut sole discretion, unlawful, obscene, threatening, abusive, libelous, or hateful, or that encourages conduct which would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national or international law.
                </div>


                <br></br>
                <h2>29. Changes To Service</h2>
                <div>
                    We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
                </div>


                <br></br>
                <h2>30. Amendments To Terms</h2>
                <div>
                    We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.
                </div>
                <br></br>
                <div>
                    Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.
                </div>
                <br></br>
                <div>
                    By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
                </div>


                <br></br>
                <h2>31. Waiver And Severability</h2>
                <div>
                    No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company to assert a right or provision under Terms shall not constitute a waiver of such right or provision.
                </div>
                <br></br>
                <div>
                    If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.
                </div>

                <br></br>
                <h2>32. Acknowledgement</h2>
                <div>
                    BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                </div>

                <br></br>
                <h2>33. Contact Us</h2>
                <div>
                    Please send your feedback, comments, requests for technical support by email: hello@duonut.com.
                </div>
                <br></br>
                <div>
                    Our Corporate address -
                    Duonut Inc
                    1111B S Governors Ave STE 6614
                    Dover, DE 19904
                </div>



                <br></br>
                <br></br>
            </div>
            <HomePageFooter />

        </div>
    );

}

export default TermCondition;