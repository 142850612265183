import React, { useEffect, useState } from "react";
import './DuonutReports.css';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

const LineChart4 = ({ transformedData, columns1, selectedColumn }) => {
    const [timeUnit, setTimeUnit] = useState('day'); // Default to daily
    const [selectedMonth, setSelectedMonth] = useState(dayjs().format('YYYY-MM')); // Default to current month
    const [selectedYear, setSelectedYear] = useState(dayjs().year()); // Default to current year

    const processData = (data) => {
        const result = {};

        data.forEach(item => {
            let dateKey;
            const date = dayjs(item['Date']);

            // Adjust the date grouping based on the selected time unit
            if (timeUnit === 'day') {
                dateKey = date.format('YYYY-MM-DD');
            } else if (timeUnit === 'week') {
                // Group by week, taking into account the selected month and year
                if (date.format('YYYY-MM') === selectedMonth) {
                    dateKey = date.startOf('week').format('YYYY-MM-DD');
                } else {
                    return;
                }
            } else if (timeUnit === 'month') {
                // Group by month, taking into account the selected year
                if (date.year() === parseInt(selectedYear)) {
                    dateKey = date.format('YYYY-MM');
                } else {
                    return;
                }
            }

            const operator = item['Operator Name'];
            const production = parseInt(item[selectedColumn]) || 0;

            if (!result[operator]) {
                result[operator] = {};
            }

            if (!result[operator][dateKey]) {
                result[operator][dateKey] = { totalProduction: 0, count: 0 };
            }

            result[operator][dateKey].totalProduction += production;
            result[operator][dateKey].count += 1;
        });

        const series = Object.keys(result).map(operator => ({
            name: operator,
            data: Object.keys(result[operator]).map(dateKey => ({
                x: dateKey,
                y: result[operator][dateKey].totalProduction / result[operator][dateKey].count
            }))
        }));

        return series;
    };

    const series = processData(transformedData);

    const options = {
        xaxis: {
            type: 'datetime',
            labels: {
                format: timeUnit === 'day' ? 'dd/MM/yyyy' : timeUnit === 'week' ? 'Week MMM dd' : 'MMM yyyy'
            }
        },
        yaxis: {
            title: {
                text: selectedColumn
            }
        },
        title: {
            text: `${selectedColumn} Operator Name wise Chart`,
            align: 'center'
        }
    };

    return (
        <div className="DR1linechart">
            <h2 style={{ paddingBottom: "1rem" }}>{`${selectedColumn} Operator Name wise Chart`}</h2>
            <br></br>

            <div className="DR1linecharfilter">
                <div>Show Graph of: </div>
                <select value={timeUnit} className='text_editor_dropdown'
                    onChange={e => setTimeUnit(e.target.value)} >
                    <option value="day">Daily</option>
                    <option value="week">Weekly</option>
                    <option value="month">Monthly</option>
                </select>
            </div>
            <br></br>

            {timeUnit === 'week' && <div className="DR1linecharfilter">
                <label>Select Month and Year: </label>
                <input className='text_editor_dropdown'
                    type="month"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                />
            </div>}
            {timeUnit === 'month' && <div className="DR1linecharfilter">
                <label>Select Year: </label>
                <input className='text_editor_dropdown'
                    type="number"
                    value={selectedYear}
                    min="2000"
                    max={dayjs().year()}
                    onChange={(e) => setSelectedYear(e.target.value)}
                />
            </div>}
            <br></br>

            <Chart options={options} series={series} type="line" width={800} height={400} />

        </div>
    );
}

export default LineChart4;