import React, { useRef, useEffect, useState } from 'react';
import './Channel.css';
import { getMyDuonuts } from '../../services/duonut'
import { frontendUrl, backendUrl } from "../../services/constants";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
// import Footer from '../../viewerComponents/Footer/Footer';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { saveChannelAsync, showChannelresponse, createChannelAsync } from "../../redux/slices/ChannelReducer";
import { saveNuggetsAsync } from "../../redux/slices/NuggetsReducer";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import Duonut from '../../Components/Duonut/Duonut';
import Nuggets from './Nuggets';
import AboutChannel from './AboutChannel';
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import ConfirmDeleteDuonut from "../../Components/Modals/ConfirmDeleteDuonut/ConfirmDeleteDuonut";

var shareLink = "";
const Channel = () => {
    const paramsid = useParams();
    const channelId = parseInt(paramsid.id);

    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [channelAllData, setchannelAllData] = useState("");
    const [nuggetsAllData, setnuggetsAllData] = useState("");
    const [channelName, setchannelName] = useState("");
    const { isLoggedIn, userProAccess, userAccountType } = useSelector((state) => state.user);
    const [duonuts, setDuonuts] = useState([]);
    const [message, setmessage] = useState("");
    const [nuggetName, setnuggetName] = useState("");
    const [createChannel, setcreateChannel] = useState(false);
    const [selectedTab, setselectedTab] = useState(1);
    const [recentDuonut, setRecentDuonut] = useState([])
    const [filteredDuonuts, setFilteredDuonuts] = useState([])
    const [drafts, setDrafts] = useState([])
    const [selectedDuonutTab, setselectedDuonutTab] = useState("1")

    const ref = useRef(null);
    const ref1 = useRef(null);
    const shareref = useRef(null);
    const shareref1 = useRef(null);
    const [iscopy, setiscopy] = useState(false);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    useEffect(() => {
        if (!userProAccess) {
            window.location = '/';
        }
    }, []);

    useEffect(() => {
        dispatch(saveChannelAsync());
        // console.log("inside");
    }, []);
    const channelData = useSelector(showChannelresponse);
    const channelData1 = channelData[0];

    // console.log(channelData1);

    useEffect(() => {
        if (channelData1) {
            // console.log(channelData1);
            if (channelData1.empty_message) {
                // setcreateChannel(true);
                navigate('/createworkflow');
            } else {
                console.log(channelData1.channels);
                const foundChannel = channelData1?.channels?.find(channelObj => channelObj.channel.id === channelId);
                setchannelAllData(foundChannel?.channel);
                setnuggetsAllData(foundChannel?.nuggets);
                console.log(foundChannel);
            }
        }
    }, [channelData1])

    const fetchDuonut = () => {
        getMyDuonuts((err, res) => {
            if (err) console.log(err.response);
            else {
                if (res.data.length === 0) {
                    setDrafts([])
                    setRecentDuonut([])
                    setDuonuts([])
                    return
                };
                // console.log('res', res.data);
                const tempdrafts = res.data.filter(item => item.published === false).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
                console.log("tempdrafts", tempdrafts)
                setDrafts(tempdrafts)
                const temprecent = res.data.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))[0];
                console.log("temprecent", temprecent)
                setRecentDuonut([temprecent])
                const publishedDuonuts = res.data.filter(item => item.published === true).sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
                console.log("publishedDuonuts", publishedDuonuts)
                setFilteredDuonuts(publishedDuonuts);
                setDuonuts(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1));
            }
        });
    }

    useEffect(() => {
        fetchDuonut()
    }, []);

    // useEffect(() => {
    //     getMyDuonuts((err, res) => {
    //         if (err) console.log(err.response)
    //         else {
    //             if (res.data.length === 0) return
    //             setDuonuts(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))
    //             // console.log(res)
    //         }
    //     })
    // }, [])

    const onClickCreateChannelButton = () => {
        var codeData = channelName.toLowerCase().replace(/\s/g, '')
        var channelData = {
            name: channelName,
            code: "@" + codeData + userId
        }
        dispatch(createChannelAsync(channelData));
        setmessage("Channel Created successfully");
        setTimeout(() => {
            setmessage("");
            window.location.reload();
        }, 3000); //miliseconds
    }

    const createNuggetsBtn = () => {
        var nuggetsData = {
            name: nuggetName,
            channelId: channelAllData?.id,
        }
        dispatch(saveNuggetsAsync(nuggetsData));
        setmessage("workflows created successfully");
        dispatch(saveChannelAsync());

        setTimeout(() => {
            dispatch(saveChannelAsync());
            setmessage("");
            setnuggetName("");
            ref1.current.click();
        }, 3000); //miliseconds

    }

    const handelSelectTab = (num) => {
        // console.log("qwerty", num)
        setselectedTab(num);
        dispatch(saveChannelAsync());
    }

    const onClickCreateButton = () => {
        ref.current.click();
    }

    const onClickShareButton = () => {
        shareLink = `${frontend_url}/viewworkflow/${channelAllData?.code}`;
        setiscopy(false);
        shareref.current.click();
    }

    const continuebtn = () => {
        shareref1.current.click();
        setiscopy(false);
    }
    const viewbtn = () => {
        shareref1.current.click();
        window.open(`/viewworkflow/${channelAllData?.code}`);
        // window.location.reload();
    }

    const copycode = () => {
        var copyText = `${frontend_url}/viewworkflow/${channelAllData?.code}`;
        // copyText.select();
        // copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText);
        setiscopy(true);
    }

    return (
        <>
            <button ref={ref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#nuggetsModal">
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="nuggetsModal" tabIndex="-1" aria-labelledby="nuggetsModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content" style={{ marginTop: "22vh", textAlign: "center", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={ref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <div className='ChannelModalTitle'> Create Workflows</div>
                            {/* <br></br> */}
                            <div className='channelInputbox'>
                                <div style={{ textAlign: "left", padding: "4px 0px" }}>Enter Workflows Name</div>
                                <input className="" value={nuggetName === null ? "" : nuggetName} onChange={(e) => (e.target.value.length > 100 ? null : setnuggetName(e.target.value))} />
                            </div>
                            {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                            <button className="aboutChannelbtn" disabled={message ? true : false} style={{ margin: "2vh", backgroundColor: message ? "rgba(7, 168, 7, 0.897)" : "" }}
                                onClick={() => createNuggetsBtn()}>{message ? "Submitted" : "Submit"}</button>
                        </div>
                    </div>
                </div>
            </div>

            <button ref={shareref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#shareModal`}>
            </button>
            <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b" }} id={`shareModal`} tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content SM1modalMain" style={{ backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px" }}>
                        <div className="modal-body">
                            <button ref={shareref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            <div className='PM1shareTitle'> Share</div>
                            {/* <div className='PM1sharemsg'>YOU CAN SHARE THIS QUIZ TO YOUR FAMILY OR FRIENDS.</div> */}
                            <br></br>
                            <div className="PM1circle_buttons">
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.facebook.com/sharer.php?u=${shareLink}`)}>
                                    <i className="fa fa-facebook" aria-hidden="false"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink} ?page=ld`)}>
                                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://twitter.com/share?text=${shareLink}`)} >
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                </label>
                                <label className='SM1iconCircle' onClick={() => window.open(`https://api.whatsapp.com/send/?text=${shareLink}`)}>
                                    <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                </label>
                            </div>
                            <br></br>
                            <button className='SM1Continuebtn' onClick={() => copycode()}>{iscopy ? "Copied" : "Copy Link"}</button>
                            <button className='SM1Continuebtn' onClick={() => viewbtn()}>View</button>
                            <button className='SM1Continuebtn' onClick={() => continuebtn()}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>

            {createChannel && <div className='channelApp'>
                <div className='channelNewCreateContainer'>
                    <h1>Create New Workspace</h1>
                    <div style={{ backgroundColor: "rgba(8,17,47,.07)", marginTop: "2rem", padding: "10px", borderRadius: "16px", boxShadow: "0 22px 44px -12px rgba(25,47,89,.38)" }}>

                        <div style={{ backgroundColor: "white", padding: "1rem" }}>
                            <div style={{ textAlign: "center", padding: "1rem 0px" }}>Enter Workspace Name</div>
                            <input style={{ width: "15rem", height: "2rem", padding: "5px", background: "rgba(8, 17, 47, 0.07)" }} value={channelName === null ? "" : channelName} onChange={(e) => (e.target.value.length > 50 ? null : setchannelName(e.target.value))} />
                            <button className='aboutChannelbtn' onClick={() => onClickCreateChannelButton()} >Create Workspace</button>
                            {message && <div style={{ margin: "1vh auto" }}>{message}</div>}
                        </div>
                    </div>
                </div>
            </div>}

            {channelAllData && <div className='channelApp' key={channelAllData}>
                <div className='channeltemplate'>
                    <div className='channelContainer'>
                        <div className='channelContainer'>
                            <div className="channelLogo" >
                                {channelAllData?.logo ? <img className="channelLogoImg" src={`${backendUrl}${channelAllData.logo}`} loading="lazy" /> : <img className="channelLogoImg" src='/assets/user.png' loading="lazy" />}
                            </div>
                            <div className='channelHeading' style={{}}>
                                <h2>{channelAllData?.name}</h2>
                                <a href={frontend_url + '/viewworkflow/' + channelAllData?.code} target="_blank">{frontend_url}</a>
                                {/* <div>{channelAllData?.code}</div> */}
                                <div style={{ paddingTop: "10px" }}>{channelAllData?.description}</div>
                            </div>
                        </div>
                        <div>
                            {/* <button className='channelShareButton' style={{ marginRight: "10px" }} onClick={() => onClickShareButton()} ><i style={{ paddingRight: "5px" }} className="fa fa-share" aria-hidden="true"></i>Share Workspace</button> */}
                            <button className='channelShareButton' style={{ marginRight: "10px" }} onClick={() => dispatch(toggleCreateFromScratchModalDuonut(true))} ><i className="fa fa-plus" aria-hidden="true"></i> Create New</button>
                            <button className='channelCreateButton' onClick={() => onClickCreateButton()} >Create Workflows</button>
                        </div>
                    </div>

                    <div className='channelTab'>
                        <label className="setting-header-label" style={{ color: selectedTab === 1 ? "#3184C9" : "" }} onClick={() => handelSelectTab(1)}>
                            All Duonuts
                            {selectedTab === 1 && <div className="setting-selected-line"></div>}
                        </label>
                        <label className="setting-header-label" style={{ color: selectedTab === 2 ? "#3184C9" : "" }} onClick={() => handelSelectTab(2)}>
                            Workflows
                            {selectedTab === 2 && <div className="setting-selected-line"></div>}
                        </label>
                        <label className="setting-header-label" style={{ color: selectedTab === 3 ? "#3184C9" : "" }} onClick={() => handelSelectTab(3)}>
                            About
                            {selectedTab === 3 && <div className="setting-selected-line"></div>}
                        </label>

                    </div>
                    <hr></hr>

                    {selectedTab === 1 && <div className="selectedTabContainer" key={channelAllData}>
                        {duonuts.length >= 1 ?
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <div className="title" style={{ position: "relative", padding: '0px 10px', cursor: "pointer", fontWeight: selectedDuonutTab === "1" ? "700" : "400" }}
                                        onClick={() => setselectedDuonutTab("1")}>
                                        {selectedDuonutTab === "1" && <div className="navbar-selected-line"></div>}
                                        Published </div>
                                    <div className="title" style={{ position: "relative", padding: '0px 10px', marginLeft: "1rem", cursor: "pointer", fontWeight: selectedDuonutTab === "2" ? "700" : "400" }}
                                        onClick={() => setselectedDuonutTab("2")}>
                                        {selectedDuonutTab === "2" && <div className="navbar-selected-line"></div>}
                                        Drafts </div>
                                </div>
                                {selectedDuonutTab === "1" &&
                                    <> {filteredDuonuts.length >= 1 ?
                                        <div className="duonuts2">
                                            {filteredDuonuts.map(duonut => {
                                                // console.log(duonut)
                                                return (
                                                    <Duonut {...duonut} nuggets={nuggetsAllData} onClickCreateButton={onClickCreateButton} key={duonut.id} isFiltered={true} fetchDuonut={fetchDuonut} />
                                                )
                                            })
                                            }
                                        </div> : <div className="duonuts2" style={{ margin: "1rem" }}>No Any Published Duonut Created!</div>}</>}

                                {selectedDuonutTab === "2" &&
                                    <> {drafts.length >= 1 ?
                                        <div className="duonuts2">
                                            {drafts.map(duonut => {
                                                // console.log(duonut)
                                                return (
                                                    <Duonut {...duonut} nuggets={nuggetsAllData} onClickCreateButton={onClickCreateButton} key={duonut.id} isFiltered={true} fetchDuonut={fetchDuonut} />
                                                )
                                            })
                                            }
                                        </div> : <div className="duonuts2" style={{ margin: "1rem" }}>No Any Drafts Duonut Created!</div>}</>}
                                {/* {duonuts.map((duonut, i) => {
                                    // console.log(i, channelData1)
                                    return (
                                        <Duonut {...duonut} nuggets={channelData1?.nuggets} onClickCreateButton={onClickCreateButton} key={duonut.id} />
                                    )
                                })} */}
                            </div>
                            : <div>No Duonuts created yet.</div>
                        }
                    </div>}
                    {selectedTab === 2 && <div className="selectedTabContainer">
                        <Nuggets nuggets={nuggetsAllData} key={channelAllData} />
                    </div>}
                    {selectedTab === 3 && <div>
                        <AboutChannel channelData={channelAllData} handelSelectTab={handelSelectTab} />
                    </div>}

                    <ConfirmDeleteDuonut setDuonuts={setDuonuts} fetchDuonut={fetchDuonut} />
                </div>

                {/* <Footer /> */}
            </div>}

        </>
    );

}

export default Channel;