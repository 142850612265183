import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./navMenu.css";
import { useLocation } from "react-router-dom";

import Close from "../../assets/icons/close";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { openModal, showSignInForm, showSignUpForm, updateLogoutModalActive } from "../../redux/slices/formDetails";
import MenuItem from "./menuItem";
// import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";

export default function NavMenu({ setNavMenuActive, navMenuActive }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const menuRef = useRef(null);

  useDisableBodyScroll(navMenuActive);
  const { isLoggedIn } = useSelector((state) => state.user);

  const handleClick = () => {
    dispatch(openModal());
  };

  const handleClickDemo = () => {
    // dispatch(toggleDemoModalDuonut(true));
  };

  const pageVariants = {
    initial: { x: "-100vw" },
    in: { x: 0 },
    out: { x: "-100vw" },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3,
  };
  useEffect(() => {
    if (!menuRef.current || !menuRef.current.style) return;
    if (navMenuActive) {
      menuRef.current.style.display = "flex";
    } else {
      setTimeout(() => {
        menuRef.current.style.display = "none";
      }, 500);
    }
  }, [navMenuActive]);

  return (
    <div ref={menuRef} className="menu_container">
      {navMenuActive && <div className="modal-overlay" onClick={() => setNavMenuActive(false)}></div>}
      {
        <AnimatePresence>
          {navMenuActive && (
            <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition} className="menu_wrapper">
              <div className="menu_header">
                <div className="menu_header__title">Menu</div>
                <div onClick={() => setNavMenuActive(false)} className="close_menu_btn">
                  <Close />
                </div>
              </div>
              <div className="menu">
                <div className="menu_header__items">
                  <MenuItem setNavMenuActive={setNavMenuActive} text="Home" linkTo="/" />
                  {/* {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Education" linkTo="./education" />} */}
                  {/* {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Create" linkTo="./design" fill="#000" />} */}
                  {/* {(location.pathname !== "/" && location.pathname !== "/original" && location.pathname !== "/education") && <MenuItem setNavMenuActive={setNavMenuActive} text="All Duonuts" linkTo="./all_duonuts" />} */}
                  {isLoggedIn && <MenuItem setNavMenuActive={setNavMenuActive} text="My Duonuts" linkTo="./myduonuts" />}
                  {/* <MenuItem setNavMenuActive={setNavMenuActive} text="What are Duonuts ?" linkTo="./home" /> */}
                  {/* <MenuItem setNavMenuActive={setNavMenuActive} text="About Us" linkTo="./aboutus" /> */}
                  {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Showcase" linkTo="./templates/all" />}
                  {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Pricing" linkTo="./pricing" />}
                  {/* {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Case Studies" linkTo="./mentalhealth" />} */}
                  {(location.pathname !== "/original") && <MenuItem setNavMenuActive={setNavMenuActive} text="Contact Us" linkTo="./contactus" />}
                  {/* <MenuItem setNavMenuActive={setNavMenuActive} text="Pricing" linkTo="./pricing" /> */}

                </div>
                <div className="menu_bottom">
                  <div className="menu_buttons">
                    {(location.pathname !== "/original") ? <>
                      {!isLoggedIn ? (
                        <button
                          onClick={() => {
                            handleClick();
                            dispatch(showSignInForm());
                          }}
                          className="btn-secondary"
                          style={{ marginRight: "10px" }}
                        >
                          Sign in
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            dispatch(updateLogoutModalActive(true));
                          }}
                          className="btn-secondary sign-in-btn"
                        >
                          Sign out
                        </button>
                      )}
                      {!isLoggedIn && (
                        <button
                          onClick={() => {
                            handleClick();
                            dispatch(showSignUpForm());
                          }}
                          className="btn-primary"
                        >
                          {/* Sign up */}
                          Start for Free
                        </button>
                      )}
                    </>
                      : <button className="btn-primary" onClick={() => handleClickDemo()}>
                        Try for Free
                      </button>}
                  </div>
                  {/* <div className="menu_social_media_icons">
                    <img src="/assets/icons/facebook.svg" className="social-media-icon" />
                    <img src="/assets/icons/twitter.svg" className="social-media-icon" />
                    <img src="/assets/icons/whatsapp.png" className="social-media-icon" />
                    <img src="/assets/icons/mail.svg" className="social-media-icon" />
                    <img src="/assets/icons/instagram.svg" className="social-media-icon" />
                  </div> */}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      }
    </div>
  );
}
