import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../assets/icons/arrow-left.svg";
import { getDuonut } from "../../services/duonut";
import { convertLinkToDataUrl } from "../../utils";
import ImageList from "./ImageList";
import "./pdf.css";
import { saveAs } from 'file-saver';
// import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

export default function Pdf() {
  const navigate = useNavigate();
  const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
  const [duonutThumbnails, setDuonutThumbnails] = useState([])
  const { id: duonutId } = useParams();
  // const images = ["https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg", "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"];

  // console.log(duonutId, userActivities);

  const fetchDuonut = () => {
    // console.log("fetchDuonut");
    getDuonut(duonutId, async (err, res) => {
      if (err) {
        console.log(err);
        navigate(-1)
        return
      }
      // let thumbnails = []
      res.data.user_activity.map(activity => {

        // thumbnails.push({ uri: activity?.thumbnail?.uri });
        setDuonutThumbnails(prev => {
          return [...prev, { uri: activity?.thumbnail?.uri }]
        })

      })
    });
  };

  useEffect(() => {
    if (duonutId) return fetchDuonut()
  }, [])


  const downloadImage = () => {
    const input = document.getElementsByClassName("image-div");

    let dataToMap = duonutThumbnails

    for (let i = 0; i < dataToMap.length; i++) {
      html2canvas(input[i], { backgroundColor: "transparent" })
        .then(async (canvas) => {
          canvas.getContext("2d");
          var imgData = canvas.toDataURL("image/png", 1.0);
          var a = document.createElement("a")
          // a.href = "data:image/png;base64," + imgData;
          a.href = imgData;
          a.download = `activity-${i + 1}.png`;
          a.click();
          if (i === dataToMap.length - 1) return console.log('done')
        })
    }

  };
  const downloadImageJpeg = () => {
    const input = document.getElementsByClassName("image-div");

    let dataToMap = duonutThumbnails

    for (let i = 0; i < dataToMap.length; i++) {
      html2canvas(input[i])
        .then(async (canvas) => {
          canvas.getContext("2d");
          // The brower will only convert to image/jpeg if its support jpeg format
          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          var a = document.createElement("a")
          // a.href = "data:image/png;base64," + imgData;
          a.href = imgData;
          a.download = `activity-${i + 1}.jpeg`;
          a.click();
          if (i === dataToMap.length - 1) return console.log('done')
        })
    }
  }
  const downloadImageJpg = () => {
    const input = document.getElementsByClassName("image-div");

    let dataToMap = duonutThumbnails

    for (let i = 0; i < dataToMap.length; i++) {
      html2canvas(input[i])
        .then(async (canvas) => {
          canvas.getContext("2d");
          // The brower will only convert to image/jpg if its support jpg format
          var imgData = canvas.toDataURL("image/jpeg", 1.0);
          var a = document.createElement("a")
          // a.href = "data:image/png;base64," + imgData;
          a.href = imgData;
          a.download = `activity-${i + 1}.jpg`;
          a.click();
          if (i === dataToMap.length - 1) return console.log('done')
        })
    }
  }

  const genPdfs = () => {
    // const pdf = new jsPDF();
    const pdf = new jsPDF('p', 'px', 'a4');
    const input = document.getElementsByClassName('image-div');

    const imgWidth = input[0].getBoundingClientRect().width;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    let dataToMap = duonutThumbnails

    for (let i = 0; i < dataToMap.length; i++) {
      html2canvas(input[i])
        .then(async (canvas) => {
          const widthRatio = pageWidth / canvas.width;
          const heightRatio = pageHeight / canvas.height;
          const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

          const canvasWidth = canvas.width * ratio;
          const canvasHeight = canvas.height * ratio;

          const marginX = (pageWidth - canvas.width) / 2;
          const marginY = (pageHeight - canvas.height) / 2;
          const imgData = await canvas.toDataURL('image/png');
          let imageX = imgWidth > 500 ? 33 : 68
          let imageY = imgWidth > 500 ? 80 : 60

          // pdf.addImage(imgData, 'JPEG', imageX, imageY);
          // pdf.addImage(imgData, 'JPEG', marginX, marginY, pageWidth - 20, canvas.height);

          const imgProps = pdf.getImageProperties(imgData);
          if (imgProps.width > 500) {
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 200, pdfWidth, pdfHeight);
          } else {
            const pdfWidth = pdf.internal.pageSize.getWidth() - 200;
            const pdfHeight = (imgProps.height * pdfWidth) / (imgProps.width);
            pdf.addImage(imgData, 'PNG', 100, 50, pdfWidth, pdfHeight);
          }

          if (i !== dataToMap.length - 1) pdf.addPage();
          if (i === dataToMap.length - 1) pdf.save(`duonuts.pdf`);
        }).catch(err => console.log(err))
    }
  }

  const handleDownloadVideo = async () => {
    // Generate the video
    // await generateVideo(images);
  };

  // const generateVideo = async (images) => {
  //   const ffmpeg = createFFmpeg({ log: true });

  //   // Load FFmpeg
  //   await ffmpeg.load();

  //   // Create a new video
  //   ffmpeg.FS('mkdir', 'input');
  //   images.forEach((image, index) => {
  //     ffmpeg.FS('writeFile', `input/${index}.png`, image);
  //   });

  //   // Generate the video
  //   await ffmpeg.run('-i', 'input/%d.png', '-r', '30', '-c:v', 'libx264', '-preset', 'slow', '-crf', '18', '-pix_fmt', 'yuv420p', 'output.mp4');

  //   // Read the video
  //   const data = ffmpeg.FS('readFile', 'output.mp4');

  //   // Create a Blob from the video data
  //   const blob = new Blob([data.buffer], { type: 'video/mp4' });

  //   // Save the video using FileSaver.js
  //   saveAs(blob, 'video.mp4');

  //   // Remove the input and output files
  //   ffmpeg.FS('unlink', 'output.mp4');
  //   ffmpeg.FS('rmdir', 'input');
  // };

  return (
    <div style={{ marginTop: "6rem" }} className="pdf-container">
      <div
        className="pdf-header">
        {/* <img src={BackIcon} className='back-icon' style={{width:" 20px"}} /> */}
        <span className="back-icon icon-button" onClick={() => navigate(-1)}>
          {" "}
          {`<`}{" "}
        </span>
        <button className="btn-primary" onClick={genPdfs}>
          Download pdf
        </button>
        {/* <button className="btn-primary" onClick={handleDownloadVideo}>
          Download Video
        </button> */}
        <button className="btn-primary" onClick={downloadImage}>
          Download Png
        </button>
        <button className="btn-primary" onClick={downloadImageJpeg}>
          Download Jpeg
        </button>
        <button className="btn-primary" onClick={downloadImageJpg}>
          Download Jpg
        </button>

      </div>
      {duonutId && <ImageList data={duonutThumbnails} />}
    </div>
  );
}
