// import { DownloadDone, DownloadOutlined, DownloadRounded, Preview, PreviewRounded } from "@mui/icons-material";
import React, { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Download } from "../../../assets/icons/download2";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
// import { toggleModal } from "../../../redux/slices/preview";
import { selectUserActivity } from "../../../redux/slices/userActivity";
// import Loader from "../../Loader";

export default function DotMenuItems({ setDotMenuItemsActive, handlePublish, publishing, handleNavigate }) {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const handleClose = () => setDotMenuItemsActive(false);
  useOutsideAlerter(menuRef, handleClose);
  // const { isVideo } = useSelector((state) => state.userActivity);

  const handlePreview = ()=>{
    // if(isVideo){
    //   dispatch(selectUserActivity(0))
    // }
    // dispatch(toggleModal())
  }
  return (
    <div className="dot-menu-items" ref={menuRef}>
      <button className="dot-menu-item" style={{ display: "flex", alignItems: "center", gap: "4px" }} onClick={handlePreview}>
        {/* <PreviewRounded style={{ width: "20px" }} /> */}
        <span>Preview</span>
      </button>
      <button
        className="dot-menu-item"
        style={{ display: "flex", alignItems: "center", gap: "4px" }}
        onClick={() => {
          handleClose();
          handleNavigate("/pdf");
        }}
      >
        {/* <DownloadRounded style={{ width: "20px" }} /> */}
        <span>Download</span>
      </button>
    </div>
  );
}
