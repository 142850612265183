import React from 'react'

export default function Close({color}) {


    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.3514 1.28033C8.64429 0.987437 8.64429 0.512563 8.3514 0.21967C8.05851 -0.0732233 7.58363 -0.0732233 7.29074 0.21967L4.28554 3.22487L1.28034 0.21967C0.987446 -0.0732227 0.512572 -0.073223 0.219679 0.21967C-0.073214 0.512563 -0.0732138 0.987437 0.219679 1.28033L3.22488 4.28553L0.21967 7.29074C-0.0732231 7.58363 -0.0732234 8.0585 0.21967 8.3514C0.512563 8.64429 0.987437 8.64429 1.28033 8.3514L4.28554 5.34619L7.29075 8.3514C7.58364 8.64429 8.05851 8.64429 8.35141 8.3514C8.6443 8.05851 8.6443 7.58363 8.35141 7.29074L5.3462 4.28553L8.3514 1.28033Z" 
            fill={color ? color : '#fff'} />
        </svg>

    )
}
