import React, { useEffect, useState } from "react";
import './HomePage.css';
import './css/namari-color.css';
import './css/style123.css';
import './css/animate.css';
import './css/font-awesome.min.css';
import { useSelector, useDispatch } from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
import { Link, useNavigate } from "react-router-dom";
// import TextTransition from "react-text-transition";
import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import { toggleDemoModalDuonut } from "../../redux/slices/DemoReducer";
import tickmark from './images/tick.svg';
import crossmark from './images/cross.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import Slider from "react-slick";
import { Tooltip as ReactTooltip } from "react-tooltip";

const HomePage = () => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { isLoggedIn } = useSelector((state) => state.user);
    const [selectedBtn, setselectedBtn] = useState(0);
    const [index, setIndex] = React.useState(0);
    const navigate = useNavigate();
    // const texts = ["forms", "surveys", "activities", "work flows"];
    const texts = ["workflows", "logic", "customization"];
    const [videoThumb, setvideoThumb] = useState(true);
    const [settingTabNum, setsettingTabNum] = useState(1);

    // console.log("homepage")

    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2000 // every 1.5 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    const handleClick = () => dispatch(openModal());

    const handleClickSignUp = () => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignUpForm());
        } else {
            navigate("/design");
        }

    };

    const handelVideoClick = () => {
        setvideoThumb(false);
        var video = document.getElementById("myVideo");
        if (video.paused) {
            video.play();
        }
    };

    const buyBtn = (item) => {
        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            setselectedBtn(item);
        } else {
            if (item === 1) {
                navigate('/payment/pro');
                // window.open("https://rzp.io/l/9uSUqpj");
            } else if (item === 2) {
                navigate('/payment/plus');
                // window.open("https://rzp.io/l/qYlzpZGWFE");
            } else if (item === 3) {
                window.open("https://rzp.io/l/OjUDGY06");
            }
            // else if (item === 4) {
            //     window.open("tel:" + "9353709306");
            // }
        }
    }

    useEffect(() => {
        // console.log("after login")
        if (selectedBtn === 1) {
            navigate('/payment/pro');
            // window.open("https://rzp.io/l/9uSUqpj");
        } else if (selectedBtn === 2) {
            navigate('/payment/plus');
            // window.open("https://rzp.io/l/qYlzpZGWFE");
        } else if (selectedBtn === 3) {
            window.open("https://rzp.io/l/OjUDGY06");
        }
        // else if (selectedBtn === 4) {
        //     window.open("tel:" + "9353709306");
        // }
    }, [isLoggedIn]);

    const handleClickCreateFromScratch = () => {
        dispatch(toggleCreateFromScratchModalDuonut(true));
    };

    const handleClickDemo = () => {
        dispatch(toggleDemoModalDuonut(true));
    };

    useEffect(() => {
        var video = document.getElementById("myVideo");
        if (video && video.paused) {
            video.play();
        }
    }, [document.getElementById("myVideo")]);


    return (
        <div className="my-container">
            {/* <div className="homepageAiContainer1">
                <div style={{ cursor: "pointer" }} onClick={() => navigate("/ai")}>Duonut AI is LIVE. <label style={{ color: "#0e2c45", cursor: "pointer" }}>Try it out &rarr;</label></div>
            </div> */}

            {/* <div className="homepageAiContainer1">
                &#128640; Limited time offer $399 for lifetime Pro access.
                <label onClick={() => navigate("/pricing")}
                    style={{ fontWeight: "600", textDecoration: "underline", cursor: "pointer", marginLeft: "8px" }}>
                    Buy once use forever</label>
                <label data-tooltip-id="offer-tooltip" data-tooltip-content="What does “lifetime” mean? Click here to know more"
                    style={{ marginLeft: "8px", cursor: "pointer" }}
                    onClick={() => window.open("https://duonut.com/blog/what-does-lifetime-mean-in-duonuts-lifetime-deal-clxsu3vc2001r501ptzmedui3")}>
                    <i className="fa fa-question-circle-o" aria-hidden="true"></i></label>
                <ReactTooltip id="offer-tooltip" place="bottom" effect="solid" />
            </div> */}

            <div id="wrapper">

                <header id="banner" className="scrollto clearfix" data-enllax-ratio=".5">

                    <div id="banner-content" className="row rowContainer clearfix">

                        <div className="" style={{ width: "100%", textAlign: "center" }}>

                            <div className="section-heading">
                                <h1 style={width > 800 ? { lineHeight: "70px" } : {}}>The most powerful no-code <label style={{ color: "#3184c9" }}>workflow</label><br></br>
                                    <label style={{ color: "#3184c9" }}>automation </label>software
                                    {/* & <label style={{ color: "#3184c9" }}>workflows</label> */}
                                    {/* <label style={{ width: width > 800 ? "27rem" : "20rem", display: "inline-flex", justifyContent: width > 800 ? "flex-start" : "center", color: "#3184c9", paddingLeft: width > 800 ? "1rem" : "0rem" }}>
                                        <TextTransition
                                            // style={{ width: "20rem", display: "inline-flex", justifyContent: "center", backgroundColor: "#3184C9", margin: "0rem 1rem" }}
                                            text={texts[index % texts.length]}
                                            // springConfig={presets.molasses }
                                            inline={false}
                                        />
                                    </label > */}
                                </h1>
                                <h2 style={{ marginBottom: "1.5rem" }}>From tasks and workflows to apps and systems, build and automate anything
                                    <br></br> in one powerful visual platform - now enhanced with GenAI.</h2>
                            </div>

                            <button onClick={() => handleClickDemo()} className="createFreeFormButton" style={{ marginBottom: "8px", padding: "18px" }}>BOOK A DEMO</button>
                            {/* <div style={{ fontSize: "0.7rem", opacity: "0.6" }}>No Signup Required</div> */}
                            {/* <a href="/templates" className="button" style={{ marginLeft: width > 800 ? "2rem" : "0rem" }}>CREATE FROM TEMPLATES</a> */}
                            <button onClick={() => handleClickCreateFromScratch()} className="button" style={{ marginLeft: width > 800 ? "2rem" : "0rem", padding: "15px" }}>GET STARTED FOR FREE</button>

                        </div>

                    </div>
                </header>

                <main id="content">

                    <div style={{ position: "relative", width: width > 800 ? "70%" : "95%", margin: width > 800 ? "-15rem auto 5rem auto" : "-5rem auto 5rem auto", border: "3px solid rgba(255, 255, 255, 0.08)", boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.08)", borderRadius: "16px" }}>
                        <video width="400" style={{ width: "100%", borderRadius: "16px" }} id="myVideo" playsinline autoplay muted loop controls>
                            <source src="/assets/file/video1.mp4" type="video/mp4" />
                            {/* <source src="mov_bbb.ogg" type="video/ogg" />
                        Your browser does not support HTML video. */}
                        </video>
                        {/* {videoThumb &&
                            <div style={{
                                cursor: "pointer", position: "absolute", left: "0", top: "0", width: "100%", height: "100%",
                                padding: "0.5rem", borderRadius: "16px", display: 'flex', justifyContent: "center", alignItems: "center"
                            }}>
                                <img onClick={e => handelVideoClick()}
                                    loading="lazy"
                                    style={{ width: "120px", height: "120px" }}
                                    src="/assets/group-1.svg" alt="message" />
                            </div>} */}
                    </div>

                    <div className="home_page_integration_section">
                        <div className="home_page_section_header">Trusted by Startups & Enterprises</div>

                        <div className="home_page_integration_section_images">
                            <a href="https://www.narayanahealth.org/" target="_blank"><img src="/assets/company/Narayana.png" loading="lazy" alt="duonut" /></a>
                            <img src="/assets/company/xe.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/mintorie.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/ourlearn.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/uleash.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/vivahit1.png" loading="lazy" alt="duonut" />
                            <a href="https://shawarmer.com/" target="_blank"><img style={{ width: "145px" }} src="/assets/company/Shawarmer.png" loading="lazy" alt="duonut" /></a>

                        </div>

                    </div>

                    <div className="home_page_integration_section">
                        <div className="home_page_section_header">Who else hit it off with Duonut?</div>

                        <div className="home_page_integration_section_images">
                            <img src="/assets/company/hubspot.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/forms.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/slack.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/gmail.png" loading="lazy" alt="duonut" />
                        </div>
                    </div>

                    <div style={{ marginBottom: "8rem" }}>
                        <div className="home_page_section_header" style={{ paddingBottom: "2rem" }}>What People Are Saying</div>
                        <Slider {...sliderSettings}>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I absolutely love using Duonut! It's so easy to create customized forms and workflows without needing any technical skills. The variety of templates makes it simple to collect all kinds of information. I highly recommend it to anyone looking for a user-friendly form builder.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review3.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Nicola Lanzilotto</div>
                                        <div>Senior Frontend Developer @Tiledesk</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    Good data and customer feedback as all we need to build quality products. These forms help with that 👍
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review4.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Niklas Buchfink</div>
                                        <div>Design Engineer</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div>
                                    <img style={{ width: "12rem", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")} src="/assets/company/shawarmer_logo.png" loading="lazy" alt="duonut" />
                                </div>
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    As a leading restaurant chain in the Middle East, at Shwarmer we are innovating new ways to engage with our customers effectively. Duonut has been a great partner in this journey. We can quickly create personalized customer workflows and collect quality feedback with a much higher completion rate.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://shawarmer.com/")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/shawarmer_profile.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Don Sajith</div>
                                        <div>E-commerce Manager (Strategy, Marketing, Loyalty, Delivery, CRM & Transformation)</div>
                                        <div>Shawarmer. Saudi Arabia</div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_page_customer_review_Container">
                                <div style={{ margin: '2rem 0rem', textAlign: "center" }}>
                                    I became a member and reviewed the tools. I didn't expect preparing a form could be this easy. It's great.
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => window.open("https://www.producthunt.com/posts/duonut-2/launch-day")}>
                                    <img style={{ width: "6rem" }} src="/assets/company/customer_review2.png" loading="lazy" alt="duonut" />
                                    <div style={{ marginLeft: "1rem" }}>
                                        <div style={{ fontSize: "1.2rem", fontWeight: "700" }}>Ayhan Ergezen</div>
                                        <div>Founder at LivelyPencil</div>
                                    </div>
                                </div>
                            </div>
                        </Slider>

                    </div>

                    <div style={{ padding: "6rem 0rem", backgroundColor: "rgb(244, 249, 252)" }}>
                        <div className="home_page_section_header" style={{ paddingBottom: "2rem" }}>Built for your use case</div>

                        <div className="home_page_casestudy_HeaderTab">
                            <div className="home_page_casestudy_HeaderTabInner">
                                <label className="home_page_casestudy_header-label" style={settingTabNum === 1 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(1)}>
                                    Insurance
                                </label>
                                <label className="home_page_casestudy_header-label" style={settingTabNum === 2 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(2)}>
                                    Healthcare
                                </label>
                                <label className="home_page_casestudy_header-label" style={settingTabNum === 3 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(3)}>
                                    Manufacturing
                                </label>
                                <label className="home_page_casestudy_header-label" style={settingTabNum === 4 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(4)}>
                                    Education
                                </label>
                                {/* <label className="home_page_casestudy_header-label" style={settingTabNum === 5 ? { backgroundColor: "white", boxShadow: "0 3px 11px rgba(22, 27, 47, .12)" } : {}} onClick={() => setsettingTabNum(5)}>
                                    Payments
                                </label> */}
                            </div>
                        </div>

                        {settingTabNum === 1 &&
                            <div className="home_page_casestudy_mainConatiner">
                                <div className="home_page_casestudy_innerConatiner">
                                    <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Insurance</div>
                                    <br></br>
                                    <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem" }}>
                                        <li>Optimize insurance quote forms </li>
                                        <br></br>
                                        <li>Streamline broker and carrier underwriting workflows  </li>
                                    </ul>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => window.open("usecases/insurance")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                        Learn more &nbsp; &rarr;
                                    </button>
                                </div>
                                <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                    <img style={{}} src="/assets/usecase/duonut_insurance.png" loading="lazy" alt="duonut" />
                                </div>
                            </div>
                        }
                        {settingTabNum === 2 &&
                            <div className="home_page_casestudy_mainConatiner">
                                <div className="home_page_casestudy_innerConatiner">
                                    <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Healthcare</div>
                                    <br></br>
                                    <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem" }}>
                                        <li>Seamless Healthcare Marketing </li>
                                        <br></br>
                                        <li>Transform Patient onboarding process </li>
                                        <br></br>
                                        <li>Medical Office management </li>
                                    </ul>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => window.open("usecases/healthcare")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                        Learn more &nbsp; &rarr;
                                    </button>
                                </div>
                                <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                    <img style={{}} src="/assets/usecase/duonut_patient_registration process.png" loading="lazy" alt="duonut" />
                                </div>
                            </div>
                        }
                        {settingTabNum === 3 &&
                            <div className="home_page_casestudy_mainConatiner">
                                <div className="home_page_casestudy_innerConatiner">
                                    <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Manufacturing</div>
                                    <br></br>
                                    <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem" }}>
                                        <li>Reduce manual errors by creating effective checklist and SOPs </li>
                                        <br></br>
                                        <li>Collect & manage data with paperless workflows </li>
                                    </ul>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => window.open("usecases/manufacturing")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                        Learn more &nbsp; &rarr;
                                    </button>
                                </div>
                                <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                    <img style={{ width: "25rem" }} src="/assets/usecase/duonut_checklist.jpg" loading="lazy" alt="duonut" />
                                </div>
                            </div>
                        }
                        {settingTabNum === 4 &&
                            <div className="home_page_casestudy_mainConatiner">
                                <div className="home_page_casestudy_innerConatiner">
                                    <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Education</div>
                                    <br></br>
                                    <ul style={{ listStyle: "unset", paddingLeft: "1rem", fontSize: "1.3rem" }}>
                                        <li>Student engagement, servicing and intake forms. </li>
                                        <br></br>
                                        <li>Streamline work across departments. </li>
                                        <br></br>
                                        <li>Prospective student and alumni registration. </li>
                                    </ul>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => window.open("usecases/education")} className="UCI1Button" style={{ marginRight: "1rem" }}>
                                        Learn more &nbsp; &rarr;
                                    </button>
                                </div>
                                <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                    <img style={{}} src="/assets/usecase/duonjut_education.png" loading="lazy" alt="duonut" />
                                </div>
                            </div>
                        }
                        {settingTabNum === 5 &&
                            <div className="home_page_casestudy_mainConatiner">
                                <div className="home_page_casestudy_innerConatiner">
                                    <div style={{ fontSize: "2rem", fontWeight: "700", marginBottom: '1.5rem' }}>Payments</div>
                                    <div>
                                        Create custom payment flows with advanced product selection controls and either built-in or custom payment collection interfaces. Utilize intuitive no-code calculations to display prices, and embed your flow within your application or website to provide a seamless checkout experience for all customers.
                                    </div>
                                </div>
                                <div className="home_page_casestudy_innerConatiner" style={{ alignItems: "center" }}>
                                    <img style={{}} src={require("./images/usecase5.png")} loading="lazy" alt="duonut" />
                                </div>
                            </div>
                        }

                    </div>

                    <div className="home_page_integration_section" style={{ margin: "2.5rem auto" }}>
                        <div className="home_page_section_header" style={{ paddingBottom: "1rem" }}>Backed by</div>
                        <div className="home_page_integration_section_images"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ marginRight: "3rem" }} src="/assets/company/backendby1.png" loading="lazy" alt="duonut" />
                            <img src="/assets/company/backendby2.png" loading="lazy" alt="duonut" />

                        </div>
                    </div>

                    <div id="services" className="scrollto clearfix" style={{ backgroundColor: "white", position: "relative", zIndex: "1", width: width > 800 ? "80%" : "95%", padding: width > 800 ? "2rem" : "1rem", margin: "auto", boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "50px" }}>

                        <div className="row rowContainer no-padding-bottom clearfix" style={{ padding: "0px" }}>

                            <div className="col-2">
                                <div className="section-heading section-heading2">
                                    {/* <h3 style={{ fontSize: "16px", letterSpacing: "10px" }}>BELIEVING</h3> */}
                                    <h2 className="section-title">Market research automation that helps you see the big picture:</h2>
                                    <p className="section-subtitle">Create research efforts by using no code builders for various types of surveys, forms, NPS surveys and more. Gather quality data instantly and use them to automate market research, as it is the only key to streamlining data collection, analysis, and reporting.
                                        <br></br><br></br>Drive high-quality leads and more inbound sourced revenue with effective lead generation.</p>
                                </div>
                                <button onClick={() => handleClickCreateFromScratch()} data-videoid="UYJ5IjBRlW8" data-videosite="youtube" className="button video link-lightbox">
                                    START FOR FREE
                                    {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                                </button>
                            </div>
                            <div className="col-2" style={width > 800 ? { display: "flex", flexDirection: "column", justifyContent: "center" } : { order: "2" }}>
                                <img loading="lazy"
                                    // style={{ boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "16px" }}
                                    src={require("./images/17.png")} alt="duonut" />
                            </div>

                        </div>

                    </div>

                    <div id="services" className="scrollto clearfix" style={{ backgroundColor: "#f4f9fc", marginTop: width > 800 ? "-58rem" : "0px", paddingBottom: "5rem" }}>

                        <div className="row rowContainer no-padding-bottom clearfix" style={{ paddingTop: width > 800 ? "65rem" : "0px" }}>

                            <div className="col-2" style={width > 800 ? {} : { order: "2" }}>
                                <img loading="lazy"
                                    // style={{ boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.1)", borderRadius: "16px" }}
                                    src={require("./images/18.png")} alt="duonut" />
                            </div>

                            <div className="col-2">
                                <div className="section-heading section-heading2">
                                    {/* <h3 style={{ fontSize: "16px", letterSpacing: "10px" }}>BELIEVING</h3> */}
                                    <h2 className="section-title">Automate your sales workflows:</h2>
                                    <p className="section-subtitle">Enhance your sales efficiency and close deals faster with automated lead routing. Streamline your prospecting process and seamlessly integrate AI into your existing sales stack. In no time, you’ll automate routine daily prospecting tasks, allowing you to focus on what truly matters: closing more deals.
                                    </p>
                                </div>
                                <button onClick={() => handleClickCreateFromScratch()} data-videoid="UYJ5IjBRlW8" data-videosite="youtube" className="button video link-lightbox">
                                    START FOR FREE
                                    {/* <i className="fa fa-play" aria-hidden="true"></i> */}
                                </button>
                            </div>
                        </div>


                    </div>

                    <div style={{
                        // backgroundColor: "rgb(244, 249, 252)"
                    }}>
                        <div className='HPS1secMain' style={{ width: width > 800 ? "80%" : "95%", margin: "auto", padding: "4rem  0" }}>
                            <div className='HPS1secContainer'>
                                <div className='HPS1secImgContainer'>
                                    <img className='HPS1secimage' src="/assets/code.svg" alt="duonut" loading="lazy" />
                                </div>
                                <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Embed</h3>
                                <div style={{ textAlign: "center" }}>
                                    Integrate Duonut forms seamlessly into any website or landing page, be it Unbounce, WordPress, Webflow, Framer, or other builders.
                                </div>
                            </div>

                            <div className='HPS1secContainer'>
                                <div className='HPS1secImgContainer'>
                                    <img className='HPS1secimage' src="/assets/link.svg" alt="duonut" loading="lazy" />
                                </div>
                                <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Duonut Links</h3>
                                <div style={{ textAlign: "center" }}>
                                    Share your unique Duonut link with anyone in any platform.
                                </div>
                            </div>

                            <div className='HPS1secContainer'>
                                <div className='HPS1secImgContainer'>
                                    <img className='HPS1secimage' src="/assets/dynamic-feed.svg" alt="duonut" loading="lazy" />
                                </div>
                                <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Popup</h3>
                                <div style={{ textAlign: "center" }}>
                                    Create popup forms wherever you want on your website in seconds.
                                </div>
                            </div>

                            <div className='HPS1secContainer'>
                                <div className='HPS1secImgContainer'>
                                    <img className='HPS1secimage' src="/assets/captive-portal.svg" alt="duonut" loading="lazy" />
                                </div>
                                <h3 style={{ padding: "10px 0px", fontSize: "1.2rem" }}>Custom Domains</h3>
                                <div style={{ textAlign: "center" }}>
                                    Host forms on your own (sub)domain to create branded form links.
                                </div>
                            </div>
                        </div>
                    </div>

                    <section id="pricing" className="text-center scrollto clearfix ">
                        <div className="row clearfix">

                            {/* <div className='pricingFreeContainerMain' style={{ backgroundColor: "white", position: "relative", zIndex: "1", width: "85%", padding: "1.5rem", margin: "1rem", margin: "auto", borderRadius: "50px", border: "4px solid #F4F9FC" }}>
                                <h2 style={{ fontWeight: "400", paddingBottom: "2rem" }}>All Duonut <label style={{ color: "#3184C9" }}>Form</label> features are freely available to every user.</h2>
                                <div className='pricingFreeContainer'>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited submissions</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited Templates</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Form Logic</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom `Thank You` page</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> File upload</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Unlimited Questions</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Email notifications</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Redirect on completion</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Answer piping</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Schedule a close date</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hundreds of Templates</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom CSS</div>
                                    </div>
                                    <div className='pricingFreeblock'>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Close on submission limit</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Custom closed message</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Slack Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Hubspot Integration</div>
                                        <div><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" /> Webhooks</div>
                                    </div>
                                </div>
                                <a onClick={() => handleClickCreateFromScratch()} className="button" style={{ margin: "2rem 0rem" }}>START FOR FREE</a>
                            </div> */}

                            {/* <div style={{ backgroundColor: "#f4f9fc", borderRadius: "120px 120px 0px 0px", paddingBottom: "4rem" }}>
                                <div className="section-heading">
                                    <h2 className="section-title" style={{ padding: "2rem" }}>Pricing</h2>
                                </div>

                                <div style={{ width: "90%", margin: "auto", paddingBottom: "2rem", display: "flex", justifyContent: "center", flexDirection: width > 800 ? "unset" : "column" }}>

                                    <div className="pricing-block featured col-3 wow fadeInUp" data-wow-delay="0.6s">
                                        <div className="pricing-block-content">
                                            <div className="pricing" style={{ backgroundColor: "rgb(49, 132, 201)", color: "white" }}>
                                                <h3>DUONUT</h3>
                                                <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Individuals</p>
                                                <div className="price" style={{ color: "rgb(14, 44, 69)", fontSize: "5rem" }}>Free</div>
                                                <div>FOREVER</div>
                                                <div style={{ marginTop: "2.8rem", fontSize: "1rem" }}>All Duonut Form features are freely <br></br>available to every user.</div>
                                            </div>
                                            <ul>
                                                <li><img className="tickmark-icon" src={tickmark} />  Unlimited templates</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Unlimited questions</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Unlimited pages</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Unlimited styles & components</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  1,500 monthly submissions</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Up to 5 live forms</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  100 emails / month</li>

                                              </ul>
                                            <button onClick={() => handleClickCreateFromScratch()} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>START FOR FREE</button>
                                        </div>
                                    </div>

                                    <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.4s">
                                        <div className="pricing-block-content">
                                            <div className="pricing" style={{ backgroundColor: "rgb(14, 44, 69)", color: "white" }}>
                                                <h3>DUONUT PRO</h3>
                                                <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Small Startups</p>
                                                <div className="price" style={{ color: "rgb(49, 132, 201)" }}>$29</div>
                                                <div>PER MONTH</div>
                                                <p style={{ fontWeight: "600", marginBottom: "0.2rem" }}>Billed annually</p>
                                                <div>Create your own branded forms<br></br> and workflows</div>
                                            </div>
                                            <ul>
                                                <li><img className="tickmark-icon" src={tickmark} />  Up to 10 live forms</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Remove Duonut branding</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Custom HTML & CSS</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Custom userflow logic</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Custom Thank you Page</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Redirect on completion</li>
                                                <li><img className="tickmark-icon" src={tickmark} />  Answer piping & more</li>
                                            </ul>
                                            <button onClick={() => buyBtn(1)} className="createFreeFormButton" style={{ width: "80%", display: "inline-block", marginBottom: "2rem", fontSize: "16px" }}>BUY TODAY</button>
                                        </div>
                                    </div>

                                    <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.8s">
                                        <div className="pricing-block-content" style={{ height: "100%" }}>
                                            <div className="pricing" style={{ backgroundColor: "#d1eaff", color: "black", borderRadius: "40px" }}>
                                                <h3 style={{ color: "black" }}>DUONUT BUSINESS</h3>
                                                <p className="pricing-sub" style={{ color: "black", marginBottom: "2rem" }}>For Large teams</p>

                                                <a className="button" onClick={() => handleClickDemo()} style={{ width: "80%", fontSize: "1rem", margin: "4.8rem auto", padding: "1rem 1.5rem" }} >Contact Us</a>
                                            </div>

                                            <ul>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Advanced logic & API</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom subdomain</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  White Glove Support</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Enterprise SLAs</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Workspaces</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Custom Form Services</li>
                                                <li><img className="tickmark-icon" src={tickmark} alt="duonut" loading="lazy" />  Unlimited Workspaces</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </section>

                </main>

            </div>
        </div >
    );
}

export default HomePage;