import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";

import axios from 'axios';
// const axios = require("axios");

const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};


export const userMappingSlide = createSlice({
  name: "userMapping",
  initialState: {
    response: [],
    modalActive: false,
    nuggetId: "",
  },
  reducers: {
    saveUserMapping: (state, action) => {
      state.response = [action.payload];
    },
    toggleUserMappingModal: (state, { payload }) => {
      state.modalActive = payload;
    },
    updateNuggetId: (state, { payload }) => {
      state.nuggetId = payload;
    },
  }
});

export const getNuggetViewerAsync = (id) => async (dispatch) => {
  try {
    const header = getAuthHeaders();
    var API_URL;
    API_URL = `${domain}/duonut/get_nugget_viewer_detail/` + id;
    const response = await axios.get(API_URL, header);
    // console.log(response.data);
    dispatch(saveUserMapping(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const createNuggetViewerMapperAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/create_nugget_viewer_mapper`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteNuggetViewerMapperAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/delete_nugget_viewer_mapper`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
  } catch (err) {
    throw new Error(err);
  }
};

export const getDuonutUserAccessAsync = (data) => async (dispatch) => {
  try {
      // console.log(data);
      const header = getAuthHeaders();
      const API_URL = `${domain}/duonut/get_duonut_user_access`;
      const response = await axios.post(API_URL, data, header);
      return response.data;
      // console.log(response);
  } catch (err) {
      throw new Error(err);
  }
};

export const { saveUserMapping, toggleUserMappingModal, updateNuggetId } = userMappingSlide.actions;
export const showUserMappingresponse = (state) => state.userMapping.response;
export default userMappingSlide.reducer;
