import React, { useState, useRef, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import "./NuggetViewer.css"
import { frontendUrl, backendUrl } from "../../services/constants";
import { saveChannelAsync, showChannelresponse } from "../../redux/slices/ChannelReducer";
import { getNuggetsAsync, showNuggetsresponse } from "../../redux/slices/NuggetsReducer";
import Duonut from './Duonut';


const NuggetViewer = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.user);

    var frontend_url = "";
    var subDomain = localStorage.getItem("subDomain");
    if (subDomain) {
        frontend_url = `https://${subDomain}.duonut.com`
    } else {
        frontend_url = frontendUrl;
    }

    const [nuggetAllData, setnuggetAllData] = useState("");
    const [nuggetDuonutData, setnuggetDuonutData] = useState([]);
    const [message, setmessage] = useState("");

    useEffect(() => {
        dispatch(getNuggetsAsync(paramsid));
        // console.log("inside");
    }, []);
    const nuggetData = useSelector(showNuggetsresponse);
    const nuggetData1 = nuggetData[0];

    // console.log(channelData1);

    useEffect(() => {
        if (nuggetData1) {
            // console.log(nuggetData1);
            if (nuggetData1.empty_message) {
                // setcreateChannel(true);
            } else {
                localStorage.setItem("subDomain", nuggetData1.subdomain);
                localStorage.setItem("channelLogo", nuggetData1.nugget_details.channel_logo);

                if (!nuggetData1.nugget_details.visibility && !isLoggedIn) {
                    setmessage("you have not access this Nuggets");
                    return;
                } else if (!nuggetData1.nugget_details.visibility && isLoggedIn) {
                    setTimeout(() => {
                        var user_id = localStorage.getItem("userId");
                        if (parseInt(nuggetData1.nugget_details.created_by) === parseInt(user_id)) {
                            setmessage("");
                            setnuggetAllData(nuggetData1.nugget_details);
                            var dData = [];
                            nuggetData1.nugget_details.nugget_duonut.map((item, i) => {
                                dData.push(item);
                            });
                            dData.sort(function (a, b) {
                                return parseFloat(a.priority) - parseFloat(b.priority);
                            });
                            // console.log(dData);
                            setnuggetDuonutData(dData);
                        } else {
                            for (let i = 0; i < nuggetData1.nugget_user.length; i++) {
                                // console.log("user_id", user_id, nuggetData1.nugget_user[i].viewer.id)
                                if (parseInt(nuggetData1.nugget_user[i].viewer.id) === parseInt(user_id) || parseInt(nuggetData1.nugget_details.created_by) === parseInt(user_id)) {
                                    setmessage("");
                                    setnuggetAllData(nuggetData1.nugget_details);
                                    var dData = [];
                                    nuggetData1.nugget_details.nugget_duonut.map((item, i) => {
                                        dData.push(item);
                                    });
                                    dData.sort(function (a, b) {
                                        return parseFloat(a.priority) - parseFloat(b.priority);
                                    });
                                    // console.log(dData);
                                    setnuggetDuonutData(dData);
                                    break;
                                } else {
                                    setmessage("you have not access this Nuggets");
                                }
                            }
                        }

                    }, 1000); //miliseconds
                } else {
                    setmessage("");
                    setnuggetAllData(nuggetData1.nugget_details);
                    var dData = [];
                    nuggetData1.nugget_details.nugget_duonut.map((item, i) => {
                        dData.push(item);
                    });
                    dData.sort(function (a, b) {
                        return parseFloat(a.priority) - parseFloat(b.priority);
                    });
                    // console.log(dData);
                    setnuggetDuonutData(dData);
                }
            }
        }
    }, [nuggetData1, isLoggedIn])

    const handleDuonut = (e, display_id) => {
        window.open(`/duonut/${display_id}`)
        e.stopPropagation();
    }


    return (
        <div className='nuggetApp' key={message}>
            <div className='ADP1backBtn' onClick={() => { navigate(`/viewworkflow/${nuggetData1?.nugget_details?.channel_code}`); }}>
                <i style={{ fontSize: "1.5rem", padding: "0px 8px 2px 0px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to Workflow
            </div>
            {message && <div style={{}}><img style={{ width: "100%", height: "70vh", objectFit: "contain" }} src='/assets/not_access.png'></img></div>}
            {nuggetAllData && <div className='NV1Template'>
                <h1 style={{ paddingBottom: "2rem" }}>{nuggetAllData.name}</h1>
                <div className='NV1duonutContainer'>
                    {nuggetDuonutData.map((duonut, i) => {
                        // console.log(duonut)
                        if (duonut.length <= 0) return;
                        return (
                            <Duonut {...duonut.duonut} key={duonut.id} />
                        )
                    })}
                </div>
            </div>}
        </div>
    );

}

export default NuggetViewer;