import { createSlice } from "@reduxjs/toolkit";

//enter-form-code email will come from forgot-password-modal
const initialState = {
  modalOpen: false,
  signInActive: false,
  signUpactive: false,
  forgotPasswordActive: false,
  enterCodeForm: {
    email: "",
    enterCodeModalActive: false,
    fromRegister: false,
  },
  changePasswordModalActive: false,
  resetPasswordEmail: "",
  logoutModalActive: false,
  deleteDuonutConfirmModal: {
    active: false,
    duonutId: null,
  },
  deleteUserConfirmModal: {
    active: false,
    userId: null,
  },
};

// form slice which the user is designing
const form = createSlice({
  name: "form",
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.modalOpen = true;
    },
    closeModal: (state, { payload }) => {
      state.modalOpen = false;
    },
    showSignInForm: (state, { payload }) => {
      state.signUpactive = false;
      state.signInActive = true;
    },
    showSignUpForm: (state, { payload }) => {
      state.signUpactive = true;
      state.signInActive = false;
    },
    toggleforgotPassword: (state, { payload }) => {
      return {
        ...state,
        forgotPasswordActive: !state.forgotPasswordActive,
        // modalOpen: state.forgotPasswordActive === true ? true : false,
      };
    },

    updateEnterCodeEmail: (state, { payload }) => {
      state.enterCodeForm.email = payload;
    },
    toggleEnterCodeModal: (state, { payload }) => {
      state.enterCodeForm.enterCodeModalActive = !state.enterCodeForm.enterCodeModalActive;
      state.enterCodeForm.fromRegister = payload.fromRegister;
    },

    togglechangePasswordModal: (state, { payload }) => {
      state.changePasswordModalActive = !state.changePasswordModalActive;
    },
    updateResetPasswordEmail: (state, { payload }) => {
      state.resetPasswordEmail = payload;
    },
    updateLogoutModalActive: (state, { payload }) => {
      state.logoutModalActive = payload;
    },
    updateDeleteDuonutConfirmModal: (state, { payload }) => {
      state.deleteDuonutConfirmModal = payload;
    },
    updateDeleteUserConfirmModal: (state, { payload }) => {
      state.deleteUserConfirmModal = payload;
    },
  },
});

export const {
  showSignInForm,
  showSignUpForm,
  openModal,
  closeModal,
  toggleforgotPassword,
  updateEnterCodeEmail,
  toggleEnterCodeModal,
  togglechangePasswordModal,
  updateResetPasswordEmail,
  updateLogoutModalActive,
  updateDeleteDuonutConfirmModal,
  updateDeleteUserConfirmModal,
} = form.actions;
export default form.reducer;
