import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';

// const axios = require("axios");

const getAuthHeaders = () => {
  const token = localStorage.getItem("access");
  let header = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return header;
};

export const creatorAnalyticsSlide = createSlice({
  name: "creatorAnalytics",
  initialState: {
    response: []
  },
  reducers: {
    saveCreatorAnalytics: (state, action) => {
      state.response = [action.payload];
    }
  }
});

export const saveCreatorAnalyticsAsync = (data) => async (dispatch) => {
  try {
    // console.log(data);
    const header = getAuthHeaders();
    const API_URL = `${domain}/duonut/save_creator_analytics`;
    const response = await axios.post(API_URL, data, header);
    // console.log(response);
    dispatch(saveCreatorAnalytics(response?.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { saveCreatorAnalytics } = creatorAnalyticsSlide.actions;
export const showcreatorAnalyticsresponse = (state) => state.creatorAnalytics.response;
export default creatorAnalyticsSlide.reducer;
