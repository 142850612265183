import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function Layers() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [zIndex, setzIndex] = useState(0)
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const zindex = tempElement?.zIndex;
            setzIndex(zindex);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleLayer1 = e => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {

            // Update the zIndex of the clicked element to be the highest and adjust others
            const updatedElements = userActivities[selectedUserActivity].elements.map((element, index) => {
                if (index === selectedComponent.target_id) {
                    return { ...element, zIndex: userActivities[selectedUserActivity]?.elements?.length };
                } else if (element.zIndex > zIndex) {
                    return { ...element, zIndex: element.zIndex - 1 };
                }
                return element;
            });
            dispatch(
                updateUserActivity({
                    elements: updatedElements,
                })
            );

        }
    }

    const handleLayer2 = e => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            if (userActivities[selectedUserActivity]?.elements[selectedComponent.target_id]?.zIndex < userActivities[selectedUserActivity].elements.length) {
                var updatedElements = userActivities[selectedUserActivity].elements.map((element, index) => {
                    if (index === selectedComponent.target_id) {
                        return { ...element, zIndex: element.zIndex + 1 };
                    } else if (element.zIndex === zIndex + 1) {
                        return { ...element, zIndex: element.zIndex - 1 };
                    }
                    return element;
                });

                dispatch(
                    updateUserActivity({
                        elements: updatedElements,
                    })
                );
            }
        }
    }
    const handleLayer3 = e => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            if (userActivities[selectedUserActivity]?.elements[selectedComponent.target_id]?.zIndex > 1) {
                var updatedElements = userActivities[selectedUserActivity].elements.map((element, index) => {
                    if (index === selectedComponent.target_id) {
                        return { ...element, zIndex: element.zIndex - 1 };
                    } else if (element.zIndex === zIndex - 1) {
                        return { ...element, zIndex: element.zIndex + 1 };
                    }
                    return element;
                });

                dispatch(
                    updateUserActivity({
                        elements: updatedElements,
                    })
                );
            }
        }
    }
    const handleLayer4 = e => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            // Update the zIndex of the clicked element to be the highest and adjust others
            const updatedElements = userActivities[selectedUserActivity].elements.map((element, index) => {
                if (index === selectedComponent.target_id) {
                    return { ...element, zIndex: 1 };
                } else if (element.zIndex < zIndex) {
                    return { ...element, zIndex: element.zIndex + 1 };
                }
                return element;
            });
            dispatch(
                updateUserActivity({
                    elements: updatedElements,
                })
            );

        }
    }

    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>Put in order</p>

            <div className='StyleEditorContainer'>
                <div data-tooltip-id="my-tooltip-layer" data-tooltip-content="Bring to front" className='font-styles-editor-icon layerIcon' onClick={handleLayer1} >
                    <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path fill='#6c29ff' d="M2.28,3.47,7.46.16a1,1,0,0,1,1.08,0l5.18,3.31a.64.64,0,0,1,0,1.06L8.54,7.84a1,1,0,0,1-1.08,0L2.28,4.53a.64.64,0,0,1,0-1.06"></path><path d="M13.72,7.68a.6.6,0,0,1,0,1L8.54,11.85a1,1,0,0,1-1.08,0L2.28,8.69a.6.6,0,0,1,0-1l.91-.56L8,10l4.81-2.88Zm0,4a.6.6,0,0,1,0,1L8.54,15.85a1,1,0,0,1-1.08,0L2.28,12.69a.6.6,0,0,1,0-1l.91-.56L8,14l4.81-2.88Z"></path></g></svg>
                </div>
                <div data-tooltip-id="my-tooltip-layer" data-tooltip-content="Bring forward" className='font-styles-editor-icon layerIcon' onClick={handleLayer2} >
                    <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g>
                        <path fill='#6c29ff' d="M2.28,5.47,7.46,2.16a1,1,0,0,1,1.08,0l5.18,3.31a.64.64,0,0,1,0,1.06L8.54,9.84a1,1,0,0,1-1.08,0L2.28,6.53a.64.64,0,0,1,0-1.06"></path><path d="M13.72,9.68l-.91-.56L8,12,3.19,9.12l-.91.56a.6.6,0,0,0,0,1l5.18,3.16a1,1,0,0,0,1.08,0l5.18-3.16a.6.6,0,0,0,0-1"></path></g></svg>
                </div>
                <div data-tooltip-id="my-tooltip-layer" data-tooltip-content="Send backward" className='font-styles-editor-icon layerIcon' onClick={handleLayer3} >
                    <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M2.28,5.47,7.46,2.16a1,1,0,0,1,1.08,0l5.18,3.31a.64.64,0,0,1,0,1.06L8.54,9.84a1,1,0,0,1-1.08,0L2.28,6.53a.64.64,0,0,1,0-1.06"></path>
                        <path fill='#6c29ff' d="M13.72,9.68l-.91-.56L8,12,3.19,9.12l-.91.56a.6.6,0,0,0,0,1l5.18,3.16a1,1,0,0,0,1.08,0l5.18-3.16a.6.6,0,0,0,0-1"></path></g></svg>
                </div>
                <div data-tooltip-id="my-tooltip-layer" data-tooltip-content="Send to back" className='font-styles-editor-icon layerIcon' onClick={handleLayer4} >
                    <svg fill="currentColor" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 16 16" style={{ verticalAlign: "middle" }}><g><path d="M2.28,3.47,7.46.16a1,1,0,0,1,1.08,0l5.18,3.31a.64.64,0,0,1,0,1.06L8.54,7.84a1,1,0,0,1-1.08,0L2.28,4.53A.64.64,0,0,1,2.28,3.47ZM13.72,7.68a.6.6,0,0,1,0,1L8.54,11.85a1,1,0,0,1-1.08,0L2.28,8.69a.6.6,0,0,1,0-1l.91-.56L8,10l4.81-2.88Z"></path>
                        <path fill='#6c29ff' d="M13.72,11.68l-.91-.56L8,14,3.19,11.12l-.91.56a.6.6,0,0,0,0,1l5.18,3.16a1,1,0,0,0,1.08,0l5.18-3.16a.6.6,0,0,0,0-1"></path></g></svg>
                </div>

                <ReactTooltip id="my-tooltip-layer" place="top" effect="solid" />

            </div>
        </div>
    )
} 
