import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import "./style.css";
import { toggleProMessageModalDuonut } from "../../../redux/slices/ProMessageReducer";
import { useNavigate } from 'react-router-dom';
import { toggleDemoModalDuonut } from "../../../redux/slices/DemoReducer";
import { openModal, showSignUpForm, showSignInForm } from "../../../redux/slices/formDetails";
import tickmark from '../../../assets/Images/tickmark.svg';

export default function ProMessage() {
  const dispatch = useDispatch();
  const { active: proMessageModalDuonutActive, message } = useSelector((state) => state.ProMessage.proMessageModalDuonut);
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.user);
  const [selectedBtn, setselectedBtn] = useState(0);

  const handleClick = () => dispatch(openModal());

  const handleClickSignUp = () => {
    if (!isLoggedIn) {
      handleClick();
      dispatch(showSignUpForm());
    } else {
      navigate("/design");
    }

  };

  useEffect(() => {
    // console.log("after login")
    if (selectedBtn === 1) {
      dispatch(toggleProMessageModalDuonut(false));
      navigate('/payment/pro');
      // window.open("https://rzp.io/l/9uSUqpj");
    } else if (selectedBtn === 2) {
      dispatch(toggleProMessageModalDuonut(false));
      navigate('/payment/plus');
      // window.open("https://rzp.io/l/qYlzpZGWFE");
    } else if (selectedBtn === 3) {
      window.open("https://rzp.io/l/OjUDGY06");
    }
    // else if (selectedBtn === 4) {
    //     window.open("tel:" + "9353709306");
    // }
  }, [isLoggedIn]);

  const handleClose = () => {
    dispatch(toggleProMessageModalDuonut(false));
  };


  const buyBtn = (item) => {
    if (!isLoggedIn) {
      handleClick();
      dispatch(showSignInForm());
      setselectedBtn(item);
    } else {
      dispatch(toggleProMessageModalDuonut(false));
      if (item === 1) {
        navigate('/payment/pro');
        // window.open("https://rzp.io/l/9uSUqpj");
      } else if (item === 2) {
        navigate('/payment/plus');
        // window.open("https://rzp.io/l/qYlzpZGWFE");
      } else if (item === 3) {
        window.open("https://rzp.io/l/OjUDGY06");
      }
      // else if (item === 4) {
      //     window.open("tel:" + "9353709306");
      // }
    }
  }

  const handleUpgrade = () => {
    dispatch(toggleProMessageModalDuonut(false));
    navigate("/pricing");
  };
  const handleClickDemo = () => {
    dispatch(toggleDemoModalDuonut(true));
  };

  return (
    <AnimatePresence>
      {proMessageModalDuonutActive && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
          className="modal_container confirm_replace_modal "
          style={{ zIndex: "1001" }}
          onClick={handleClose}
        >
          <motion.div
            initial={{ scale: 0.7 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.7 }}
            transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
            className="modal_basic"
            style={{ padding: "2rem", maxWidth: "85%", width: "85%", overflow: "auto", height: "85vh" }}
            onClick={(e) => e.stopPropagation()}
          >
            <h1 style={{ textAlign: "center" }}>Supercharge things up with Pro</h1>
            <div style={{ textAlign: "center", paddingTop: "1rem" }}>DuoNut is free to use, but if you need to
              <label style={{ color: "#3184C9" }}> {message}</label>, upgrade to Duonut Pro.
            </div>

            <div className='my-container' style={{ marginTop: "2rem" }}>
              <section id="pricing" className="text-center scrollto clearfix ">
                <div className="row rowContainer clearfix" style={{ padding: "0", display: "flex", justifyContent: "center" }}>
                  <div className="pricing-block col-3 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="pricing-block-content">
                      <div className="pricing" style={{ backgroundColor: "rgb(14, 44, 69)", color: "white" }}>
                        <h3>DUONUT PRO</h3>
                        <p className="pricing-sub" style={{ marginBottom: "2rem" }}>For Small Startups</p>
                        <div className="price" style={{ color: "rgb(49, 132, 201)" }}>$49</div>
                        <div>PER MONTH</div>
                        <p style={{ fontWeight: "600", marginBottom: "0.2rem" }}>Billed annually</p>
                        <div>Make your activities your own with your brand</div>
                      </div>
                      <ul>
                        <li><img className="tickmark-icon" src={tickmark} /> Workspaces</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Remove Duonut branding</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Custom HTML & CSS</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Custom userflow logic</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Custom Thank you Page</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Redirect on completion</li>
                        <li><img className="tickmark-icon" src={tickmark} />  Answer piping & more</li>
                      </ul>
                      <a onClick={() => buyBtn(1)} className="button" style={{ width: "80%" }}>BUY TODAY</a>
                    </div>
                  </div>

                </div>
              </section>
            </div>

            <div className="close-icon icon-button" onClick={handleClose}>
              <img src="/assets/icons/close.svg" alt="" />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
