import React, { useEffect, useState } from "react";
import "./LeftSidebar.css";
import { useSelector, useDispatch } from "react-redux";
import Slides from "./Slides";
import ElementsList from "./ElementsList";

const LeftSidebar = () => {
    const dispatch = useDispatch();
    const [selectedTab, setselectedTab] = useState(1);

    const handelSelectTab = (num) => {
        // console.log("tab", num)
        setselectedTab(num);
    }

    return (
        <div className="LeftSidebarMainContainer">
            <div className="LeftSidebarContainer">
                <div className="LeftSidebarTab" onClick={() => handelSelectTab(1)}>Flow
                    {selectedTab === 1 && <div className="selected-line"></div>}
                </div>
                <div className="LeftSidebarTab" onClick={() => handelSelectTab(2)}>Elements
                    {selectedTab === 2 && <div className="selected-line"></div>}
                </div>
            </div>
            {selectedTab === 1 && <Slides />}
            {selectedTab === 2 && <ElementsList />}
        </div>
    );
}

export default LeftSidebar;