import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import BorderColorEditor from './BorderColorEditor/BorderColorEditor';
import { EditText, EditTextarea } from "react-edit-text";

const maxAnswerChar = 2;

export default function Border() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [styles, setStyles] = useState({});
    const [borderWidth, setBorderWidth] = useState(0)
    const [pattern, setPattern] = useState("solid")
    const [color, setColor] = useState("#000000");
    const dispatch = useDispatch()

    useEffect(() => {
        if (styles?.border) {
            // Split the border property into width, pattern, and color
            var parts = styles.border.split(' ');
            // console.log("parts", parts, parts[0].slice(0, -2))
            setBorderWidth(parseInt(parts[0].slice(0, -2))); // First part is the width
            setPattern(parts[1]); // Second part is the pattern
            setColor(parts[2]); // Third part is the color
        } else {
            setBorderWidth(0);
            setPattern("solid");
            setColor("#000000");
        }
    }, [selectedComponent.name, selectedUserActivity, styles, userActivities[selectedUserActivity], selectedComponent.target_id])

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            setStyles(elementAttrib);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);

    const handleBorderwidth = e => {
        setBorderWidth(e.target.value);
        const value = `${e.target.value}px ${pattern} ${color}`;
        // console.log("bordervalue", value)

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    border: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    const handlePatternChange = e => {
        const value = `${borderWidth}px ${e.target.value} ${color}`;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    border: value,
                },
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    return (
        <div className='EditorRowContainer'>
            <p style={{ marginRight: "1rem" }}>{selectedComponent.type === "FlipCard" ? "Front Border" : "Border"}</p>

            <div style={{ marginRight: "1rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <input type="number" min="0" value={borderWidth} id='borderWidth'
                    style={{ border: "1px solid #11111333", borderRadius: "5px", textAlign: "center", width: "2.5rem", height: "1.8rem", padding: "0" }}
                    onChange={(e) => (e.target.value.length > maxAnswerChar ? null : handleBorderwidth(e))} />
                <label style={{ marginLeft: "5px" }}>px</label>
            </div>

            <select value={pattern} className='text_editor_dropdown' style={{ width: "80px" }}
                onChange={e => handlePatternChange(e)} >
                {Patterns.map(pattern =>
                    <option key={pattern} value={pattern} > {pattern} </option>
                )}
            </select>

            <BorderColorEditor />
            {/* <img src='/assets/icons/down-arrow.svg' className='select-icon' /> */}
        </div>
    )
}

const Patterns = [
    'solid',
    'dashed',
    'dotted'
]
