import React, { useState, useEffect } from 'react';
import './Element.css';
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';

const RadioGroup = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, other, gap,
        labelFontSize, labelFontWeight, labelColor,
    },
    elementHoverAttr,
    conditions,
}) => {
    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, elements, selectedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name } = useSelector((state) => state.duonut);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [otherText, setOtherText] = useState('');
    const [isOther, setisOther] = useState(false);
    const [hover, setHover] = useState(-1);

    useEffect(() => {
        setisOther(false);
    }, []);

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)

            if (item === "other") {
                setisOther(true);
            } else {
                setisOther(false);
            }

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: item,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "RadioGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const submitTextResponse = (item) => {
        if (location.pathname.includes("/duonut/")) {
            // console.log("ans", item)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: item + " - " + otherText,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                // url_params: props.details.queryParamsObject
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "RadioGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item + " - " + otherText,
            }
            dispatch(saveQuizResponse(save_response));
        }
    }

    const handleOtherInputChange = (e) => {
        if (location.pathname.includes("/duonut/")) {
            setOtherText(e.target.value);
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }

    return (
        <div style={{
            display: "flex", flexDirection: "column", gap: gap
        }}>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing
            }}>{text}</div>
            <div>
                {options.map((item, i) => {
                    const radioId = `radio_option_${slideId}_${i}`;
                    return <div style={{
                        display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight
                    }} key={i} >
                        <input type="radio" id={radioId} name={`radio_option_${slideId}`}
                            disabled={location.pathname.includes("/duonut/") ? false : true}
                            style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px" }}
                            onChange={() => submitResponse(item)} />
                        <label for={radioId} style={{
                            backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                            color: hover === i ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin,
                            cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%"
                        }}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(-1)}>
                            <div style={{}}>{item}</div>
                        </label>
                    </div>
                })}
                {other && <div style={{
                    display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight
                }} >
                    <input type="radio" id={"radio_option_other" + slideId} name="radio_option"
                        disabled={location.pathname.includes("/duonut/") ? false : true}
                        style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px" }}
                        onChange={() => submitResponse("other")} />
                    <label for={"radio_option_other" + slideId} style={{
                        backgroundColor: hover === options.length ? elementHoverAttr?.backgroundColor : backgroundColor,
                        border: hover === options.length ? elementHoverAttr?.border : border ? border : "0",
                        color: hover === options.length ? elementHoverAttr?.color : color,
                        borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                        textTransform, textAlign, letterSpacing, padding, margin,
                        cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                        width: "100%", height: "100%"
                    }}
                        onMouseEnter={() => setHover(options.length)}
                        onMouseLeave={() => setHover(-1)} >
                        <div style={{}}>Other</div>
                    </label>
                    {(location.pathname.includes("/duonut/") && isOther) && <input type='text' style={{
                        minWidth: "2rem", marginLeft: "6px", padding: "10px", width: "100%",
                        color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing,
                        backgroundColor, borderBottom: "1px solid gray", borderRadius,
                    }}
                        onChange={(e) => handleOtherInputChange(e)}
                        onBlur={() => {
                            submitTextResponse("other");
                        }}
                        readOnly={(location.pathname.includes("/duonut/") && isOther) ? false : true}
                    />}
                </div>}
            </div>
        </div>
    );
}

export default RadioGroup;
