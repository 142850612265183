import React, { useRef, useEffect } from 'react';
import './CorrectModel.css';
import correctans from '../../assets/audio/Correct-answer.mp3';
import ConfettiExplosion from 'react-confetti-explosion';

const CorrectModel = (props) => {
    // console.log(props.bgcolor);
    const openref1 = useRef(null);
    const closeref1 = useRef(null);
    const audioRef = useRef(null);
    const openref2 = useRef(null);
    const closeref2 = useRef(null);
    const openref3 = useRef(null);
    const closeref3 = useRef(null);
    const openref4 = useRef(null);
    const closeref4 = useRef(null);

    const randNo = Math.floor(Math.random() * 4) + 1;
    // const randNo = 4;

    const source = {
        position: "absolute",
        right: "50%",
        left: "50%",
        top: "-150px"
    };
    const bigExplodeProps = {
        force: 0.6,
        duration: 3000,
        particleCount: 200,
        floorHeight: 1600,
        floorWidth: 1600
    };

    const littleExplodeProps = {
        force: 0.4,
        duration: 3000,
        particleCount: 60,
        floorHeight: 1000,
        floorWidth: 1000
    };

    const tinyExplodeProps = {
        force: 0.4,
        duration: 2000,
        particleCount: 30,
        floorHeight: 500,
        floorWidth: 300
    };

    useEffect(() => {
        audioRef.current.play();
        audioRef.current.volume = 0.15;
        setTimeout(() => {
            if (randNo === 1) {
                openref1.current.click();
            } else if (randNo === 2) {
                openref2.current.click();
            } else if (randNo === 3) {
                openref3.current.click();
            } else if (randNo === 4) {
                openref4.current.click();
            } else {
                openref1.current.click();
            }
        }, 100); //miliseconds

        setTimeout(() => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            if (randNo === 1) {
                closeref1.current.click();
            } else if (randNo === 2) {
                closeref2.current.click();
            } else if (randNo === 3) {
                closeref3.current.click();
            } else if (randNo === 4) {
                closeref4.current.click();
            } else {
                closeref1.current.click();
            }
        }, 3000); //miliseconds
    }, []);

    return (
        <>
            <audio
                src={correctans}
                ref={audioRef}
            ></audio>
            <button ref={openref1} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#correctModal1">
            </button>
            <div className="modal fade" id="correctModal1" tabIndex="-1" aria-labelledby="correctModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M1modalBody">
                        <div className="modal-body">
                            <button ref={closeref1} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <div style={source}>
                                <ConfettiExplosion {...littleExplodeProps} />
                            </div>
                            <img className='M1correctimg' src={require('../../assets/Images/correct.gif')} alt="nice" />
                            <div className='M1text'>
                                <div className='M1cong'> Congratulations!</div>
                                {props.message ? <div className='M1msg'>{props.message}</div>
                                    : <div className='M1msg'>Your Answer is correct.</div>}
                            </div>
                            {/* <br></br>
                            <br></br>
                            <div className='M1explain'>Want to see explanation?</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <button ref={openref2} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#correctModal2">
            </button>
            <div className="modal fade" id="correctModal2" tabIndex="-1" aria-labelledby="correctModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M1modalBody2">
                        <div className="modal-body">
                            <button ref={closeref2} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <div className='M1text2'>
                                <div style={source}>
                                    <ConfettiExplosion {...littleExplodeProps} />
                                </div>
                                <img className='M1correctimg2' src={require('../../assets/Images/correct.gif')} alt="nice" />
                                <div className='M1cong2'> Congratulations!</div>
                                {/* <div className='M1msg2'>Your Answer is correct.</div> */}
                                {props.message ? <div className='M1msg2'>{props.message}</div>
                                    : <div className='M1msg2'>Your Answer is correct.</div>}
                            </div>
                            {/* <br></br>
                            <br></br>
                            <div className='M1explain'>Want to see explanation?</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <button ref={openref3} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#correctModal3">
            </button>
            <div className="modal fade" id="correctModal3" tabIndex="-1" aria-labelledby="correctModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M1modalBody3">
                        <div className="modal-body">
                            <button ref={closeref3} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <div className='M1text2'>
                                <div style={source}>
                                    <ConfettiExplosion {...littleExplodeProps} />
                                </div>
                                <img className='M1correctimg2' src={require('../../assets/Images/correct.gif')} alt="nice" />
                                <div className='M1cong3'> PERFECT!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button ref={openref4} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#correctModal4">
            </button>
            <div className="modal fade" id="correctModal4" tabIndex="-1" aria-labelledby="correctModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content M1modalBody4">
                        <div className="modal-body">
                            <button ref={closeref4} style={{ display: "none" }} type="button" className="btn btn-secondary" data-backdrop="false" data-bs-dismiss="modal">Close</button>
                            <div className='M1text4'>
                                <div style={source}>
                                    <ConfettiExplosion {...littleExplodeProps} />
                                </div>
                                <img className='M1correctimg4' src={require('../../assets/Images/Badge.png')} alt="nice" />
                                {/* <div className='M1cong4'>Congratulations! Your Answer is correct.</div> */}
                                {props.message ? <div className='M1cong4'>Congratulations! {props.message}</div>
                                    : <div className='M1cong4'>Congratulations! Your Answer is correct.</div>}
                            </div>
                            {/* <br></br>
                            <br></br>
                            <div className='M1explain'>Want to see explanation?</div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

export default CorrectModel;