import React, { useState, useEffect } from 'react';
import './AiStartPage.css';
import { domain } from "../../services/constants";
import axios from "axios";
import { openModal, showSignUpForm, showSignInForm } from "../../redux/slices/formDetails";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { saveAiAsync, showAiresponse, createAiAsync } from "../../redux/slices/AiReducer";
import { saveAiDuonutAsync, showAiDuonutresponse, createAiDuonutAsync } from "../../redux/slices/AiDuonutReducer";
import { toggleAiModal, updateAiType } from "../../redux/slices/AiSettingReducer";
import AiSetting from "../AiData/AiSetting/AiSetting";

const AiStartPage = () => {
    const { isLoggedIn, userAccountType, userProAccess } = useSelector((state) => state.user);
    const { userActivities, selectedUserActivity } = useSelector((state) => state.userActivity);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [gptQuestionInput, setgptQuestionInput] = useState('');
    const [errMess, seterrMess] = useState("");
    const [selectedPage, setselectedPage] = useState(0);
    const [websiteData, setwebsiteData] = useState('');
    const [websiteDataText, setwebsiteDataText] = useState('');
    const [websiteDataQuestionsText, setwebsiteDataQuestionsText] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(true); // State to toggle sidebar

    useEffect(() => {
        if (!isLoggedIn) {
            setselectedPage(1);
        } else {
            dispatch(saveAiAsync());
        }
    }, [isLoggedIn]);
    const aiData = useSelector(showAiresponse);
    const aiData1 = aiData[0];

    useEffect(() => {
        if (aiData1) {
            if (aiData1.empty_message) {
                setselectedPage(1);
            } else if (!userProAccess) {
                navigate('/aidata');
            } else {
                if (userProAccess && userAccountType === "pro" && aiData1.length >= 2) {
                    navigate('/aidata');
                } else if (userProAccess && userAccountType === "plus" && aiData1.length >= 5) {
                    navigate('/aidata');
                } else if (userAccountType === "free") {
                    navigate('/aidata');
                } else {
                    setselectedPage(1);
                }
            }
        }
    }, [aiData1])


    const handleGPTQuestionChange = (event) => {
        setgptQuestionInput(event.target.value);
        // console.log(event);
        // console.log(event.target.value);
        if (event.keyCode === 13) {
            document.getElementById("gptBtn").click();
        }
    };

    const handleClick = () => dispatch(openModal());

    const handleGPTSubmit = () => {

        if (!isLoggedIn) {
            handleClick();
            dispatch(showSignInForm());
            return null;
        }

        // Regular expression to validate URL
        const urlRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;

        if (urlRegex.test(gptQuestionInput)) {
            console.log('URL is valid:', gptQuestionInput);
        } else {
            seterrMess("Enter valid URL");
            setTimeout(() => {
                seterrMess("");
            }, 3000); //miliseconds
            return null;
        }

        setselectedPage(2);

        // return null;

        axios({
            method: "GET",
            url: "https://r.jina.ai/" + gptQuestionInput,
            headers: {
                "Authorization": "Bearer jina_31eefa5028044206b1be8a3711a25b6cG7jW1JF2sLvL7Vko44A6DRMnt53Y",
                "X-With-Links-Summary": "true",
                "X-No-Cache": "true",
            },
        }).then((res) => {
            console.log(res);
            if (res?.status === 200) {
                console.log(res.data);
                var jinaResponse = res.data;

                setselectedPage(3);

                const prompt = `
               Provide the following information in JSON format:
                - company_name
                - category
                - summary
                - products_services, including:
                - name
                - features
                - benefits
                - unique_selling_points

                Use the following data as the input:
                ${res.data}
                `;

                const payload = {
                    messages: [{ "role": "user", "content": prompt }],
                    max_tokens: 2000,
                    temperature: 0.7,
                    n: 1,
                    model: "gpt-4o",
                }

                axios({
                    method: "POST",
                    url: "https://api.openai.com/v1/chat/completions",
                    data: payload,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer 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"
                    }
                }).then((res) => {
                    console.log(res);
                    if (res?.status === 200) {
                        console.log(res.data.choices[0]?.message.content);
                        var text = res.data.choices[0]?.message.content;
                        try {
                            const trimText = text.replace(/^[\s\S]*?```json/, '').replace(/```/g, '').trim()

                            setwebsiteDataText(trimText);

                            const structuredData = JSON.parse(trimText);
                            console.log(structuredData);
                            // Convert all keys to lowercase
                            const structuredDataLowercaseKeys = Object.fromEntries(
                                Object.entries(structuredData).map(([key, value]) => [key.toLowerCase(), value])
                            );

                            // Check if 'category' is present and accessible
                            const category = structuredData?.category ? structuredData?.category : structuredData?.Category;
                            console.log('Category:', category, structuredDataLowercaseKeys); // Debugging output

                            const prompt1 = `
                            By looking at the category given, reply whether a given company is B2B, B2C, B2B2C, subscription based etc. 
                            category is ${category}. Give short answer with full form.
                            `
                            const payload1 = {
                                messages: [{ "role": "user", "content": prompt1 }],
                                max_tokens: 2000,
                                temperature: 0.7,
                                n: 1,
                                model: "gpt-4o",
                            }

                            axios({
                                method: "POST",
                                url: "https://api.openai.com/v1/chat/completions",
                                data: payload1,
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization:
                                        "Bearer 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"
                                }
                            }).then((res) => {
                                console.log(res);
                                if (res?.status === 200) {
                                    console.log(res.data.choices[0]?.message.content);
                                    var text = res.data.choices[0]?.message.content;
                                    // Replace the category in the data object
                                    structuredDataLowercaseKeys.category = text;
                                    setwebsiteData(structuredDataLowercaseKeys);

                                    var ai_Data = {
                                        websiteUrl: gptQuestionInput,
                                        websiteDataText: jinaResponse,
                                        websiteData: structuredDataLowercaseKeys,
                                    }
                                    dispatch(createAiAsync(ai_Data)).then((res) => {
                                        console.log(res);
                                        handleGenerateQuestion(res?.created_object?.id, trimText);

                                    }).catch((e) => {
                                        console.log(e.message, e);
                                    });
                                }
                            }).catch((e) => {
                                console.log(e.message, e);
                            });


                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    };

    const handleGenerateQuestion = (aidataId, trimText) => {
        setselectedPage(4);

        var newQuestion = `${trimText} create 10 NPS questions from this summary, for which customer can rate from 1 to 5`;

        var payload = {
            messages: [{ "role": "user", "content": newQuestion }],
            max_tokens: 2000,
            temperature: 0.7,
            n: 1,
            model: "gpt-3.5-turbo",
        }

        axios({
            method: "POST",
            url: "https://api.openai.com/v1/chat/completions",
            data: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer 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"
            }
        }).then((res) => {
            console.log(res);
            if (res?.status === 200) {
                console.log(res.data.choices[0]?.message.content);
                var formatet_text = formatTextToHtml(res.data.choices[0]?.message.content);
                console.log(formatet_text);

                setwebsiteDataQuestionsText(formatet_text);
                convertInJson(res.data.choices[0]?.message?.content, aidataId);
            }
        }).catch((e) => {
            console.log(e.message, e);
        });

    }

    const formatTextToHtml = (text) => {
        return text
            .replace(/\n/g, '<br>')
            .replace(/(\d+\.)/g, '<p>$1')
            .replace(/(a\.|b\.|c\.|d\.|e\.)/g, '<li>$1'); // Optional: Convert options to list items
    };


    const convertInJson = (res, aidataId) => {
        // console.log(res, gptQuestionInput, selectedCategory);
        let jsonData = [];

        // console.log("nps survey");
        let splitData = res.split(/\n\n\d+\.\s|\n\d+\.\s/).filter(element => element);
        // console.log(splitData);
        splitData.map((item, i) => {
            let splitData1 = item.split("\n").filter(element => element);
            // Remove the number (e.g., "1.") from the start of each item
            splitData1 = splitData1.map(e => e.replace(/^\d+\.\s/, ''));
            jsonData.push(splitData1);
        })

        console.log(jsonData);

        var ai_Duonut_Data = {
            question_data: jsonData,
            aiData_id: aidataId,
        }
        dispatch(createAiDuonutAsync(ai_Duonut_Data)).then((res) => {
            // console.log(res);
            if (res.status === 201) {
                navigate('/aidata');
            }

        }).catch((e) => {
            console.log(e.message, e);
        });

    };


    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    return (
        <div className='ASP1App'>
            <div className='ASP1template column'>
                {/* <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'} col-md-2 sidebar-content`}>
                    <div className=''>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            {sidebarOpen ?
                                <img src="/assets/icons/duonut_logo.png" className="sidebar-logo" alt="Logo" />
                                : <img src="/assets/icons/duonut_icon.png" className="sidebar-logo" alt="Logo" style={{ width: "40px", heigth: "40px" }} />
                            }
                            {sidebarOpen ?
                                <button className='toggle-icon' onClick={toggleSidebar} aria-hidden="true"><i className="fa fa-angle-left" style={{ color: 'rgb(27, 75, 157)' }}></i></button>
                                : <button className='toggle-icon' onClick={toggleSidebar} aria-hidden="true"><i className="fa fa-angle-right" style={{ color: 'rgb(27, 75, 157)' }}></i></button>
                            }
                        </div>
                        <div className='sidebar-icons'>
                            {sidebarOpen ?
                                <div>
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'red' }}></i>
                                    <p style={{ fontSize: "14px" }}><span style={{ fontWeight: "600" }}>Upgrade now</span><br />
                                        You've reached your monthly response limit.<br />
                                        Upgrade now to unlock more conversations and new features.</p>
                                </div>
                                :
                                <i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: 'rgb(27, 75, 157)' }}></i>
                            }
                            <i className="fa fa-user-circle" aria-hidden="true" style={{ color: 'rgb(27, 75, 157)' }}></i>
                        </div>
                    </div>
                </div> */}
                <div className='ASP1container'>
                    {selectedPage === 0 && <div style={{ position: "absolute", display: "flex", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
                    </div>}
                    {selectedPage === 1 && <div className=''>
                        <img style={{ width: "3rem", height: "3rem", borderRadius: '3rem' }}
                            src={"/assets/duonut_icon.png"} alt="duonut" loading="lazy" /><br /><br />
                        <h3>Duonut Interactive Surveys</h3>
                        <h5 style={{ color: "#3498db" }}>Powered by Duonut AI</h5>
                        <div style={{
                            margin: "2rem",
                            lineHeight: "1.2rem",
                            width: "30rem",
                            fontSize: "14px",
                            fontWeight: "300"
                        }}>
                            Enter your website url below and Duonut AI will generate a summary about your company.This summary will be used to generate questions to ask your customers
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <input
                                id="GPT-input_box"
                                className='Ai-input_box'
                                type="text"
                                placeholder="Enter your company website to get started..."
                                autoFocus
                                onKeyUp={e => handleGPTQuestionChange(e)}
                            />
                            <button type='submit' id="gptBtn"
                                style={{ backgroundColor: "transparent", border: "1px solid rgba(149, 149, 161, 0.2)", borderRadius: "0px 5px 5px 0px", cursor: "pointer" }}
                                onClick={() => handleGPTSubmit()}>
                                {/* <i class="fa fa-send-o" style={{ fontSize: "20px" }} aria-hidden="true"></i> */}
                                <img src="/assets/icons/sendButton.svg" style={{ width: "2.3rem" }} alt="duonut" loading="lazy" />
                            </button>
                            {/* <button type='submit' id="gptBtn" className='GPT-submitBtn' onClick={() => handleGPTSubmit()}>Generate</button> */}
                        </div>
                        {errMess && <div style={{ color: "red", padding: "5px" }}>{errMess}</div>}

                        {aiData1 && aiData1?.length >= 1 && <div style={{ marginTop: "1rem" }}>Already have Company Details? <a href="/aidata">Click here</a></div>}

                    </div>}


                    {selectedPage === 2 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "10rem" }} />
                        <div>Duonut AI is learning about your company to become an expert on your products.</div>
                    </div>}
                    {selectedPage === 3 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "10rem" }} />
                        <div>Duonut AI will suggest questions for your customers, and you can also add your own and send NPS surveys to your users.</div>
                    </div>}
                    {selectedPage === 4 && <div style={{ position: "absolute", display: "flex", flexDirection: "column", justifyContent: "center", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                        <img alt="loader" src={require(`../../assets/Images/DuonutAnimation.gif`)} style={{ margin: "0 auto", height: "10rem" }} />
                        <div>Duonut AI will suggest questions for your customers, and you can also add your own and send NPS surveys to your users.</div>
                    </div>}
                </div>

            </div>
        </div>
    );

}

export default AiStartPage;